import cn from "classnames";
import classNames from "classnames";
import React, { useState } from "react";
import { IAvailableDaycampScheduleSet } from "src/services/api";
import { IAvailableOvernightScheduleSet } from "src/services/api/availability";
import formatStartEndDate from "src/util/formatStartEndDate";
import { Button, ExpandableRow } from "@sizdevteam1/funjoiner-uikit";
import ScheduleSetDescriptionModal from "../ScheduleSetDescriptionModal/ScheduleSetDescriptionModal";
import dayjs from "dayjs";

import { twMerge } from "tailwind-merge";

interface IProps {
  programCards: React.ReactNode[];
  set: IAvailableDaycampScheduleSet | IAvailableOvernightScheduleSet;
  isOpen: boolean;
  setOpenedId: (id: string | null) => void;
  isNavigateToScheduleButtonInDescriptionModalVisible: boolean;
}

const ScheduleSetCard: React.FC<IProps> = ({
  programCards,
  set,
  isOpen,
  setOpenedId,
  isNavigateToScheduleButtonInDescriptionModalVisible,
}) => {
  const [isDescriptionModalOpen, setIsDescriptionModalOpen] = useState(false);

  return (
    <div>
      <ExpandableRow
        className={classNames("w-full  !duration-500 ")}
        isOpen={isOpen}
        children={
          <div
            onClick={() => setOpenedId(!isOpen ? set.id : null)}
            className={cn(
              "flex cursor-pointer  items-start justify-between gap-[10px] rounded-[10px] bg-card-color p-4 shadow-big"
            )}
          >
            <div
              className={cn(
                "typography-h3  overflow-hidden text-ellipsis  text-header-color"
              )}
            >
              {set.name}
              {set.selected_description && (
                <div
                  className={twMerge(
                    "grid  translate-y-5 scale-0 grid-rows-[0px] overflow-hidden    transition-all duration-500  ease-in-out",
                    isOpen && "scale-1 translate-y-0 scale-100 grid-rows-[24px]"
                  )}
                >
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsDescriptionModalOpen(true);
                    }}
                    kind="text"
                    className={classNames(
                      "!typography-label mt-[6px] justify-self-start whitespace-nowrap"
                    )}
                  >
                    <i className="icon doc-icon" />
                    Schedule Description
                  </Button>
                </div>
              )}
            </div>
            <div
              className={
                "flex items-center justify-between gap-1 whitespace-nowrap text-gray-text-color"
              }
            >
              <div className={"text-small typography-small__t"}>
                {formatStartEndDate(set)}
              </div>
              <i
                className={cn(
                  "icon chevron-down-icon icon-hover transition-all duration-[400ms] ease-in-out",
                  isOpen && "rotate-180"
                )}
              />
            </div>
          </div>
        }
        expand={
          <div
            className={cn(
              "mx-[10px] mt-2   mb-4  flex h-min flex-col gap-[10px]"
            )}
          >
            {programCards}
          </div>
        }
      />
      {isDescriptionModalOpen && (
        <ScheduleSetDescriptionModal
          descriptionData={{
            description: set.selected_description,
            location_id: set.location_id,
            funbox_mode: set.funbox_mode,
            funbox_id: set.funbox_id,
            schedule_set_id: set.id,
            set_start_date: set.start_date,
            set_end_date: set.end_date,
            first_nearby_session:
              set.funbox_mode === "SESSIONS"
                ? set.programs.map((p) =>
                    p.sessions.find(
                      (s) =>
                        dayjs(s.date).isSame(dayjs().format("YYYY-MM-DD")) ||
                        dayjs(s.date).isAfter(dayjs().format("YYYY-MM-DD"))
                    )
                  )[0]
                : undefined,
          }}
          onClose={() => setIsDescriptionModalOpen(false)}
          isNavigateToScheduleButtonVisible={
            isNavigateToScheduleButtonInDescriptionModalVisible
          }
        />
      )}
    </div>
  );
};

export default ScheduleSetCard;
