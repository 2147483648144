import React from "react";
import { observer } from "mobx-react-lite";
import {
  CompleteCustomerProfilePageVmProvider,
  CustomerAsParticipantFormVm,
  CustomerFormVm,
  EmergencyContactVm,
  useCompleteCustomerProfilePageVm,
} from "./CompleteCustomerProfilePageVm";
import { defineRoute } from "../../../util/typedRouting";
import PageHeader from "../../../components/PageHeader";
import cn from "classnames";
import PageRoot from "src/components/PageRoot";
import {
  Avatar,
  Button,
  DatePicker,
  Input,
  Label,
  PhoneInput,
  Select,
  SingleFileUploader,
  Textarea,
  toOrdinal,
} from "@sizdevteam1/funjoiner-uikit";
import { FormField } from "../../../models/formField";
import exists from "@sizdevteam1/funjoiner-uikit/util/exists";
import { computed } from "mobx";
import dayjs from "dayjs";
import GenderPickerVm from "../../../models/GenderPickerVm";
import PronounPickerVm from "../../../models/PronounPickerVm";
import { IStudentDTO } from "../../../services/api";

export const completeCustomerProfilePageRoute = defineRoute<{
  state: {
    action: "submit" | "join_waitlist" | "apply_to_program";
    program_id?: string;
    student_ids?: number[];
  };
}>({
  path: "/schedule/complete-customer-profile",
  build: (path) => path,
});

interface IProps {
  selectedStudents: IStudentDTO[];
}

export const CompleteCustomerProfilePage: React.FC<IProps> = observer(
  (props) => {
    return (
      <CompleteCustomerProfilePageVmProvider>
        <CompleteCustomerProfilePageImpl {...props} />
      </CompleteCustomerProfilePageVmProvider>
    );
  }
);

const CompleteCustomerProfilePageImpl: React.FC<IProps> = observer((props) => {
  const vm = useCompleteCustomerProfilePageVm();
  return (
    <PageRoot>
      <PageHeader
        showBackLink
        className={cn("typography-h1  text-header-color")}
      >
        Complete Profile
      </PageHeader>
      <div className="mb-6 flex flex-col gap-6">
        <div className={cn("text-main  text-gray-text-color")}>
          Fill out the information below to schedule:
        </div>
        {vm.formVm instanceof CustomerFormVm ? (
          <CustomerLayout vm={vm.formVm} />
        ) : (
          <CustomerAsParticipantLayout vm={vm.formVm} />
        )}
        <Button
          className="mb-3 mt-auto"
          size={"big"}
          onClick={vm.submit}
          autoLoading
        >
          Save Information
        </Button>
      </div>
    </PageRoot>
  );
});
const CustomerLayout = observer(({ vm }: { vm: CustomerFormVm }) => {
  const fields = [
    vm.firstName && <FirstName field={vm.firstName} />,
    vm.lastName && <LastName field={vm.lastName} />,
    vm.phoneNumber && <PhoneNumber field={vm.phoneNumber} />,
    vm.email && <Email field={vm.email} />,
    vm.address && <Address field={vm.address} />,
  ].filter(exists);

  return (
    <div className={"flex flex-col gap-6"}>
      {...fields}
      {vm.emergencyContacts && (
        <>
          {fields.length > 0 && (
            <div className="h-[1px] w-full bg-separator-color" />
          )}
          <EmergencyContacts contacts={vm.emergencyContacts} />
        </>
      )}
      {vm.participantsToComplete.map(
        ({ participant, genderVm, pronounVm }, index) => (
          <>
            {index !== 0 && <div className={"h-[1px] bg-separator-color"} />}
            <div
              className={
                "typography-h3 flex items-center gap-2 text-text-color"
              }
            >
              <Avatar person={participant} avatarSize={"32px"} />
              {participant.full_name}
            </div>
            {genderVm && (
              <GenderPicker isCustomerAsParticipant={false} vm={genderVm} />
            )}
            {pronounVm && (
              <PronounPicker isCustomerAsParticipant={false} vm={pronounVm} />
            )}
          </>
        )
      )}
    </div>
  );
});

const CustomerAsParticipantLayout = observer(
  ({ vm }: { vm: CustomerAsParticipantFormVm }) => {
    const customerFields = [
      vm.firstName && <FirstName field={vm.firstName} />,
      vm.lastName && <LastName field={vm.lastName} />,
      vm.phoneNumber && <PhoneNumber field={vm.phoneNumber} />,
      vm.email && <Email field={vm.email} />,
      vm.address && <Address field={vm.address} />,
    ].filter(exists);

    return (
      <div className={"flex flex-col gap-6"}>
        <SingleFileUploader
          crop={true}
          cropAspect={1}
          defaultSourceUrl={vm.updatePhoto.sourceUrl}
          onSelect={vm.updatePhoto.setNewImage}
          placeholder={"Add Photo"}
          mobile={true}
          loading={vm.updatePhoto.isUploading}
        />
        {...customerFields}
        {customerFields.length > 0 && (
          <div className="h-[1px] w-full bg-separator-color" />
        )}
        <DateOfBirth field={vm.dateOfBirth} />
        <MedicalConditions field={vm.medicalConditions} />
        <Allergies field={vm.allergies} />
        <Notes field={vm.notes} />
        {vm.pronounPicker && (
          <PronounPicker isCustomerAsParticipant={true} vm={vm.pronounPicker} />
        )}
        {vm.genderPicker && (
          <GenderPicker isCustomerAsParticipant={true} vm={vm.genderPicker} />
        )}
      </div>
    );
  }
);

const FirstName = observer(({ field }: { field: FormField<string> }) => {
  return (
    <div>
      <Label>Your First Name</Label>
      <Input
        autoComplete={"given-name"}
        name={"first_name"}
        value={field.value}
        onChange={(e) => field.set(e.currentTarget.value)}
        errorText={field.error}
      />
    </div>
  );
});

const LastName = observer(({ field }: { field: FormField<string> }) => {
  return (
    <div>
      <Label>Your Last Name</Label>
      <Input
        autoComplete={"family-name"}
        name={"last_name"}
        value={field.value}
        onChange={(e) => field.set(e.currentTarget.value)}
        errorText={field.error}
      />
    </div>
  );
});

const PhoneNumber = observer(({ field }: { field: FormField<string> }) => {
  return (
    <div>
      <Label>Your Phone Number</Label>
      <PhoneInput
        placeholder={"(222) 222-2222"}
        defaultCountry="US"
        value={field.value}
        onChange={(val) => field.set(val ?? "")}
        errorText={field.error}
      />
    </div>
  );
});

const Email = observer(({ field }: { field: FormField<string> }) => {
  return (
    <div>
      <Label>Your Email</Label>
      <Input
        autoComplete={"email"}
        placeholder={"example@example.com"}
        value={field.value}
        onChange={(v) => field.set(v.currentTarget.value)}
        errorText={field.error}
      />
    </div>
  );
});

const Address = observer(({ field }: { field: FormField<string> }) => {
  return (
    <div>
      <Label>Your Address</Label>
      <Input
        autoComplete={"street-address"}
        value={field.value}
        onChange={(v) => field.set(v.currentTarget.value)}
        errorText={field.error}
      />
    </div>
  );
});

const EmergencyContacts = observer(
  ({ contacts }: { contacts: EmergencyContactVm[] }) => {
    return (
      <div className={"flex flex-col gap-6"}>
        <div className={"flex flex-col gap-3"}>
          <div className={"typography-h2 text-header-color"}>
            Emergency Contacts
          </div>
          <div className={"typography-main text-gray-text-color"}>
            We need your information to know who to contact in case of emergency
          </div>
        </div>
        {contacts.map((contact, index, arr) => (
          <>
            <div key={index} className="flex flex-col gap-4">
              <div>
                <Label>
                  {arr.length > 1 ? toOrdinal(index + 1) : ""} Contact Full Name
                </Label>
                <Input
                  value={contact.name.value}
                  onChange={(e) => contact.name.set(e.currentTarget.value)}
                  errorText={contact.name.error}
                />
              </div>
              <div>
                <Label>
                  {arr.length > 1 ? toOrdinal(index + 1) : ""} Contact Phone
                  Number
                </Label>
                <PhoneInput
                  value={contact.phoneNumber.value}
                  onChange={contact.phoneNumber.set}
                  defaultCountry="US"
                  errorText={contact.phoneNumber.error}
                />
              </div>
            </div>
            {index < arr.length - 1 && (
              <div className="mx-3 box-content h-[1px] bg-separator-color" />
            )}
          </>
        ))}
      </div>
    );
  }
);

const DateOfBirth = observer(
  ({ field }: { field: FormField<string | undefined> }) => {
    const defaultDate = computed(() => {
      return field.value
        ? dayjs(field.value).toISOString()
        : dayjs().subtract(20, "year").toISOString();
    });

    return (
      <div>
        <Label>Date of Birth</Label>
        <DatePicker
          value={field.value}
          onSelect={field.set}
          defaultDate={defaultDate.get()}
          errorText={field.error}
        />
      </div>
    );
  }
);

const PronounPicker = observer(
  ({
    vm,
    isCustomerAsParticipant,
  }: {
    vm: PronounPickerVm;
    isCustomerAsParticipant: boolean;
  }) => {
    return (
      <div>
        <Label>
          {isCustomerAsParticipant ? "Your" : "Participant's"} Pronoun(s)
        </Label>
        <Select
          options={vm.availableOptions}
          selected={vm.selectedOption}
          onSelect={(val) => vm.field.set(val.id)}
          errorText={vm.field.error ?? undefined}
        />
      </div>
    );
  }
);

const GenderPicker = observer(
  ({
    vm,
    isCustomerAsParticipant,
  }: {
    vm: GenderPickerVm;
    isCustomerAsParticipant: boolean;
  }) => {
    return (
      <div>
        <Label>
          {isCustomerAsParticipant ? "Your" : "Participant's"} Gender
        </Label>
        <Select
          options={vm.availableOptions}
          selected={vm.selectedOption}
          onSelect={vm.selectOption}
          errorText={vm.field.error ?? undefined}
        />
      </div>
    );
  }
);
const MedicalConditions = observer(
  ({ field }: { field: FormField<string> }) => {
    return (
      <div>
        <Label>
          Do you have any medical conditions? <br></br>
          <span className="!text-placeholder-color">(Leave blank if not)</span>
        </Label>
        <Input
          name={"medical_considerations"}
          value={field.value}
          onChange={(e) => field.set(e.currentTarget.value)}
          errorText={field.error}
          placeholder={"e. g. Headaches, knee pain, etc. "}
        />
      </div>
    );
  }
);

const Allergies = observer(({ field }: { field: FormField<string> }) => {
  return (
    <div>
      <Label>
        Do you have any allergies?{" "}
        <span className="!text-placeholder-color">(Leave blank if not)</span>
      </Label>
      <Input
        name={"allergies"}
        value={field.value}
        onChange={(e) => field.set(e.currentTarget.value)}
        errorText={field.error}
        placeholder={"e. g. Tomato, broccoli, etc. "}
      />
    </div>
  );
});

const Notes = observer(({ field }: { field: FormField<string> }) => {
  return (
    <div>
      <Label>Notes</Label>
      <Textarea
        textareaClassName="resize-y min-h-[90px]"
        value={field.value}
        onChange={(e) => field.set(e.currentTarget.value)}
        errorText={field.error}
        placeholder={"Any additional info we should know"}
      />
    </div>
  );
});
