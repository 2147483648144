import React, { useState } from "react";
import cn from "classnames";
import { observer } from "mobx-react-lite";
import PageRoot from "src/components/PageRoot";
import {
  CreditIcon,
  ExpandableRow,
  Loader,
  pluralize,
  PriceWithCents,
  Splash,
} from "@sizdevteam1/funjoiner-uikit";
import PageHeader from "../../components/PageHeader";
import api, { IOrderDTO } from "src/services/api";
import notificator from "src/services/systemNotifications/notificationCenterService";
import InfiniteScroll from "react-infinite-scroller";
import { HistoryPageVmProvider, useHistoryPageVm } from "./HistoryPageVm";
import paymentSourceLabel from "../../util/paymentSourceLabel";
import { downloadFile } from "../../util/downloadFile";
import { IOrderPaymentPlanDTO } from "src/services/api/orders";
import classNames from "classnames";
import { formatDate } from "@sizdevteam1/funjoiner-uikit/util";
import groupBy from "../../util/groupBy";
import canDownloadPdfReceipt from "../../util/canDownloadPdfReceipt";

const HistoryPage: React.FC = observer(() => {
  return (
    <HistoryPageVmProvider>
      <HistoryPageImpl />
    </HistoryPageVmProvider>
  );
});

const HistoryPageImpl: React.FC = observer(() => {
  const vm = useHistoryPageVm();

  return (
    <PageRoot>
      <PageHeader showBackLink>Transaction History</PageHeader>
      {vm.isInitializing ? (
        <Splash pathToAnimation={"/splash.json"} />
      ) : vm.isEmpty ? (
        <div className={"typography-h3 text-text-color"}>
          You have no transactions
        </div>
      ) : (
        <InfiniteScroll
          loadMore={vm.loadNextPage}
          hasMore={vm.orderHistory.has_more}
        >
          {vm.orderHistory.items.map((order, index) => {
            const credits = order.items.flatMap((i) =>
              i.type === "credit_item" ? i.credits_created : []
            );

            return (
              <div
                key={index}
                className={cn(
                  "typography-small__t mb-3 rounded-[10px] text-text-color shadow-big"
                )}
              >
                <div
                  className={
                    "flex justify-between gap-1  rounded-t-[10px] bg-card-color py-3 px-4"
                  }
                >
                  <div className="flex flex-col gap-[2px]">
                    <div className="typography-h3 w-[220px] overflow-hidden text-ellipsis whitespace-nowrap ">
                      {order.description}
                    </div>
                    <span className={"text-gray-text-color"}>
                      {formatDate(order.created_at)}
                    </span>
                  </div>
                  <div className="flex flex-col gap-[2px]">
                    <div className="flex gap-4">
                      {canDownloadPdfReceipt(order) && (
                        <DownloadButton order={order} />
                      )}

                      {order.final_price > 0 && (
                        <div className={"typography-main_sb ml-auto"}>
                          <PriceWithCents
                            amount={order.final_price}
                            prefix="$"
                            typography="typography-main_sb"
                          />
                        </div>
                      )}
                    </div>
                    {order.payment &&
                      order.payment.alternative_payment_source !== null && (
                        <span
                          className={
                            "whitespace-nowrap text-right text-gray-text-color"
                          }
                        >
                          {paymentSourceLabel(
                            order.payment.alternative_payment_source
                          )}
                        </span>
                      )}
                  </div>
                </div>

                {order.payment_plan && (
                  <PaymentPlanExpand
                    key={order.id}
                    paymentPlan={order.payment_plan}
                  />
                )}

                {credits.length > 0 &&
                  Object.values(
                    groupBy(credits, "credit_type_id").map((credits, index) => (
                      <div key={index} className={"px-4 py-2"}>
                        <div
                          className={"flex items-center justify-between"}
                          key={credits[0].credit_type.id}
                        >
                          <div className="flex gap-1">
                            <div>{credits[0].credit_type.name}</div>
                            <CreditIcon
                              size={16}
                              isFree={credits[0].credit_type.is_free}
                              isProgram={
                                credits[0].credit_type.is_program_credit
                              }
                            />
                          </div>

                          <div
                            className={"typography-label text-gray-text-color"}
                          >
                            ×&nbsp;{credits.length}
                          </div>
                        </div>
                        {credits[0].expires_at != null && (
                          <div
                            className={
                              "typography-small ml-2 italic text-gray-text-color"
                            }
                          >
                            Usable until {formatDate(credits[0].expires_at)}
                          </div>
                        )}
                      </div>
                    ))
                  )}
              </div>
            );
          })}
        </InfiniteScroll>
      )}
      {vm.isLoadingNextPage && <Loader />}
    </PageRoot>
  );
});

const PaymentPlanExpand = ({
  paymentPlan,
}: {
  paymentPlan: IOrderPaymentPlanDTO;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="bg-on-main-color">
      <ExpandableRow
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        children={
          <div className="typography-label mb-1 flex w-[334px] cursor-pointer items-center justify-between bg-on-main-color py-[2px] px-4 text-gray-text-color">
            <div>
              {" "}
              {paymentPlan.installments.length}{" "}
              {pluralize("Payment", paymentPlan.installments.length)}
            </div>
            <i
              className={classNames(
                "icon chevron-down-icon !h-4 !w-4 transition-all ",
                isOpen && "rotate-180"
              )}
            />
          </div>
        }
        expand={
          <div className="typography-small__t  mb-3 flex flex-col gap-[2px] bg-on-main-color text-gray-text-color">
            {paymentPlan.installments.map((i) => (
              <div className="flex justify-between   pr-4 pl-7" key={i.id}>
                <div>
                  {i.is_paid ? "Paid" : "Due"} on{" "}
                  {formatDate(
                    i.is_paid && i.payment?.completed_at
                      ? i.payment.completed_at
                      : i.due_date
                  )}
                </div>
                <PriceWithCents
                  amount={i.amount}
                  prefix="$"
                  typography="typography-small__t"
                />
              </div>
            ))}
          </div>
        }
      />
    </div>
  );
};

const DownloadButton: React.FC<{
  order: IOrderDTO;
}> = (props) => {
  const { order } = props;
  const [isLoading, setIsLoading] = useState(false);

  return isLoading ? (
    <Loader className="!h-4 !w-4 !p-1" />
  ) : (
    <i
      onClick={async () => {
        setIsLoading(true);
        try {
          await downloadFile({
            apiCall: () => api.profile.receipt(order.id),
            fileName: `receipt-${order.id}.pdf`,
          });
        } catch (e) {
          notificator.error("Error", "Failed to download receipt");
        } finally {
          setIsLoading(false);
        }
      }}
      className="download-icon icon bg-main-color hover:cursor-pointer hover:opacity-80"
    />
  );
};

export default HistoryPage;
