import * as Sentry from "@sentry/react";
import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from "mobx";
import { computedFn } from "mobx-utils";
import React, { useMemo } from "react";
import { assert } from "@sizdevteam1/funjoiner-uikit";
import { IStorageFileDTO } from "@sizdevteam1/funjoiner-uikit/components/Gallery/Gallery";
import {
  FileUploadQuestionDTO,
  MultiChoiceQuestionDTO,
  SelectQuestionDTO,
  SmartFormDTO,
  SmartFormQuestionDTO,
  TextQuestionDTO,
} from "src/services/api/smartForms";
import useVM from "../../../hooks/useVM";
import { FileUpload } from "@sizdevteam1/funjoiner-uikit/components/FileUploader/UploadControllerProvider";

export type SetAnswerDTO = {
  question_template_id: string;
  answer: string | string[];
};

export type IQuestionPipelineParameters = {
  delegate: IQuestionPipelineDelegate;
  studentsProvider: IStudentsProvider;
  questionSetProcessorDelegate: IQuestionSetProcessorDelegate;
  onError: (error: any) => void;
};

export class QuestionPipelineVm {
  private _state: _QuestionPipelineState;
  private _delegate: IQuestionPipelineParameters["delegate"];
  private _studentsProvider: IQuestionPipelineParameters["studentsProvider"];
  private _questionSetProcessorDelegate: IQuestionPipelineParameters["questionSetProcessorDelegate"];
  private _onError: IQuestionPipelineParameters["onError"];

  constructor(params: IQuestionPipelineParameters) {
    makeObservable(this);
    this._delegate = params.delegate;
    this._studentsProvider = params.studentsProvider;
    this._questionSetProcessorDelegate = params.questionSetProcessorDelegate;
    this._onError = params.onError;

    this._state = new _QuestionPipelineState(this._delegate.startIndexFor);
    const disposeReaction = reaction(
      () => this._state.currentQuestion,
      (question) => {
        this._answerVms[question.id] ??= this.createAnswerVm(question);
        this.answerVm = this._answerVms[question.id];
      }
    );

    this.dispose = () => {
      disposeReaction();
    };
    this.init();
  }

  dispose: () => void;

  @observable
  isLoading: boolean = true;

  @observable
  error?: string;

  @observable
  answerVm!: IAnswerVm;

  @observable
  private _answerVms: {
    [questionId: string]: IAnswerVm;
  } = {};

  @computed
  get currentStudent() {
    return this._students[0];
  }

  @computed
  get otherStudents(): IStudent[] {
    const [, ...other] = this._students;
    return other;
  }

  @computed
  get questionSetName() {
    return this._state.currentSmartForm.name;
  }

  @computed get resubmissionReason() {
    return this._state.currentSmartForm.resubmission_reason;
  }

  @computed
  get question() {
    return this._state.currentQuestion;
  }

  @computed
  get currentQuestionOrdinalNumber() {
    return (
      this._studentQuestions.findIndex(
        (e) => e.id === this._state.currentQuestionId
      ) + 1
    );
  }

  @computed
  get questionCount() {
    return this._studentQuestions.length;
  }

  @computed
  private get _studentQuestions() {
    return this._state.smartFormsByStudentId
      .get(this.currentStudent.id)!
      .flatMap((e) => e.questions);
  }

  @computed
  get showSkipTheseQuestions() {
    return (
      this._state.isQuestionFirstInSet &&
      !this._state.currentSmartForm.is_required
    );
  }

  @computed
  get hasPrevious() {
    return this._state.previousId != null;
  }

  @computed
  get canQuestionBeSkipped() {
    return !this._state.currentQuestion.is_required && this.answerVm.isBlank;
  }

  @computed
  get submitText(): string {
    if (this._state.isQuestionLastInSet) {
      if (
        this.currentQuestionOrdinalNumber === this.questionCount &&
        this.otherStudents.length > 0
      ) {
        const nextStudent = this.otherStudents[0];
        return `Proceed to ${nextStudent.first_name}`;
      }

      return this._delegate.finishText ?? "Save Answers";
    } else if (this.canQuestionBeSkipped) {
      return "Skip Question";
    } else {
      return "Next";
    }
  }

  @computed
  private get _students() {
    const studentIds = new Set(
      this._state.unansweredQuestionSetsByStudentId.keys()
    );
    return [...studentIds].map(
      (id) =>
        this._studentsProvider.studentsWithCustomerAsParticipant.find(
          (e) => e.id === id
        )!
    );
  }

  @computed
  get isSubmitDisabled() {
    return this.question.is_required && this.answerVm.isBlank;
  }

  @action.bound
  async submit() {
    if (this.isSubmitDisabled) return;

    if (!this._state.isQuestionLastInSet) {
      return this._state.toNextQuestion();
    }

    const signatureQuestionId =
      this._state.currentSmartForm.virtual_signature_question_id;

    let virtualSignature: string | null = null;
    if (signatureQuestionId != null) {
      const signatureVm = this._answerVms[signatureQuestionId];
      if (signatureVm != null && signatureVm.isEditable) {
        virtualSignature = signatureVm.sanitizedAnswer as string;
      }
    }
    const answers = Object.values(this._answerVms)
      .filter((vm) => !vm.isBlank && vm.isEditable)
      .filter((vm) => vm.question.id !== signatureQuestionId);
    // On edit. Only submit answers if they are not blank or if there is a virtual signature
    const isEdit = this._state.currentSmartForm.status !== "INCOMPLETE";
    if (!isEdit || answers.length > 0 || virtualSignature != null) {
      try {
        await this._questionSetProcessorDelegate.submitQuestionSet(
          this._state.currentSmartForm.template_id,
          this.currentStudent.id,
          answers.map((vm) => ({
            question_template_id: vm.question.template_id,
            answer: vm.sanitizedAnswer,
          })),
          virtualSignature
        );
      } catch (e) {
        this._onError(e);
        throw e;
      }
    }

    this._toNextSmartForm();
  }

  @action.bound
  async skipQuestionSet() {
    await this._questionSetProcessorDelegate.skipQuestionSet(
      this._state.currentSmartForm.template_id,
      this._state.currentSmartForm.student_id
    );
    this._toNextSmartForm();
  }

  @action.bound
  toPreviousQuestion() {
    this._state.toPreviousQuestion();
  }

  @computed
  private get _currentSmartFormCachePrefix() {
    return `qp__template_${this._state.currentSmartForm.template_id}_student_${this._state.currentSmartForm.student_id}`;
  }

  private _clearSmartFormCache() {
    const keys = Object.keys(localStorage).filter((key) =>
      key.startsWith(this._currentSmartFormCachePrefix)
    );
    for (const key of keys) {
      localStorage.removeItem(key);
    }
  }

  @action.bound
  private _toNextSmartForm() {
    this._clearSmartFormCache();
    if (this._state.unansweredSmartForms.length === 1) {
      this._delegate.onFinish();
    } else {
      this._answerVms = {};
      this._state.toNextSmartForm();
    }
  }

  uploadFile = async (file: File) => {
    return await this._delegate.uploadFile(file);
  };

  private createAnswerVm(question: SmartFormQuestionDTO) {
    const options: AnswerVmOptions = {
      isCacheEnabled: !this._state.currentSmartForm.was_submitted_at_least_once,
      cachePrefix: this._currentSmartFormCachePrefix,
      isResubmission:
        this._state.currentSmartForm.status === "INCOMPLETE" &&
        this._state.currentSmartForm.was_submitted_at_least_once,
    };

    switch (question.type) {
      case "MULTI_CHOICE":
        return new MultiChoiceVm(question, options, () => {
          if (this.isSubmitDisabled) return;

          if (!this._state.isQuestionLastInSet) {
            return this._state.toNextQuestion();
          }
        });
      case "TEXT":
      case "SELECT":
        return new TextAnswerVm(question, options);
      case "FILE_UPLOAD":
        return new FileAnswerVm(question, options);
    }
  }

  @action.bound
  private async init() {
    try {
      const questionSets = await this._delegate.fetchSmartForms();

      if (questionSets.length === 0) {
        this.error = "No questions to answer";
        return;
      }

      this._state.initQuestionSets(questionSets);
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      runInAction(() => (this.error = "Unexpected error"));
    } finally {
      runInAction(() => (this.isLoading = false));
    }
  }
}

type IAnswerVm = MultiChoiceVm | TextAnswerVm | FileAnswerVm;

type DisplayAnswer = string | IStorageFileDTO[];

type AnswerVmOptions = {
  isCacheEnabled: boolean;
  cachePrefix: string;
  isResubmission: boolean;
};
abstract class _AnswerVm {
  protected constructor(
    public question: SmartFormQuestionDTO,
    private options: AnswerVmOptions
  ) {}

  abstract get displayAnswer(): DisplayAnswer;
  abstract get sanitizedAnswer(): SetAnswerDTO["answer"];

  abstract get isBlank(): boolean;

  abstract toJson(): string;
  abstract fromJson(json: string): void;

  protected restoreState() {
    if (this.options.isCacheEnabled && this.question.answer == null) {
      const localStorageKey = `${this.options.cachePrefix}_qt_${this.question.template_id}`;

      try {
        const cached = localStorage.getItem(localStorageKey);
        if (cached != null) {
          this.fromJson(cached);
        }
      } catch (e) {
        console.error(e);
        Sentry.captureException(e);
      }

      reaction(
        () => this.sanitizedAnswer,
        (v) => {
          localStorage.setItem(localStorageKey, this.toJson());
        }
      );
    }
  }
  @computed
  get isEditable() {
    return (
      this.options.isResubmission ||
      this.question.answer == null ||
      this.question.is_answer_editable_by_customer
    );
  }
}

export class MultiChoiceVm extends _AnswerVm {
  constructor(
    question: MultiChoiceQuestionDTO,
    options: AnswerVmOptions,
    private onQuickSelect: () => void
  ) {
    super(question, options);
    makeObservable(this);

    assert(question.type === "MULTI_CHOICE");

    this.questionAllowsOtherOption = question.has_other_option;
    this.availableOptions = question.options;
    this.multipleSelection = question.multiple_selection;
    this._options = {};

    if (question.answer?.type === "MULTI_CHOICE") {
      const currentAnswers = question.answer.value;

      for (let option of this.availableOptions) {
        this._options[option] = currentAnswers.includes(option);
      }

      this._otherOption = currentAnswers.find(
        (answer) => !this.availableOptions.includes(answer)
      );
    }
    this.restoreState();
  }

  @observable
  private _options: {
    [option: string]: boolean;
  };

  public get sanitizedAnswer(): string[] {
    const answers = Object.entries(this._options)
      .filter(([_, isEnabled]) => isEnabled)
      .map(([value]) => value);

    if (this.otherOption != null && this.otherOption.trim().length > 0) {
      answers.push(this.otherOption);
    }
    return answers;
  }

  public get displayAnswer(): string {
    return this.sanitizedAnswer.join(", ");
  }

  @observable
  private _otherOption: string | undefined;
  get otherOption(): string | undefined {
    return this._otherOption;
  }

  setOtherOption(value: string | undefined) {
    this._otherOption = value;
  }

  @computed
  get isOtherOptionSelected() {
    return this.otherOption != null;
  }

  questionAllowsOtherOption: boolean;
  availableOptions: string[];
  private readonly multipleSelection: boolean;

  @computed
  get isBlank() {
    return this.sanitizedAnswer.length === 0;
  }

  isSelected = computedFn((option: string) => this._options[option]);

  @action.bound
  toggleOption(option: string, enabled: boolean) {
    if (!this.multipleSelection) {
      this._options = {};
      this.toggleOtherOption(false);
    }
    this._options[option] = enabled;

    if (enabled && !this.multipleSelection) this.onQuickSelect();
  }

  @action.bound
  toggleOtherOption(enabled: boolean) {
    if (!this.multipleSelection) {
      this._options = {};
    }
    this._otherOption = enabled ? "" : undefined;
  }

  fromJson(json: string): void {
    const { options, other } = JSON.parse(json);
    this._options = options;
    this._otherOption = other;
  }

  toJson(): string {
    return JSON.stringify({
      options: this._options,
      other: this._otherOption,
    });
  }
}

export class TextAnswerVm extends _AnswerVm {
  constructor(
    public question: TextQuestionDTO | SelectQuestionDTO,
    options: AnswerVmOptions
  ) {
    super(question, options);
    makeObservable(this);
    this._answer = question.answer?.value ?? "";
    this.restoreState();
  }

  @observable
  private _answer: string;

  get displayAnswer(): string {
    return this._answer;
  }

  get sanitizedAnswer(): string {
    return this._answer;
  }

  setAnswer(answer: string) {
    this._answer = answer;
  }

  @computed
  get isBlank() {
    return this._answer.trim().length === 0;
  }

  fromJson(json: string): void {
    console.log(`json: ${json}`);
    this._answer = json;
  }

  toJson(): string {
    return this._answer;
  }
}

export class FileAnswerVm extends _AnswerVm {
  constructor(question: FileUploadQuestionDTO, options: AnswerVmOptions) {
    super(question, options);
    makeObservable(this);
    this.storageFiles = question.answer?.value ?? [];
    this.restoreState();
  }

  @observable
  private storageFiles: IStorageFileDTO[];

  get displayAnswer(): IStorageFileDTO[] {
    return this.storageFiles;
  }

  get sanitizedAnswer(): string[] {
    return this.storageFiles.map((file) => file.key);
  }

  setUploads = (files: FileUpload[]) => {
    const storageFiles = Array<IStorageFileDTO>();
    for (const file of files) {
      if (file.type !== "success") continue;
      storageFiles.push(file.storage_file);
    }

    this.storageFiles = storageFiles;
  };

  @computed
  get isBlank() {
    return this.storageFiles.length === 0;
  }

  fromJson(json: string): void {
    this.storageFiles = JSON.parse(json);
  }

  toJson(): string {
    return JSON.stringify(this.storageFiles);
  }
}

export class _QuestionPipelineState {
  constructor(private startIndexFor: (set: SmartFormDTO) => number) {
    makeObservable(this);
  }

  @observable
  private _currentQuestionId!: string;

  @computed
  get currentQuestionId(): string {
    return this._currentQuestionId;
  }

  @observable
  private _smartForms: SmartFormWithSignature[] = [];

  @observable
  private _unansweredQuestionSetsBackingField: SmartFormWithSignature[] = [];

  @computed
  get unansweredSmartForms() {
    return this._unansweredQuestionSetsBackingField;
  }

  @action.bound
  private _setUnansweredQuestionSets(value: SmartFormWithSignature[]) {
    const incompleteQuestionSets = value.filter(
      (smartForm) => smartForm.status === "INCOMPLETE"
    );
    if (incompleteQuestionSets.length !== 0) {
      value = incompleteQuestionSets;
    }

    this._unansweredQuestionSetsBackingField = value;

    const currentSmartForm = value[0];
    const startIndex = this.startIndexFor(currentSmartForm);

    this._currentQuestionId = currentSmartForm.questions[startIndex].id;
  }

  @computed
  get smartForms(): SmartFormDTO[] {
    return this._smartForms;
  }

  @action.bound
  initQuestionSets(value: SmartFormDTO[]) {
    const sets = value.map(copyWithSignatureAsEntry);
    this._smartForms = sets;
    this._setUnansweredQuestionSets(sets);
  }

  @computed
  get smartFormsByStudentId(): Map<number, SmartFormDTO[]> {
    return this._groupByStudentId(this._smartForms);
  }

  @computed
  get unansweredQuestionSetsByStudentId(): Map<number, SmartFormDTO[]> {
    return this._groupByStudentId(this.unansweredSmartForms);
  }

  @computed
  get currentQuestion(): SmartFormQuestionDTO {
    return this.unansweredSmartForms
      .flatMap((e) => e.questions)
      .find((e) => e.id === this.currentQuestionId)!;
  }

  @computed
  get currentSmartForm() {
    return this.unansweredSmartForms.find(
      (e) => e.id === this.currentQuestion.smart_form_id
    )!;
  }

  @computed
  get isQuestionFirstInSet() {
    return this.currentIndexInQuestionSet === 0;
  }

  @computed
  get isQuestionLastInSet() {
    return (
      this.currentIndexInQuestionSet ===
      this.currentSmartForm.questions.length - 1
    );
  }

  @computed
  get nextId(): string | undefined {
    if (this.isQuestionLastInSet) {
      return undefined;
    } else {
      return this.currentSmartForm.questions[this.currentIndexInQuestionSet + 1]
        .id;
    }
  }

  @computed
  get previousId(): string | undefined {
    if (this.isQuestionFirstInSet) {
      return undefined;
    } else {
      return this.currentSmartForm.questions[this.currentIndexInQuestionSet - 1]
        .id;
    }
  }

  @computed
  get currentIndexInQuestionSet() {
    return this.currentSmartForm.questions.findIndex(
      (e) => e.id === this.currentQuestionId
    )!;
  }

  @action.bound
  toNextSmartForm() {
    const newQuestionSets = [...this.unansweredSmartForms];
    newQuestionSets.splice(
      this.unansweredSmartForms.findIndex(
        (e) => e.id === this.currentSmartForm.id
      ),
      1
    );

    this._setUnansweredQuestionSets(newQuestionSets);
  }

  @action.bound
  toPreviousQuestion() {
    this._currentQuestionId = this.previousId!;
  }

  @action.bound
  toNextQuestion() {
    this._currentQuestionId = this.nextId!;
  }

  private _groupByStudentId(sets: SmartFormDTO[]): Map<number, SmartFormDTO[]> {
    const map = new Map<number, SmartFormDTO[]>();

    for (const set of sets) {
      const studentId = set.student_id;
      if (!map.has(studentId)) {
        map.set(studentId, []);
      }

      map.get(studentId)!.push(set);
    }

    return map;
  }
}

type SmartFormWithSignature = {
  virtual_signature_question_id: string | undefined;
} & SmartFormDTO;

function copyWithSignatureAsEntry(
  smartForm: SmartFormDTO
): SmartFormWithSignature {
  const questions = [...smartForm.questions];
  const signatureId = `#sign_${smartForm.id}`;

  if (smartForm.virtual_signature_settings != null) {
    const signatureQuestion: TextQuestionDTO = {
      id: signatureId,
      smart_form_id: smartForm.id,
      type: "TEXT",
      input_type: "INPUT",
      is_answer_editable_by_customer: questions.some(
        (q) => q.is_answer_editable_by_customer
      ),
      is_required: true,
      template_id: signatureId,
      is_answer_hidden_on_employee_app: false,
      answer_valid_for_days: undefined,
      text: smartForm.virtual_signature_settings.instructions,
      description: smartForm.virtual_signature_settings.description,
      answer:
        smartForm.virtual_signature != null
          ? {
              type: "TEXT",
              value: smartForm.virtual_signature.sign_value,
              employee_id: undefined,
              created_at: smartForm.virtual_signature.signed_at,
            }
          : undefined,
    };

    questions.push(signatureQuestion);
  }

  return {
    ...smartForm,
    questions: questions,
    virtual_signature_question_id: signatureId,
  };
}

const ctx = React.createContext<QuestionPipelineVm | null>(null);

export const QuestionPipelineVmProvider: React.FC<
  IQuestionPipelineParameters
> = ({ children, ...props }) => {
  const vm = useMemo(() => new QuestionPipelineVm(props), []);
  React.useEffect(() => () => vm.dispose(), [vm]);

  return <ctx.Provider value={vm}>{children}</ctx.Provider>;
};

export const useQuestionPipelineVm = () => useVM(ctx);

type IStudent = {
  id: number;
  first_name?: string;
  last_name?: string;
  full_name?: string;
  thumbnail?: string;
};
export type IStudentsProvider = {
  studentsWithCustomerAsParticipant: IStudent[];
};

export interface IQuestionPipelineDelegate {
  finishText?: string;
  startIndexFor: (set: SmartFormDTO) => number;
  onFinish: () => void;
  fetchSmartForms: () => Promise<SmartFormDTO[]> | SmartFormDTO[];
  uploadFile: (file: File) => Promise<IStorageFileDTO>;
}

export interface IQuestionSetProcessorDelegate {
  submitQuestionSet: (
    smartFormTemplateId: string,
    studentId: number,
    answers: SetAnswerDTO[],
    virtual_signature: string | null
  ) => Promise<void>;

  skipQuestionSet: (
    smartFormTemplateId: string,
    studentId: number
  ) => Promise<void>;
}
