import React from "react";
import { observer } from "mobx-react-lite";
import WhatNextSection from "./WhatNextSection/WhatNextSection";
import ReceiptSection from "./PaymentSuccessPage/ReceiptSection";
import SizedBox from "src/components/SizedBox";
import PageRoot from "src/components/PageRoot";
import { defineRoute } from "src/util/typedRouting";
import { IBookingResult } from "./ScheduleSharingPageVM";
import { Image } from "@sizdevteam1/funjoiner-uikit";
import Success from "../../assets/imgs/payment-success.svg";
import useStores from "../../hooks/useStores";
import { Redirect } from "react-router-dom";
import { ROUTES } from "../../stores/RouterStore";
import DownloadReceiptButton from "./PaymentSuccessPage/components/DownloadReceiptButton";

export const scheduleAndPaySuccessRoute = defineRoute<{
  state: {
    bookingResult: IBookingResult;
    emailsSent?: boolean;
    installmentId?: string;
  };
}>({
  path: "/schedule_and_pay_success",
  build: (path) => path,
});
const ScheduleAndPaySuccessPage: React.FC = observer(() => {
  const {
    paymentStore: { completedOrder },
  } = useStores();
  const { state } = scheduleAndPaySuccessRoute.useParams();

  if (completedOrder == null) {
    return <Redirect to={ROUTES.DASHBOARD} />;
  }

  const titleProps =
    state?.emailsSent === true
      ? {
          titleNode: (
            <div className="!mt-0 grid grid-cols-[58px_240px] gap-5">
              <Image
                imageClassName="h-[58px] w-[58px]"
                src={Success}
                alt="success"
              />
              <div className="flex flex-col items-baseline gap-2">
                <span className="typography-h1 text-header-color">
                  Thank You!
                </span>
                <span className="typography-main text-text-color">
                  Emails sent successfully
                </span>
              </div>
            </div>
          ),
        }
      : {
          title: state?.installmentId
            ? "Payment Successful!"
            : "Scheduling & Payment Successful!",
        };
  return (
    <PageRoot>
      <ReceiptSection
        order={completedOrder.order}
        paymentOption={{
          type: "card",
          card: completedOrder.card,
        }}
        {...titleProps}
      />
      <DownloadReceiptButton order={completedOrder.order} />
      <SizedBox height={24} />
      <WhatNextSection
        showDashboardLink={true}
        bookingResult={state?.bookingResult}
      />
    </PageRoot>
  );
});

export default ScheduleAndPaySuccessPage;
