import React from "react";
import { observer } from "mobx-react-lite";
import {
  CheckoutAndPaymentPageVMProvider,
  useCheckoutAndPaymentPageVM,
} from "./Checkout&PaymentVM";
import Checkout from "./Checkout";
import Payment from "./Payment";
import ProcessingPayment from "./components/ProcessingPayment";
import SelectPaymentPlanPage from "./SelectPaymentPlanPage/SelectPaymentPlanPage";
import { assertNever } from "@sizdevteam1/funjoiner-uikit/util";

export const CheckoutAndPayment = () => (
  <CheckoutAndPaymentPageVMProvider>
    <CheckoutAndPaymentImpl />
  </CheckoutAndPaymentPageVMProvider>
);

const CheckoutAndPaymentImpl = observer(() => {
  const vm = useCheckoutAndPaymentPageVM();

  if (vm.processingPayment) {
    return (
      <ProcessingPayment
        card={vm.processingPayment.paymentMethod}
        amount={vm.processingPayment.amount}
      />
    );
  }

  if (vm.step === "checkout") return <Checkout />;
  if (vm.step === "payment") return <Payment />;
  if (vm.step === "payment_plans") return <SelectPaymentPlanPage />;

  assertNever(vm.step);
});

export default CheckoutAndPayment;
