import React from "react";
import cn from "classnames";
import { ILocationDTO } from "../../services/api";
import { twMerge } from "tailwind-merge";
import { Button } from "@sizdevteam1/funjoiner-uikit";

interface IProps {
  className?: string;
  selectedLocation?: ILocationDTO;
  onClick?: () => void;
  disabled?: boolean;
}
const LocationSelect: React.FC<IProps> = ({
  className,
  selectedLocation,
  onClick,
  disabled,
}) => {
  return (
    <Button
      disabled={disabled}
      kind="text"
      onClick={onClick}
      className={twMerge(
        "!typography-main w-fit !items-start !justify-start overflow-clip !text-start ",
        disabled && "!text-gray-text-color !opacity-100",
        className
      )}
    >
      <i
        className={cn(
          "icon !h-6 !w-6",
          disabled && "!bg-icon-grey-color",
          disabled ? "locations-icon" : "location-color-icon"
        )}
      />

      {selectedLocation?.name ?? "Select Location"}
    </Button>
  );
};

export default React.memo(LocationSelect);
