import cn from "classnames";
import React from "react";
import { ILocationDTO } from "src/services/api";
import styles from "./LocationCard.module.scss";

interface IProps {
  location: ILocationDTO;
  select: (l: ILocationDTO) => void;
}

const LocationCard: React.FC<IProps> = ({ location, select }) => {
  return (
    <div className={styles.location}>
      <div className={styles.icon}>
        <i className={"icon location-color-icon"} />
      </div>
      <div>
        <div className={cn(styles.name, "text-h3")}>{location.name}</div>
        <div className={cn(styles.address, "text-small")}>
          {location.address}
        </div>
        <div
          className={cn(styles.link, "text-h4")}
          onClick={() => select(location)}
        >
          Select This Location
          <i className={"icon out-icon"} />
        </div>
      </div>
    </div>
  );
};
export default LocationCard;
