import React, { ReactNode } from "react";
import {
  UpgradeCreditPageVMProvider,
  useUpgradeCreditPageVM,
} from "./UpgradeCreditPageVM";
import { observer } from "mobx-react-lite";
import PageRoot from "src/components/PageRoot";
import PageHeader from "src/components/PageHeader";
import classNames from "classnames";
import { ICreditTypeDTO } from "src/services/api";
import {
  Button,
  CreditIcon,
  PriceWithCents,
  Radio,
} from "@sizdevteam1/funjoiner-uikit";
import Policies from "../../components/Policies";
import useStores from "src/hooks/useStores";
import { Redirect } from "react-router-dom";
import { ROUTES } from "src/stores/RouterStore";

const UpgradeCreditPage = () => {
  const { commonStore } = useStores();
  if (!commonStore.publicSettings.credits.upgrades_by_customer) {
    return <Redirect to={ROUTES.DASHBOARD} />;
  }
  return (
    <UpgradeCreditPageVMProvider>
      <UpgradeCreditPageImpl />
    </UpgradeCreditPageVMProvider>
  );
};
const UpgradeCreditPageImpl = observer(() => {
  const vm = useUpgradeCreditPageVM();

  return (
    <PageRoot>
      <PageHeader showBackLink={true}>Credit Upgrade</PageHeader>
      <CreditTile creditType={vm.fromCreditType}>
        <AmountPicker />
      </CreditTile>
      <Arrows />
      <div className="flex flex-col gap-5">
        {vm.upgradeOptions.map((e, index) => (
          <CreditTile key={index} creditType={e.to}>
            <div className="flex flex-row space-x-3">
              <div className="text-h3 text-text-color">
                <PriceWithCents
                  amount={e.price}
                  prefix="$"
                  typography="typography-h3"
                />
              </div>
              <Radio
                checked={vm.selectedUpgrade?.to.id === e.to.id}
                onChange={(isSelected) =>
                  (vm.selectedUpgrade = isSelected ? e : undefined)
                }
              />
            </div>
          </CreditTile>
        ))}
      </div>
      <div className="mt-auto flex flex-col items-stretch">
        <TotalCost />
        <Policies className={"mt-4"} />
        <Button
          autoLoading={true}
          className="mt-4 mb-3"
          size="big"
          disabled={!vm.isValid}
          onClick={vm.upgradeCredit}
        >
          Proceed to Payment
        </Button>
      </div>
    </PageRoot>
  );
});

const Arrows: React.FC = observer(() => {
  const isSelected = useUpgradeCreditPageVM().selectedUpgrade != null;
  return (
    <div className="mb-[20px] mt-[10px] flex flex-col items-center space-y-3">
      <span className="text-main text-text-color">
        {isSelected ? "will be upgraded to" : "may be upgraded to"}
      </span>
      <div className="flex flex-row">
        {[...Array(3)].map((i, index) => (
          <div
            key={index}
            className="icon arrow-down-icon bg-separator-color"
          />
        ))}
      </div>
    </div>
  );
});

const AmountPicker: React.FC = observer(() => {
  const { quantity, canDecrement, canIncrement, increment, decrement } =
    useUpgradeCreditPageVM();
  return (
    <div className="flex items-center space-x-2">
      <span
        onClick={decrement}
        className={classNames("icon partial-icon icon-hover", {
          "bg-main-color": canDecrement,
        })}
      />
      <span className="text-h3 flex min-w-[20px] justify-center text-main-color">
        {quantity}
      </span>
      <span
        onClick={increment}
        className={classNames("icon add-icon icon-hover", {
          "bg-main-color": canIncrement,
        })}
      />
    </div>
  );
});

interface ICreditTileProps {
  creditType: ICreditTypeDTO;
  children: ReactNode;
}

const CreditTile: React.FC<ICreditTileProps> = observer(
  ({ creditType, children }: ICreditTileProps) => {
    return (
      <div className="flex flex-row items-center space-x-4 rounded-2xl bg-on-main-color p-4">
        <CreditIcon
          isProgram={creditType.is_program_credit}
          isFree={creditType.is_free}
          size={24}
        />
        <div className="flex flex-1 flex-col">
          <span className="text-h3 text-text-color">{creditType.name}</span>
          <span className="text-small text-gray-text-color">
            {creditType.is_program_credit ? "Program" : "Session"}{" "}
            {creditType.is_free && "Free"} Credit
          </span>
        </div>
        {children}
      </div>
    );
  }
);

const TotalCost: React.FC = observer(() => {
  const { totalUpgradePrice } = useUpgradeCreditPageVM();
  return totalUpgradePrice != null ? (
    <div className="flex flex-row rounded-[10px] bg-white p-4">
      <span className="text-h3 text-text-color">Total Upgrade Cost</span>
      <div className="text-h2 ml-auto text-text-color">
        <PriceWithCents
          amount={totalUpgradePrice}
          prefix="$"
          typography="typography-h2"
        />
      </div>
    </div>
  ) : null;
});

export default UpgradeCreditPage;
