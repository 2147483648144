import React from "react";
import { twMerge } from "tailwind-merge";

export const PaymentMethodListTile = (props: {
  title: React.ReactNode;
  description?: React.ReactNode;
  trailing?: React.ReactNode;
  className?: string;
  onClick?: () => void;
}) => {
  return (
    <div
      onClick={props.onClick}
      className={twMerge(
        "flex flex-col gap-3 rounded-[10px] bg-on-main-color p-4 shadow-big",
        props.className,
        props.onClick != null && "cursor-pointer"
      )}
    >
      <div className={"flex flex-row gap-2"}>
        <div className={"flex-1"}>{props.title}</div>
        {props.trailing}
      </div>
      {props.description}
    </div>
  );
};
