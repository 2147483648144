import apiService from "./apiService";
import { ISOString, TimeString } from "@sizdevteam1/funjoiner-uikit/types";
import { IStorageFileDTO } from "./files";
import {
  FunboxMode,
  IPagination,
  IPaginationParams,
  IProgramTypeDTO,
} from "./common";
import { TDocumentDTO } from "./documents";
import { IInvoiceDTO, IStudentDTO } from "./index";
import { SmartFormDTO } from "./smartForms";

export interface ILocationMonthsWithPrograms {}
export const monthsWithPrograms = (searchParams: {
  funbox_id: string;
  location_id: number;
  date_from?: ISOString;
  date_to?: ISOString;
}) =>
  apiService.get<ISOString[]>(
    `/customer_api/v2/availability/months_with_programs`,
    {
      searchParams,
    }
  );

export interface IGetAvailabilityDTO {
  date_from: ISOString;
  date_to: ISOString;
  location_id: number;
  funbox_id: string;
  show_programs_without_program_credits_attached?: boolean;
  program_type_ids?: number[];
  from_age?: number;
  to_age?: number;
  view?: "all" | "available" | "available_and_waitlist";
}

export interface IGetAvailableProgramTypesDTO {
  date_from: ISOString;
  date_to?: ISOString;
  location_id: number;
  funbox_id: string;
  program_type_ids?: number[];
  from_age?: number;
  to_age?: number;
  view?: "all" | "available" | "available_and_waitlist";
}

export const getAvailableProgramTypes = (searchParams: {
  searchParams: IGetAvailableProgramTypesDTO;
}) =>
  apiService.get<IProgramTypeDTO[]>(
    `/customer_api/v2/availability/available_program_types`,
    searchParams
  );

export const getAvailablePrograms = (
  searchParams: {
    date_from: ISOString;
    location_id: number;
    funbox_id: string;
    program_type_id: number;
    date_to?: ISOString;
    from_age?: number;
    to_age?: number;
    view?: "all" | "available" | "available_and_waitlist";
  } & IPaginationParams
) =>
  apiService.get<
    IPagination<IAvailableDaycampScheduleSet | IAvailableOvernightScheduleSet>
  >(`/customer_api/v2/availability/available_programs_paginated`, {
    searchParams: searchParams,
  });

export const getAvailableDaycampPrograms = (searchParams: {
  searchParams: IGetAvailabilityDTO;
}) =>
  apiService.get<IAvailableDaycampScheduleSet[]>(
    `/customer_api/v2/availability/available_daycamp_programs`,
    searchParams
  );

export const getAvailableDaycampSessions = (searchParams: {
  searchParams: IGetAvailabilityDTO;
}) =>
  apiService.get<IAvailableDaycampSession[]>(
    `/customer_api/v2/availability/available_daycamp_sessions`,
    searchParams
  );

export const getAvailableOvernightPrograms = (searchParams: {
  searchParams: IGetAvailabilityDTO;
}) =>
  apiService.get<IAvailableOvernightScheduleSet[]>(
    `/customer_api/v2/availability/available_overnight_programs`,
    searchParams
  );

export type AlreadySignedForProgramInfo = {
  type: "already_signed_for_program";
  student_id: number;
  program_id: string;
  start: ISOString;
  end: ISOString;
};

export type AlreadySignedForSessionInfo = {
  type: "already_signed_for_session";
  student_id: number;
  program_id: string;
  session_id: string;
  start: ISOString;
  end: ISOString;
};

export type InWaitlistForProgramInfo = {
  type: "in_waitlist_for_program";
  student_id: number;
  program_id: string;
};

export type AgeRestrictedInfo = {
  type: "age_restricted";
  student_id: number;
  from_age: number | null;
  to_age: number | null;
  actual_age: number;
};

export type HasApplicationForProgramInfo = {
  type: "has_application_for_program";
  student_id: number;
  program_id: string;
  application_id: string;
};

export type HasSelectedSessionInSelectedProgram = {
  type: "has_selected_session_in_program";
  student_id: number;
  session: IAvailableDaycampSession;
};

export type HasSelectedProgramThatIncludesSelectedSession = {
  type: "has_selected_program_includes_selected_session";
  student_id: number;
  program: TAvailableProgram;
};

export type TAvailabilityStudentInfo =
  | AlreadySignedForProgramInfo
  | AlreadySignedForSessionInfo
  | InWaitlistForProgramInfo
  | AgeRestrictedInfo
  | HasApplicationForProgramInfo
  | HasSelectedSessionInSelectedProgram
  | HasSelectedProgramThatIncludesSelectedSession;

export const APPLICATION_STATUSES = [
  "DRAFT",
  "PENDING",
  "REJECTED",
  "RESUBMISSION",
  "APPROVED",
  "AWAITING_PAYMENT",
] as const;

export type ApplicationStatus = (typeof APPLICATION_STATUSES)[number];
export type ApplicationDTO = {
  id: string;
  student: IStudentDTO;
  documents: TDocumentDTO[];
  smart_forms: SmartFormDTO[];
  reject_reason?: string;
  status: ApplicationStatus;
  location_id: number;
  location_name: string;
  funbox_name: string;
  schedule_set_name: string;
  order: IInvoiceDTO | null;
  program: {
    id: string;
    start_date: ISOString;
    end_date: ISOString;
    program_type_name: string;
  };
};

export type ApplicationDrafts = {
  description: string;
  requires_payment_information: boolean;
  hide_price_in_customer_hub: boolean;
  applications: ApplicationDTO[];
};

interface ProgramTypeDTO {
  id: number;
  name: string;
  description: string;
  from_age: number | null;
  to_age: number | null;
  photo_file: IStorageFileDTO | null;
  thumbnail_file: IStorageFileDTO | null;
  mode: FunboxMode;
}
export interface ClosedRegistrationWithWaitlist {
  registration: ClosedProgramRegistrationInfo & { waitlist: WaitlistInfoDTO };
}

export interface ClosedRegistrationWithApplication {
  registration: ClosedProgramRegistrationInfo & {
    application: ApplicationInfoDTO;
  };
}

export type ProgramRegistrationInfo =
  | OpenProgramRegistrationInfo
  | ClosedProgramRegistrationInfo;

export type OpenProgramRegistrationInfo = {
  status: "OPEN";
  spots_left: number | null;
  application: ApplicationInfoDTO | null;
};

export type ClosedProgramRegistrationInfo = {
  status: "CLOSED";
  spots_left: number | null;
  is_locked: boolean;
  is_time_elapsed: boolean;
  application: ApplicationInfoDTO | null;
  waitlist: WaitlistInfoDTO | null;
  no_spots_left: boolean;
};

export interface ApplicationInfoDTO {
  button_name: string;
  is_blocking_schedule: boolean;
}

export interface WaitlistInfoDTO {
  policy: string;
  requires_payment_information: boolean;
}
interface _AvailableProgramBase {
  id: string;
  location_id: number;
  schedule_set_id: string;
  schedule_set_name: string;
  program_type: ProgramTypeDTO;
  available_spots: number | null;
  registration: ProgramRegistrationInfo;
  start: ISOString;
  start_date: ISOString;
  end_date: ISOString;
  student_info: TAvailabilityStudentInfo[];
}

export interface IAvailableSessionInProgram {
  id: string;
  date: ISOString;
  start_time: TimeString;
  end_time: TimeString;
  available_spots: number | null;
  participant_limit: number | null;
  participant_count: number;
  is_available_for_schedule_in_customer_hub: boolean;
}
export interface IAvailableDaycampProgram extends _AvailableProgramBase {
  available_drop_ins: IAvailableSessionInProgram[];
  available_drop_in_ids: string[];
  sessions: IAvailableSessionInProgram[];
}

interface OpenSessionRegistrationInfo {
  status: "OPEN";
  spots_left: number | null;
}
interface ClosedSessionRegistrationInfo {
  status: "CLOSED";
  spots_left: number | null;
  is_locked: boolean;
  is_time_elapsed: boolean;
  no_spots_left: boolean;
}

export type SessionRegistrationInfo =
  | OpenSessionRegistrationInfo
  | ClosedSessionRegistrationInfo;

export interface IAvailableDaycampSession {
  id: string;
  schedule_set_id: string;
  schedule_set_name: string;
  schedule_set_description: ISetDescription;
  program_type: ProgramTypeDTO;
  program_id: string;
  location_id: number;
  available_spots: number | null;
  participant_limit: number | null;
  participant_count: number;
  registration: SessionRegistrationInfo;
  start: ISOString;
  date: ISOString;
  start_time: TimeString;
  end_time: TimeString;
  funbox_id: string;
  student_info: TAvailabilityStudentInfo[];
  funbox_mode: FunboxMode;
}

export interface ISetDescription {
  id: string;
  title: string;
  subtitle?: string;
  details: string;
  attachments: IStorageFileDTO[];
  is_public: boolean;
  name?: string;
}

export interface IAvailableDaycampScheduleSet {
  id: string;
  name: string;
  funbox_id: string;
  location_id: number;
  start_date: ISOString;
  end_date: ISOString;
  programs: IAvailableDaycampProgram[];
  selected_description: ISetDescription | null;
  funbox_mode: FunboxMode;
  type: "daycamp";
}

export interface IAvailableOvernightProgram extends _AvailableProgramBase {}

export type TAvailableProgram =
  | IAvailableDaycampProgram
  | IAvailableOvernightProgram;

export type TAvailableScheduleSet =
  | IAvailableDaycampScheduleSet
  | IAvailableOvernightScheduleSet;

export interface IAvailableOvernightScheduleSet {
  id: string;
  name: string;
  funbox_id: string;
  location_id: number;
  start_date: ISOString;
  end_date: ISOString;
  programs: IAvailableOvernightProgram[];
  selected_description: ISetDescription | null;
  funbox_mode: "PROGRAMS";
  type: "overnight";
}

export type SessionInProgram = {
  id: string;
  date: ISOString;
  start_time: TimeString;
  end_time: TimeString;
  participant_limit: number | null;
};

export interface DaycampProgram {
  id: string;
  program_type_id: number;
  program_type_name: string;
  location_id: number;
  is_locked: boolean;
  is_waitlist_enabled: boolean;
  start_date: ISOString;
  end_date: ISOString;
  participant_limit?: number;
  sessions: SessionInProgram[];
}

export interface OvernightProgram {
  id: string;
  program_type_id: number;
  program_type_name: string;
  location_id: number;
  is_locked: boolean;
  is_waitlist_enabled: boolean;
  start_date: ISOString;
  end_date: ISOString;
  participant_limit?: number;
}

export type _BaseScheduleSet = {
  id: string;
  funbox_mode: FunboxMode;
  name: string;
  location_id: number;
  start_date: ISOString;
  end_date: ISOString;
  funbox_id: string;
  selected_description: ISetDescription | null;
};
export type DaycampScheduleSet = _BaseScheduleSet & {
  programs: DaycampProgram[];
};

export type OvernightScheduleSet = _BaseScheduleSet & {
  funbox_mode: "PROGRAMS";
  programs: OvernightProgram[];
};

export type ScheduleSet = DaycampScheduleSet | OvernightScheduleSet;

export const getScheduleSetById = (schedule_set_id: string) =>
  apiService.get<ScheduleSet>(
    `/customer_api/v2/availability/get_schedule_set_by_id/${schedule_set_id}`
  );
