import { observer } from "mobx-react-lite";
import IconTile from "./IconTile";
import useStores from "../../hooks/useStores";
import { ROUTES } from "../../stores/RouterStore";
import React from "react";

export const DirectionsAndDetailsTile = observer(
  ({ locationId }: { locationId: number }) => {
    const { routerStore, funboxStore } = useStores();
    const location = funboxStore.activeLocations.find(
      (e) => e.id === locationId
    )!;

    return (
      <IconTile
        onClick={() => routerStore.navigate(ROUTES.DIRECTIONS_DETAILS)}
        title={"Directions and Details"}
        subtitle={location.address}
        iconBgClassName={"bg-main-color"}
        iconClassName={"locations-icon bg-on-main-color"}
      />
    );
  }
);
