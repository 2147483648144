import React from "react";
import cn from "classnames";
import styles from "../Checkout.module.scss";
import { PriceWithCents } from "@sizdevteam1/funjoiner-uikit";

interface IProps {
  discount: number;
}

const PackageDiscount: React.FC<IProps> = ({ discount }) => {
  return (
    <div className={styles.discount}>
      <div className={cn("text-label")}>Package Discount</div>
      <div className={"text-label"}>
        <PriceWithCents
          amount={discount}
          prefix="-$"
          typography="typography-label"
        />
      </div>
    </div>
  );
};
export default PackageDiscount;
