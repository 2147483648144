import {
  Avatar,
  Button,
  Checkbox,
  Input,
  Label,
  pluralize,
  Splash,
  toOrdinal,
} from "@sizdevteam1/funjoiner-uikit";
import cn from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";
import { Link, Redirect } from "react-router-dom";
import PageBlock from "src/components/PageBlock/PageBlock";
import PageHeader from "src/components/PageHeader";
import PageRoot from "src/components/PageRoot";
import useStores from "src/hooks/useStores";

import { ROUTES } from "src/stores/RouterStore";
import { defineRoute } from "src/util/typedRouting";
import {
  IBookingResult,
  IDaycampScheduleSession,
  IOvernightScheduleProgram,
  ScheduleSharingVMProvider,
  useScheduleSharingPageVM,
} from "./ScheduleSharingPageVM";

import dayjs from "dayjs";
import classNames from "classnames";
import formatStartEndDate from "../../util/formatStartEndDate";
import { assertNever } from "@sizdevteam1/funjoiner-uikit/util";
import formatStartEndTime from "../../util/formatStartEndTime";
import * as Sentry from "@sentry/react";

export const scheduleSharingRoute = defineRoute<{
  state: Partial<{
    bookingResult: IBookingResult;
    routeFrom: string;
  }>;
}>({
  path: "/schedule-sharing",
  build: (path) => path,
});

const ScheduleSharingPage: React.FC = observer(() => {
  const { state } = scheduleSharingRoute.useParams();
  if (state?.bookingResult == null || state.routeFrom == null) {
    return <Redirect to={ROUTES.ROOT} />;
  }
  if (state.bookingResult.attendanceIds.length === 0) {
    Sentry.captureMessage("Booking result has no attendance ids");
    return <Redirect to={ROUTES.ROOT} />;
  }
  return (
    <ScheduleSharingVMProvider
      bookingResult={state.bookingResult}
      routeFrom={state.routeFrom}
    >
      <ScheduleSharingPageImpl />
    </ScheduleSharingVMProvider>
  );
});

const ScheduleSharingPageImpl = observer(() => {
  const { customerStore, commonStore } = useStores();
  const vm = useScheduleSharingPageVM();
  return (
    <PageRoot>
      <PageHeader showBackLink>Email Friends & Family</PageHeader>
      {vm.loading ? (
        <Splash pathToAnimation={"/splash.json"} />
      ) : vm.hasInitializationError ? (
        <div>Error happened during the load of schedule information</div>
      ) : (
        <div className="flex flex-col gap-6">
          <div className="typography-main text-gray-text-color">
            You successfully scheduled! Share your plans with your family and
            friends, so they can join the fun too! Email Preview Below:
          </div>
          <div className="typography-h3 flex justify-between">
            {pluralize("Receiver", vm.emailInputs.length)} Email{" "}
            {vm.isSingleEmailInput ? "Address" : "Addresses"}
            <Button onClick={vm.addNewEmailField} kind="text" className="">
              <i className="icon small-add-icon" />
              Add More
            </Button>
          </div>
          <div className="flex flex-col gap-6">
            {vm.emailInputs.map((i, index) => (
              <EmailFieldRow
                key={index}
                value={i.value}
                index={index}
                onChange={vm.onChangeValue}
                errorText={i.errorText}
                deleteField={vm.deleteEmailFieldByIndex}
                isSingleEmailInput={vm.isSingleEmailInput}
              />
            ))}
          </div>
          <Checkbox
            checked={vm.includeSchedule}
            onChange={vm.setIncludeSchedule}
          >
            Include your Schedule
          </Checkbox>
          <PageBlock className={"typography-main mb-10 flex flex-col gap-3"}>
            <div>Hey there,</div>
            <div>
              Hope you are doing well. I’ve just scheduled{" "}
              {vm.programTypeNamesString}{" "}
              {pluralize("reservation", vm.programTypeNames.length)} with{" "}
              {commonStore.companyProfile.name}. I’m inviting you to check out{" "}
              <Link to={window.location.hostname}>
                {window.location.hostname}
              </Link>{" "}
              because I believe they are a worthwhile resource for you.
            </div>
            <div>
              Best Regards,
              <br />
              {customerStore.customer.full_name}
              <br />
              {customerStore.customer.phone_number}
            </div>
            {vm.includeSchedule && (
              <>
                <div className={"h-[1px] bg-separator-color"} />
                <div className={"flex flex-row justify-center gap-1"}>
                  <i className={"icon locations-icon bg-main-color"} />
                  <div className={"typography-main text-text-color"}>
                    {vm.scheduleLocationName}
                  </div>
                </div>
                <Schedule />
              </>
            )}
          </PageBlock>
        </div>
      )}
      <div className="sticky bottom-3 mt-auto">
        <Button
          loading={vm.isSendingEmails}
          disabled={vm.emailInputs.length === 0}
          onClick={vm.sendScheduleShareEmails}
          className="w-full"
          size="big"
        >
          Send
        </Button>
      </div>
    </PageRoot>
  );
});

const Schedule = observer(() => {
  const vm = useScheduleSharingPageVM();
  return (
    <div className={"flex flex-col gap-6"}>
      {Array.from(vm.schedules.entries()).map(([studentId, items]) => {
        const student = items[0].student;
        return (
          <div className={"flex flex-col gap-2"} key={student.id}>
            <div className={"flex flex-row items-center gap-3"}>
              <Avatar person={student} avatarSize={"44px"} />
              <div className={"typography-h3 text-text-color"}>
                {student.full_name}
              </div>
            </div>
            {items.map((element) => {
              if (element.type === "daycamp") {
                return (
                  <ScheduleWidget
                    title={`${element.scheduleSetName}. ${element.programTypeName}`}
                    items={element.sessions}
                    itemProps={(item) => ({
                      leftText: dayjs(`${item.date}T${item.start_time}`).format(
                        "MMM Do"
                      ),
                      rightText: formatStartEndTime(item),
                    })}
                  />
                );
              } else if (element.type === "overnight") {
                return (
                  <ScheduleWidget
                    title={element.scheduleSetName}
                    items={element.programs}
                    itemProps={(item) => ({
                      leftText: item.programTypeName,
                      rightText: formatStartEndDate({
                        start_date: item.startDate,
                        end_date: item.endDate,
                      }),
                    })}
                  />
                );
              } else {
                return assertNever(element);
              }
            })}
          </div>
        );
      })}
    </div>
  );
});
function ScheduleWidget<
  T extends IOvernightScheduleProgram | IDaycampScheduleSession
>(props: {
  title: string;
  items: T[];
  itemProps: (item: T) => {
    leftText: string;
    rightText: string;
  };
}) {
  return (
    <div
      className={
        "flex flex-col overflow-clip rounded-[8px] bg-card-color shadow-big"
      }
    >
      <div className={"typography-h3 px-4 py-3 text-text-color"}>
        {props.title}
      </div>
      {props.items.map((element, index) => {
        const itemProps = props.itemProps(element);
        return (
          <div
            key={index}
            className={classNames(
              "flex flex-row justify-between px-3 py-2",
              index % 2 === 0 ? "bg-table-row-color" : "bg-on-main-color"
            )}
          >
            <div className={"typography-main text-text-color"}>
              {itemProps.leftText}
            </div>
            <div className={"typography-main text-gray-text-color"}>
              {itemProps.rightText}
            </div>
          </div>
        );
      })}
    </div>
  );
}

const EmailFieldRow: React.FC<{
  index: number;
  value: string;
  onChange: (value: string, index: number) => void;
  deleteField: (index: number) => void;
  errorText?: string;
  isSingleEmailInput: boolean;
}> = ({
  index,
  value,
  onChange,
  errorText,
  deleteField,
  isSingleEmailInput,
}) => {
  return (
    <div>
      {!isSingleEmailInput && <Label>{toOrdinal(index + 1)} Email</Label>}
      <div
        className={cn(
          "items-center justify-between",
          !isSingleEmailInput && "flex"
        )}
      >
        <Input
          className={cn(!isSingleEmailInput && "w-[334px]")}
          type={"email"}
          value={value}
          errorText={errorText}
          onChange={(e) => {
            onChange(e.currentTarget.value, index);
          }}
        />
        {!isSingleEmailInput && (
          <i
            onClick={() => deleteField(index)}
            className="icon cross-icon icon-hover"
          />
        )}
      </div>
    </div>
  );
};
export default ScheduleSharingPage;
