import React from "react";
import { observer } from "mobx-react-lite";
import useStores from "src/hooks/useStores";
import PageRoot from "src/components/PageRoot";

import AttentionMessage from "src/components/AttentionMessage";
import styles from "./DirectionsPage.module.scss";
import LocationInfo from "../../components/LocationInfo";
import PageHeader from "../../components/PageHeader";

interface IProps {}

const DirectionsPage: React.FC<IProps> = observer(() => {
  const {
    funboxStore: { activeLocations, selectedLocation },
    commonStore,
  } = useStores();
  return (
    <PageRoot className={styles.rules}>
      <PageHeader showBackLink={true}>Locations</PageHeader>
      <AttentionMessage
        text={commonStore.publicSettings.attention_message.text}
        enabled={commonStore.publicSettings.attention_message.enabled}
        color="#F55050"
      />
      {activeLocations.map((location) => (
        <LocationInfo
          key={location.id}
          location={location}
          alwaysExpanded={activeLocations.length === 1}
          defaultExpandedLocationId={selectedLocation?.id}
        />
      ))}
    </PageRoot>
  );
});

export default DirectionsPage;
