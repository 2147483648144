import {
  Button,
  Gallery,
  getFileType,
  Image,
  Input,
  Label,
  MarkdownText,
  Media,
  Modal,
  useOnClickOutside,
} from "@sizdevteam1/funjoiner-uikit";
import classNames from "classnames";
import React, { useLayoutEffect, useRef, useState } from "react";
import { IStorageFileDTO } from "src/services/api";
import storageFileResolver from "src/services/storageFileResolver";
import { twMerge } from "tailwind-merge";
import Dot from "../Dot/Dot";
import {
  IDescriptionData as ISetDescriptionModalData,
  ScheduleSetDescriptionModalVM,
} from "./ScheduleSetDescriptionModalVM";
import styles from "./ScheduleSetDescriptionModal.module.scss";
import notificator from "src/services/systemNotifications/notificationCenterService";
import { observer } from "mobx-react-lite";
import useStores from "src/hooks/useStores";

const ScheduleSetDescriptionModal = observer(
  ({
    descriptionData,
    onClose,
    isNavigateToScheduleButtonVisible,
  }: {
    descriptionData: ISetDescriptionModalData;
    onClose: () => void;
    isNavigateToScheduleButtonVisible: boolean;
  }) => {
    const { routerStore, authStore } = useStores();
    const [vm] = useState(
      () =>
        new ScheduleSetDescriptionModalVM(
          descriptionData,
          routerStore,
          authStore,
          onClose,
          isNavigateToScheduleButtonVisible
        )
    );

    if (!vm.description) return null;

    return (
      <Modal
        contentClassName={twMerge("p-6  lg:px-8 lg:py-6")}
        containerClassName={twMerge(
          "max-w-[390px] min-w-[390px]  lg:max-w-[931px]"
        )}
        onClose={vm.onClose}
      >
        <div>
          <div className="flex w-full  items-end justify-between lg:items-center">
            <div className="typography-label text-gray-text-color   lg:typography-h3">
              {vm.setDatesString}
            </div>
            <Button onClick={vm.onClose} className="ml-auto" kind="icon">
              <i className="icon cross-icon !bg-icon-grey-color " />
            </Button>
          </div>

          <div className="mt-2 flex items-center justify-between gap-2">
            <div
              className={twMerge(
                "typography-h2 break-words  text-header-color lg:typography-h1",
                "max-w-[261px]",
                "lg:max-w-[802px]"
              )}
            >
              {vm.description.title}
            </div>
            <ShareComponent vm={vm} />
          </div>
          {vm.description.subtitle && (
            <div className="typography-main_sb mt-3 text-start  text-text-color lg:typography-h2 lg:mt-4">
              {vm.description.subtitle}
            </div>
          )}
          {vm.description.attachments.length > 0 && (
            <Slider
              className={twMerge("mt-3 flex  lg:hidden ")}
              attachments={vm.description.attachments}
            />
          )}
          <MarkdownText className="typography-main  mt-2  text-start text-gray-text-color lg:mt-4">
            {vm.description.details}
          </MarkdownText>
        </div>
        <Gallery
          getSignedLink={storageFileResolver.getSignedLink}
          preSelectedFileIndex={0}
          className={twMerge("mt-6 hidden lg:flex")}
          files={vm.description.attachments}
        />
        {vm.isNavigateToScheduleButtonVisible && (
          <Button
            className="mt-4 w-full"
            size="big"
            color="orange"
            onClick={vm.navigateToSchedule}
          >
            Schedule this Option
          </Button>
        )}
      </Modal>
    );
  }
);

const ShareComponent = observer(
  ({ vm }: { vm: ScheduleSetDescriptionModalVM }) => {
    const { authStore } = useStores();
    const [isShareOpen, setIsShareOpen] = useState(false);
    const [isEmailOptionActive, setIsEmailOptionActive] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    const isEmailOptionVisible = authStore.loggedIn;

    useOnClickOutside(containerRef, () => {
      setIsShareOpen(false);
      setIsEmailOptionActive(false);
      vm.emailError = null;
      vm.email = "";
    });

    return (
      <div className="relative">
        <Button
          onClick={() => setIsShareOpen(!isShareOpen)}
          color="purple"
          kind="text"
          className={classNames(
            isShareOpen && "!text-surface-dark-purple-color"
          )}
        >
          <i
            className={classNames(
              "icon share-icon !h-6 !w-6",
              isShareOpen && "!bg-surface-dark-purple-color"
            )}
          />
          Share
        </Button>
        {isShareOpen && (
          <div
            ref={containerRef}
            className={twMerge(
              `border-1 absolute top-8 right-0 z-50 flex flex-col items-end justify-between gap-4  rounded-2xl rounded-tr-none border-solid
      border-separator-color bg-on-main-color p-4 shadow-big`,
              "delay-50 transition-width h-[137px] min-w-[72px] duration-[300ms] ease-linear lg:h-[52px] lg:min-w-[178px] lg:flex-row lg:items-center ",
              !isEmailOptionVisible && "!h-fit min-h-fit  !min-w-fit",
              isEmailOptionActive &&
                "h-max min-w-[306px] lg:h-auto lg:min-w-[556px]"
            )}
          >
            <div
              onClick={() => {
                navigator.clipboard.writeText(vm.shareLink.href);
                vm.onClose();
                notificator.success("Success", "URL Link Copied to Clipboard");
              }}
              className="group group flex min-w-[68px] cursor-pointer flex-col items-center gap-1"
            >
              <i className="icon connect-icon !h-8 !w-8 bg-main-color group-hover:bg-dark-main-color" />
              <div className="typography-small whitespace-nowrap  text-gray-text-color">
                Copy Link
              </div>
            </div>
            {isEmailOptionVisible && (
              <div
                className={twMerge(
                  " h-[1px] w-full bg-separator-color",
                  "  lg:h-[52px] lg:w-[1px]"
                )}
              ></div>
            )}
            {isEmailOptionVisible && !isEmailOptionActive && (
              <div
                onClick={() => {
                  setIsEmailOptionActive(true);
                  setTimeout(() => {
                    inputRef.current?.focus();
                  });
                }}
                className={classNames(
                  "group flex min-w-[68px] cursor-pointer flex-col items-center gap-1 transition-all"
                )}
              >
                <i className="icon emails-icon !h-8 !w-8 bg-main-color group-hover:bg-dark-main-color" />
                <div className="typography-small text-gray-text-color ">
                  Email
                </div>
              </div>
            )}
            {isEmailOptionVisible && (
              <div
                className={classNames(
                  "hidden h-0 w-0 items-end gap-3",
                  isEmailOptionActive && "!flex !h-fit !w-full",
                  "lg:!h-[48px]"
                )}
              >
                <div className="w-full">
                  <Label className="whitespace-nowrap lg:hidden">
                    Share via Email
                  </Label>
                  <Input
                    ref={inputRef}
                    placeholder="Receiver Email"
                    value={vm.email}
                    errorText={vm.emailError}
                    onChange={({ currentTarget }) => {
                      vm.email = currentTarget.value;
                      vm.emailError = null;
                    }}
                  />
                </div>
                <Button
                  className="h-[48px]"
                  autoLoading
                  onClick={vm.sendEmail}
                  size="big"
                >
                  Send
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
);

const Slider = ({
  attachments,
  className,
}: {
  attachments: IStorageFileDTO[];
  className?: string;
}) => {
  const [selectedAttachment, setSelectedAttachment] = useState<IStorageFileDTO>(
    attachments[0]
  );
  const [selectedSlideIndex, setSelectedSlideIndex] = useState(0);

  useLayoutEffect(() => {
    if (ref.current) {
      const index = attachments.indexOf(selectedAttachment);

      ref.current.scrollLeft = index * 366;
    }
  }, [selectedAttachment, attachments]);

  const ref = useRef<HTMLDivElement>(null);

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const scrollLeft = event.currentTarget.scrollLeft;
    const slideWidth = 366;
    const newSlideIndex = Math.round(scrollLeft / slideWidth);
    setSelectedSlideIndex(newSlideIndex);
  };

  return (
    <div className={twMerge("flex flex-col ", className)}>
      <div
        ref={ref}
        onScroll={handleScroll}
        className={classNames(
          "flex h-[226px] max-w-[342px] items-center   gap-6 overflow-x-scroll scroll-smooth rounded-[8px] transition-all ",
          styles.hideScroll
        )}
      >
        {attachments.map((a, index) => (
          <AttachmentComponent file={a} key={index} />
        ))}
      </div>
      {attachments.length > 1 && (
        <div className="mx-auto flex gap-2 py-3">
          {attachments.map((a, index) => (
            <Dot
              key={a.key}
              onClick={() => setSelectedAttachment(a)}
              dotClassName={classNames(
                "h-2 w-2 cursor-pointer transition-all ease-linear",
                index === selectedSlideIndex
                  ? "!bg-icon-grey-color"
                  : "!bg-separator-color"
              )}
            />
          ))}
        </div>
      )}
    </div>
  );
};
const AttachmentComponent = ({ file }: { file: IStorageFileDTO }) => {
  const fileType = getFileType(file.download_url);

  const AttachmentComponent = {
    media: (
      <div className="min-w-[342px] bg-on-main-color">
        <Media
          source={{
            getSignedLink: storageFileResolver.getSignedLink,
            file: file,
          }}
          className="max-h-[210px] w-full max-w-[342px] rounded-[8px] "
        />
      </div>
    ),
    image: (
      <div className="w-[342px]">
        <Image
          fromFadeEffect
          className="!flex  min-h-[24px] justify-center"
          imageClassName="object-cover max-w-[342px] max-h-[210px] rounded-[8px]"
          src={{
            getSignedLink: storageFileResolver.getSignedLink,
            file: file,
          }}
        />
      </div>
    ),
    document: null,
    unknown: null,
    no_file: null,
  };
  return AttachmentComponent[fileType];
};

export default ScheduleSetDescriptionModal;
