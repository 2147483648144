import React from "react";
import cn from "classnames";
import useStores from "src/hooks/useStores";
import FunJoinerLogo from "src/assets/imgs/fj_logo_sm.svg";
import styles from "./ContactsAndTerms.module.scss";
import dayjs from "dayjs";
import formatPhone from "../../util/formatPhone";

const ContactsAndTerms: React.FC<{ hideContacts?: boolean }> = (props) => {
  const {
    commonStore: { companyProfile },
  } = useStores();

  const linkProps = {
    className: "text-small",
    href: "https://funjoin.com/",
    target: "_blank",
    rel: "noreferrer",
  };
  return (
    <div className={cn(styles.footer)}>
      {companyProfile?.phone && !props.hideContacts && (
        <a
          className={cn("text-main text-gray", styles.phone)}
          href={`tel:${companyProfile.phone}`}
        >
          {formatPhone(companyProfile.phone)}
        </a>
      )}
      <div className={styles.social}>
        {companyProfile?.social.pinterest && (
          <a
            href={companyProfile.social.pinterest}
            target={"_blank"}
            rel="noreferrer"
          >
            <i className={"icon pinterest-icon icon-hover"} />
          </a>
        )}
        {companyProfile?.social.facebook && (
          <a
            href={companyProfile.social.facebook}
            target={"_blank"}
            rel="noreferrer"
          >
            <i className={"icon facebook-icon icon-hover"} />
          </a>
        )}
        {companyProfile?.social.twitter && (
          <a
            href={companyProfile.social.twitter}
            target={"_blank"}
            rel="noreferrer"
          >
            <i className={"icon twitter-icon icon-hover"} />
          </a>
        )}
        {companyProfile?.social.instagram && (
          <a
            href={companyProfile.social.instagram}
            target={"_blank"}
            rel="noreferrer"
          >
            <i className={"icon instagram-icon icon-hover"} />
          </a>
        )}
        {companyProfile?.social.youtube && (
          <a
            href={companyProfile.social.youtube}
            target={"_blank"}
            rel="noreferrer"
          >
            <i className={"icon youtube-icon icon-hover"} />
          </a>
        )}
      </div>
      {companyProfile?.email && !props.hideContacts && (
        <a
          className={cn("text-main text-gray", styles.email)}
          href={`mailto:${companyProfile.email}`}
        >
          {companyProfile.email}
        </a>
      )}
      <span className={cn(styles.terms, "text-gray typography-main")}>
        <a
          style={{ fontWeight: 300 }}
          href={companyProfile?.documents.cancellation_policy}
          target={"_blank"}
          rel="noreferrer"
        >
          Policy
        </a>
        &nbsp; • &nbsp;
        <a
          href={companyProfile?.documents.terms_of_service}
          target={"_blank"}
          rel="noreferrer"
        >
          Terms of Service
        </a>
      </span>
      <div className={styles.separator} />

      <footer className={styles.fj}>
        <a {...linkProps}>
          <img src={FunJoinerLogo} alt={"“FUNJOIN SOFTWARE"} />
        </a>
        <div className={cn(styles.textWrapper, "text-small text-gray")}>
          <p>
            Powered by
            <a {...linkProps}>FunJoin</a>
          </p>
          <p>© {dayjs().format("YYYY")} All Rights Reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default ContactsAndTerms;
