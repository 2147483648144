import React from "react";
import { observer } from "mobx-react-lite";
import PageRoot from "src/components/PageRoot";
import DocumentItem from "./components/DocumentItem";
import { DocumentsPageVMProvider, useDocumentsPageVM } from "./DocumentsPageVM";
import styles from "./styles.module.scss";
import PageHeader from "../../components/PageHeader";
import { Splash } from "@sizdevteam1/funjoiner-uikit";
import StudentsSelector from "../../components/StudentSelector";
import { CompletenessPercentage } from "src/components/CompletenessPercentage";
import { defineRoute } from "../../util/typedRouting";

export const documentsPageRoute = defineRoute<{
  searchParams: {
    selectedStudentId?: string;
  };
}>({
  path: "/documents/",
  build: (path) => path,
});
const DocumentsPage: React.FC = () => (
  <DocumentsPageVMProvider>
    <DocumentsPageImpl />
  </DocumentsPageVMProvider>
);

const DocumentsPageImpl: React.FC = observer(() => {
  const vm = useDocumentsPageVM();

  return (
    <PageRoot className={styles.documents}>
      <PageHeader showBackLink={true}>Documents</PageHeader>
      {vm.loading ? (
        <Splash pathToAnimation={"/splash.json"} />
      ) : (
        <>
          {vm.students.length > 0 && (
            <StudentsSelector
              className={"mb-4"}
              students={vm.students}
              onSelectStudent={(s) => vm.setSelectedStudentId(s.id)}
              selectedStudentId={vm.selectedStudent?.id}
              renderCompleteness={(s) => (
                <CompletenessPercentage
                  className="typography-label"
                  percentage={vm.completenessFor(s.id)}
                />
              )}
            />
          )}
          <div className="flex flex-col gap-6">
            {!vm.hasDocuments && (
              <div className={"typography-h3"}>No Documents found</div>
            )}
            {vm.unsignedDocuments.length > 0 && (
              <div className="typography-h2">
                Required Documents
                {vm.unsignedDocuments.map((document) => (
                  <DocumentItem key={document.id} document={document} />
                ))}
              </div>
            )}
            {vm.signedAndNotExpiredDocuments.length > 0 && (
              <div className="typography-h2">
                Completed Documents
                {vm.signedAndNotExpiredDocuments.map((document) => (
                  <DocumentItem key={document.id} document={document} />
                ))}
              </div>
            )}
            {vm.expiredDocuments.length > 0 && (
              <div className="typography-h2">
                Expired Documents
                {vm.expiredDocuments.map((document) => (
                  <DocumentItem key={document.id} document={document} />
                ))}
              </div>
            )}
          </div>
        </>
      )}
    </PageRoot>
  );
});

export default DocumentsPage;
