import classNames from "classnames";
import React from "react";

interface Props {
  className?: string;
  dotClassName?: string;
  onClick?: () => void;
}

const Dot: React.FC<Props> = ({
  dotClassName,
  className,
  children,
  onClick,
}) => {
  return (
    <div
      onClick={() => onClick?.() ?? undefined}
      className={classNames("flex flex-row items-center", className)}
    >
      <span
        className={classNames(
          "h-[6px] w-[6px] rounded-full bg-dark-main-color ",
          dotClassName
        )}
      />
      {children}
    </div>
  );
};

export default Dot;
