import React from "react";
import { observer } from "mobx-react-lite";
import PageRoot from "../../components/PageRoot";
import { Image } from "@sizdevteam1/funjoiner-uikit";
import ThankYouImg from "../../assets/imgs/thankyou.svg";
import { ROUTES } from "../../stores/RouterStore";
import { Link } from "react-router-dom";

const ScheduleSuccessPage: React.FC = observer(() => {
  return (
    <PageRoot>
      <ThankYouHeader />
      <div className={"typography-h2 mt-6 text-header-color"}>What's Next?</div>
      <div
        className={
          "typography-main mt-4 rounded-[10px] bg-on-main-color p-5 text-text-color"
        }
      >
        View your Applications on <Link to={ROUTES.DASHBOARD}>Dashboard</Link>
      </div>
    </PageRoot>
  );
});

const ThankYouHeader = observer(() => {
  return (
    <div className={"rounded-[16px] bg-on-main-color p-6"}>
      <div className="flex flex-col  items-center gap-4">
        <Image
          imageClassName="!h-[156px] !w-366px]"
          src={ThankYouImg}
          alt="success"
        />
        <div className="typography-h2 flex flex-col items-center gap-2 text-header-color">
          <div>Thank You!</div>
          <div>Application Received!</div>
        </div>
      </div>
    </div>
  );
});

export default ScheduleSuccessPage;
