import apiService from "./apiService";
import { TSignUpDTO } from "./orders";
import { ISOString, TimeString } from "@sizdevteam1/funjoiner-uikit/types";
import { IPagination, IPaginationParams } from "./common";
import { ICreditDTO } from "./profile";

export const signUp = (dto: TSignUpDTO[]): Promise<string[]> =>
  apiService.post("/customer_api/v2/attendances/sign_up", { data: dto });

export const getStudentSchedule = (
  studentId: number,
  searchParams: {
    from_date: ISOString;
    is_archive?: boolean;
    funbox_id?: string;
  },
  paginationParams: IPaginationParams = { limit: 25, offset: 0 }
): Promise<IPagination<TAttendanceDTO>> =>
  apiService.get(`/customer_api/v2/attendances/student/${studentId}`, {
    searchParams: { ...searchParams, ...paginationParams },
  });

export const cancel = (attendanceId: string): Promise<void> =>
  apiService.post(`/customer_api/v2/attendances/${attendanceId}/cancel`);

type TRescheduleToDTO =
  | {
      new_schedule_set_id: string;
      new_session_id: string;
      new_program_id: string;
    }
  | {
      new_schedule_set_id: string;
      new_program_id: string;
    };
export const reschedule = (
  attendanceId: string,
  data: TRescheduleToDTO
): Promise<void> =>
  apiService.post(`/customer_api/v2/attendances/${attendanceId}/reschedule`, {
    data,
  });

export const shareSchedule = (data: {
  recipients: string[];
  attendance_ids: string[];
  include_schedule: boolean;
}) =>
  apiService.post(`/customer_api/v2/attendances/schedule/share`, {
    data,
  });

export const shareScheduleSet = (data: {
  recipient: string;
  schedule_set_id: string;
}) =>
  apiService.post(`/customer_api/v2/attendances/share_schedule_set`, {
    data,
  });

export const getAttendancesByIds = (ids: string[]): Promise<TAttendanceDTO[]> =>
  apiService.get("/customer_api/v2/attendances/by_ids", {
    searchParams: { ids: ids },
  });

export type TCheckinStatus =
  | "signed_up"
  | "checked_in"
  | "no_show"
  | "checked_out";

interface _AttendanceBaseDTO {
  id: string;
  company_id: string;
  location_id: number;
  location_name: string;
  funbox_id: string;
  funbox_name: string;
  schedule_set_id: string;
  schedule_set_name: string;
  program_id: string;
  credit_id: number;
  credit: ICreditDTO;
  student_id: number;
  program_type_id: number;
  program_type_name: string;
  credit_type_id: number;
  credit_type_name: string;
  created_at: ISOString;
  start: ISOString;
  end: ISOString;
}

export interface IOvernightProgramAttendanceDTO extends _AttendanceBaseDTO {
  type: "program";
  start_date: ISOString;
  end_date: ISOString;
  status: TCheckinStatus;
}

export interface IAttendanceItem {
  session_id: string;
  date: ISOString;
  start_time: TimeString;
  end_time: TimeString;
  status: TCheckinStatus;
}

export interface IProgramOfSessionsAttendanceDTO extends _AttendanceBaseDTO {
  type: "program_of_sessions";
  items: IAttendanceItem[];
}

export interface ISessionInProgramAttendanceDTO extends _AttendanceBaseDTO {
  type: "session_in_program";
  session_id: string;
  date: ISOString;
  start_time: TimeString;
  end_time: TimeString;
  status: TCheckinStatus;
}

export type TAttendanceDTO =
  | IOvernightProgramAttendanceDTO
  | IProgramOfSessionsAttendanceDTO
  | ISessionInProgramAttendanceDTO;
