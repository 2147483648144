import React from "react";
import cn from "classnames";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import PageRoot from "src/components/PageRoot";
import {
  Avatar,
  Button,
  CounterBubble,
  Loader,
  PriceWithCents,
  Splash,
} from "@sizdevteam1/funjoiner-uikit";
import CreditItem from "./components/CreditItem";
import Invoice from "./components/Invoice";
import YourSchedule from "./components/YourSchedule/YourSchedule";
import { DashboardPageVMProvider, useDashboardPageVM } from "./DashboardPageVM";
import { ROUTES } from "src/stores/RouterStore";
import styles from "./styles.module.scss";
import StudentSelector from "../../components/StudentSelector";
import useStores from "src/hooks/useStores";
import AttentionMessage from "src/components/AttentionMessage";
import ScheduleFiltersModal from "./components/ScheduleFiltersModal";
import { IScheduleAndPayOrderWithPaymentPLanDTO } from "src/services/api/orders";
import dayjs from "dayjs";
import { formatDate } from "@sizdevteam1/funjoiner-uikit/util";
import { PieChartCompletenessCard } from "../../components/PieChartCompletenessCard";

const DashboardPage: React.FC = () => (
  <DashboardPageVMProvider>
    <DashboardPageImpl />
  </DashboardPageVMProvider>
);

const DashboardPageImpl: React.FC = observer(() => {
  const vm = useDashboardPageVM();
  const { funboxStore, commonStore, routerStore } = useStores();
  const unspentCreditTypeIds = Object.keys(vm.unspentCreditsByType).map(Number);
  const hasUnspentCredits = unspentCreditTypeIds.length > 0;
  const hasStudents = vm.students.length > 0;

  return (
    <>
      {vm.loading ? (
        <Splash pathToAnimation={"/splash.json"} />
      ) : (
        <PageRoot className={"!min-h-fit"}>
          <div className={"typography-h1 mb-6 text-header-color"}>
            My Dashboard
          </div>
          <AttentionMessage
            color="#0BA9DA"
            text={commonStore.publicSettings.dashboard_message.text}
            enabled={commonStore.publicSettings.dashboard_message.enabled}
            onClose={vm.onCloseDashboardMessage}
            visibleCondition={vm.isDashboardMessageVisible}
          />
          {(vm.isFormsBlockVisible || vm.isDocumentsBlockVisible) && (
            <DocsAndSmartFormsBlock />
          )}
          {vm.ordersWithIncompletePaymentPlans.length > 0 && <PaymentPlans />}

          {vm.hasPendingInvoices && <Invoices />}
          {vm.hasApplicationWaitlistRecords && (
            <ApplicationsAndWaitlistsBlock />
          )}
          <div className={styles.title}>
            <div className={"text-h2"}>Unused Credits</div>
            {funboxStore.isBuyCreditsAndSaveEnabled &&
              funboxStore.hasPaidCreditTypes &&
              hasUnspentCredits && (
                <Link to={ROUTES.FLEXIBLE_PAYMENTS}>
                  <Button kind={"text"}>
                    <i className={"icon credit-types-icon !h-6 !w-6"} />
                    Buy Credits
                  </Button>
                </Link>
              )}
          </div>
          {hasUnspentCredits ? (
            unspentCreditTypeIds.map((credit_type_id) => (
              <CreditItem
                key={credit_type_id}
                creditType={vm.creditTypesById[credit_type_id]}
                amount={vm.unspentCreditsByType[credit_type_id]}
                onUpgradeClick={
                  vm.creditTypesById[credit_type_id].upgrade_options.length >
                    0 && commonStore.publicSettings.credits.upgrades_by_customer
                    ? vm.navigateToUpgradeCreditPage
                    : undefined
                }
              />
            ))
          ) : (
            <NoUnusedCredits />
          )}

          <div className={"typography-h2 mt-8 flex justify-between"}>
            Schedules
            {hasStudents && <ScheduleFilter />}
          </div>
          <div className={cn("text-main mt-2 text-gray-text-color")}>
            {hasStudents
              ? "Select a participant to view their Schedule"
              : "You have no registered participants"}
          </div>
          {!hasStudents && (
            <Button
              size="big"
              onClick={() => routerStore.navigate(ROUTES.PROFILE)}
              kind="secondary"
              className="mt-4"
            >
              <i className="icon add-participant-icon !h-6 !w-6" />
              Add Participant(s) in Your Profile
            </Button>
          )}

          <StudentSelector
            className={classNames("my-[16px]")}
            onSelectStudent={vm.handleSelectStudent}
            selectedStudentId={vm.selectedStudent?.id}
            students={vm.students}
          />
          {vm.isScheduleLoading ? (
            <Loader />
          ) : (
            vm.selectedStudent && <YourSchedule />
          )}
          {vm.isScheduleFilterModalOpened && <ScheduleFiltersModal />}
        </PageRoot>
      )}
    </>
  );
});

const PaymentPlans = observer(() => {
  const vm = useDashboardPageVM();

  return (
    <div className="mb-8 flex flex-col gap-4">
      <div className="typography-h2 text-text-color">Payment Plans</div>
      {vm.ordersWithIncompletePaymentPlans.map((o) => (
        <PaymentPlan key={o.id} order={o} />
      ))}
    </div>
  );
});

const PaymentPlan = observer(
  ({ order }: { order: IScheduleAndPayOrderWithPaymentPLanDTO }) => {
    if (!order.payment_plan) return null;

    const failedInstallments = order.payment_plan.installments.filter(
      (i) => !i.is_paid && i.is_missed
    );
    const pendingInstallments = order.payment_plan.installments.filter(
      (i) => !i.is_paid && !i.is_missed
    );

    return (
      <div className="flex flex-col rounded-[10px] bg-on-main-color px-4 py-3 shadow-big">
        <div className="flex items-center justify-between">
          <div className="flex flex-col gap-[2px]">
            <span className="typography-h4 text-text-color">
              Order {formatDate(order.created_at)}
            </span>
            <span className="typography-small text-gray-text-color">
              {failedInstallments.length > 0 ? (
                <span className="text-delete-color">Last Payment Failed </span>
              ) : (
                `Next Payment on ${dayjs(
                  pendingInstallments[0].due_date
                ).format("MMM Do")}`
              )}
            </span>
          </div>
          <PriceWithCents
            amount={order.final_price}
            prefix="$"
            typography="typography-h2"
          />
        </div>
        <Installments order={order} />
      </div>
    );
  }
);

const Installments = observer(
  ({ order }: { order: IScheduleAndPayOrderWithPaymentPLanDTO }) => {
    const vm = useDashboardPageVM();
    const installments = order.payment_plan?.installments;
    if (!installments) return null;

    const paidInstallments = installments.filter((i) => i.is_paid);
    const pendingInstallments = installments.filter(
      (i) => !i.is_paid && !i.is_missed
    );
    const failedInstallments = installments.filter(
      (i) => i.is_missed && !i.is_paid
    );

    const totalPaidAmount = paidInstallments.reduce((acc, item) => {
      return acc + item.amount;
    }, 0);

    const totalRemainingAmount = pendingInstallments.reduce((acc, item) => {
      return acc + item.amount;
    }, 0);

    const totalFailedAmount = failedInstallments.reduce((acc, item) => {
      return acc + item.amount;
    }, 0);

    return (
      <div className="mt-4 flex flex-col gap-2">
        <div
          className="grid  grid-cols-5 grid-rows-[6px_1fr]  gap-[2px]"
          style={{
            gridTemplateColumns: `repeat(${installments.length},1fr`,
          }}
        >
          {paidInstallments.map((i) => (
            <div key={i.id} className="h-[6px] rounded bg-main-color"></div>
          ))}
          {failedInstallments.map((i) => (
            <div key={i.id} className="h-[6px] rounded bg-delete-color"></div>
          ))}
          {pendingInstallments.map((i) => (
            <div
              key={i.id}
              className="h-[6px] rounded bg-separator-color"
            ></div>
          ))}
          {totalPaidAmount > 0 && (
            <div
              style={{
                gridColumnStart: installments.indexOf(paidInstallments[0]) + 1,
                gridColumnEnd: paidInstallments.length + 1,
              }}
              className="typography-label  py-1 text-text-color "
            >
              <PriceWithCents
                amount={totalPaidAmount}
                prefix="$"
                typography="typography-label"
              />
            </div>
          )}
          {totalFailedAmount > 0 && (
            <div
              style={{
                gridColumnStart:
                  installments.indexOf(failedInstallments[0]) + 1,
                gridColumnEnd:
                  installments.indexOf(
                    failedInstallments[failedInstallments.length - 1]
                  ) + 2,
              }}
              className="typography-label mx-auto  py-1 text-center text-delete-color"
            >
              <PriceWithCents
                amount={totalFailedAmount}
                prefix="$"
                typography="typography-label"
              />
            </div>
          )}
          {totalRemainingAmount > 0 && (
            <div
              style={{
                gridColumnStart:
                  installments.indexOf(pendingInstallments[0]) + 1,
                gridColumnEnd: -1,
              }}
              className="typography-label ml-auto  py-1 text-gray-text-color"
            >
              <PriceWithCents
                amount={totalRemainingAmount}
                prefix="$"
                typography="typography-label"
              />
            </div>
          )}
        </div>
        {pendingInstallments.length > 0 && failedInstallments.length === 0 && (
          <Button onClick={() => vm.navigateToPaymentPlan(order)} kind="text">
            <i className="icon pie-icon !h-6 !w-6 bg-main-color" />
            Pay Now
          </Button>
        )}
        {failedInstallments.length > 0 && (
          <Button
            onClick={() => vm.navigateToPaymentPlan(order)}
            color={"red"}
            kind="text"
          >
            <i className="icon pie-icon !h-6 !w-6" />
            Resubmit Payment
          </Button>
        )}
      </div>
    );
  }
);

const Invoices = observer(() => {
  const vm = useDashboardPageVM();
  return (
    <div className={"mb-8"}>
      <div className={"flex items-center gap-2"}>
        <div className={"text-h2"}>Pending Invoices</div>
        <CounterBubble>{vm.pendingInvoices.length}</CounterBubble>
      </div>
      <div className={"gap mt-3 flex flex-col gap-3"}>
        {vm.pendingInvoices.map((invoice) => (
          <Invoice key={invoice.id} invoice={invoice} vm={vm.invoiceVm} />
        ))}
      </div>
    </div>
  );
});
const NoUnusedCredits = observer(() => {
  const { funboxStore } = useStores();
  return (
    <React.Fragment>
      <div className={cn("text-main mt-2 text-gray-text-color")}>
        You have no unused credits
      </div>
      {funboxStore.isBuyCreditsAndSaveEnabled &&
        funboxStore.hasPaidCreditTypes && (
          <Link to={ROUTES.FLEXIBLE_PAYMENTS}>
            <Button kind={"secondary"} className={"mt-4 w-full"} size={"big"}>
              <i className={"icon credit-types-icon !h-6 !w-6"} />
              Buy Credits
            </Button>
          </Link>
        )}
    </React.Fragment>
  );
});

const DocsAndSmartFormsBlock = observer(() => {
  const vm = useDashboardPageVM();
  const { customerStore } = useStores();

  return (
    <div>
      <span className="typography-h2 text-text-color">
        Required Before Arrival
      </span>
      <div className="mb-8 mt-4 grid grid-cols-2 gap-4">
        {vm.isDocumentsBlockVisible && (
          <PieChartCompletenessCard
            name="Docs"
            percentage={vm.requiredDocsPercentage}
            count={vm.unsignedDocsCount}
            renderNavigateButton={() => (
              <Button
                onClick={vm.navigateToDocuments}
                className="!typography-h4"
                kind="text"
              >
                <i className="icon doc-icon !h-6 !w-6" />
                Sign Now
              </Button>
            )}
          />
        )}
        {vm.isFormsBlockVisible && (
          <PieChartCompletenessCard
            name="Forms"
            percentage={vm.requiredSmartFormsPercentage}
            count={customerStore.unansweredRequiredSmartFormsCount}
            renderNavigateButton={() => (
              <Button
                onClick={vm.navigateToPersonalInformation}
                className="!typography-h4"
                kind="text"
              >
                <i className="icon smart-forms-icon !h-6 !w-6" />
                Fill Now
              </Button>
            )}
          />
        )}
      </div>
    </div>
  );
});

const ApplicationsAndWaitlistsBlock = observer(() => {
  const vm = useDashboardPageVM();
  return (
    <div className={"mb-8"}>
      <div className="typography-h2 text-text-color">
        Applications/Waitlists
      </div>
      <div className="mt-4 grid grid-cols-2 gap-3">
        {vm.applicationWaitlistRecords.map(({ student, count }) => (
          <div
            key={student.id}
            className={
              "flex flex-row items-center gap-[6px] rounded-[10px] border border-solid border-separator-color bg-on-main-color px-4 py-3 transition-shadow hover:cursor-pointer hover:shadow"
            }
            onClick={() => vm.navigateToApplicationsAndWaitlists(student.id)}
          >
            <Avatar person={student} avatarSize={"24px"} />
            <div className={"typography-label flex-1 text-text-color"}>
              {student.first_name}
            </div>
            <CounterBubble className={"!typography-label h-[20px] w-[20px]"}>
              {count}
            </CounterBubble>
          </div>
        ))}
      </div>
    </div>
  );
});

const ScheduleFilter = observer(() => {
  const vm = useDashboardPageVM();
  return (
    <Button kind="text" onClick={() => (vm.isScheduleFilterModalOpened = true)}>
      {vm.selectedFilter === "Waitlist" ? (
        <i className="icon waitlist-icon !h-6 !w-6" />
      ) : (
        <i className="icon funbox-icon !h-6 !w-6" />
      )}
      <span className="max-w-[180px] overflow-hidden text-ellipsis">
        {vm.selectedFilter}
      </span>
      <i className="icon chevron-down-icon ml-[2px] h-4 w-4" />
    </Button>
  );
});

export default DashboardPage;
