import React from "react";
import { observer } from "mobx-react-lite";
import PageRoot from "src/components/PageRoot";
import CardsListItem from "src/components/cards/CardsListItem";
import useStores from "src/hooks/useStores";
import PageHeader from "../../components/PageHeader";
import { Button, Dropdown } from "@sizdevteam1/funjoiner-uikit";
import NewCard from "../../components/cards/NewCard";
import { useElements } from "@stripe/react-stripe-js";
import exists from "@sizdevteam1/funjoiner-uikit/util/exists";

const CardsPage: React.FC = observer(() => {
  const { paymentMethodStore } = useStores();
  const hasCards =
    paymentMethodStore.stripePaymentMethods.payment_methods.length > 0;

  React.useEffect(() => {
    paymentMethodStore.sync();
  }, [paymentMethodStore]);

  const [isAddingCard, setIsAddingCard] = React.useState(!hasCards);
  const elements = useElements();

  const handleAddCard = async () => {
    if (!elements) return;
    const paymentMethod =
      await paymentMethodStore.createPaymentMethodFromElements(elements);
    await paymentMethodStore.attachNewCard(paymentMethod);
    setIsAddingCard(false);
  };

  return (
    <PageRoot>
      <PageHeader showBackLink>Cards</PageHeader>
      <div className={"flex flex-col gap-3"}>
        {paymentMethodStore.stripePaymentMethods.payment_methods.map((card) => (
          <CardsListItem
            key={card.payment_method_id}
            data={card}
            isDefault={
              card.payment_method_id ===
              paymentMethodStore.stripePaymentMethods.default_payment_method
            }
            trailing={
              <Dropdown
                showLoaderOnOptionsActions
                base={<i className={"icon more-options-icon icon-hover"} />}
                options={[
                  paymentMethodStore.stripePaymentMethods
                    .default_payment_method !== card.payment_method_id && (
                    <div
                      onClick={() =>
                        paymentMethodStore.setDefaultPaymentMethod(
                          card.payment_method_id
                        )
                      }
                    >
                      <i className={"icon change-date-icon !bg-main-color"} />
                      Set as default
                    </div>
                  ),
                  <div
                    onClick={() =>
                      paymentMethodStore.remove(card.payment_method_id)
                    }
                  >
                    <i className={"icon trash-icon !bg-delete-color"} />
                    Delete
                  </div>,
                ].filter(exists)}
              />
            }
          />
        ))}
      </div>
      <div className={"mt-3"}>
        {isAddingCard ? (
          <NewCard
            onClose={hasCards ? () => setIsAddingCard(false) : undefined}
            onAddCard={handleAddCard}
          />
        ) : (
          <Button
            className={"w-full"}
            size={"big"}
            kind={"secondary"}
            onClick={() => setIsAddingCard(true)}
          >
            <i className={"icon small-add-icon"} />
            Add New Card
          </Button>
        )}
      </div>
    </PageRoot>
  );
});

export default CardsPage;
