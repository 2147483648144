import React, { ReactNode } from "react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import cn from "classnames";

interface IProps {
  onClick: () => void;
  title: string;
  subtitle: ReactNode;
  iconBgClassName: string;
  iconClassName: string;
}

const IconTile: React.FC<IProps> = observer((props: IProps) => {
  return (
    <div
      className="flex cursor-pointer flex-row overflow-clip rounded-[10px] shadow-big"
      onClick={props.onClick}
    >
      <div
        className={classNames(
          props.iconBgClassName,
          "flex items-center justify-center px-[11px] py-[22px]"
        )}
      >
        <i className={cn("icon !h-9 !w-9", props.iconClassName)} />
      </div>
      <div className="flex-grow gap-1 bg-on-main-color p-4">
        <div className="typography-h3 text-header-color">{props.title}</div>
        <div className="typography-small__t text-gray-text-color">
          {props.subtitle}
        </div>
      </div>
    </div>
  );
});

export default IconTile;
