import React from "react";
import { observer } from "mobx-react-lite";
import PageRoot from "src/components/PageRoot";
import { Button } from "@sizdevteam1/funjoiner-uikit";
import SizedBox from "src/components/SizedBox";
import AdditionalPages from "./components/AdditionalPages";
import useStores from "src/hooks/useStores";
import { IStudentDTO } from "src/services/api/students";
import QrCodesModal from "./components/QRCodesModal";
import StudentSelector from "../../../components/StudentSelector/StudentSelector";
import PageHeader from "../../../components/PageHeader";

const ProfilePage: React.FC = observer(() => {
  const { customerStore, authStore } = useStores();

  const [participant, setParticipant] = React.useState<Omit<
    IStudentDTO,
    "customer"
  > | null>(null);
  return (
    <PageRoot>
      <PageHeader className="!mb-6">Account Profile</PageHeader>
      {customerStore.studentsWithCustomerAsParticipant.length > 0 && (
        <div>
          <div className="typography-h2 flex gap-[6px] text-header-color">
            <i className="icon qr-icon bg-main-color" />
            QR CODES
          </div>
          <div className={"typography-main mt-2 text-gray-text-color"}>
            Select Participant to access
          </div>
          <StudentSelector
            className={"mt-3"}
            onSelectStudent={setParticipant}
            selectedStudentId={participant?.id}
            students={customerStore.studentsWithCustomerAsParticipant}
            disableOpacityOnInactive
          />
        </div>
      )}
      <SizedBox height={20} />
      <AdditionalPages />
      <SizedBox height={28} />
      <Button
        kind={"text"}
        style={{ width: "fit-content" }}
        onClick={async () => {
          await authStore.signOut();
        }}
      >
        <i className={"icon out-icon"} />
        Sign Out
      </Button>
      {participant && (
        <QrCodesModal
          studentId={participant.id}
          onClose={() => setParticipant(null)}
        />
      )}
    </PageRoot>
  );
});

export default ProfilePage;
