import { action, computed, makeObservable, observable, toJS } from "mobx";
import { computedFn } from "mobx-utils";

import {
  IAvailableDaycampSession,
  TAvailableProgram,
} from "src/services/api/availability";
import {
  AvailabilityVM,
  DaycampAvailability,
  OvernightAvailability,
} from "src/pages/AvailabilityPage/components/AvailabilityVM";
import { ISOString } from "@sizdevteam1/funjoiner-uikit/types";
import dayjs from "dayjs";

export abstract class ProgramsVM {
  abstract selected: TAvailableProgram[];
  abstract availabilityVM: AvailabilityVM;
  abstract get availability(): DaycampAvailability | OvernightAvailability;
  abstract toggle(program: TAvailableProgram): void;
  abstract getSelectedSessionIds(): Set<string>;

  get selectedIds(): Set<string> {
    return new Set(this.selected.map((e) => e.id));
  }
  @observable openScheduleSetId: string | null = null;
  @action.bound toggleOpenScheduleSet(id: string) {
    if (this.openScheduleSetId === id) {
      this.openScheduleSetId = null;
    } else {
      this.openScheduleSetId = id;
    }
  }

  getHighlightForProgram = (id: string) => {
    if (this.highlightedProgramId === id) {
      return {
        remove: this.resetHighlight,
        action: this.highlightAction,
      };
    }
  };

  @action.bound resetHighlight() {
    this.highlightedProgramId = null;
    this.highlightAction = undefined;
  }

  @action.bound setToHighLight({
    id,
    action,
  }: {
    id: string;
    action?: "select" | "join_waitlist" | "apply";
  }) {
    this.highlightedProgramId = id;
    this.highlightAction = action;
  }
  @observable private highlightAction?: "select" | "join_waitlist" | "apply" =
    undefined;
  @observable private highlightedProgramId: string | null = null;
}

export abstract class SessionsVM {
  abstract availabilityVM: AvailabilityVM;
  abstract get availability(): IAvailableDaycampSession[];
  abstract get selected(): IAvailableDaycampSession[];
  abstract toggle(session: IAvailableDaycampSession): void;
  abstract getSelectedProgramIds(): Set<string>;

  protected constructor() {
    makeObservable(this);
  }

  availabilityForDay = computedFn((date: ISOString) => {
    return this.availability.filter((e) => e.date === date);
  });

  @computed
  get selectedIds(): Set<string> {
    return new Set(this.selected.map((e) => e.id));
  }

  @computed
  get selectedDates(): ISOString[] {
    return [...new Set(this.selected.map((e) => e.date))];
  }

  @computed
  get selectedSessionsByProgram(): GroupedSessions[] {
    const map = new Map<string, IAvailableDaycampSession[]>();
    for (const session of this.selected) {
      const key = session.program_id;
      if (!map.has(key)) {
        map.set(key, []);
      }
      map.get(key)!.push(session);
    }
    return [...map.entries()].map(([id, sessions]) => ({
      id,
      sessions: sessions
        .slice()
        .sort((a, b) => (dayjs(a.start).isBefore(b.start) ? -1 : 1)),
    }));
  }
  @observable focusedDate?: ISOString;

  getHighlightForSession = (id: string) => {
    if (this.highlightedSessionId === id) {
      return {
        remove: this.resetHighlight,
        shouldSelect: toJS(this.shouldSelectHighlighted),
      };
    }
  };
  @action.bound resetHighlight() {
    this.highlightedSessionId = null;
    this.shouldSelectHighlighted = false;
  }
  @action.bound setToHighLight(id: string, shouldSelect: boolean) {
    this.highlightedSessionId = id;
    this.shouldSelectHighlighted = shouldSelect;
  }
  @observable private shouldSelectHighlighted: boolean = false;
  @observable private highlightedSessionId: string | null = null;
}
type GroupedSessions = {
  id: string;
  sessions: IAvailableDaycampSession[];
};
