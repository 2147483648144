import { Avatar, Button } from "@sizdevteam1/funjoiner-uikit";
import classNames from "classnames";
import React from "react";
import PageHeader from "src/components/PageHeader";
import PageRoot from "src/components/PageRoot";
import useStores from "src/hooks/useStores";
import { IStudentDTO } from "src/services/api";
import { ROUTES } from "src/stores/RouterStore";
import getAge from "src/util/getAge";

const ParticipantsPage = () => {
  const { customerStore, routerStore } = useStores();
  return (
    <PageRoot>
      <PageHeader showBackLink backLinkRoute={ROUTES.PROFILE}>
        Participants
      </PageHeader>
      <div className="mb-8 flex w-full flex-col gap-4">
        {customerStore.studentsWithCustomerAsParticipant.map((s) => (
          <StudentCard key={s.id} student={s} />
        ))}
        <Button
          className="group !justify-start !p-4"
          kind={"text"}
          onClick={() => {
            routerStore.navigate(ROUTES.ADD_PARTICIPANT);
          }}
        >
          <div
            className={classNames(
              "box-border flex h-[48px] w-[48px] items-center justify-center rounded-[50%] border-[2px] border-solid border-main-color "
            )}
          >
            <i
              className={classNames(
                "icon add-participant2-icon  !h-[26px] !w-[29px] bg-main-color"
              )}
            />
          </div>
          <span className="ml-4"> Add New Participant</span>
        </Button>
      </div>
    </PageRoot>
  );
};

const StudentCard = ({ student }: { student: IStudentDTO }) => {
  const { routerStore, customerStore } = useStores();
  return (
    <div>
      <div className="flex rounded-[10px] bg-on-main-color p-4 shadow-big">
        <div className="flex w-full items-center justify-between gap-4">
          <div className="flex items-center gap-4">
            <Avatar person={student} avatarSize="48px" />
            <div className="flex flex-col gap-[2px]">
              <div className="typography-label text-header-color">
                {student.type === "CUSTOMER_AS_PARTICIPANT"
                  ? student.full_name + " (You)"
                  : student.full_name}
              </div>
              <div className="typography-small text-gray-text-color">
                {getAge(student.birth_date)} yrs
              </div>
            </div>
          </div>
          <Button
            onClick={() =>
              routerStore.navigate(ROUTES.EDIT_PARTICIPANT, {
                id: student.id,
              })
            }
            kind="icon"
          >
            <i className="icon edit-icon !bg-main-color hover:!bg-dark-main-color" />
          </Button>
        </div>
      </div>
      {student.type === "CUSTOMER_AS_PARTICIPANT" &&
        customerStore.studentsWithCustomerAsParticipant.length > 1 && (
          <div className="mx-4 mt-5 mb-1 h-[1px] bg-separator-color"></div>
        )}
    </div>
  );
};

export default ParticipantsPage;
