import * as React from "react";
import cn from "classnames";
import { defineRoute } from "../../util/typedRouting";
import PageRoot from "../../components/PageRoot";
import PageHeader from "../../components/PageHeader";
import { useCheckoutAndPaymentPageVM } from "../Checkout&Payment/Checkout&PaymentVM";
import useStores from "../../hooks/useStores";
import NewCard from "../../components/cards/NewCard";
import { useElements } from "@stripe/react-stripe-js";
import { useSchedulePageVM } from "../../stores/ScheduleStore/SchedulePageVM";
import { Button } from "@sizdevteam1/funjoiner-uikit";
import { Redirect } from "react-router-dom";
import { ROUTES } from "../../stores/RouterStore";
import { observer } from "mobx-react-lite";

export const providePaymentInformationPageRoute = defineRoute<{
  state: {};
}>({
  path: "/schedule/provide-payment-information",
  build: (path) => path,
});

const ProvidePaymentInformationPage = observer(() => {
  const vm = useSchedulePageVM();
  const { paymentMethodStore } = useStores();
  const elements = useElements();

  const submit = async () => {
    if (!elements) return;
    const paymentMethod =
      await paymentMethodStore.createPaymentMethodFromElements(elements);
    await paymentMethodStore.attachNewCard(paymentMethod);
    return vm.secureReservation();
  };

  if (!vm.mustProvidePaymentInformation) {
    return <Redirect to={ROUTES.SCHEDULE} />;
  }

  return (
    <PageRoot>
      <PageHeader
        showBackLink
        className={cn("typography-h1  text-header-color")}
      >
        Payment Information
      </PageHeader>
      <div className={cn("typography-main text-gray-text-color")}>
        Company requires you to provide payment information before securing your
        Reservation. It will be used for all your future purchases.
      </div>
      <NewCard className={"mt-6"} />
      <Button
        className={cn("sticky bottom-3 mt-auto")}
        autoLoading={true}
        size={"big"}
        onClick={submit}
      >
        Use this Card
      </Button>
    </PageRoot>
  );
});
export default ProvidePaymentInformationPage;
