import React from "react";
import PieChart from "../pages/DashboardPage/components/PieChart/PieChart";
import { Loader } from "@sizdevteam1/funjoiner-uikit";

export const PieChartCompletenessCard = ({
  name,
  percentage,
  count,
  renderNavigateButton,
  isLoading = false,
}: {
  name: string;
  percentage: number;
  count: number;
  renderNavigateButton: () => React.ReactNode;
  isLoading?: boolean;
}) => {
  const isCompleted = percentage === 100;
  return (
    <div className="flex  max-w-[175px] justify-between rounded-lg bg-on-main-color px-[10px] py-3 shadow-big">
      <PieChart percentage={percentage} />
      <div className="flex flex-col justify-between">
        <div className="typography-label flex items-center justify-end gap-[2px] text-gray-text-color">
          <div>{name}</div>
          {!isCompleted && (
            <>
              <div>•</div>
              {isLoading ? (
                <Loader className={"!m-0 !h-4 !w-4 !p-0"} />
              ) : (
                <span className="text-text-color">{count}</span>
              )}
            </>
          )}
        </div>
        <div className="flex gap-[2px]">
          {isCompleted ? (
            <span className="typography-label  pb-[4.5px] text-icon-dark-green-color">
              Completed!
            </span>
          ) : (
            renderNavigateButton()
          )}
        </div>
      </div>
    </div>
  );
};
