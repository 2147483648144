import React, { useMemo, useEffect } from "react";
import { computed, makeObservable, observable } from "mobx";
import useVM from "src/hooks/useVM";
import useStores from "src/hooks/useStores";
import api from "src/services/api";
import { CustomerStore, DocumentsStore } from "src/stores";
import FunboxStore from "src/stores/FunboxStore";
import { IBookingResult } from "../ScheduleSharingPageVM";
import { SmartFormDTO } from "../../../services/api/smartForms";

export class WhatNextSectionVm {
  constructor(
    private bookingResult: IBookingResult | undefined,
    private documentsStore: DocumentsStore,
    private funboxStore: FunboxStore,
    private customerStore: CustomerStore
  ) {
    makeObservable(this);
    this.init();
  }

  @observable
  isLoading = true;

  @observable
  hasUnsignedDocuments = false;

  @observable
  requiredSmartForms: SmartFormDTO[] = [];
  @computed
  get hasRequiredSmartForms() {
    return this.requiredSmartForms.length > 0;
  }

  @computed
  get location() {
    return this.funboxStore.selectedLocation;
  }

  private _disposers = Array<() => void>();

  private async init() {
    try {
      await this.customerStore.loadCredits();

      if (
        this.bookingResult != null &&
        this.bookingResult.attendanceIds.length > 0
      ) {
        const { smart_forms, documents } =
          await api.requiredForAttendance.getRequirementsByAttendanceIds(
            this.bookingResult.attendanceIds
          );

        this.requiredSmartForms = smart_forms;
        this.hasUnsignedDocuments = documents.length > 0;
      }
    } finally {
      this.isLoading = false;
    }
  }
  dispose = () => {
    this._disposers.forEach((d) => d());
  };
}

const ctx = React.createContext<WhatNextSectionVm | null>(null);

export const WhatNextSectionVmProvider: React.FC<{
  bookingResult: IBookingResult | undefined;
}> = ({ children, bookingResult }) => {
  const { documentsStore, funboxStore, customerStore } = useStores();
  const vm = useMemo(
    () =>
      new WhatNextSectionVm(
        bookingResult,
        documentsStore,
        funboxStore,
        customerStore
      ),
    [bookingResult, documentsStore, funboxStore, customerStore]
  );
  useEffect(() => () => vm.dispose());
  return <ctx.Provider value={vm}>{children}</ctx.Provider>;
};

export const useWhatNextSectionVm = () => useVM(ctx);
