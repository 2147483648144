import React from "react";
import cn from "classnames";
import styles from "../Checkout.module.scss";
import CreditRow from "src/components/CreditRow";
import { IUsedCreditRow } from "src/services/api/credits";

interface IProps {
  creditsToUse: IUsedCreditRow[];
}

const ExistingCreditsSection: React.FC<IProps> = ({ creditsToUse }) => {
  if (!creditsToUse.length) return null;
  return (
    <section className={cn(styles.Section, styles.existing)}>
      <div className={cn("text-h3", styles.title)}>Existing Credits</div>
      {creditsToUse.map((item) => (
        <CreditRow key={item.credit_type_id} {...item} />
      ))}
    </section>
  );
};

export default ExistingCreditsSection;
