import React from "react";
import { Route, RouteProps } from "react-router-dom";

interface IProps extends RouteProps {
  title?: string;
}

const PublicRoute: React.FC<IProps> = ({ title, ...rest }) => {
  React.useEffect(() => {
    if (title) document.title = title;
  }, [title]);

  return <Route {...rest} />;
};
export default PublicRoute;
