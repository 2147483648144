import { IPaymentDTO } from "../services/api";

export default function paymentSourceLabel(
  source: IPaymentDTO["alternative_payment_source"]
) {
  return source == null
    ? undefined
    : {
        venmo: "Venmo",
        cash: "Cash",
        wire_transfer: "Wire Transfer",
        check: "Check",
        other: "Other",
      }[source];
}
