import React, { useMemo } from "react";
import { action, makeObservable, observable, runInAction } from "mobx";
import useVM from "src/hooks/useVM";
import useStores from "src/hooks/useStores";
import FunboxStore from "src/stores/FunboxStore";
import { RouterStore } from "src/stores";
import { ROUTES } from "src/stores/RouterStore";
import { IFunboxDTO, ILocationDTO } from "src/services/api";

export class PreselectFunboxByIdOrUrlPartPageVM {
  constructor(
    private funboxStore: FunboxStore,
    private routerStore: RouterStore,
    private funboxIdToSelect?: string,
    private funboxUrlToSelect?: string
  ) {
    makeObservable(this);
    this.preselectFunboxByIdOrByUrl();
  }

  @observable state: "loading" | "funbox-not-found" | IFunboxDTO = "loading";

  @action.bound selectLocationAndGoToWelcomeScreen(location: ILocationDTO) {
    this.funboxStore.selectLocationId(location.id);
    this.routerStore.navigate(ROUTES.WELCOME_SCREEN);
  }

  @action.bound private async preselectFunboxByIdOrByUrl() {
    const funbox = await this.funboxStore.getFunboxByIdOrUrlPart({
      id: this.funboxIdToSelect,
      url_part: this.funboxUrlToSelect,
    });
    if (funbox == null) {
      runInAction(() => {
        this.state = "funbox-not-found";
      });
      return;
    }

    this.funboxStore.selectFunbox(funbox);
    if (funbox.active_locations.length === 1) {
      this.selectLocationAndGoToWelcomeScreen(funbox.active_locations[0]);
    }
    runInAction(() => {
      this.state = funbox;
    });
  }
}

const ctx = React.createContext<PreselectFunboxByIdOrUrlPartPageVM | null>(
  null
);

export const PreselectFunboxByIdOrUrlPartPageVMProvider: React.FC<{
  id?: string;
  url_part?: string;
}> = ({ children, id, url_part }) => {
  const { funboxStore, routerStore } = useStores();
  const vm = useMemo(
    () =>
      new PreselectFunboxByIdOrUrlPartPageVM(
        funboxStore,
        routerStore,
        id,
        url_part
      ),
    [funboxStore, routerStore, id, url_part]
  );
  return <ctx.Provider value={vm}>{children}</ctx.Provider>;
};

export const useSelectLocationInFunboxPageVM = () => useVM(ctx);
