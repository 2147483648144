import styles from "../../SchedulePage.module.scss";
import cn from "classnames";
import { Button, Modal } from "@sizdevteam1/funjoiner-uikit";
import React from "react";

interface IProps {
  onClose: () => void;
  onSubmit: () => void;
}

export default function ConfirmSelectLocationModal({
  onClose,
  onSubmit,
}: IProps) {
  return (
    <Modal
      className={styles.confirmModal}
      onClose={onClose}
      headerText={"Change Location"}
      containerClassName="min-w-[366px] max-w-[414px]"
    >
      <div className={cn("text-h3", styles.modalBody)}>
        You’ve selected some sessions. If you change location right now your
        selection will be lost
      </div>
      <div className={styles.modalButtons}>
        <Button kind={"secondary"} size={"big"} onClick={onClose}>
          Cancel
        </Button>
        <Button size={"big"} onClick={onSubmit}>
          Change
        </Button>
      </div>
    </Modal>
  );
}
