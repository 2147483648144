import classNames from "classnames";
import { action, computed, makeObservable, observable } from "mobx";
import React, { useMemo } from "react";
import useStores from "src/hooks/useStores";
import useVM from "src/hooks/useVM";
import { IFunboxDTO } from "src/services/api";
import { CommonStore, RouterStore } from "src/stores";
import FunboxStore from "src/stores/FunboxStore";
import { ROUTES } from "src/stores/RouterStore";
import getUniqueFoldersFromLocations from "src/util/getUniqueFoldersFromLocations";

type Tab = "funbox" | "location";

export class SelectFunboxPageVM {
  constructor(
    private funboxStore: FunboxStore,
    private routerStore: RouterStore,
    private commonStore: CommonStore
  ) {
    makeObservable(this);
    this.preSelectLocationFolderFromStorage();
    this.preselectTabFromStorage();
  }

  @computed get selectedFunboxId() {
    return this.routerStore.searchParams.funbox_id;
  }

  @computed get selectedLocationId() {
    return +this.routerStore.searchParams.location_id;
  }

  @action.bound selectFunboxAndReturnToSourcePage(funbox: IFunboxDTO) {
    if (!isNaN(+this.selectedLocationId)) {
      this.funboxStore.selectLocationId(this.selectedLocationId);
      this.funboxStore.selectFunbox(funbox);
      this.routerStore.returnToSourcePage(ROUTES.WELCOME_SCREEN);
      return;
    }
    if (this.routerStore.searchParams.funbox_id === funbox.id) {
      this.routerStore.setSearchParam("funbox_id", undefined, true);
      return;
    }
    this.routerStore.setSearchParam("funbox_id", funbox.id, true);
  }

  @action.bound selectLocation(location_id: number) {
    if (
      !isNaN(+this.routerStore.searchParams.location_id) &&
      +this.routerStore.searchParams.location_id === location_id
    ) {
      this.routerStore.setSearchParam("location_id", undefined, true);
      return;
    }
    this.routerStore.setSearchParam(
      "location_id",
      location_id.toString(),
      true
    );
    const funbox = this.funboxStore.funboxes.find(
      (f) => f.id === this.selectedFunboxId
    );
    if (funbox != null) {
      this.funboxStore.selectLocationId(location_id);
      this.selectFunboxAndReturnToSourcePage(funbox);
      return;
    }
  }

  @action.bound private resetLocationSelection() {
    this.routerStore.setSearchParam("location_id", undefined, true);
  }

  @action.bound private resetFunboxSelection() {
    this.routerStore.setSearchParam("funbox_id", undefined, true);
  }

  tabOptions: { key: Tab; label: React.ReactNode }[] = [
    {
      key: "location",
      label: (
        <div className="flex gap-[6px]">
          <i className="icon locations-icon" />
          By Location
        </div>
      ),
    },
    {
      key: "funbox",
      label: (
        <div className="flex gap-[6px]">
          <i className="icon funbox-icon" /> By FUNBOX{" "}
        </div>
      ),
    },
  ];

  @computed
  get hasFunboxes() {
    return this.funboxStore.funboxes.length > 0;
  }

  @computed
  get locationsWithFunboxesByFolder() {
    return this.funboxStore.activeLocations
      .map((l) => ({
        ...l,
        funboxes: this.funboxStore.funboxes.filter((f) =>
          f.active_locations.map((loc) => loc.id).includes(l.id)
        ),
      }))
      .filter(
        (locWithFunboxes) =>
          this.selectedLocationFolderOption.id === "all" ||
          this.selectedLocationFolderOption.id === locWithFunboxes.folder?.id
      );
  }

  get funboxesByFolder() {
    return this.funboxStore.funboxes.filter((f) =>
      f.active_locations.some(
        (l) =>
          l.folder?.id === this.selectedLocationFolderOption.id ||
          this.selectedLocationFolderOption.id === "all"
      )
    );
  }

  @computed get atLeastOneLocationFolderExist() {
    return this.locationFolderOptions.length > 1;
  }

  @computed get locationFolderOptions() {
    return [
      {
        name: (
          <div
            className={classNames(
              "ml-[30px] flex justify-between ",
              this.selectedLocationFolderOption.id === "all" &&
                "text-main-color"
            )}
          >
            <div> All Locations</div>

            {this.selectedLocationFolderOption.id === "all" && (
              <i className="icon check-in-object-icon ml-[188px] " />
            )}
          </div>
        ),
        id: "all",
      },
      ...getUniqueFoldersFromLocations(
        this.funboxStore.funboxes.flatMap((f) => f.active_locations)
      ).map((f) => ({
        id: f.id,
        name: (
          <div className="flex gap-[6px]">
            <i
              className={classNames(
                "icon locations-icon",
                this.selectedLocationFolderOption.id === f.id && "bg-main-color"
              )}
            />
            <div className="w-[310px] overflow-hidden text-ellipsis">
              {f.name}
            </div>
            {this.selectedLocationFolderOption.id === f.id && (
              <i className="icon check-in-object-icon" />
            )}
          </div>
        ),
      })),
    ];
  }

  @observable
  selectedLocationFolderOption: (typeof this.locationFolderOptions)[number] = {
    id: "all",
    name: <div className="ml-[30px]">All Locations</div>,
  };

  @action.bound selectLocationFolderOption(
    o: (typeof this.locationFolderOptions)[number]
  ) {
    this.selectedLocationFolderOption = o;
    this.resetLocationSelection();
    this.resetFunboxSelection();
    localStorage.setItem(LOCATION_FOLDER_STORAGE_KEY, o.id);
  }

  @computed get selectedTab(): Tab {
    const selectedTab = this.routerStore.searchParams.selected_tab;
    if (this.isTab(selectedTab)) {
      return selectedTab;
    }
    return "location";
  }

  private isTab(value: string): value is Tab {
    return value === "location" || value === "funbox";
  }

  @action.bound selectTab(t: Tab) {
    this.routerStore.setSearchParam("selected_tab", t, true);
    localStorage.setItem(SELECTED_TAB_STORAGE_KEY, t);
    this.resetFunboxSelection();
    this.resetLocationSelection();
  }

  get companyName() {
    return this.commonStore.companyProfile.name;
  }

  @action.bound private preSelectLocationFolderFromStorage() {
    const locationFolderId = localStorage.getItem(LOCATION_FOLDER_STORAGE_KEY);
    if (locationFolderId == null) {
      return;
    }
    const folderOption = this.locationFolderOptions.find(
      (f) => f.id === locationFolderId
    );
    if (folderOption == null) return;
    this.selectLocationFolderOption(folderOption);
  }
  @action.bound
  private preselectTabFromStorage() {
    const selectedTabFromQuery = this.routerStore.searchParams.selected_tab;
    if (this.isTab(selectedTabFromQuery)) {
      return;
    }
    const selectedTabFromStorage = localStorage.getItem(
      SELECTED_TAB_STORAGE_KEY
    );
    if (selectedTabFromStorage && this.isTab(selectedTabFromStorage)) {
      console.log("selectedTabFromStorage", selectedTabFromStorage);
      this.selectTab(selectedTabFromStorage);
    }
  }
}
const LOCATION_FOLDER_STORAGE_KEY = "location_folder_id";
const SELECTED_TAB_STORAGE_KEY = "select_funbox.selected_tab";
const ctx = React.createContext<SelectFunboxPageVM | null>(null);

export const SelectFunboxPageVMProvider: React.FC = ({ children }) => {
  const { funboxStore, routerStore, commonStore } = useStores();

  const vm = useMemo(
    () => new SelectFunboxPageVM(funboxStore, routerStore, commonStore),
    [funboxStore, routerStore, commonStore]
  );

  return <ctx.Provider value={vm}>{children}</ctx.Provider>;
};

export const useSelectFunboxPageVM = () => useVM(ctx);
