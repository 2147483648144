import React, { useEffect, useState } from "react";
import { Button } from "@sizdevteam1/funjoiner-uikit";
import cn from "classnames";

const TimeoutButton = ({
  onClick,
  className,
  loading,
}: {
  onClick: () => void;
  className?: string;
  loading?: boolean;
}) => {
  const timeout = 60; //sec

  const [seconds, setSeconds] = useState(0);
  const [disabled, setDisabled] = useState(true);

  const reset = () => {
    setSeconds(0);
    setDisabled(true);
  };

  useEffect(() => {
    let interval: any = null;
    if (disabled) {
      interval = setInterval(
        () =>
          seconds !== timeout - 1
            ? setSeconds((seconds) => seconds + 1)
            : setDisabled(false),
        1000
      );
    } else if (!disabled && seconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [disabled, seconds]);

  const handleClick = async () => {
    await onClick();
    reset();
  };

  return (
    <Button
      className={cn("ml-auto mr-auto", className)}
      kind={"text"}
      disabled={disabled}
      autoLoading={loading != null}
      loading={loading}
      onClick={handleClick}
    >
      Resend Code {disabled && `(${timeout - seconds}s)`}
    </Button>
  );
};

export default TimeoutButton;
