import React, { useEffect } from "react";
import { Calendar, Loader } from "@sizdevteam1/funjoiner-uikit";
import useStores from "src/hooks/useStores";
import { Observer, observer } from "mobx-react-lite";
import classNames from "classnames";
import dayjs from "dayjs";
import styles from "./SelectSession.module.scss";
import { IAvailableDaycampSession, IStudentDTO } from "src/services/api";
import { SessionsVM } from "src/stores/ScheduleStore/Programs&SessionsVMs";
import { TAvailableProgram } from "src/services/api/availability";
import NoAvailableProgramsMessage from "../../../../AvailabilityPage/components/NoAvailableProgramsMessage";
import { ISOString } from "@sizdevteam1/funjoiner-uikit/types";
import SessionCard from "src/components/AvailabilityCards/SessionCard";

interface ISelectSessionProps {
  vm: SessionsVM;
  selectedParticipants: IStudentDTO[];
  getSelectedUnlinkedParticipantsAmount?: (id: string) => number | undefined;
  selectedPrograms: TAvailableProgram[];
}

const SelectSession: React.FC<ISelectSessionProps> = observer(
  ({
    vm,
    selectedParticipants,
    selectedPrograms,
    getSelectedUnlinkedParticipantsAmount,
  }) => {
    const avVM = vm.availabilityVM;

    const {
      commonStore: { companyProfile },
      funboxStore,
    } = useStores();

    let focusedDate: string | undefined = vm.focusedDate;

    if (
      focusedDate !== dayjs(focusedDate).format("YYYY-MM-DD") ||
      dayjs(avVM.selectedMonth).get("M") !== dayjs(focusedDate).get("M")
    ) {
      focusedDate = undefined;
    }
    useEffect(() => {
      //checks if focusedDate is in previous or next month. this can happen if a user clicks on an icon in the programs tab
      if (
        focusedDate &&
        Math.abs(
          dayjs(focusedDate)
            .startOf("month")
            .diff(dayjs(avVM.selectedMonth).startOf("month"), "month")
        ) === 1
      ) {
        if (dayjs(focusedDate).isAfter(dayjs(avVM.selectedMonth))) {
          avVM.nextMonth();
        } else {
          avVM.previousMonth();
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const noSessionsThisMonth =
      vm.availability.length === 0 &&
      avVM.selectedLocationMaxProgramMonth !== null;

    const noSessionsAtAll =
      !avVM.loading && avVM.selectedLocationMaxProgramMonth == null;

    const getEnabledDates = (
      availability: IAvailableDaycampSession[]
    ): ISOString[] => {
      return [
        ...new Set(
          availability
            .filter((e) => e.registration.status === "OPEN")
            .map((e) => e.date)
        ),
      ];
    };
    console.log();
    return (
      <div>
        <Calendar
          className={classNames(
            styles.calendar,
            noSessionsAtAll && styles.hideCalendar,
            noSessionsThisMonth && styles.hideCalendarDates
          )}
          monthSelectorClassName="!px-2 !py-4 bg-card-color shadow-big rounded-xl"
          focusedDate={focusedDate}
          onNextMonth={avVM.nextMonth}
          onNextYear={() => {}}
          onPrevMonth={avVM.previousMonth}
          onPrevYear={() => {}}
          selectedMonth={avVM.selectedMonth}
          selectedDates={vm.selectedDates}
          bookedDates={[]}
          enabledDates={getEnabledDates(vm.availability)}
          loading={avVM.loading}
          onDayClick={(day) => (vm.focusedDate = day)}
          monthSelectorKind={"mobile"}
          minDate={avVM.minAvailableDate}
          maxDate={
            avVM.selectedLocationMaxProgramMonth ?? avVM.minAvailableDate
          }
          renderExpand={(date) => (
            <div className={styles.scheduleForTheDay}>
              {vm.availabilityForDay(date).map((session) => (
                // Observer is needed to track changes in unlinked mode for getSelectedUnlinkedParticipantsAmount method
                <Observer>
                  {() => (
                    <SessionCard
                      key={session.id}
                      props={{
                        session,
                        highlight: vm.getHighlightForSession(session.id),
                        isSelected: [...vm.selectedIds].includes(session.id),
                        onSelect: () => vm.toggle(session),
                        selectedParticipants,
                        selectedPrograms,
                        selectedUnlinkedParticipantsAmount:
                          getSelectedUnlinkedParticipantsAmount?.(session.id),
                      }}
                    />
                  )}
                </Observer>
              ))}
            </div>
          )}
          showWeekPicker={false}
        />
        {avVM.loading && noSessionsThisMonth && (
          <div className="h-[100px]">
            <Loader />
          </div>
        )}
        {vm.availability.length === 0 && !avVM.loading && (
          <NoAvailableProgramsMessage
            className="mt-4"
            company={companyProfile}
            selectedLocation={funboxStore.selectedLocation}
            hasSelectedLocationSessions={
              avVM.selectedLocationMaxProgramMonth != null
            }
          />
        )}
      </div>
    );
  }
);

export default SelectSession;
