import { observer } from "mobx-react-lite";
import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import { CompletenessPercentage } from "src/components/CompletenessPercentage";
import {
  DocumentsPageVMProvider,
  useDocumentsPageVM,
} from "src/pages/DocumentsPage/DocumentsPageVM";
import {
  SmartFormsCompletenessVmProvider,
  useSmartFormsCompletenessVm,
} from "src/pages/SmartFormsPage/SmartFormsCompletenessVm";
import { ROUTES } from "src/stores/RouterStore";
import styles from "../styles.module.scss";

const AdditionalPages = () => {
  return (
    <div>
      <PageLink to={ROUTES.PARTICIPANTS} iconClassName="issue-customer-icon">
        Participants
      </PageLink>
      <PageLink to={ROUTES.PERSONAL_INFO} iconClassName="personal-info-icon">
        Personal Info
      </PageLink>
      <PageLink to={ROUTES.CONTACTS} iconClassName="contact-icon">
        Contacts
      </PageLink>
      <PageLink to={ROUTES.HISTORY} iconClassName="history-icon">
        Transaction History
      </PageLink>
      <PageLink to={ROUTES.CARDS} iconClassName="payment-processor-icon">
        My Cards
      </PageLink>
      <DocumentsPageVMProvider>
        <DocumentsPageLink />
      </DocumentsPageVMProvider>
      <SmartFormsCompletenessVmProvider>
        <PersonalInformationPageLink />
      </SmartFormsCompletenessVmProvider>
      <PageLink to={ROUTES.PROMOCODES} iconClassName="promocodes-icon">
        Promo Codes
      </PageLink>
    </div>
  );
};

const DocumentsPageLink: React.FC = observer(() => {
  const { averageCompleteness } = useDocumentsPageVM();

  return (
    <PageLink to={ROUTES.DOCUMENTS} iconClassName="doc-icon">
      <div className="flex items-center">
        Documents
        <TileCompletenessPercentage percentage={averageCompleteness} />
      </div>
    </PageLink>
  );
});

const PersonalInformationPageLink: React.FC = observer(() => {
  const { totalCompletenessPercentage } = useSmartFormsCompletenessVm();
  return (
    <PageLink to={ROUTES.SMART_FORMS} iconClassName={"smart-forms-icon"}>
      <div className="flex flex-row items-center">
        Forms
        <TileCompletenessPercentage percentage={totalCompletenessPercentage} />
      </div>
    </PageLink>
  );
});

const TileCompletenessPercentage: React.FC<{
  percentage: number | undefined;
}> = ({ percentage }) => {
  if (percentage == null) {
    return null;
  }
  return (
    <div className="flex flex-row items-center text-gray-text-color">
      <span className="mx-1">•</span>
      <CompletenessPercentage percentage={percentage} />
      <span className="typography-main_sb">&nbsp;complete</span>
    </div>
  );
};

interface IPageLinkProps {
  to: string;
  iconClassName: string;
  children: string | ReactNode;
}

const PageLink: React.FC<IPageLinkProps> = (props: IPageLinkProps) => {
  return (
    <Link className={styles.link} to={props.to}>
      <div className={styles.sectionLink}>
        <div>
          <div className={styles.icon}>
            <i className={`icon ${props.iconClassName}`} />
          </div>
          <div className={"text-h3"}>{props.children}</div>
        </div>
        <i className={"icon chevron-right-icon"} />
      </div>
    </Link>
  );
};

export default AdditionalPages;
