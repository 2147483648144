import { action } from "mobx";
import { downloadFile } from "../util/downloadFile";
import api from "../services/api";
import * as Sentry from "@sentry/react";
import notificator from "../services/systemNotifications/notificationCenterService";

export class InvoiceVm {
  constructor(private onInvoiceAlreadyPaid: () => Promise<void> | void) {}

  @action.bound
  async downloadInvoicePdf(invoiceId: number) {
    try {
      await downloadFile({
        apiCall: () => api.invoices.downloadInvoicePdf(invoiceId),
        fileName: `invoice-${invoiceId}.pdf`,
      });
    } catch (e) {
      Sentry.captureException(e);
      notificator.error("Failed to download invoice pdf", e);
    }
  }
  @action.bound
  async payInvoice(invoiceId: number) {
    try {
      const link = await api.invoices.getInvoicePaymentLink(invoiceId);
      window.open(link, "_blank");
    } catch (e) {
      Sentry.captureException(e);
      await this.onInvoiceAlreadyPaid?.();
    }
  }
}
