import { action, computed, makeObservable, observable } from "mobx";

export default function formField<T>(
  value: T,
  options: FormFieldOptions<T> = {}
) {
  const { validator, autoValidate, setMiddleware } = options;
  return new FormField(value, validator, autoValidate, setMiddleware);
}

export class FormField<T> {
  @observable
  private _value: T;
  @observable
  private _error: string | null = null;
  constructor(
    value: T,
    private validator: (v: T) => string | null = () => null,
    private autoValidate = false,
    private transformSet: (v: T) => T = (v) => v
  ) {
    this._value = value;
    makeObservable(this);
  }

  @action.bound set(v: T) {
    this._value = this.transformSet(v);
    if (this.autoValidate) {
      this.validate();
    } else {
      this._error = null;
    }
  }
  @action.bound setError(err: string | null) {
    this._error = err;
  }
  @computed
  get value() {
    return this._value;
  }
  @computed
  get error() {
    return this._error;
  }

  validate = (): boolean => {
    this._error = this.validator(this._value);
    return this._error == null;
  };

  get isValid() {
    this.validate();
    return this._error == null;
  }
}

interface FormFieldOptions<T> {
  validator?: (v: T) => string | null;
  setMiddleware?: (v: T) => T;
  autoValidate?: boolean;
}
