import React, { ReactNode, useEffect, useMemo } from "react";
import * as Sentry from "@sentry/react";
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
  toJS,
} from "mobx";
import useStores from "../../hooks/useStores";
import useVM from "../../hooks/useVM";
import {
  CustomerStore,
  DocumentsStore,
  PaymentMethodStore,
  PaymentStore,
} from "../../stores";
import { applicationProcessPageRoute } from "./ApplicationProcessPage";
import {
  ApplicationDrafts,
  DaycampProgram,
  OvernightProgram,
  ScheduleSet,
} from "../../services/api/availability";
import api from "../../services/api";
import { computedFn } from "mobx-utils";
import { ICalculatedScheduleAndPayOrderDTO } from "../../services/api/orders";
import notificator from "../../services/systemNotifications/notificationCenterService";
import RouterStore, { ROUTES } from "../../stores/RouterStore";
import { smartFormPipelinePageRoute } from "../SmartFormPipelinePage/SmartFormPipelinePage";
import { StripeElements } from "@stripe/stripe-js";
import { useElements } from "@stripe/react-stripe-js";
import { PaymentMethodPickerVm } from "../../components/PaymentMethods/PaymentMethodPickerVm";
import { Redirect } from "react-router-dom";

export class ApplicationProcessPageVm {
  constructor(
    public programId: string,
    private scheduleSetId: string,
    private documentId: string | undefined,
    public selectedParticipantIds: number[],
    private elements: StripeElements | null,
    private paymentStore: PaymentStore,
    private paymentMethodStore: PaymentMethodStore,
    private routerStore: RouterStore,
    private customerStore: CustomerStore,
    private documentsStore: DocumentsStore
  ) {
    makeObservable(this);
    this._init();
    this.paymentMethodPickerVm = new PaymentMethodPickerVm(
      {
        isInvoiceEnabled: computed(() => false),
        // applePay: {
        //   payment: computed(() => this.calculatedOrder),
        //   isImmediateChargeRequired: true,
        // },
      },
      this.elements,
      this.paymentStore,
      this.paymentMethodStore,
      this.customerStore
    );

    this._disposers.push(this.paymentMethodPickerVm.dispose);
  }

  paymentMethodPickerVm;

  @observable
  scheduleSet!: ScheduleSet;

  @computed
  get program(): DaycampProgram | OvernightProgram {
    return (this.scheduleSet.programs as { id: string }[]).find(
      (p) => p.id === this.programId
    ) as DaycampProgram | OvernightProgram;
  }

  @observable
  applicationSettings!: ApplicationDrafts;

  @observable
  calculatedOrder?: ICalculatedScheduleAndPayOrderDTO;

  @observable
  isCalculatingOrder = true;

  @observable
  isLoading = true;

  @computed
  get selectedParticipants() {
    const selectedStudentIds = new Set(this.selectedParticipantIds);
    return this.customerStore.studentsWithCustomerAsParticipant.filter(
      (student) => selectedStudentIds.has(student.id)
    );
  }

  @computed
  get haveAllDocsAndFormsCompleted() {
    for (const application of this.applicationSettings.applications) {
      if (
        application.smart_forms.some(
          (smartForm) => smartForm.status === "INCOMPLETE"
        )
      ) {
        return false;
      }
      if (application.documents.some((document) => !document.signed)) {
        return false;
      }
    }

    return true;
  }
  @computed
  get isApplyButtonDisabled() {
    return this.isLoading || !this.haveAllDocsAndFormsCompleted;
  }

  applicationByStudentId = computedFn((studentId: number) => {
    return this.applicationSettings.applications.find(
      (application) => application.student.id === studentId
    )!;
  });

  isFormsPieVisible = computedFn((studentId: number) => {
    const application = this.applicationByStudentId(studentId);
    return application.smart_forms.length > 0;
  });

  isDocumentsPieVisible = computedFn((studentId: number) => {
    const application = this.applicationByStudentId(studentId);
    return application.documents.length > 0;
  });

  isPollingDocuments = computedFn((studentId: number) => {
    const application = this.applicationByStudentId(studentId);
    return (
      this.documentId != null &&
      this.documentsStore.isWaitingForDocumentUpdate(this.documentId) &&
      application.documents.some((d) => d.id === this.documentId)
    );
  });

  unsignedDocumentCount = computedFn((studentId: number) => {
    const application = this.applicationByStudentId(studentId);
    return application.documents.filter((document) => !document.signed).length;
  });

  unansweredSmartFormCount = computedFn((studentId: number) => {
    const application = this.applicationByStudentId(studentId);
    return application.smart_forms.filter(
      (smartForm) => smartForm.status === "INCOMPLETE"
    ).length;
  });

  isComplete = computedFn((studentId: number) => {
    return (
      this.documentCompletenessPercentage(studentId) === 100 &&
      this.smartFormCompletenessPercentage(studentId) === 100
    );
  });

  documentCompletenessPercentage = computedFn((studentId: number) => {
    const application = this.applicationByStudentId(studentId);
    const docs = application.documents;
    if (docs.length === 0) {
      return 100;
    }

    const completedDocs = docs.filter((doc) => doc.signed);
    return (completedDocs.length / docs.length) * 100;
  });

  smartFormCompletenessPercentage = computedFn((studentId) => {
    const application = this.applicationByStudentId(studentId);
    const smartForms = application.smart_forms;
    if (smartForms.length === 0) {
      return 100;
    }

    const completedSmartForms = smartForms.filter(
      (doc) => doc.status !== "INCOMPLETE"
    );
    return (completedSmartForms.length / smartForms.length) * 100;
  });

  @action
  navigateToIncompleteDocument = async (studentId: number) => {
    const application = this.applicationByStudentId(studentId);
    const incompleteDocument = application.documents.find((doc) => !doc.signed);
    if (incompleteDocument == null) {
      return;
    }

    await this.documentsStore.signDocument(incompleteDocument, (document) => {
      const searchParams = new URLSearchParams({
        scheduleSetId: this.scheduleSetId,
        documentId: document.type === "signnow_document" ? document.id : "",
        programId: this.program.id,
        selectedParticipantIds: this.selectedParticipants
          .map((student) => student.id)
          .join(","),
      });
      return `${window.location.origin}${applicationProcessPageRoute.path}?${searchParams}`;
    });
  };

  @action
  navigateToIncompleteSmartForms = (studentId: number) => {
    const studentApplication = this.applicationByStudentId(studentId);

    const studentSmartForms = studentApplication.smart_forms;
    const othersSmartForms = this.applicationSettings.applications
      .filter((application) => application.student.id !== studentId)
      .flatMap((application) => application.smart_forms);

    const smartForms = [...studentSmartForms, ...othersSmartForms].filter(
      (form) => form.status === "INCOMPLETE"
    );

    if (smartForms.length > 0) {
      this.routerStore.navigateToRoute(
        smartFormPipelinePageRoute.build({
          state: { smartForms: smartForms.map((s) => toJS(s)) },
        })
      );
    }
  };

  @computed
  get displayPrice() {
    return !this.applicationSettings.hide_price_in_customer_hub;
  }

  @computed
  get isPaymentInformationRequired() {
    return this.applicationSettings.requires_payment_information;
  }

  @action
  confirmApplication = async () => {
    if (!this.calculatedOrder) return;

    if (this.isPaymentInformationRequired) {
      await this.paymentMethodPickerVm.confirmSelectedPaymentMethod();
    }

    try {
      await this.customerStore.submitApplications(
        this.applicationSettings.applications.map(
          (application) => application.id
        ),
        this.calculatedOrder.final_price > 0
      );
      this.routerStore.navigate(ROUTES.APPLICATION_SUCCESS, {
        replace: true,
      });
    } catch (e) {
      notificator.error("Error", e);
      Sentry.captureException(e);
    }
  };

  private _disposers = Array<() => void>();

  private async _init() {
    this.isLoading = true;
    this.isCalculatingOrder = true;
    try {
      if (this.documentId != null) {
        this._pollDocument();
      }
      await this._refresh();
    } catch (e) {
      notificator.error("Error", e);
      Sentry.captureException(e);
      this.routerStore.returnToSourcePage(ROUTES.SCHEDULE);
    }
  }

  private _pollDocument = async () => {
    const documentId = this.documentId;

    if (documentId == null || this.documentsStore.isSigned(documentId)) {
      return;
    }
    await this.documentsStore.waitUntilDocumentIsSigned(documentId);
    await this._refresh();
  };

  private _refresh = async () => {
    this.scheduleSet = await api.availability.getScheduleSetById(
      this.scheduleSetId
    );

    this.applicationSettings = await api.applications.getOrCreateApplications(
      this.scheduleSetId,
      this.programId,
      this.selectedParticipants.map((student) => student.id)
    );

    this.calculatedOrder = await api.applications.calculateOrder({
      application_ids: this.applicationSettings.applications.map((a) => a.id),
      use_existing_credits: false,
    });

    runInAction(() => {
      this.isLoading = false;
      this.isCalculatingOrder = false;
    });
  };
  dispose = () => {
    this._disposers.forEach((d) => d());
  };
}

const ctx = React.createContext<ApplicationProcessPageVm | null>(null);

export const ApplicationProcessPageVmProvider: React.FC<{}> = ({
  children,
}) => {
  const { searchParams } = applicationProcessPageRoute.useParams();

  if (
    searchParams.programId == null ||
    searchParams.selectedParticipantIds == null ||
    searchParams.scheduleSetId == null
  ) {
    return <Redirect to={ROUTES.SCHEDULE} />;
  }

  return (
    <ApplicationProcessPageVmProviderImpl
      searchParams={{
        programId: searchParams.programId,
        scheduleSetId: searchParams.scheduleSetId,
        documentId: searchParams.documentId,
        selectedParticipantIds: searchParams.selectedParticipantIds,
      }}
    >
      {children}
    </ApplicationProcessPageVmProviderImpl>
  );
};

const ApplicationProcessPageVmProviderImpl: React.FC<{
  children: ReactNode;
  searchParams: {
    programId: string;
    scheduleSetId: string;
    documentId?: string;
    selectedParticipantIds: string;
  };
}> = ({ children, searchParams }) => {
  const {
    paymentMethodStore,
    routerStore,
    customerStore,
    documentsStore,
    paymentStore,
  } = useStores();
  const elements = useElements();

  const vm = useMemo(() => {
    const parsedParticipantIds = searchParams.selectedParticipantIds
      .split(",")
      .map(Number);
    return new ApplicationProcessPageVm(
      searchParams.programId,
      searchParams.scheduleSetId,
      searchParams.documentId,
      parsedParticipantIds,
      elements,
      paymentStore,
      paymentMethodStore,
      routerStore,
      customerStore,
      documentsStore
    );
  }, [elements]);

  useEffect(() => () => vm.dispose(), [vm]);

  return <ctx.Provider value={vm}>{children}</ctx.Provider>;
};

export const useApplicationProcessPageVm = () => useVM(ctx);
