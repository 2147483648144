import React, { ReactNode } from "react";
import cn from "classnames";
import { Avatar, StrictOmit } from "@sizdevteam1/funjoiner-uikit";
import { IStudentDTO } from "src/services/api";
import styles from "./StudentSelector.module.scss";

type StudentWithoutCustomer = StrictOmit<IStudentDTO, "customer">;
interface IProps {
  className?: string;
  students: StudentWithoutCustomer[];
  selectedStudentId?: number;
  onSelectStudent: (student: StudentWithoutCustomer) => void;
  renderCompleteness?: (student: StudentWithoutCustomer) => ReactNode;
  disableOpacityOnInactive?: boolean;
}

const StudentsSelector: React.FC<IProps> = ({
  className,
  students,
  onSelectStudent,
  selectedStudentId,
  renderCompleteness,
  disableOpacityOnInactive,
}) => {
  return (
    <div
      className={cn(
        "flex flex-wrap justify-center gap-y-[16px] gap-x-[4px]",
        className
      )}
    >
      {students.map((student, i, array) => {
        const isActive = selectedStudentId === student.id;
        return (
          <div key={student.id} className="flex">
            <div
              className={cn(styles.student, {
                [styles.active]: isActive,
                "opacity-60": !isActive && !disableOpacityOnInactive,
              })}
              onClick={() => onSelectStudent(student)}
            >
              <div className={styles.avatarWrapper}>
                <Avatar person={student} avatarSize={"48px"} />
              </div>
              <div
                className={cn(
                  styles.info,
                  "text-label flex flex-col items-center gap-1"
                )}
              >
                <span className=" w-[88px] overflow-hidden text-ellipsis whitespace-nowrap text-center">
                  {student.type === "REGULAR" ? student.first_name : "You"}
                </span>
                {renderCompleteness && renderCompleteness(student)}
              </div>
            </div>
            {student.type === "CUSTOMER_AS_PARTICIPANT" && array.length > 1 && (
              <div className="mx-8 h-[80px] w-[1px] bg-separator-color"> </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default StudentsSelector;
