import React from "react";
import cn from "classnames";
import { observer } from "mobx-react-lite";
import useStores from "src/hooks/useStores";
import { isMobile } from "@sizdevteam1/funjoiner-uikit";

interface IProps {
  className?: string;
  buttonName?: string;
}
const Policies: React.FC<IProps> = observer(
  ({ className, buttonName = "Proceed to Payment" }) => {
    const {
      commonStore: { companyProfile },
    } = useStores();

    return (
      <p
        className={cn(
          "typography-small__t m-0 mx-auto  text-center text-text-color",
          className
        )}
      >
        By clicking {buttonName}, you agree to our&nbsp;<br></br>
        <LinkTo to={companyProfile.documents.terms_of_service}>
          Terms of Service
        </LinkTo>
        &nbsp;and&nbsp;
        <LinkTo to={companyProfile.documents.cancellation_policy}>
          Cancellation Policy.
        </LinkTo>
      </p>
    );
  }
);

export default Policies;

const LinkTo: React.FC<{
  className?: string;
  to: string;
}> = ({ to, className, children }) =>
  isMobile() ? (
    <a className={className} href={to} target={"_blank"} rel="noreferrer">
      {children}
    </a>
  ) : (
    <a
      className={className}
      href={to}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        window.open(to, "", popupParams);
      }}
    >
      {children}
    </a>
  );

const popupParams = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
width=414,height=600,left=50,top=150`;
