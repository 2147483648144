import { action, computed, makeObservable } from "mobx";
import React, { useMemo } from "react";
import useStores from "src/hooks/useStores";
import useVM from "src/hooks/useVM";
import { CustomerStore, RouterStore } from "src/stores";
import {
  SmartFormsCompletenessVm,
  useSmartFormsCompletenessVm,
} from "./SmartFormsCompletenessVm";
import { SmartFormDTO } from "../../services/api/smartForms";
import { SmartFormUtils } from "../../util/questionSetUtils";
import { studentQuestionSetRoute } from "./StudentQuestionSetPage/StudentQuestionSetPage";

export class SmartFormsPageVm {
  constructor(
    private customerStore: CustomerStore,
    private routerStore: RouterStore,
    public completenessVm: SmartFormsCompletenessVm
  ) {
    makeObservable(this);
    this._init();
  }

  @computed
  private get _selectedStudentId() {
    return +this.routerStore.searchParams.selectedStudentId || undefined;
  }

  @computed get selectedStudent() {
    if (!this._selectedStudentId) {
      return undefined;
    }
    return this.customerStore.studentsWithQuestions.find(
      (s) => s.id === this._selectedStudentId
    );
  }

  @action.bound
  setSelectedStudentId(studentId: number) {
    this.routerStore.setSearchParam("selectedStudentId", studentId, true);
  }

  @action.bound async _init() {
    const smartFormIdToSelect = this.routerStore.searchParams["smart_form_id"];
    if (smartFormIdToSelect) {
      this.routerStore.setSearchParam("smart_form_id", undefined, true);
      const smartFormToOpen = this.customerStore.smartForms.find(
        (e) => e.id === smartFormIdToSelect
      );
      if (smartFormToOpen) {
        this.setSelectedStudentId(smartFormToOpen.student_id);
        if (smartFormToOpen.status === "INCOMPLETE") {
          this.routerStore.navigateToRoute(
            studentQuestionSetRoute.build({
              pathParams: {
                student_id: String(smartFormToOpen.student_id),
                smart_form_template_id: smartFormToOpen.template_id,
              },
              searchParams: {
                action: "fill",
              },
            })
          );
        }
      }
    }
    const firstStudent = this.students[0];
    if (firstStudent != null && this.selectedStudent == null) {
      this.setSelectedStudentId(firstStudent.id);
    }
  }

  @computed
  get students() {
    return this.customerStore.studentsWithQuestions;
  }

  @computed
  get requiredSets() {
    return SmartFormUtils.filterIncompleteRequired(this._studentQuestionSets);
  }
  @computed
  get optionalSets() {
    return SmartFormUtils.filterIncompleteOptional(this._studentQuestionSets);
  }

  @computed
  get completedSets() {
    return SmartFormUtils.filterComplete(this._studentQuestionSets);
  }

  @computed
  private get _studentQuestionSets(): SmartFormDTO[] {
    const studentSmartForms = this.customerStore.smartForms.filter(
      (e) => e.student_id === this.selectedStudent?.id
    );

    return studentSmartForms ?? [];
  }

  dispose = () => {};
}

const ctx = React.createContext<SmartFormsPageVm | null>(null);

export const SmartFormsPageVmProvider: React.FC = ({ children }) => {
  const { customerStore, routerStore } = useStores();
  const completenessVm = useSmartFormsCompletenessVm();
  const vm = useMemo(() => {
    return new SmartFormsPageVm(customerStore, routerStore, completenessVm);
  }, [customerStore, routerStore, completenessVm]);

  React.useEffect(() => () => vm.dispose(), [vm]);

  return <ctx.Provider value={vm}>{children}</ctx.Provider>;
};

export const useSmartFormsPageVm = () => useVM(ctx);
