import { ICreditDTO } from "src/services/api";
import { IUsedCreditRow } from "src/services/api/credits";
import { findById } from "@sizdevteam1/funjoiner-uikit";

export function groupUsedCredits(
  selectIds: number[],
  allCredits: ICreditDTO[]
): IUsedCreditRow[] {
  const usedCreditIds = new Set(selectIds);
  const usedCredits = allCredits.filter((e) => usedCreditIds.has(e.id));
  const quantityById = new Map<number, number>();

  for (const credit of usedCredits) {
    const quantity = quantityById.get(credit.credit_type_id) ?? 0;
    quantityById.set(credit.credit_type_id, quantity + 1);
  }

  return [...quantityById.entries()].map(([id, quantity]) => {
    const creditType = findById(id, [
      ...new Set(allCredits.map((e) => e.credit_type)),
    ]);
    return {
      credit_type_id: creditType.id,
      is_program_credit: creditType.is_program_credit,
      name: creditType.name,
      quantity: quantity,
      is_free: creditType.is_free,
      total: allCredits.filter((e) => e.credit_type_id === creditType.id)
        .length,
    };
  });
}
