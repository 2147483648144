import React from "react";
import {
  IAvailableDaycampProgram,
  IAvailableOvernightProgram,
  TAvailableProgram,
} from "src/services/api/availability";
import isDaycampProgram from "src/util/isDayCampProgram";
import dayjs from "dayjs";
import formatStartEndDate from "src/util/formatStartEndDate";
import formatStartEndTime from "../../../../../util/formatStartEndTime";

interface IProps {
  program: TAvailableProgram;
  toggleProgram: (program: TAvailableProgram) => void;
}

const ProgramScheduleTable: React.FC<IProps> = ({ program, toggleProgram }) => {
  return (
    <div className="flex flex-col">
      <div className="typography-h3 flex justify-between p-4">
        {program.program_type.name}
        <i
          className={"icon close-icon icon-hover"}
          onClick={() => toggleProgram(program)}
        />
      </div>
      {isDaycampProgram(program) ? (
        program.sessions.map((s) => (
          <SessionInProgramRow session={s} key={s.id} />
        ))
      ) : (
        <OvernightProgramRow key={program.id} program={program} />
      )}
    </div>
  );
};

const SessionInProgramRow = ({
  session,
}: {
  session: IAvailableDaycampProgram["sessions"][number];
}) => {
  return (
    <div className="typography-main flex justify-between px-4 py-2 even:bg-table-row-color">
      <div>{dayjs(session.date).format("MMM Do")}</div>
      <div className="text-gray">{formatStartEndTime(session)}</div>
    </div>
  );
};

const OvernightProgramRow = ({
  program,
}: {
  program: IAvailableOvernightProgram;
}) => {
  return (
    <div className="typography-main px-4 py-2 text-center even:bg-table-row-color">
      {formatStartEndDate(program)}
    </div>
  );
};

export default ProgramScheduleTable;
