import { Dropdown } from "@sizdevteam1/funjoiner-uikit";
import React from "react";
import useStores from "src/hooks/useStores";
import dayjs from "dayjs";
import cn from "classnames";
import { ISOString } from "@sizdevteam1/funjoiner-uikit/types";

type TDropdownAction = {
  icon: React.ReactNode;
  label: string;
  onClick: () => void;
};

interface IProgramTileProps {
  className?: string;
  onClick?: () => void;
  attendance: (
    | {
        start: ISOString;
        end: ISOString;
      }
    | {
        start_date: ISOString;
        end_date: ISOString;
      }
  ) & {
    program_type_name: string;
    schedule_set_name: string;
    location_id: number;
  };
  actions: TDropdownAction[];
}

const ProgramTile = React.forwardRef<HTMLDivElement, IProgramTileProps>(
  ({ attendance, actions, className, onClick }, ref) => {
    const [start, end] =
      "start_date" in attendance
        ? [attendance.start_date, attendance.end_date]
        : [attendance.start, attendance.end];
    const roundedCardClass = "bg-on-main-color shadow-big rounded-[10px]";
    const locationName =
      useStores().commonStore.locationsById[attendance.location_id].name;
    const isSameMonth = dayjs(start).isSame(dayjs(end), "month");
    const monthLabel = isSameMonth
      ? dayjs(start).format("MMM")
      : dayjs(start).format("MMM") + "/" + dayjs(end).format("MMM");
    const daysLabel = dayjs(start).format("D") + " - " + dayjs(end).format("D");
    const isOneDayDuration = dayjs(start).isSame(dayjs(end), "day");

    return (
      <div
        className={cn(
          "relative  box-border flex w-full  items-center gap-4 p-4 ",
          roundedCardClass,
          className
        )}
        onClick={onClick}
      >
        <div className="flex min-w-[90px] justify-center">
          <div
            className={cn(
              "flex h-[60px] w-[90px] flex-col  items-center justify-center rounded-full bg-highlight-color text-dark-main-color"
            )}
          >
            <span className={"typography-label uppercase"}>{monthLabel}</span>
            <span className={"typography-h3"}>
              {isOneDayDuration ? dayjs(start).format("D") : daysLabel}
            </span>
          </div>
        </div>

        <div className={"mr-auto flex flex-col gap-1  text-text-color"}>
          <div className={"typography-small text-gray-text-color"}>
            {locationName}
          </div>
          <div
            className={
              "typography-small w-[188px] overflow-hidden text-ellipsis whitespace-nowrap text-text-color"
            }
          >
            {attendance.schedule_set_name}
          </div>
          <div
            className={
              "typography-main w-[188px] overflow-hidden text-ellipsis whitespace-nowrap text-text-color"
            }
          >
            {attendance.program_type_name}
          </div>
        </div>
        <div onClick={(e) => e.stopPropagation()}>
          {actions.length > 0 && (
            <Dropdown
              showLoaderOnOptionsActions
              base={<i className={"icon more-options-icon icon-hover"} />}
              options={actions.map((a) => (
                <div onClick={a.onClick}>
                  {a.icon}
                  {a.label}
                </div>
              ))}
            />
          )}
        </div>
      </div>
    );
  }
);

export default ProgramTile;
