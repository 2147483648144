import React from "react";
import cn from "classnames";
import { Avatar } from "@sizdevteam1/funjoiner-uikit";
import styles from "./style.module.scss";

interface IProps {
  onClick?: () => void;
  participants: {
    first_name?: string;
    last_name?: string;
    photo?: string;
  }[];
}

const MultiAvatar: React.FC<IProps> = ({ participants, onClick }) => {
  const students = participants.slice(0, 2);
  const double = students.length === 2;
  const avatarSize = double ? 28 : 44;
  return (
    <div
      className={cn(
        styles.wrapper,
        { [styles.double]: double },
        onClick && "cursor-pointer"
      )}
      onClick={onClick}
    >
      {students.map((participant, index) => (
        <Avatar
          key={index}
          avatarSize={avatarSize + "px"}
          person={participant}
        />
      ))}
    </div>
  );
};

export default MultiAvatar;
