import { action, makeObservable, observable, runInAction } from "mobx";
import { blobToDataURL } from "@sizdevteam1/funjoiner-uikit/util";
import api, { IStorageFileDTO, TOwnershipType } from "../services/api";
import storageFileResolver from "../services/storageFileResolver";

export default class UpdatePhoto {
  constructor(
    private ownershipType: TOwnershipType,
    private currentFile?: IStorageFileDTO
  ) {
    this.newFile = currentFile;
    makeObservable(this);
    if (currentFile) {
      if (currentFile.ownership_type === "PUBLIC") {
        this.sourceUrl = currentFile.download_url;
      } else {
        storageFileResolver
          .getSignedLink(currentFile.download_url)
          .then((photo) => (this.sourceUrl = photo));
      }
    }
  }

  @observable
  sourceUrl: string = "";

  @observable
  isUploading = false;

  private newFile?: IStorageFileDTO;

  @action.bound
  async setNewImage(file: File | undefined) {
    if (!file) {
      this.sourceUrl = "";
      this.newFile = undefined;
      return;
    }
    try {
      runInAction(() => {
        this.isUploading = true;
      });
      this.newFile = await api.files.uploadFile(file, this.ownershipType);
      this.sourceUrl = await blobToDataURL(file);
    } finally {
      this.isUploading = false;
    }
  }

  get hasChanged() {
    return this.newFile?.key !== this.currentFile?.key;
  }

  get newFileKey() {
    return this.newFile?.key ?? null;
  }
}
