import * as Sentry from "@sentry/react";
import { FileUpload } from "@sizdevteam1/funjoiner-uikit/components/FileUploader/UploadControllerProvider";
import { action, computed, makeObservable, observable } from "mobx";
import React, { useMemo } from "react";
import useStores from "src/hooks/useStores";
import useVM from "src/hooks/useVM";
import api, { ICustomDocumentDTO, IStudentDTO } from "src/services/api";
import notificator from "src/services/systemNotifications/notificationCenterService";
import { CustomerStore, DocumentsStore, RouterStore } from "src/stores";
import { ROUTES } from "src/stores/RouterStore";
import { customDocumentPageRoute } from "./CustomDocumentPage";

const ctx = React.createContext<CustomDocumentPageVM | null>(null);

export const CustomDocumentPageVMProvider: React.FC = ({ children }) => {
  const { routerStore, customerStore, documentsStore } = useStores();
  const { id } = customDocumentPageRoute.useParams().pathParams;

  const vm = useMemo(
    () =>
      new CustomDocumentPageVM(id, routerStore, customerStore, documentsStore),
    [routerStore, customerStore, documentsStore, id]
  );

  return <ctx.Provider value={vm}>{children}</ctx.Provider>;
};

export const useCustomDocumentPageVM = () => useVM(ctx);

export class CustomDocumentPageVM {
  @observable
  isLoading: boolean = true;

  @observable
  document?: ICustomDocumentDTO;

  @observable
  uploads: FileUpload[] = [];

  @computed
  get student(): IStudentDTO | undefined {
    return this.customerStore.studentsWithCustomerAsParticipant.find(
      (e) => e.id === this.document?.student_id
    );
  }

  @computed
  get isSubmitEnabled(): boolean {
    return (
      this.uploads.length > 0 && this.uploads.every((e) => e.type === "success")
    );
  }

  constructor(
    private id: string,
    private routerStore: RouterStore,
    private customerStore: CustomerStore,
    private documentsStore: DocumentsStore
  ) {
    makeObservable(this);

    this.init();
  }

  async uploadFile(file: File) {
    return await api.files.uploadFile(file, "END_USER");
  }

  @action.bound
  setUploads(uploads: FileUpload[]) {
    this.uploads = uploads;
  }

  @action.bound
  async submit() {
    if (!this.isSubmitEnabled) return;
    let selectedStudentId = undefined;

    try {
      const keys: string[] = [];
      for (let upload of this.uploads) {
        if (upload.type === "success") {
          keys.push(upload.key);
        }
      }
      const doc = await api.documents.attachFiles(this.document!.id, keys);
      selectedStudentId = doc.student_id;
    } catch (e) {
      notificator.error("Error!", e);
    }

    const redirectTo = this.routerStore.searchParams["redirect_to"];
    if (redirectTo != null) {
      /**
       *  FIXME: Page_1 ->(push) Page_2 -> (replace) Page_1.
       *  Остаются 2 Page_1 в стэке, из-за чего back задваивается
       *
       *  Поэтому приходится делать returnToSourcePage.
       *  Это должно работать для application'ов
       **/
      this.routerStore.returnToSourcePage(redirectTo);
    } else {
      this.routerStore.navigate(ROUTES.DOCUMENTS, {
        searchParams: {
          selectedStudentId: selectedStudentId?.toString() ?? "",
        },
        replace: true,
      });
    }
  }

  @action.bound
  private async init() {
    try {
      const document = await api.documents.getById(this.id);
      if (document?.type === "custom_document") {
        this.document = document;
      } else {
        this.routerStore.navigate(ROUTES.DOCUMENTS);
        return;
      }
      this.isLoading = false;
    } catch (e) {
      notificator.error("Error!", e);
      Sentry.captureException(e);
    }
  }
}
