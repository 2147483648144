import { Button, Loader, MonthSelector } from "@sizdevteam1/funjoiner-uikit";
import React from "react";
import useStores from "src/hooks/useStores";
import { observer } from "mobx-react-lite";
import { ProgramsVM } from "src/stores/ScheduleStore/Programs&SessionsVMs";
import {
  IAvailableDaycampSession,
  TAvailableProgram,
} from "src/services/api/availability";
import { ISOString } from "@sizdevteam1/funjoiner-uikit/types";
import NoAvailableProgramsMessage from "../../../AvailabilityPage/components/NoAvailableProgramsMessage";
import ScheduleSetCard from "src/components/AvailabilityCards/ScheduleSetCard";
import { ProgramCard } from "src/components/AvailabilityCards/ProgramCard";
import { IStudentDTO } from "src/services/api";

interface ISelectProgram {
  vm: ProgramsVM;
  joinWaitlist: (program: TAvailableProgram) => void;
  applyToProgram: (program: TAvailableProgram) => Promise<void> | void;
  selectedParticipants: IStudentDTO[];
  selectedSessions: IAvailableDaycampSession[];
  getSelectedUnlinkedParticipantsAmount?: (
    programId: string
  ) => number | undefined;
  showDropIns: boolean;
  showApplications: boolean;
  onDropInClick: ({ id, date }: { id: string; date: ISOString }) => void;
}

const SelectProgram: React.FC<ISelectProgram> = observer(
  ({
    vm,
    joinWaitlist,
    applyToProgram,
    selectedParticipants,
    selectedSessions,
    onDropInClick,
    showApplications,
    showDropIns,
    getSelectedUnlinkedParticipantsAmount,
  }) => {
    const avVM = vm.availabilityVM;

    const {
      commonStore: { companyProfile },
      funboxStore,
    } = useStores();
    const availableProgramSets = vm.availability;
    const noAvailability = avVM.availability.scheduleSets.length === 0;

    return (
      <div>
        {(avVM.loading || avVM.selectedLocationMaxProgramMonth) && (
          <MonthSelector
            className={"rounded-xl bg-card-color py-4 px-2 shadow-big"}
            kind={"mobile"}
            minMonth={avVM.minAvailableDate}
            maxMonth={
              avVM.selectedLocationMaxProgramMonth || avVM.minAvailableDate
            }
            selectedMonth={avVM.selectedMonth}
            onNextMonth={avVM.nextMonth}
            onPrevMonth={avVM.previousMonth}
            onNextYear={() => {}}
            onPrevYear={() => {}}
          />
        )}

        {avVM.loading ? (
          <div className="py-[54px]">
            <Loader />
          </div>
        ) : noAvailability ? (
          <NoAvailableProgramsMessage
            className="mt-4"
            company={companyProfile}
            selectedLocation={funboxStore.selectedLocation}
            hasSelectedLocationSessions={
              avVM.selectedLocationMaxProgramMonth != null
            }
          />
        ) : (
          <div className="mt-4 flex flex-col gap-4">
            {availableProgramSets.scheduleSets.map((set) => (
              <ScheduleSetCard
                isNavigateToScheduleButtonInDescriptionModalVisible={false}
                key={set.id}
                set={set}
                isOpen={vm.openScheduleSetId === set.id}
                setOpenedId={() => vm.toggleOpenScheduleSet(set.id)}
                programCards={set.programs.map((program) => (
                  <ProgramCard
                    key={program.id}
                    highlight={vm.getHighlightForProgram(program.id)}
                    selectedUnlinkedParticipantsAmount={getSelectedUnlinkedParticipantsAmount?.(
                      program.id
                    )}
                    {...{
                      onApplyClick: () => applyToProgram(program),
                      onJoinWaitlistClick: () => joinWaitlist(program),
                      isSelected: [...vm.selectedIds].includes(program.id),
                      onSelectProgram: () => vm.toggle(program),
                      showDropIns: showDropIns,
                      showApplications: showApplications,
                      selectionKind: "checkbox",
                      onDropInClick,
                      program,
                      selectedParticipants,
                      selectedSessions,
                    }}
                  />
                ))}
              />
            ))}
            {avVM.hasNextMonth && (
              <Button kind={"text"} onClick={avVM.nextMonth}>
                More Programs Next Month!
              </Button>
            )}
          </div>
        )}
      </div>
    );
  }
);

export default SelectProgram;
