import React from "react";
import style from "./CancelOrReschedulePage.module.scss";
import dayjs from "dayjs";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import useStores from "src/hooks/useStores";
import { useCancelOrReschedulePageVM } from "./CancelOrRescheduleVM";
import { Avatar, Button } from "@sizdevteam1/funjoiner-uikit";
import { ReactNode } from "react";
import PageHeader from "src/components/PageHeader";
import PageRoot from "src/components/PageRoot";

import { IProgramOfSessionsAttendanceDTO } from "src/services/api";

import {
  IAttendanceItem,
  IOvernightProgramAttendanceDTO,
} from "src/services/api/attendances";
import formatStartEndDate from "src/util/formatStartEndDate";

export const ExpiredMsg: React.FC = () => {
  const {
    commonStore: {
      companyProfile: {
        documents: { cancellation_policy },
      },
    },
  } = useStores();
  return (
    <span className={classNames("text-main", style.expireMsg)}>
      Due to <a href={cancellation_policy}>Company Policy</a>, Self Rescheduling
      and Cancelation expired for this reservation. Please get in touch with the
      business directly for special consideration.
    </span>
  );
};

export const StudentTile: React.FC = observer(() => {
  const student = useCancelOrReschedulePageVM().student;

  return (
    <div className={style.studentTile}>
      <Avatar person={student} avatarSize={"44px"} />
      <span className="text-h3" style={{ color: "var(--text-color)" }}>
        {[student.first_name, student.last_name].join(" ")}
      </span>
    </div>
  );
});

export const CancelOrRescheduleFrom: React.FC = observer(() => {
  const vm = useCancelOrReschedulePageVM();

  const { scheduleSetName, programTypeName, location, attendance } = vm.from;
  const type = attendance.type;

  return (
    <div>
      <div className={"flex gap-1 pl-[10px]"}>
        <span className="icon locations-icon" />
        <span className="typography-main w-[326px] overflow-hidden text-ellipsis whitespace-normal text-gray-text-color">
          {location.name}
        </span>
      </div>
      <div className={classNames(style.from, "mt-4")}>
        <div className={style.header}>
          <span className="typography-h3 text-header-color">
            {programTypeName}
          </span>
          <div className={"typography-label text-gray-text-color"}>
            {scheduleSetName}
          </div>
        </div>
        {
          {
            program: (
              <OvernightProgramTile
                overnightProgram={attendance as IOvernightProgramAttendanceDTO}
              />
            ),
            program_of_sessions: (
              <ProgramOfSessionsTile
                daycampProgram={attendance as IProgramOfSessionsAttendanceDTO}
              />
            ),

            session_in_program: (
              <SessionTile session={attendance as IAttendanceItem} />
            ),
          }[type]
        }
      </div>
    </div>
  );
});

const ProgramOfSessionsTile = ({
  daycampProgram,
}: {
  daycampProgram: IProgramOfSessionsAttendanceDTO;
}) => {
  return (
    <>
      {daycampProgram.items.map((s) => (
        <SessionTile key={s.session_id} session={s} />
      ))}
    </>
  );
};

const SessionTile = ({ session }: { session: IAttendanceItem }) => {
  const date = dayjs(session.date);
  const fakeDate = "2000-01-01";
  const start = dayjs(`${fakeDate}T${session.start_time}`);
  const end = dayjs(`${fakeDate}T${session.end_time}`);

  return (
    <div className={style.attendanceTile}>
      <span className="typography-main text-text-color even:bg-table-row-color">
        {date.format("MMM Do")}
      </span>
      <span className="typography-main text-gray-text-color">
        {start.format("h:mmA")} - {end.format("h:mmA")}
      </span>
    </div>
  );
};

const OvernightProgramTile = ({
  overnightProgram,
}: {
  overnightProgram: IOvernightProgramAttendanceDTO;
}) => {
  return (
    <div className="typography-main overflow-hidden bg-table-row-color py-2   text-center text-text-color">
      {formatStartEndDate(overnightProgram)}
    </div>
  );
};

interface ISubmitButtonProps {
  onSubmit: () => Promise<void>;
  disabled?: boolean;
  children: string;
}

export const SubmitButton: React.FC<ISubmitButtonProps> = observer(
  (props: ISubmitButtonProps) => {
    return (
      <div className={style.submitButton}>
        <Button
          kind={"action"}
          size={"big"}
          disabled={props.disabled}
          onClick={props.onSubmit}
          autoLoading={true}
        >
          {props.children}
        </Button>
      </div>
    );
  }
);

interface ICancelOrReschedulePageRootProps {
  header: string;
  children: ReactNode[] | ReactNode;
  footer: ReactNode;
}

export const CancelOrReschedulePageRoot: React.FC<ICancelOrReschedulePageRootProps> =
  observer((props: ICancelOrReschedulePageRootProps) => {
    return (
      <>
        <PageRoot className={style.root}>
          <div>
            <PageHeader showBackLink={true}>{props.header}</PageHeader>
            {props.children}
          </div>
        </PageRoot>
        <div className={style.footer}>{props.footer}</div>
      </>
    );
  });
