import React from "react";
import { observer } from "mobx-react-lite";
import PageRoot from "../../../components/PageRoot";
import cn from "classnames";
import styles from "./Payment.module.scss";
import FlexContainer from "../../../components/FlexContainer";
import Border from "../../../components/Border";
import { useCheckoutAndPaymentPageVM } from "../Checkout&PaymentVM";
import {
  Button,
  CreditIcon,
  PriceWithCents,
} from "@sizdevteam1/funjoiner-uikit";
import {
  ICreditOrderItemDTO,
  IUpgradeOrderItem,
} from "src/services/api/orders";
import useStores from "src/hooks/useStores";
import PageHeader from "src/components/PageHeader";
import { PaymentMethodPicker } from "../../../components/PaymentMethods/PaymentMethodPicker";

const Payment: React.FC = observer(() => {
  const vm = useCheckoutAndPaymentPageVM();
  return (
    <PageRoot>
      <PageHeader showBackLink>Payment</PageHeader>
      <div className={cn(styles.Section)}>
        {vm.order.items.map((item) =>
          item.type === "credit_item" ? (
            <CreditItem key={item.id} data={item} />
          ) : item.type === "upgrade_item" ? (
            <UpgradeItem key={item.credit_id} orderItem={item} />
          ) : null
        )}
        <FlexContainer
          className={cn(
            vm.order.items.length > 0 &&
              "border-0 border-t-[1px] border-solid border-separator-color"
          )}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <div className={cn("text-main_sb")}>
            {vm.selectedConfirmedAvailablePaymentPlan
              ? "Payment Plan. 1st Installment"
              : "Final Price"}
          </div>
          <div className={cn("text-h2 text-text-color")}>
            <PriceWithCents
              amount={
                vm.attachedPaymentPlan
                  ? vm.attachedPaymentPlan.installments[0].amount
                  : vm.order.final_price
              }
              prefix="$"
              typography="typography-h2"
            />
          </div>
        </FlexContainer>
      </div>
      <Border />
      <div className={"my-8"}>
        {(vm.paymentMethodPickerVm.cards.length > 0 ||
          vm.paymentMethodPickerVm.isApplePayOptionVisible ||
          vm.paymentMethodPickerVm.isInvoiceOptionVisible) && (
          <div className={cn("typography-h2", styles.subtitle)}>
            Choose Payment Method
          </div>
        )}
        <PaymentMethodPicker vm={vm.paymentMethodPickerVm} />
      </div>
      <SubmitButton />
    </PageRoot>
  );
});

const SubmitButton = observer(() => {
  const vm = useCheckoutAndPaymentPageVM();
  const isApplePaySelected = vm.paymentMethodPickerVm.isApplePayOptionSelected;
  return (
    <Button
      className={cn(
        "sticky bottom-3 mt-auto",
        isApplePaySelected && "!border-text-color !bg-text-color"
      )}
      autoLoading={true}
      size={"big"}
      loader={isApplePaySelected ? { color: "white" } : undefined}
      onClick={vm.onPayClicked}
    >
      {isApplePaySelected ? (
        <i className={"icon apple-pay-icon min-h-[24px] min-w-[59px]"} />
      ) : (
        <>
          Pay&nbsp;
          <PriceWithCents
            amount={
              vm.attachedPaymentPlan
                ? vm.attachedPaymentPlan.installments[0].amount
                : vm.order.final_price
            }
            prefix="$"
            typography="typography-button"
          />
        </>
      )}
    </Button>
  );
});
const CreditItem: React.FC<{ data: ICreditOrderItemDTO }> = ({ data }) => {
  return (
    <FlexContainer
      className="gap-2"
      key={data.id}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <FlexContainer alignItems={"center"} className="gap-[6px]">
        <div className={"text-main flex"}>{data.credit_type.name}</div>
        <div
          className={"text-small"}
          style={{ color: "var(--gray-text-color)" }}
        >
          {data.credit_type.is_free && "Free "}
          {data.credit_type.is_program_credit ? "Program" : "Session"}
        </div>
      </FlexContainer>
      <div className="flex gap-1">
        <CreditIcon
          size={24}
          isFree={data.credit_type.is_free}
          isProgram={data.credit_type.is_program_credit}
        />
        <div className={"typography-main"}>×&nbsp;{data.quantity}</div>
      </div>
    </FlexContainer>
  );
};

interface IUpgradeItemProps {
  orderItem: IUpgradeOrderItem;
}

const UpgradeItem: React.FC<IUpgradeItemProps> = observer(
  (props: IUpgradeItemProps) => {
    const toCreditType =
      useStores().commonStore.creditTypesById[
        props.orderItem.to_credit_type_id
      ];

    return (
      <div className="flex flex-row items-center gap-2">
        <span className="text-main text-text-color">
          {toCreditType?.name} Upgrade
        </span>
        <span className="text-small text-gray-text-color">
          {toCreditType.is_program_credit ? "Program" : "Session"}
        </span>
        <CreditIcon
          className="ml-auto"
          isProgram={toCreditType.is_program_credit}
          isFree={toCreditType.is_free}
          size={24}
        />
      </div>
    );
  }
);

export default Payment;
