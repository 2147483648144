import React, { useMemo, useEffect } from "react";
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import useStores from "../../../hooks/useStores";
import useVM from "../../../hooks/useVM";
import { AuthStore, CommonStore, CustomerStore } from "../../../stores";
import { getNameError, validateEmail } from "../../../util/validators";
import { isValidPhoneNumber } from "react-phone-number-input";
import notificator from "../../../services/systemNotifications/notificationCenterService";
import api from "../../../services/api";

export class PersonalInfoPageVM {
  private _disposers: (() => void)[] = [];
  constructor(
    private customerStore: CustomerStore,
    authStore: AuthStore,
    private commonStore: CommonStore
  ) {
    makeObservable(this);
    this.modal = new EmailOrPhoneEditModalVM(customerStore, authStore);
    this.nameModal = new NameModalVM(
      customerStore,
      () => (this.isNameModalOpen = false)
    );
    this.addressModal = new AddressModalVM(
      customerStore,
      commonStore,
      () => (this.isAddressModalOpen = false)
    );
    this.reportAbuseModal = new ReportAbuseVM(
      customerStore,
      () => (this.isReportAbuseModalOpen = false)
    );
  }

  @computed get customer() {
    return this.customerStore.customer;
  }
  @computed get isNameDefined() {
    return this.customer.first_name || this.customer.last_name;
  }

  @observable isNameModalOpen = false;
  @observable isAddressModalOpen = false;
  @observable isReportAbuseModalOpen = false;
  nameModal: NameModalVM;
  addressModal: AddressModalVM;
  reportAbuseModal: ReportAbuseVM;
  modal: EmailOrPhoneEditModalVM;

  dispose = () => {
    this._disposers.forEach((d) => d());
  };
}

type TInputs = "first_name" | "last_name";
type TErrors = "first_name_error" | "last_name_error";
class NameModalVM {
  constructor(
    private customerStore: CustomerStore,
    public onClose: () => void
  ) {
    makeObservable(this);
  }

  @computed get isEdit() {
    return this.customerStore.hasFullCustomerInfo;
  }

  @observable
  first_name = this.customerStore.customer?.first_name ?? "";

  @observable
  first_name_error: string | null = null;

  @observable
  last_name = this.customerStore.customer?.last_name ?? "";

  @observable
  last_name_error: string | null = null;

  @action
  handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name as TInputs;
    const errorName = (e.currentTarget.name + "_error") as TErrors;
    this[name] = e.currentTarget.value;
    this[errorName] = null;
  };

  @action private validate() {
    this.first_name_error = getNameError(this.first_name);
    this.last_name_error = getNameError(this.last_name);
    return !this.first_name_error && !this.last_name_error;
  }

  @action.bound async submit() {
    if (!this.validate()) return;
    await this.customerStore.updateProfile({
      first_name: this.first_name,
      last_name: this.last_name,
    });
    this.onClose();
  }
}

class AddressModalVM {
  constructor(
    private customerStore: CustomerStore,
    private commonStore: CommonStore,
    public onClose: () => void
  ) {
    makeObservable(this);
  }

  @computed get isEdit() {
    return this.customerStore.customer.address != null;
  }

  @observable
  address: string = this.customerStore.customer.address ?? "";

  @observable addressError: string | null = null;

  @action.bound setAddress(v: string) {
    this.addressError = null;
    this.address = v;
  }

  @action private validate() {
    if (
      this.address.length === 0 &&
      this.commonStore.publicSettings.baseline_questions.for_customers.address
        .status === "required"
    ) {
      this.addressError = "Cannot be Empty";
      return false;
    }
    return true;
  }

  @action.bound async submit() {
    if (!this.validate()) return;
    await this.customerStore.updateProfile({
      address: this.address,
    });
    this.onClose();
  }
}

class ReportAbuseVM {
  constructor(
    private customerStore: CustomerStore,
    public onClose: () => void
  ) {
    makeObservable(this);
  }
  @observable abuseText = "";
  @observable abuseTextError: null | string = null;

  @action.bound setAbuseText(v: string) {
    this.abuseTextError = null;
    this.abuseText = v;
  }

  @action private validate() {
    if (this.abuseText.trim().length < 8) {
      this.abuseTextError = "Min 8 symbols";
      return false;
    }
    return true;
  }
  @action.bound async submit() {
    if (!this.validate()) return;
    try {
      await api.profile.reportSmsAbuse(this.abuseText);
      this.abuseText = "";
      this.onClose();
    } catch (e) {
      notificator.error("Error!", e);
    }
  }
}

class EmailOrPhoneEditModalVM {
  constructor(
    private customerStore: CustomerStore,
    private authStore: AuthStore
  ) {
    makeObservable(this);
  }
  @observable state: "email" | "phone" | "closed" = "closed";
  @observable step: "input" | "code_validation" = "input";

  @observable email = "";
  @action setEmail = (v: string) => (this.email = v);

  @observable phone = "";
  @action setPhone = (v: string | undefined) => (this.phone = v ?? "");

  @observable code = "";

  @action setCode = (v: string) => {
    this.code = v.slice(0, 6);
    if (this.code.length === 6) {
      this.submitCode();
    }
  };

  @observable isSubmittingCode = false;
  private async submitCode() {
    if (this.code.length !== 6 || this.isSubmittingCode) return;
    this.isSubmittingCode = true;
    try {
      if (this.state === "email") {
        const { email } = await this.authStore.changeEmailWithEmailCode(
          this.code
        );
        runInAction(() => {
          this.customerStore.customer.email = email;
        });
      } else {
        const { phone_number } = await this.authStore.changePhoneWithCode(
          this.code
        );
        runInAction(() => {
          this.customerStore.customer.phone_number = phone_number;
        });
      }
      this.close();
    } catch (e) {
      notificator.error("Error!", e);
    } finally {
      this.isSubmittingCode = false;
    }
  }
  @computed get hasEmailAddress() {
    return Boolean(this.customerStore.customer.email);
  }
  @computed get hasPhone() {
    return Boolean(this.customerStore.customer.phone_number);
  }

  @computed get shouldGetCode() {
    return (
      (this.state === "email" && this.customerStore.customer.email != null) ||
      (this.state === "phone" &&
        this.customerStore.customer.phone_number != null)
    );
  }

  @computed get isValidInput() {
    if (this.state === "email") {
      return (
        this.email !== this.customerStore.customer.email &&
        validateEmail(this.email)
      );
    } else {
      return (
        this.customerStore.customer.phone_number !== this.phone &&
        isValidPhoneNumber(this.phone)
      );
    }
  }
  @action.bound async save() {
    if (!this.isValidInput || this.state === "closed") return;
    try {
      if (this.state === "email") {
        await this.customerStore.updateProfile({
          email: this.email,
        });
      } else {
        await this.customerStore.updateProfile({
          phone_number: this.phone,
        });
      }
      this.close();
    } catch (e) {
      notificator.error("Error!", e);
    }
  }
  @action.bound async getCode() {
    if (!this.isValidInput || this.state === "closed") return;
    try {
      if (this.state === "email") {
        await this.authStore.getEmailAuthCode(this.email);
      } else {
        await this.authStore.getPhoneNumberAuthCode(this.phone);
      }
      this.step = "code_validation";
    } catch (e) {
      notificator.error("Error!", e);
    }
  }

  @action.bound close() {
    this.state = "closed";
    this.step = "input";
    this.email = "";
    this.phone = "";
    this.code = "";
  }
}

const ctx = React.createContext<PersonalInfoPageVM | null>(null);

export const PersonalInfoPageVMProvider: React.FC<{}> = ({ children }) => {
  const { customerStore, authStore, commonStore } = useStores();
  const vm = useMemo(
    () => new PersonalInfoPageVM(customerStore, authStore, commonStore),
    [customerStore, authStore, commonStore]
  );
  useEffect(() => () => vm.dispose());
  return <ctx.Provider value={vm}>{children}</ctx.Provider>;
};

export const usePersonalInfoPageVM = () => useVM(ctx);
