import {
  Button,
  Input,
  Label,
  Modal,
  PhoneInput,
} from "@sizdevteam1/funjoiner-uikit";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";
import PageHeader from "src/components/PageHeader";
import PageRoot from "src/components/PageRoot";
import {
  Contact,
  ContactsPageVMProvider,
  useContactsPageVM,
} from "./ContactsPageVM";

const ContactsPage = () => (
  <ContactsPageVMProvider>
    <ContactsPageImpl />
  </ContactsPageVMProvider>
);

const ContactsPageImpl = observer(() => {
  const vm = useContactsPageVM();
  return (
    <PageRoot>
      <PageHeader showBackLink>Contacts</PageHeader>
      {vm.contacts.length === 0 && (
        <div className="flex flex-col items-center gap-2 rounded-[10px] bg-card-color py-4 px-6 shadow-big">
          <div className="typography-main_sb text-center text-gray-text-color">
            Please provide contact details of trusted people who can be
            contacted in case of an emergency
          </div>
          <Button
            onClick={() => vm.openContactModal()}
            className="w-fit"
            kind="text"
          >
            Add New Contact Now
          </Button>
        </div>
      )}
      {vm.contacts.length > 0 && (
        <div className={"flex flex-col gap-6"}>
          {vm.contacts.map((contact, index) => (
            <EmergencyContact
              key={index}
              contact={contact}
              onEditClick={vm.openContactModal}
            />
          ))}
          <div className="h-[1px] w-full bg-separator-color"></div>
          <Button
            onClick={() => vm.openContactModal()}
            kind="text"
            className="w-fit"
          >
            <i className="icon add-icon" />
            Add New Contact
          </Button>
        </div>
      )}
      {vm.isContactsModalOpen && <ContactsModal />}
    </PageRoot>
  );
});

const ContactsModal = observer(() => {
  const vm = useContactsPageVM();
  const editVM = vm.EditContactVM;
  return (
    <Modal
      onClose={vm.closeContactModal}
      headerText={
        vm.EditContactVM.contactOnEdit ? "Edit Contact" : "Add New Contact"
      }
    >
      <div className="flex flex-col gap-6">
        <div>
          <Label>Contact’s Name</Label>
          <Input
            value={editVM.name}
            onChange={({ currentTarget }) =>
              editVM.setName(currentTarget.value)
            }
            errorText={editVM.nameError}
          />
        </div>
        <div>
          <Label>Phone</Label>
          <PhoneInput
            placeholder={"(222) 222-2222"}
            value={editVM.phone}
            onChange={editVM.setPhone}
            errorText={editVM.phoneError}
          />
        </div>
        <Button autoLoading size="big" onClick={editVM.submit}>
          Save
        </Button>
        {editVM.contactOnEdit && (
          <Button
            autoLoading
            onClick={editVM.deleteContact}
            className="mx-auto w-fit"
            color="red"
            kind="text"
          >
            <i className="icon trash-icon !h-6 !w-6" />
            Delete this Contact
          </Button>
        )}
      </div>
    </Modal>
  );
});

const EmergencyContact = ({
  contact,
  onEditClick,
}: {
  contact: Contact;
  onEditClick: (contact: Contact) => void;
}) => {
  return (
    <div className={"flex flex-col gap-3 text-text-color"}>
      <div className={"typography-main_sb flex justify-between gap-4"}>
        {contact.name}
        <Button kind="icon" onClick={() => onEditClick(contact)}>
          <i className="icon edit-icon icon-hover  !bg-main-color hover:!bg-dark-main-color" />
        </Button>
      </div>
      <div className={"ml-2 flex flex-col gap-3"}>
        {contact.phone_number && (
          <div key={"phone"} className={"flex flex-row gap-3"}>
            <i className={classNames("icon mobile-icon")} />
            <div className={"typography-main"}>{contact.phone_number}</div>
          </div>
        )}
        {contact.email && (
          <div key={"email"} className={"flex flex-row gap-3"}>
            <i className={classNames("icon emails-icon")} />
            <div className={"typography-main"}>{contact.email}</div>
          </div>
        )}
        {contact.address && (
          <div className={"flex flex-row items-start gap-3"}>
            <i className={classNames("icon address-icon")} />
            <div className={"typography-main"}>{contact.address}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactsPage;
