import React from "react";
import "react-phone-number-input/style.css";
import PageRoot from "src/components/PageRoot";
import { observer } from "mobx-react-lite";
import { SignInPageVMProvider, useSignInPageVM } from "./SignInPageVM";
import PageHeader from "../../components/PageHeader";
import { useLocation } from "react-router-dom";
import {
  Button,
  Input,
  isMobile,
  PhoneInput,
} from "@sizdevteam1/funjoiner-uikit";
import cn from "classnames";
import classNames from "classnames";
import Tabs from "@sizdevteam1/funjoiner-uikit/components/Tabs";
import CodeInput from "../../components/CodeInput";
import { twMerge } from "tailwind-merge";
import TimeoutButton from "../../components/TimeoutButton";

const SignInPage = () => (
  <SignInPageVMProvider>
    <SignInPageImpl />
  </SignInPageVMProvider>
);

const SignInPageImpl: React.FC<{}> = observer(() => {
  const vm = useSignInPageVM();

  return vm.waitingForCode ? <Verification /> : <EnterCredentials />;
});

const EnterCredentials = observer(() => {
  const location = useLocation<{ from?: string } | undefined>();

  const from = location.state?.from;

  let titleMessage = "";
  if (from?.includes("buy-credits")) {
    titleMessage = " to Buy Credits";
  }
  if (from?.includes("checkout")) {
    titleMessage = " to Checkout";
  }
  if (from?.includes("schedule")) {
    titleMessage = " to Schedule";
  }
  const vm = useSignInPageVM();

  return (
    <PageRoot>
      <PageHeader>Sign In{titleMessage}</PageHeader>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          return vm.getCode();
        }}
        className={"contents"}
      >
        <div className={"typography-h2 text-header-color"}>
          Welcome to {vm.companyName}!
        </div>
        <Tabs.List
          selected={vm.selectedLoginType}
          onSelect={vm.selectLoginType}
          items={[
            {
              value: "phone",
              label: (
                <div className={"typography-h3 flex gap-1"}>
                  <i
                    className={cn(
                      "icon mobile-icon",
                      vm.selectedLoginType === "phone" && "bg-main-color"
                    )}
                  />
                  Cell Phone
                </div>
              ),
            },
            {
              value: "email",
              label: (
                <div className={"typography-h3  flex gap-1"}>
                  <i
                    className={cn(
                      "icon emails-icon",
                      vm.selectedLoginType === "email" && "bg-main-color"
                    )}
                  />
                  Email
                </div>
              ),
            },
          ]}
        />

        <div className={"mt-6"}>
          {vm.selectedLoginType === "phone" ? (
            <PhoneInput
              placeholder={"(222) 222-2222"}
              value={vm.phoneNumber}
              onChange={(phone) => (vm.phoneNumber = phone ?? "")}
              errorText={vm.phoneError}
            />
          ) : (
            <Input
              placeholder={"mailbox@example.com"}
              inputMode={"email"}
              type={"email"}
              onChange={(e) => (vm.email = e.currentTarget.value)}
              value={vm.email}
            />
          )}
        </div>

        <div className={cn("text-small text-gray mt-4 text-center")}>
          Enter your{" "}
          {vm.selectedLoginType === "phone" ? "phone number" : "email address"}{" "}
          to continue
        </div>

        <Button
          type={"submit"}
          className={classNames("mb-3 w-full", isMobile() ? "mt-5" : "mt-auto")}
          size={"big"}
          loading={vm.loading}
          disabled={!vm.canGetCode}
        >
          Continue
        </Button>
      </form>
    </PageRoot>
  );
});

const Verification = observer(() => {
  const vm = useSignInPageVM();

  return (
    <PageRoot>
      <PageHeader showBackLink onBackLinkClick={vm.backToCredentials}>
        {vm.selectedLoginType === "email" ? "Email" : "SMS"} Verification
      </PageHeader>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          return vm.signInWithCode();
        }}
        className={"contents"}
      >
        <div className={"typography-main mt-4 text-gray-text-color"}>
          An {vm.selectedLoginType === "email" ? "email" : "SMS"} with a code
          has been sent to:
        </div>
        <div className={"typography-main mt-1 text-header-color"}>
          {vm.selectedLoginType === "email" ? vm.email : vm.phoneNumber}
        </div>

        <CodeInput
          className={"mt-6"}
          values={vm.code.split("")}
          onChange={vm.setCode}
        />
        {vm.error && (
          <div className={cn("text-small mt-2 text-delete-color")}>
            {vm.error}
          </div>
        )}

        <div className={twMerge(isMobile() ? "mt-5" : "sticky mt-auto")}>
          <TimeoutButton onClick={vm.resendCode} />
          <Button
            className={classNames("mt-5 mb-3 w-full")}
            size={"big"}
            loading={vm.loading}
            disabled={!vm.isCodeOk}
            type={"submit"}
          >
            Continue
          </Button>
        </div>
      </form>
    </PageRoot>
  );
});

export default SignInPage;
