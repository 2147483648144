import React, { useMemo } from "react";
import useVM from "src/hooks/useVM";
import useStores from "src/hooks/useStores";
import { action, computed, makeObservable, observable } from "mobx";
import { AuthStore, CommonStore, RouterStore } from "src/stores";
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";
import { ROUTES } from "../../stores/RouterStore";
import * as Sentry from "@sentry/react";
import { validateEmail } from "../../util/validators";

export class SignInPageVM {
  constructor(
    private authStore: AuthStore,
    private routerStore: RouterStore,
    private commonStore: CommonStore
  ) {
    makeObservable(this);
    if (this.authStore.loggedIn)
      this.routerStore.returnToSourcePage(ROUTES.ROOT);
    const customerEmail = this.routerStore.searchParams["customer_email"];
    if (customerEmail) {
      this.selectedLoginType = "email";
      this.email = customerEmail;
      this.routerStore.setSearchParam("customer_email", undefined, true);
    }
  }
  get companyName() {
    return this.commonStore.companyProfile.name;
  }

  @observable selectedLoginType: "email" | "phone" = "phone";

  @action.bound selectLoginType(type: "email" | "phone") {
    this.selectedLoginType = type;
  }

  @observable email = "";

  @observable phoneNumber = "";

  @observable code = "";
  @computed get isCodeOk() {
    return this.code.length === 6;
  }
  @action.bound async setCode(code: string) {
    this.code = code;
    if (this.isCodeOk) {
      await this.signInWithCode();
    }
  }

  @observable error = "";

  @observable loading = false;

  @computed
  get waitingForCode() {
    return (
      (this.selectedLoginType === "phone"
        ? this.authStore.waitingForSmsCode
        : this.authStore.waitingForEmailCode) &&
      this._step === "code_validation"
    );
  }

  @computed
  get phoneError() {
    return this.phonePossible && !this.phoneValid ? "Invalid phone number" : "";
  }

  @action
  getCode = async () => {
    try {
      this.loading = true;
      if (this.selectedLoginType === "phone") {
        await this.authStore.getPhoneNumberAuthCode(this.phoneNumber);
      } else {
        await this.authStore.getEmailAuthCode(this.email);
      }
      this._step = "code_validation";
    } finally {
      this.loading = false;
    }
  };
  @action.bound
  async resendCode() {
    this.code = "";
    if (this.selectedLoginType === "phone") {
      await this.authStore.getPhoneNumberAuthCode(this.phoneNumber);
    } else {
      await this.authStore.getEmailAuthCode(this.email);
    }
  }
  @action
  backToCredentials = () => {
    this.error = "";
    this.code = "";
    this._step = "credentials";
  };

  @computed get canGetCode() {
    return !this.loading && this.selectedLoginType === "phone"
      ? this.phoneValid
      : validateEmail(this.email);
  }
  @action signInWithCode = async () => {
    if (this.loading || !this.isCodeOk) return;

    this.error = "";
    try {
      this.loading = true;
      if (this.selectedLoginType === "email") {
        await this.authStore.signInWithEmailCode(this.code);
      } else {
        await this.authStore.signInWithSmsCode(this.code);
      }
      this.routerStore.returnToSourcePage(ROUTES.ROOT, true);
    } catch (e) {
      console.error(e);
      const error: any = e;
      if (
        typeof error == "object" &&
        error != null &&
        "code" in error &&
        error.code === "auth/invalid-verification-code"
      ) {
        this.error = "Invalid code";
      } else if (typeof error === "string") {
        this.error = error;
      } else {
        Sentry.captureException(e);
        this.error = "Something went wrong";
      }
    } finally {
      this.loading = false;
    }
  };

  @computed private get phoneValid() {
    return isValidPhoneNumber((this.phoneNumber as string) || "");
  }
  @computed private get phonePossible() {
    return isPossiblePhoneNumber((this.phoneNumber as string) || "");
  }
  @observable private _step: "credentials" | "code_validation" = "credentials";
}

const ctx = React.createContext<SignInPageVM | null>(null);

export const SignInPageVMProvider: React.FC<{}> = ({ children }) => {
  const { authStore, routerStore, commonStore } = useStores();

  const vm = useMemo(
    () => new SignInPageVM(authStore, routerStore, commonStore),
    [authStore, commonStore, routerStore]
  );

  return <ctx.Provider value={vm}>{children}</ctx.Provider>;
};

export const useSignInPageVM = () => useVM(ctx);
