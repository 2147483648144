import React, { useMemo } from "react";
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import { IPagination } from "../../services/api/common";
import api, { IOrderDTO } from "../../services/api";
import useVM from "../../hooks/useVM";

class HistoryPageVm {
  static ITEMS_PER_PAGE = 25;

  constructor() {
    makeObservable(this);
    this.init();
  }

  @observable
  orderHistory: IPagination<IOrderDTO> = {
    total: 0,
    offset: 0,
    has_more: false,
    items: [],
  };

  @observable
  isInitializing = true;

  @observable
  isLoadingNextPage = false;

  @computed
  get isEmpty() {
    return this.orderHistory.items.length === 0;
  }
  @action.bound
  async init() {
    this.isInitializing = true;
    try {
      const history = await api.orders.getOrders({
        limit: HistoryPageVm.ITEMS_PER_PAGE,
        offset: 0,
      });
      runInAction(() => {
        this.orderHistory = history;
      });
    } finally {
      runInAction(() => {
        this.isInitializing = false;
      });
    }
  }

  @action.bound
  async loadNextPage() {
    if (this.isLoadingNextPage) return;

    this.isLoadingNextPage = true;
    try {
      const result = await api.orders.getOrders({
        limit: HistoryPageVm.ITEMS_PER_PAGE,
        offset: this.orderHistory.items.length,
      });

      this.orderHistory = {
        offset: result.offset,
        total: result.total,
        has_more: result.has_more,
        items: [...this.orderHistory.items, ...result.items],
      };
    } finally {
      runInAction(() => {
        this.isLoadingNextPage = false;
      });
    }
  }

  dispose() {}
}

const ctx = React.createContext<HistoryPageVm | null>(null);

export interface HistoryPageVmProviderProps {}

export const HistoryPageVmProvider: React.FC<HistoryPageVmProviderProps> = ({
  children,
}) => {
  const vm = useMemo(() => new HistoryPageVm(), []);
  React.useEffect(() => () => vm.dispose(), [vm]);

  return <ctx.Provider value={vm}>{children}</ctx.Provider>;
};

export const useHistoryPageVm = () => useVM(ctx);
