import { ICompanyProfileDTO, ILocationDTO } from "../../../services/api";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../stores/RouterStore";
import React from "react";
import InfoMessage from "src/components/InfoMessage/InfoMessage";
import cn from "classnames";

const NoAvailableProgramsMessage = (props: {
  className?: string;
  company: ICompanyProfileDTO;
  hasSelectedLocationSessions: boolean;
  selectedLocation?: ILocationDTO;
}) => (
  <InfoMessage className={cn("py-8 px-12", props.className)}>
    {props.hasSelectedLocationSessions ? (
      <>
        Apologies, no Programs/Sessions are available that match your current
        selection and Filter settings. If you need assistance please{" "}
        <Link to={ROUTES.HELP}>contact</Link> us.
      </>
    ) : (
      <>
        {props.company.name || "Business"} has not released new schedules for{" "}
        {props.selectedLocation?.name}. Please{" "}
        <Link to={ROUTES.HELP}>contact</Link> them for new info.
      </>
    )}
  </InfoMessage>
);

export default NoAvailableProgramsMessage;
