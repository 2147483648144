import { Splash } from "@sizdevteam1/funjoiner-uikit";
import { observer } from "mobx-react-lite";
import React from "react";
import { Redirect, useParams } from "react-router-dom";
import LocationCard from "src/components/LocationCard/LocationCard";
import PageRoot from "src/components/PageRoot";
import useStores from "src/hooks/useStores";

import {
  PreselectFunboxByIdOrUrlPartPageVMProvider,
  useSelectLocationInFunboxPageVM,
} from "./PreselectFunboxByIdOrUrlPartPageVM";
import { ROUTES } from "../../stores/RouterStore";

const PreselectFunboxByIdOrUrlPartPage = observer(() => {
  const { id, url_part } = useParams<{ id?: string; url_part?: string }>();
  return (
    <PreselectFunboxByIdOrUrlPartPageVMProvider id={id} url_part={url_part}>
      <PreselectFunboxByIdOrUrlPartPageImpl />
    </PreselectFunboxByIdOrUrlPartPageVMProvider>
  );
});

const PreselectFunboxByIdOrUrlPartPageImpl = observer(() => {
  const vm = useSelectLocationInFunboxPageVM();
  const { commonStore } = useStores();

  return vm.state === "loading" ? (
    <Splash pathToAnimation={"/splash.json"} />
  ) : vm.state === "funbox-not-found" ? (
    <Redirect to={ROUTES.ROOT} />
  ) : (
    <PageRoot>
      <div className="typography-h1 text-center text-header-color">
        {commonStore.companyProfile.name}
      </div>
      <div className="mt-2 mr-1 flex justify-center gap-1">
        <i className="icon funbox-icon !h-6 !w-6 bg-icon-grey-color " />
        <div className="typography-h3  max-w-[306px] overflow-hidden text-ellipsis whitespace-nowrap text-text-color">
          {vm.state.name}
        </div>
      </div>
      <div className="typography-main mt-3 text-gray-text-color">
        Select a location to continue:
      </div>
      <div className="mt-6">
        {vm.state.active_locations.map((location) => (
          <LocationCard
            key={location.id}
            location={location}
            select={vm.selectLocationAndGoToWelcomeScreen}
          />
        ))}
      </div>
    </PageRoot>
  );
});

export default PreselectFunboxByIdOrUrlPartPage;
