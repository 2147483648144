import {
  IAvailableDaycampProgram,
  IAvailableOvernightProgram,
} from "src/services/api/availability";

function isDaycampProgram(
  program: IAvailableDaycampProgram | IAvailableOvernightProgram
): program is IAvailableDaycampProgram {
  return (program as IAvailableDaycampProgram).sessions !== undefined;
}

export default isDaycampProgram;
