import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import { ROUTES } from "src/stores/RouterStore";
import LocationImg from "src/assets/imgs/location.svg";
import styles from "./DirectionsBlock.module.scss";

const DirectionsBlock: React.FC = () => {
  return (
    <div className={cn(styles.directions, "shadow-big")}>
      <div>
        <img src={LocationImg} alt={"Location icon"} />
      </div>
      <div className={styles.text}>
        <div className={cn("text-h3", styles.name)}>Directions and Details</div>
        <div
          className={cn(
            styles.small,
            "typography-small__t text-gray-text-color"
          )}
        >
          Review our <Link to={ROUTES.DIRECTIONS_DETAILS}>Location</Link> page
          in advance for essential directions and details.
        </div>
      </div>
    </div>
  );
};

export default DirectionsBlock;
