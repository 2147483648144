import {
  action,
  computed,
  IComputedValue,
  makeObservable,
  observable,
} from "mobx";
import { IStudentDTO } from "../../services/api";
import CustomerStore from "../CustomerStore";

export type TStudentOrCreateIntention = Omit<IStudentDTO, "customer">;

const SELECTED_STUDENT_KEY = "selectedStudentIds";
export default class ParticipantSelectionVM {
  constructor(
    private customerStore: CustomerStore,
    private browserBack: () => void,
    public readonly isSelectedFunboxCustomerAsParticipant: IComputedValue<boolean>
  ) {
    makeObservable(this);
    // this.selectFromLocalStorage();
  }

  @observable private _isReSelectingParticipants = false;
  @computed get isSelectingParticipants() {
    return (
      this._isReSelectingParticipants || this.selectedParticipants.length === 0
    );
  }

  @action.bound goBack() {
    if (this._isReSelectingParticipants) {
      this._isReSelectingParticipants = false;
    } else {
      this.browserBack();
    }
  }
  @action.bound toSelectParticipants() {
    this._isReSelectingParticipants = true;
  }

  @computed get selectedParticipants() {
    if (this.isSelectedFunboxCustomerAsParticipant.get()) {
      return [
        this.customerStore.customerAsParticipant ??
          this.fakeCustomerAsParticipant,
      ];
    }
    return this.customerStore.studentsWithoutCustomerAsParticipant.filter(
      ({ id }) => this._selectedParticipantIds.includes(id)
    );
  }
  @computed
  get selectedParticipantIds() {
    return this.selectedParticipants.map((e) => e.id);
  }

  @computed
  get selectedStudentIds() {
    return this.selectedParticipants.map((e) => e.id);
  }

  @action.bound
  selectParticipants = (newSelection: Array<IStudentDTO>) => {
    this._selectedParticipantIds = newSelection.map(({ id }) => id);
    this._isReSelectingParticipants = false;
    localStorage.setItem(
      SELECTED_STUDENT_KEY,
      newSelection.map(({ id }) => id).toString()
    );
  };

  @action
  private selectFromLocalStorage = () => {
    const selectedStudentIds =
      localStorage.getItem(SELECTED_STUDENT_KEY)?.split(",").map(Number) ?? [];
    const studentsToSelect =
      this.customerStore.studentsWithoutCustomerAsParticipant.filter((s) =>
        selectedStudentIds.includes(s.id)
      );
    this.selectParticipants(studentsToSelect);
  };

  @action
  removeParticipant = (idToDelete: number) => {
    this.selectParticipants(
      this.selectedParticipants.filter(({ id }) => id !== idToDelete)
    );
  };

  @action.bound
  reset() {
    this._selectedParticipantIds = [];
  }

  @observable
  private _selectedParticipantIds: number[] = [];

  private fakeCustomerAsParticipant: IStudentDTO = {
    id: -1,
    birth_date: "01/01/2000",
    first_name: "",
    last_name: "",
    full_name: "",
    name: "",
    qr: "",
    allergies: "",
    medical_considerations: "",
    customer: this.customerStore.customer,
    type: "CUSTOMER_AS_PARTICIPANT",
    customer_notes: "",
    personal_pronoun: "none",
    gender: null,
  };
}
