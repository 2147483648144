import { action, computed, makeObservable, observable } from "mobx";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import useStores from "src/hooks/useStores";
import useVM from "src/hooks/useVM";
import api, { ICreditTypeDTO } from "src/services/api";
import {
  CommonStore,
  CustomerStore,
  PaymentStore,
  RouterStore,
} from "src/stores";
import { ROUTES } from "src/stores/RouterStore";
import { Cents } from "@sizdevteam1/funjoiner-uikit/types";

export type ConcreteUpgradeOption = {
  to: ICreditTypeDTO;
  price: Cents;
};

export class UpgradeCreditPageVM {
  @observable
  private _quantity: number = 1;

  public get quantity(): number {
    return this._quantity;
  }

  @computed
  get totalUpgradePrice(): number | undefined {
    return this.selectedUpgrade != null
      ? this.selectedUpgrade.price * this.quantity
      : undefined;
  }

  @computed
  get canDecrement() {
    return this._quantity > 1;
  }

  @action.bound
  decrement() {
    if (this.canDecrement) {
      this._quantity--;
    }
  }

  @computed
  get canIncrement() {
    return (
      this._quantity <
      this.customerStore.credits.filter(
        (e) => e.credit_type_id === this.fromCreditTypeId
      ).length
    );
  }

  @action.bound
  increment() {
    if (this.canIncrement) {
      this._quantity++;
    }
  }

  @observable
  selectedUpgrade?: ConcreteUpgradeOption;

  @computed
  get upgradeOptions(): ConcreteUpgradeOption[] {
    return this.fromCreditType.upgrade_options.map((e) => {
      return {
        to: this.commonStore.creditTypesById[e.to_credit_type_id],
        price: e.price,
      };
    });
  }

  @computed
  get isValid() {
    return this.totalUpgradePrice != null;
  }

  @action.bound
  async upgradeCredit() {
    if (!this.isValid) return;

    const creditsToUpgrade = this.customerStore.credits
      .filter((e) => e.credit_type_id === this.fromCreditTypeId)
      .slice(0, this.quantity);

    const order = await api.credits.upgrade(
      creditsToUpgrade.map((e) => ({
        credit_id: e.id,
        new_credit_type_id: this.selectedUpgrade!.to.id,
      }))
    );

    this.paymentStore.setIncompleteOrder(order);
    this.routerStore.navigate(`${ROUTES.CHECKOUT}?step=payment`);
  }

  constructor(
    private fromCreditTypeId: number,
    private commonStore: CommonStore,
    private customerStore: CustomerStore,
    private paymentStore: PaymentStore,
    private routerStore: RouterStore
  ) {
    makeObservable(this);
  }

  @computed
  get fromCreditType(): ICreditTypeDTO {
    return this.commonStore.creditTypesById[this.fromCreditTypeId];
  }
}

const ctx = React.createContext<UpgradeCreditPageVM | null>(null);

export const UpgradeCreditPageVMProvider: React.FC = ({ children }) => {
  const { customerStore, commonStore, paymentStore, routerStore } = useStores();
  const { id } = useParams<{ id: string }>();
  const vm = useMemo(
    () =>
      new UpgradeCreditPageVM(
        parseInt(id),
        commonStore,
        customerStore,
        paymentStore,
        routerStore
      ),
    [id, customerStore, commonStore, paymentStore, routerStore]
  );

  return <ctx.Provider value={vm}>{children}</ctx.Provider>;
};

export const useUpgradeCreditPageVM = () => useVM(ctx);
