import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import RootStore from "./RootStore";
import authService, { IDecodedToken } from "../services/auth/authService";
import { ConfirmationResult } from "firebase/auth";
import firebasePhoneAuth from "../services/auth/firebasePhoneAuth";
import * as Sentry from "@sentry/react";
import api from "../services/api";

export default class AuthStore {
  constructor() {
    authService.onAuthStateChanged(this._onAuthStateChanged);
    makeObservable(this);
    // we set initializing for the first half second to let auth event fire
    setTimeout(() => runInAction(() => (this.initialized = true)), 500);
  }

  @observable
  loggedIn = false;

  @observable
  initialized = false;

  @computed get waitingForSmsCode() {
    return this._confirmationResult != null;
  }
  @computed get waitingForEmailCode() {
    return this.emailAuthCodeId != null;
  }

  @action getPhoneNumberAuthCode = async (phoneNumber: string) => {
    this._confirmationResult = await firebasePhoneAuth.getConfirmationObject(
      phoneNumber
    );
  };

  @action signInWithSmsCode = async (code: string) => {
    if (!this._confirmationResult) {
      throw new Error("No confirmation object");
    }
    const token = await firebasePhoneAuth.confirm(
      code,
      this._confirmationResult
    );
    await authService.signInWithFirebaseToken(token);
    runInAction(() => (this._confirmationResult = undefined));
  };

  @action changePhoneWithCode = async (code: string) => {
    if (!this._confirmationResult) {
      throw new Error("No confirmation object");
    }
    const token = await firebasePhoneAuth.confirm(
      code,
      this._confirmationResult
    );
    const result = await api.profile.changePhoneWithFirebaseToken(token);
    runInAction(() => (this._confirmationResult = undefined));
    return result;
  };
  @action getEmailAuthCode = async (email: string) => {
    this.emailAuthCodeId = await authService.getEmailCode(email);
  };

  @action signInWithEmailCode = async (code: string) => {
    if (!this.emailAuthCodeId) {
      throw new Error("No email auth code id");
    }
    await authService.continueWithEmailCode(this.emailAuthCodeId, code);
    runInAction(() => (this.emailAuthCodeId = undefined));
  };
  @action changeEmailWithEmailCode = async (code: string) => {
    if (!this.emailAuthCodeId) {
      throw new Error("No email auth code id");
    }
    const result = await api.profile.changeEmailWithEmailCode(
      this.emailAuthCodeId,
      code
    );
    runInAction(() => (this.emailAuthCodeId = undefined));
    return result;
  };

  @action
  signOut = async () => {
    try {
      await authService.logout();
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      throw e;
    } finally {
    }
  };

  @observable private _confirmationResult?: ConfirmationResult;
  @observable private emailAuthCodeId?: string;

  @action
  private _onAuthStateChanged = async (state: IDecodedToken | null) => {
    console.log(state);
    this.loggedIn = state != null;
  };
}
