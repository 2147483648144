export default function setDefault<TKey, TValue>(
  map: Map<TKey, TValue>,
  { key, value }: { key: TKey; value: TValue }
): TValue {
  if (!map.has(key)) {
    map.set(key, value);
  }

  return map.get(key)!;
}
