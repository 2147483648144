import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import useStores from "src/hooks/useStores";
import { QuestionPipelinePage } from "src/pages/QuestionPipelinePage/QuestionPipelinePage";
import { IQuestionPipelineDelegate } from "src/components/shared/QuestionPipeline/QuestionPipelineVm";
import { SmartFormDTO } from "src/services/api/smartForms";
import { CustomerStore, RouterStore } from "src/stores";
import { startIndexForQuestionSet } from "src/util/questionSetUtils";
import { defineRoute, ReturnPageParams } from "src/util/typedRouting";
import api from "../../../services/api";

export type StudentQuestionSetAction = "edit" | "fill";
type PageParams = {
  pathParams: {
    student_id: string;
    smart_form_template_id: string;
  };
  searchParams: {
    action: StudentQuestionSetAction;
  };
};

export const studentQuestionSetRoute = defineRoute<PageParams>({
  path: "/personal-information/:student_id/sets/:smart_form_template_id",
  build(path, params) {
    return path
      .replace(":student_id", params.student_id)
      .replace(":smart_form_template_id", params.smart_form_template_id);
  },
});

const StudentQuestionSetPage: React.FC<{
  description: string;
}> = observer(({ description }) => {
  const params = studentQuestionSetRoute.useParams();

  const { routerStore, customerStore } = useStores();
  const delegate = useMemo(
    () => new PersonalInformationProcessor(params, routerStore, customerStore),
    [routerStore, customerStore]
  );
  return <QuestionPipelinePage delegate={delegate} description={description} />;
});

class PersonalInformationProcessor implements IQuestionPipelineDelegate {
  constructor(
    private params: ReturnPageParams<PageParams>,
    private routerStore: RouterStore,
    private customerStore: CustomerStore
  ) {}
  finishText?: string | undefined;

  startIndexFor = (set: SmartFormDTO) => {
    const action = this.params.searchParams?.action;

    if (action === "fill") {
      return startIndexForQuestionSet(set);
    } else {
      return 0;
    }
  };

  fetchSmartForms = async () => {
    if (this.customerStore.smartForms.length === 0) {
      await this.customerStore.loadSmartForms();
    }

    const smartForms = this.customerStore.smartForms.filter(
      (e) => e.student_id === parseInt(this.params.pathParams.student_id)
    );

    const selected = smartForms.find(
      (e) => e.template_id === this.params.pathParams.smart_form_template_id
    )!;

    return [selected];
  };

  onFinish = () => {
    this.routerStore.history.goBack();
  };

  uploadFile = async (file: File) => {
    return await api.files.uploadFile(file, "END_USER");
  };
}

export default StudentQuestionSetPage;
