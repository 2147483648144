import React, { useEffect, useMemo } from "react";
import useVM from "src/hooks/useVM";
import useStores from "src/hooks/useStores";
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import { ROUTES } from "src/stores/RouterStore";
import {
  CommonStore,
  CustomerStore,
  PaymentStore,
  RouterStore,
} from "src/stores";
import api, { ICardDTO, IOrderDTO } from "../../services/api";
import { StripeElements } from "@stripe/stripe-js";
import { useElements } from "@stripe/react-stripe-js";
import notificator from "src/services/systemNotifications/notificationCenterService";
import { groupUsedCredits } from "src/util/groupCredits";
import { PaymentMethodStore } from "../../stores";
import {
  IScheduleAndPayOrderDTO,
  isScheduleAndPayOrder,
} from "../../services/api/orders";
import * as Sentry from "@sentry/react";
import { scheduleAndPaySuccessRoute } from "../SuccessPages/ScheduleAndPaySuccessPage";
import { IUsedCreditRow } from "src/services/api/credits";
import { invoiceSentSuccessRoute } from "../SuccessPages/InvoiceSentSuccessPage";
import { PaymentMethodPickerVm } from "../../components/PaymentMethods/PaymentMethodPickerVm";
import { raise } from "@sizdevteam1/funjoiner-uikit";

export class CheckoutAndPaymentPageVM {
  private disposers: (() => void)[] = [];
  dispose = () => {
    this.disposers.forEach((dispose) => dispose());
  };
  constructor(
    private paymentStore: PaymentStore,
    paymentMethodStore: PaymentMethodStore,
    private routerStore: RouterStore,
    private customerStore: CustomerStore,
    private commonStore: CommonStore,
    private elements: StripeElements | null
  ) {
    makeObservable(this);
    if (!this.paymentStore.incompleteOrder) {
      this.routerStore.navigate(ROUTES.DASHBOARD, {
        replace: true,
      });
    }

    this.selectedPaymentPlanId = this.routerStore.searchParams.payment_plan_id;

    this.paymentMethodPickerVm = new PaymentMethodPickerVm(
      {
        isInvoiceEnabled: computed(
          () =>
            isScheduleAndPayOrder(this.order) &&
            this.selectedConfirmedAvailablePaymentPlan == null
        ),
        applePay: {
          payment: computed(() => {
            let finalPrice: number;
            if (this.selectedConfirmedAvailablePaymentPlan) {
              const installments = this.order.payment_plan?.installments;
              finalPrice = installments?.[0]?.amount ?? 0;
            } else {
              finalPrice = this.order.final_price;
            }

            return {
              final_price: finalPrice,
              description: this.order.description,
            };
          }),
          isImmediateChargeRequired: true,
        },
      },
      elements,
      paymentStore,
      paymentMethodStore,
      customerStore
    );

    this.disposers.push(this.paymentMethodPickerVm.dispose);
  }

  @observable
  paymentMethodPickerVm: PaymentMethodPickerVm;

  @computed
  get step() {
    return this.routerStore.searchParams["step"] === "payment"
      ? "payment"
      : this.routerStore.searchParams["step"] === "payment_plans"
      ? "payment_plans"
      : "checkout";
  }

  //PaymentPlans

  @computed get availablePaymentPlans() {
    return this.paymentStore.availablePaymentPlans;
  }

  @action.bound selectPaymentPlan(id?: string) {
    this.selectedPaymentPlanId = id;
  }

  @observable selectedPaymentPlanId?: string;

  @computed get selectedConfirmedAvailablePaymentPlan() {
    if (!this.selectedPaymentPlanId) return undefined;
    return this.availablePaymentPlans.find(
      (p) => p.payment_plan.id === this.selectedPaymentPlanId
    );
  }

  @computed get attachedPaymentPlan() {
    return this.order.payment_plan;
  }

  @action.bound navigateToPaymentPlans() {
    this.routerStore.setSearchParam("step", "payment_plans", true);
  }

  @action.bound async confirmPaymentPlanOption() {
    this.selectedPaymentPlanId
      ? await this.paymentStore.attachPaymentPlan({
          order_id: this.order.id,
          payment_plan_id: this.selectedPaymentPlanId,
        })
      : await this.paymentStore.detachPaymentPlan({
          order_id: this.order.id,
        });

    const paymentPlanId =
      this.selectedConfirmedAvailablePaymentPlan?.payment_plan.id;

    this.routerStore.navigate(
      ROUTES.SCHEDULE_CHECKOUT,
      paymentPlanId != null
        ? {
            searchParams: {
              payment_plan_id: paymentPlanId,
            },
            replace: true,
          }
        : {}
    );
  }

  //Payment Plans End

  @observable
  agreeToPolicy: boolean = false;

  @observable
  isPromoInputFocused: boolean = false;

  @computed
  get creditsToUse(): IUsedCreditRow[] {
    return "credits_used" in this.order && this.order["credits_used"]
      ? groupUsedCredits(this.order["credits_used"], this.customerStore.credits)
      : [];
  }

  get order(): IOrderDTO | IScheduleAndPayOrderDTO {
    return this.paymentStore.incompleteOrder ?? raise("Order is null");
  }

  @computed get orderOwnFeesTotalAmount() {
    return this.order.own_fees.reduce((acc, f) => acc + f.amount, 0);
  }

  @action
  proceedToPayment = () => {
    this.routerStore.setSearchParam("step", "payment");
  };

  @observable
  processingPayment: {
    paymentMethod: ICardDTO;
    amount: number;
  } | null = null;

  @action
  onPayClicked = async () => {
    const paymentMethod =
      await this.paymentMethodPickerVm.confirmSelectedPaymentMethod();

    if (!this.order || !this.elements) throw new Error("Order is null");

    try {
      if (paymentMethod.type === "invoice") {
        const order = await this.paymentStore.changePaymentTypeToInvoice(
          this.order.id
        );
        this.routerStore.navigateToRoute(
          invoiceSentSuccessRoute.build({ state: { order } })
        );
        return;
      }

      let clientSecret = await this._getStripeClientSecretToConfirm();
      await this.paymentStore.confirmPayment(
        clientSecret,
        paymentMethod.paymentMethodId,
        true
      );
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      notificator.error("Payment failed!", e);
      throw e;
    }

    this._startOrderPolling(paymentMethod.card);
  };

  private _getStripeClientSecretToConfirm = async () => {
    if (isScheduleAndPayOrder(this.order) && this.order.payment_plan !== null) {
      const order = await api.orders.payInstallment(
        this.order.id,
        this.order.payment_plan.installments[0].id
      );

      return (
        order.payment_plan?.installments[0].payment?.stripe_client_secret ??
        raise("stripe_client_secret is null for Payment Plan")
      );
    } else {
      return (
        this.order.payment.stripe_client_secret ??
        raise("stripe_client_secret is null")
      );
    }
  };

  _startOrderPolling = async (card: ICardDTO) => {
    try {
      this.processingPayment = {
        paymentMethod: card,
        amount:
          isScheduleAndPayOrder(this.order) && this.order.payment_plan
            ? this.order.payment_plan.installments[0].amount
            : this.order.final_price,
      };

      const order = await this.paymentStore.waitForOrderProceed(this.order.id);

      this.paymentStore.saveCompletedOrder(order, card);
      runInAction(() => {
        this.paymentStore.resetAvailablePaymentPlans();
        this.paymentStore.setIncompleteOrder(null);
        this.processingPayment = null;
        this.customerStore.loadPromocodes();
        if (isScheduleAndPayOrder(order)) {
          this.routerStore.navigateToRoute(
            scheduleAndPaySuccessRoute.build({
              state: {
                bookingResult: {
                  attendanceIds: order.sign_up.created_attendance_ids,
                },
              },
            })
          );
        } else {
          this.routerStore.navigate(ROUTES.PAYMENT_SUCCESS);
        }
      });
      //Todo: Find more elegant way to load credits
      this.customerStore.loadCredits().then();
    } catch (e) {
      runInAction(() => {
        this.processingPayment = null;
      });

      console.error(e);
      Sentry.captureException(e);
      //fixme: should use generic method to show error messages https://funjoiner.atlassian.net/browse/FJ-1302
      notificator.error("Payment failed!", e);
    }
  };
  // Email input
  @computed
  get requiresEmail() {
    return !this.customerStore.customer.email;
  }

  @observable
  emailInput = "";

  @observable
  emailError: string | null = null;

  @action
  setEmailInput = (v: string) => {
    this.emailError = null;
    this.emailInput = v;
  };

  @computed
  get policies() {
    return this.commonStore.companyProfile?.documents.terms_of_service;
  }
}

const ctx = React.createContext<CheckoutAndPaymentPageVM | null>(null);

export const CheckoutAndPaymentPageVMProvider: React.FC<{}> = ({
  children,
}) => {
  const {
    paymentStore,
    paymentMethodStore,
    routerStore,
    customerStore,
    commonStore,
  } = useStores();
  const elements = useElements();
  const vm = useMemo(
    () =>
      new CheckoutAndPaymentPageVM(
        paymentStore,
        paymentMethodStore,
        routerStore,
        customerStore,
        commonStore,
        elements
      ),
    [
      paymentStore,
      paymentMethodStore,
      routerStore,
      customerStore,
      commonStore,
      elements,
    ]
  );

  useEffect(() => () => vm.dispose());
  return <ctx.Provider value={vm}>{children}</ctx.Provider>;
};

export const useCheckoutAndPaymentPageVM = () => useVM(ctx);
