import { Loader } from "@sizdevteam1/funjoiner-uikit";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";
import { OrderState } from "src/stores/ScheduleStore/SchedulePageVM";
import CreditsToBuyOrSchedule from "./components/CreditsToBuyOrSchedule";
import styles from "./SummarySection.module.scss";

interface IProps {
  orderVM: { state: OrderState };
  className?: string;
}

const SummarySection: React.FC<IProps> = observer(({ orderVM, className }) => {
  const { state } = orderVM;

  return (
    <section className={classNames(className)}>
      {state?.case({
        pending: () => <Loader className={classNames("py-[17.5px] px-0")} />,
        fulfilled: ({ creditsToBuy, creditsToUse }) => (
          <CreditsToBuyOrSchedule
            itemsToBuy={creditsToBuy}
            itemsToSchedule={creditsToUse}
          />
        ),
      })}
    </section>
  );
});

export default SummarySection;
