import classNames from "classnames";
import { action, computed, makeObservable, observable } from "mobx";
import React, { useMemo } from "react";
import useStores from "src/hooks/useStores";
import useVM from "src/hooks/useVM";
import { IFunboxDTO, ILocationDTO } from "src/services/api";
import { RouterStore } from "src/stores";

import { ROUTES } from "src/stores/RouterStore";
import getUniqueFoldersFromLocations from "src/util/getUniqueFoldersFromLocations";

export class SelectLocationModalVM {
  constructor(
    private routerStore: RouterStore,
    public readonly selectedLocation: ILocationDTO,
    public readonly selectedFunbox: IFunboxDTO,
    private onSelectLocation: (location_id: number) => void,
    public onClose: () => void,
    public readonly isShowingViewMoreButton: boolean,
    public readonly useGlobalFolderSelector: boolean
  ) {
    makeObservable(this);
    useGlobalFolderSelector && this.preSelectLocationFolderFromStorage();
  }

  get funboxLocationsByFolder() {
    return this.selectedFunbox.active_locations
      .filter(
        (locWithFunboxes) =>
          this.selectedLocationFolderOption.id === "all" ||
          this.selectedLocationFolderOption.id === locWithFunboxes.folder?.id
      )
      .sort((a, b) => {
        if (a.id === this.selectedLocation.id) return -1;
        if (b.id === this.selectedLocation.id) return 1;
        return 0;
      });
  }

  @computed get isAtLeastOneLocationFolderExist() {
    return this.locationFolderOptions.length > 1;
  }

  @action.bound navigateToSelectFunboxPage() {
    this.onClose();
    this.routerStore.navigate(ROUTES.SELECT_FUNBOX_PAGE, {
      searchParams: {
        selected_tab: "location",
      },
    });
  }

  @computed get locationFolderOptions() {
    return [
      {
        name: (
          <div
            className={classNames(
              "ml-[30px]",
              this.selectedLocationFolderOption.id === "all" &&
                "text-main-color"
            )}
          >
            All Locations
          </div>
        ),
        id: "all",
      },
      ...getUniqueFoldersFromLocations(
        this.selectedFunbox.active_locations
      ).map((f) => ({
        id: f.id,
        name: (
          <div className="flex gap-[6px]">
            <i
              className={classNames(
                "icon locations-icon",
                this.selectedLocationFolderOption.id === f.id && "bg-main-color"
              )}
            />
            <div className="max-w-[270px] overflow-hidden text-ellipsis">
              {f.name}
            </div>
          </div>
        ),
      })),
    ];
  }

  @observable
  selectedLocationFolderOption: (typeof this.locationFolderOptions)[number] = {
    id: "all",
    name: <div className="ml-[30px]">All Locations</div>,
  };

  @action.bound selectLocationFolderOption(
    o: (typeof this.locationFolderOptions)[number]
  ) {
    this.selectedLocationFolderOption = o;
  }

  @action.bound selectLocation(location: ILocationDTO) {
    this.onSelectLocation(location.id);
    this.useGlobalFolderSelector &&
      localStorage.setItem(
        "location_folder_id",
        this.selectedLocationFolderOption.id
      );
    this.onClose();
  }

  @action.bound private preSelectLocationFolderFromStorage() {
    const locationFolderId = localStorage.getItem("location_folder_id");
    if (locationFolderId == null) return;
    const folderOption = this.locationFolderOptions.find(
      (f) => f.id === locationFolderId
    );
    if (folderOption == null) return;
    this.selectLocationFolderOption(folderOption);
  }
}
const ctx = React.createContext<SelectLocationModalVM | null>(null);

export const SelectLocationModalVMProvider: React.FC<{
  selectedLocation: ILocationDTO;
  selectedFunbox: IFunboxDTO;
  onSelectLocation: (location_id: number) => void;
  onClose: () => void;
  isShowingViewMoreButton: boolean;
  useGlobalFolderSelector: boolean;
}> = ({
  children,
  selectedLocation,
  selectedFunbox,
  onSelectLocation,
  onClose,
  isShowingViewMoreButton,
  useGlobalFolderSelector,
}) => {
  const { routerStore } = useStores();

  const vm = useMemo(
    () =>
      new SelectLocationModalVM(
        routerStore,
        selectedLocation,
        selectedFunbox,
        onSelectLocation,
        onClose,
        isShowingViewMoreButton,
        useGlobalFolderSelector
      ),
    [
      routerStore,
      selectedLocation,
      selectedFunbox,
      onSelectLocation,
      onClose,
      isShowingViewMoreButton,
      useGlobalFolderSelector,
    ]
  );

  return <ctx.Provider value={vm}>{children}</ctx.Provider>;
};

export const useSelectLocationModalVM = () => useVM(ctx);
