import { Button, PriceWithCents, Radio } from "@sizdevteam1/funjoiner-uikit";
import { observer } from "mobx-react-lite";
import React, { useLayoutEffect, useRef, useState } from "react";
import PageHeader from "src/components/PageHeader";
import PageRoot from "src/components/PageRoot";

import {
  CheckoutAndPaymentPageVM,
  useCheckoutAndPaymentPageVM,
} from "../Checkout&PaymentVM";
import dayjs from "dayjs";
import StickyFooter from "src/components/StickyFooter";
import useStores from "src/hooks/useStores";
import styles from "./SelectPaymentPlanPage.module.scss";
import { twMerge } from "tailwind-merge";
import {
  IOrderPaymentPlanInstallmentDTO,
  TPaymentPlanType,
} from "src/services/api/orders";
import classNames from "classnames";
import { assertNever } from "@sizdevteam1/funjoiner-uikit/util";

const SelectPaymentPlanPage = observer(() => {
  const vm = useCheckoutAndPaymentPageVM();
  const { commonStore, routerStore } = useStores();

  return (
    <div>
      <PageRoot>
        <PageHeader
          onBackLinkClick={() =>
            routerStore.setSearchParam("step", "checkout", true)
          }
          showBackLink
        >
          Create Payment Option
        </PageHeader>
        <div className="typography-main text-gray-text-color">
          Your credit card will be automatically charged for each following
          installment on the scheduled deadline. There are no extra fees or
          interest charges involved.
        </div>
        {commonStore.paymentPlanPolicy && (
          <a
            href={commonStore.paymentPlanPolicy}
            rel="noreferrer"
            target="_blank"
            className="group !typography-main_sb mt-3 ml-3 flex w-fit gap-1"
          >
            <i
              className={twMerge(
                "icon doc-icon !h-6 !w-6 bg-main-color group-hover:bg-dark-main-color"
              )}
            />
            Payment Plans Policy
          </a>
        )}
        <div className="mt-5 mb-4 flex flex-col gap-4">
          <PayInFullOption vm={vm} />
          {vm.availablePaymentPlans.map((p) => {
            const dueNowInstallment = p.installments[0];
            const futureInstallments = p.installments.slice(1);

            return (
              <div
                key={p.payment_plan.id}
                className="overflow-hidden rounded-lg shadow-big"
              >
                <div className="bg-on-main-color p-4">
                  <div className="flex justify-between">
                    <span className="typography-h3 capitalize text-text-color">
                      {p.payment_plan.name}
                    </span>
                    <Radio
                      onChange={(v) => vm.selectPaymentPlan(p.payment_plan.id)}
                      checked={vm.selectedPaymentPlanId === p.payment_plan.id}
                    />
                  </div>
                  {p.payment_plan.description && (
                    <PaymentPlanDescription text={p.payment_plan.description} />
                  )}
                </div>
                <InstallmentTile
                  key={dueNowInstallment.id}
                  title={"Due Now"}
                  installment={dueNowInstallment}
                />
                {p.payment_plan.type === "biweekly_payments" ||
                p.payment_plan.type === "weekly_payments" ||
                p.payment_plan.type === "monthly_payments" ? (
                  <PeriodicInstallmentsGrouped
                    futureInstallments={futureInstallments}
                    type={p.payment_plan.type}
                  />
                ) : (
                  futureInstallments.map((installment) => (
                    <InstallmentTile
                      key={installment.id}
                      title={dayjs(installment.due_date).format("MMM Do")}
                      installment={installment}
                    />
                  ))
                )}
                {p.payment_plan.setup_fee && (
                  <div className="bg-on-main-color">
                    <div className="mx-4 h-[1px] bg-separator-color "></div>
                    <SetupFee
                      amount={p.payment_plan.setup_fee.flat_amount}
                      name={"Payment Plan Set-up Fee"}
                    />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </PageRoot>
      <StickyFooter className="py-3">
        <Button
          autoLoading
          onClick={vm.confirmPaymentPlanOption}
          size="big"
          className="mx-auto  mt-auto !w-[366px]"
        >
          Confirm
        </Button>
      </StickyFooter>
    </div>
  );
});

const PayInFullOption = observer(({ vm }: { vm: CheckoutAndPaymentPageVM }) => {
  return (
    <div className="overflow-hidden rounded-lg shadow-big">
      <div className="bg-on-main-color p-4">
        <div className="flex justify-between ">
          <span className="typography-h3 text-text-color">Pay in Full</span>
          <Radio
            onChange={() => vm.selectPaymentPlan(undefined)}
            checked={!vm.selectedPaymentPlanId}
          />
        </div>
        <div className="typography-small__t mt-2  text-text-color">
          Complete your full payment now
        </div>
      </div>
      <div className="flex justify-between bg-table-row-color px-4  py-2">
        <span className="typography-main text-text-color">Due Now</span>
        <PriceWithCents
          amount={
            vm.order.final_price -
            vm.order.installment_fees.reduce((acc, i) => i.amount + acc, 0)
          }
          prefix="$"
          typography="typography-main_sb"
        />
      </div>
    </div>
  );
});

const SetupFee = ({ name, amount }: { name: string; amount: number }) => {
  return (
    <div className="typography-label flex justify-between bg-on-main-color px-4 py-3 text-gray-text-color">
      <span>{name}</span>
      <PriceWithCents
        amount={amount}
        prefix="$"
        typography="typography-label"
      />
    </div>
  );
};

const InstallmentTile = ({
  title,
  installment,
}: {
  title: string;
  installment: IOrderPaymentPlanInstallmentDTO;
}) => {
  return (
    <div className="flex items-center justify-between px-4 py-2 text-text-color odd:bg-on-main-color even:bg-table-row-color ">
      <span className="typography-main">
        {title}
        {/*{dayjs(installment.due_date).format("MMM Do")}*/}
      </span>
      <PriceWithCents
        amount={installment.amount}
        prefix="$"
        typography="typography-main_sb"
      />
    </div>
  );
};

const PeriodicInstallmentsGrouped = ({
  futureInstallments,
  type,
}: {
  futureInstallments: IOrderPaymentPlanInstallmentDTO[];
  type: "biweekly_payments" | "weekly_payments" | "monthly_payments";
}) => {
  const lastInstallment = futureInstallments.at(-1);
  if (lastInstallment == null) return null;

  const firstFutureInstallment = futureInstallments[0];
  let period: string;
  switch (type) {
    case "biweekly_payments":
      period = "bi-weekly";
      break;
    case "weekly_payments":
      period = "weekly";
      break;
    case "monthly_payments":
      period = "monthly";
      break;
    default:
      assertNever(type);
  }
  return (
    <div className="flex items-center justify-between bg-on-main-color px-4 py-2 text-text-color">
      <div className={"flex flex-col gap-[2px]"}>
        <div className={"typography-small text-gray-text-color"}>
          Start from {dayjs(firstFutureInstallment.due_date).format("MMM Do")}
        </div>
        <div className="typography-main">
          Bill {period} until {dayjs(lastInstallment.due_date).format("MMM Do")}
        </div>
      </div>
      <div className="flex gap-1">
        <span className="typography-main text-gray-text-color">
          {futureInstallments.length}&nbsp;×
        </span>
        <PriceWithCents
          amount={firstFutureInstallment.amount}
          prefix="$"
          typography="typography-main_sb"
        />
      </div>
    </div>
  );
};

const PaymentPlanDescription = ({ text }: { text: string }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    if (ref.current) {
      setHasMore(ref.current?.offsetHeight > 64);
    }
  }, [ref]);

  return (
    <div
      onClick={() => (hasMore ? setIsOpen(!isOpen) : undefined)}
      style={{
        maxHeight:
          isOpen && ref.current?.offsetHeight
            ? ref.current?.offsetHeight + 28
            : 64,
      }}
      className={twMerge(
        "typography-small__t mt-2 text-text-color",
        "relative  z-0 h-full overflow-hidden bg-transparent transition-all ease-linear"
      )}
    >
      <div
        className={classNames(hasMore && !isOpen && styles.blurContainer)}
        ref={ref}
      >
        {text}

        <Button
          className={twMerge(
            !isOpen && "absolute bottom-[-4px] left-0 z-20",
            !hasMore && "!hidden",
            hasMore && isOpen && "mt-1"
          )}
          onClick={() => setIsOpen(!isOpen)}
          kind="text"
        >
          {isOpen ? "View Less" : "View More"}
          <i
            className={classNames(
              "icon chevron-down-icon ml-1 transition-all",
              isOpen && "rotate-180"
            )}
          />
        </Button>
      </div>
    </div>
  );
};

export default SelectPaymentPlanPage;
