import React, { ReactNode } from "react";
import styles from "./ReceiptSection.module.scss";
import cn from "classnames";
import { observer } from "mobx-react-lite";
import Credit from "./components/Credit";
import FlexContainer from "src/components/FlexContainer";
import Border from "src/components/Border";

import { ICardDTO, IOrderDTO } from "../../../services/api";
import { PriceWithCents, toOrdinal } from "@sizdevteam1/funjoiner-uikit";
import { CreditCardName } from "../../../components/cards/CardsListItem";

type IProps = {
  order: IOrderDTO;
  paymentOption: { type: "card"; card: ICardDTO } | { type: "invoice" };
} & (
  | {
      title: string;
    }
  | {
      titleNode: ReactNode;
    }
);

const ReceiptSection: React.FC<IProps> = observer((props: IProps) => {
  const { order, paymentOption } = props;
  const paidInstallmentsOrdinals =
    order.payment_plan?.installments
      .filter((i) => i.is_paid)
      .map((i) => i.ordinal_number) || [];

  const lastPaidOrdinal = Math.max(...paidInstallmentsOrdinals);
  const lastPaidInstallment = order.payment_plan?.installments.find(
    (i) => i.ordinal_number === lastPaidOrdinal
  );

  return (
    <>
      <div className={styles.icon}>
        {"titleNode" in props ? (
          props.titleNode
        ) : (
          <>
            <i className="icon success-pay-icon" />
            <div className={"text-h2 leading-7"}>{props.title}</div>
          </>
        )}
      </div>
      <div className={cn(styles.Section)}>
        {lastPaidInstallment && lastPaidInstallment.payment?.amount ? (
          <div className="typography-main py-4 px-5">{`Payment Plan. ${toOrdinal(
            lastPaidInstallment.ordinal_number
          )} Installment`}</div>
        ) : (
          order.items.map(
            (item) =>
              item.type === "credit_item" && (
                <Credit key={item.id} data={item} />
              )
          )
        )}
        <FlexContainer
          className="!bg-on-main-color"
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          {paymentOption.type === "card" ? (
            <div className={cn(styles.card, "text-main_sb")}>
              <CreditCardName card={paymentOption.card} />
            </div>
          ) : (
            <div className={"typography-main flex flex-row items-center gap-2"}>
              <i className={"icon invoice-icon bg-main-color"} />
              Invoice{" "}
            </div>
          )}

          <div className={cn("text-h2 text-text-color")}>
            {lastPaidInstallment?.payment?.amount ? (
              <PriceWithCents
                typography="typography-h3"
                prefix="$"
                amount={lastPaidInstallment.payment?.amount}
              />
            ) : (
              <PriceWithCents
                amount={order.final_price}
                prefix="$"
                typography="typography-h2"
              />
            )}
          </div>
        </FlexContainer>
      </div>
      <Border />
    </>
  );
});

export default ReceiptSection;
