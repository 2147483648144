import React, { useEffect, useMemo } from "react";
import dayjs from "dayjs";
import useVM from "src/hooks/useVM";
import useStores from "src/hooks/useStores";
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import {
  CommonStore,
  CustomerStore,
  DocumentsStore,
  RouterStore,
} from "src/stores";
import api, {
  IInvoiceDTO,
  IStudentDTO,
  TAttendanceDTO,
} from "src/services/api";
import { ROUTES } from "../../stores/RouterStore";
import FunboxStore from "../../stores/FunboxStore";
import * as Sentry from "@sentry/react";
import { IWaitlistRecordDTO } from "../../services/api/waitlists";
import notificator from "../../services/systemNotifications/notificationCenterService";
import { IPagination } from "src/services/api/common";
import { downloadFile } from "../../util/downloadFile";
import { IScheduleAndPayOrderWithPaymentPLanDTO } from "src/services/api/orders";
import { applicationsAndWaitlistsPageRoute } from "../ApplicationsAndWaitlistsPage/ApplicationsAndWaitlistsPage";
import { InvoiceVm } from "../../models/InvoiceVm";

export class DashboardPageVM {
  static ITEMS_PER_PAGE = 10; // Default Value
  private disposers: (() => void)[] = [];
  dispose = () => {
    this.disposers.forEach((dispose) => dispose());
  };
  constructor(
    private customerStore: CustomerStore,
    private commonStore: CommonStore,
    private routerStore: RouterStore,
    private funboxStore: FunboxStore,
    private documentsStore: DocumentsStore
  ) {
    makeObservable(this);
    this.invoiceVm = new InvoiceVm(() => this.loadPendingInvoices());
    this.init();
  }

  invoiceVm: InvoiceVm;

  @observable
  private schedulePage: IPagination<TAttendanceDTO> = {
    total: 0,
    offset: 0,
    has_more: false,
    items: [],
  };

  @computed
  get getSchedule() {
    return this.schedulePage;
  }
  @observable
  selectedStudent: Omit<IStudentDTO, "customer"> | null = null;

  @computed get funboxNames() {
    return this.funboxStore.funboxes.map((f) => f.name);
  }

  @observable private _selectedFilter: string = "All";

  @computed get selectedFilter() {
    return this._selectedFilter;
  }

  @computed get selectedFilterFunboxId() {
    return this.funboxStore.funboxes.find((f) => f.name === this.selectedFilter)
      ?.id;
  }

  @action.bound
  setFilter(f: (typeof this.funboxNames)[number] | "All" = "All") {
    if (f === this._selectedFilter) {
      this.isScheduleFilterModalOpened = false;
      return;
    }

    if (f === "All") {
      this.loadSchedule();
    } else {
      const funboxId = this.funboxStore.funboxes.find(
        (funbox) => funbox.name === f
      )?.id;
      this.loadSchedule(funboxId);
    }

    this._selectedFilter = f;
    this.isScheduleFilterModalOpened = false;
  }

  @observable isScheduleFilterModalOpened = false;

  @observable
  loading = false;

  @action.bound
  navigateToPersonalInformation() {
    this.routerStore.navigate(ROUTES.SMART_FORMS);
  }

  @action.bound navigateToDocuments() {
    this.routerStore.navigate(ROUTES.DOCUMENTS);
  }

  @computed
  get waitlistRecords(): IWaitlistRecordDTO[] {
    return this.customerStore.waitlistRecords.filter(
      (wr) =>
        wr.student_id === this.selectedStudent?.id &&
        wr.funbox_id === this.funboxStore.selectedFunbox?.id
    );
  }

  @computed get isDocumentsBlockVisible() {
    return this.unsignedDocsCount > 0;
  }

  @computed
  get totalDocsCount() {
    return this.documentsStore.documents.length;
  }

  @computed get unsignedDocsCount() {
    return this.documentsStore.documents.filter((d) => d.status === "UNSIGNED")
      .length;
  }

  @computed get requiredDocsPercentage() {
    if (this.totalDocsCount === 0) return 100;
    return 100 - (this.unsignedDocsCount / this.totalDocsCount) * 100;
  }

  @computed get isFormsBlockVisible() {
    return this.unansweredRequiredSmartFormsCount > 0;
  }
  @computed get unansweredRequiredSmartFormsCount() {
    return this.customerStore.unansweredRequiredSmartFormsCount;
  }

  @computed get totalRequiredSmartFormsCount() {
    return this.customerStore.totalRequiredSmartFormsCount;
  }

  @computed get requiredSmartFormsPercentage() {
    if (this.totalRequiredSmartFormsCount === 0) return 100;
    return (
      100 -
      (this.customerStore.unansweredRequiredSmartFormsCount /
        this.customerStore.totalRequiredSmartFormsCount) *
        100
    );
  }

  @computed get requiredSmartFormsFullyCompleted() {
    return this.requiredSmartFormsPercentage === 100;
  }

  @computed
  get creditTypesById() {
    return this.commonStore.creditTypesById;
  }

  @computed
  get hasSchedule() {
    return this.schedulePage.items.length > 0;
  }

  @computed
  get students() {
    return this.customerStore.studentsWithCustomerAsParticipant;
  }

  @computed
  get unspentCreditsByType() {
    return this.customerStore.credits.reduce(
      (acc: { [key: number]: number }, cur) => {
        const prev = acc[cur.credit_type_id] || 0;
        acc[cur.credit_type_id] = prev + 1;

        return acc;
      },
      {}
    );
  }

  @computed
  get applicationWaitlistRecords() {
    const waitlistRecords = this.customerStore.waitlistRecords;
    const applications = this.customerStore.applications;

    return this.students
      .map((student) => ({
        student: student,
        count: [
          ...waitlistRecords.map((record) => record.student_id),
          ...applications.map((application) => application.student.id),
        ].filter((studentId) => studentId === student.id).length,
      }))
      .filter((item) => item.count > 0);
  }

  @computed
  get hasApplicationWaitlistRecords() {
    return this.applicationWaitlistRecords.length > 0;
  }

  @action
  navigateToApplicationsAndWaitlists(studentId: number) {
    this.routerStore.navigateToRoute(
      applicationsAndWaitlistsPageRoute.build({
        searchParams: { studentId: studentId.toString() },
      })
    );
  }

  @observable
  pendingInvoices: IInvoiceDTO[] = [];

  @computed
  get hasPendingInvoices() {
    return this.pendingInvoices.length > 0;
  }

  @observable
  isScheduleLoading: boolean = false;

  @observable isLoadingNextPage = false;

  @action.bound
  async handleSelectStudent(student: Omit<IStudentDTO, "customer">) {
    this.selectedStudent = student;
    this.isScheduleLoading = true;
    await this.loadSchedule();
  }

  @action.bound private async loadSchedule(funboxId?: string) {
    if (!this.selectedStudent) return;
    this.isScheduleLoading = true;
    try {
      const schedule = await api.attendances.getStudentSchedule(
        this.selectedStudent?.id,
        {
          funbox_id: funboxId || this.selectedFilterFunboxId,
          from_date: dayjs().format("YYYY-MM-DD"),
        },
        {
          limit: DashboardPageVM.ITEMS_PER_PAGE,
          offset: 0,
        }
      );
      runInAction(() => {
        this.schedulePage = schedule;
      });
    } finally {
      this.isScheduleLoading = false;
    }
  }

  @action.bound
  onCloseDashboardMessage() {
    const currentMessage = this.commonStore.publicSettings.dashboard_message;
    const savedMessageText = localStorage.getItem("dashboard_message_text");
    const closeCount = localStorage.getItem("dashboard_message_close_count");
    if (currentMessage.text !== savedMessageText) {
      localStorage.setItem(
        "dashboard_message_text",
        this.commonStore.publicSettings.dashboard_message.text
      );
      localStorage.setItem("dashboard_message_close_count", "1");
      return;
    }
    localStorage.setItem(
      "dashboard_message_close_count",
      closeCount ? (+closeCount + 1).toString() : "1"
    );
  }

  @computed get isDashboardMessageVisible() {
    const currentMessage = this.commonStore.publicSettings.dashboard_message;
    const savedMessageText = localStorage.getItem("dashboard_message_text");
    const closeCount = localStorage.getItem("dashboard_message_close_count");
    return (
      closeCount == null ||
      +closeCount < 2 ||
      currentMessage.text !== savedMessageText
    );
  }

  @action.bound
  async loadNextPage() {
    if (
      this.isLoadingNextPage ||
      !this.selectedStudent ||
      !this.schedulePage.has_more
    )
      return;
    this.isLoadingNextPage = true;
    try {
      const schedule = await api.attendances.getStudentSchedule(
        this.selectedStudent.id,
        {
          funbox_id: this.selectedFilterFunboxId,
          from_date: dayjs().format("YYYY-MM-DD"),
        },
        {
          limit: DashboardPageVM.ITEMS_PER_PAGE,
          offset: this.schedulePage.items.length,
        }
      );
      runInAction(() => {
        this.schedulePage = {
          offset: 0,
          total: schedule.total,
          has_more: schedule.has_more,
          items: [...this.schedulePage.items, ...schedule.items],
        };
      });
    } finally {
      this.isLoadingNextPage = false;
    }
  }

  @action.bound
  navigateToAddParticipant() {
    this.routerStore.navigate(ROUTES.ADD_PARTICIPANT);
  }

  @action.bound
  async leaveWaitlist(
    programId: string,
    studentId: number,
    scheduleSetId: string
  ) {
    this.isScheduleLoading = true;
    try {
      await api.waitlists.removeStudents(scheduleSetId, {
        program_id: programId,
        student_ids: [studentId],
      });
      this.customerStore.loadWaitlistRecords();
    } catch (e) {
      notificator.error("Error!", e);
    } finally {
      this.isScheduleLoading = false;
    }
  }

  @observable
  ordersWithIncompletePaymentPlans: IScheduleAndPayOrderWithPaymentPLanDTO[] =
    [];

  @action.bound async loadPaymentPlans() {
    try {
      const orders = await api.orders.getOrdersWithIncompletePaymentPlans();
      runInAction(() => {
        this.ordersWithIncompletePaymentPlans = orders;
      });
    } catch (e) {
      Sentry.captureException(e);
      notificator.error("Error", e);
    }
  }

  @action.bound
  private async init() {
    this.loading = true;
    try {
      await Promise.all([
        this.loadPendingInvoices(),
        this.documentsStore.loadDocuments(),
        this.customerStore.loadSmartForms(),
        this.loadPaymentPlans(),
      ]);
      if (this.students[0]) {
        await this.handleSelectStudent(this.students[0]);
      }
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  private async loadPendingInvoices() {
    try {
      const invoices = await api.invoices.get({ status: "incomplete" });
      runInAction(() => {
        this.pendingInvoices = invoices.items;
      });
    } catch (e) {
      Sentry.captureException(e);
    }
  }

  @action.bound navigateToPaymentPlan(
    order: IScheduleAndPayOrderWithPaymentPLanDTO
  ) {
    this.routerStore.navigate(
      ROUTES.PAYMENT_PLAN,

      { id: order.id }
    );
  }
  @action.bound navigateToUpgradeCreditPage(credit_typeId: number) {
    this.routerStore.navigate(ROUTES.UPGRADE_CREDIT, { id: credit_typeId });
  }
}

const ctx = React.createContext<DashboardPageVM | null>(null);

export const DashboardPageVMProvider: React.FC<{}> = ({ children }) => {
  const {
    customerStore,
    commonStore,
    routerStore,
    funboxStore,
    documentsStore,
  } = useStores();

  const vm = useMemo(
    () =>
      new DashboardPageVM(
        customerStore,
        commonStore,
        routerStore,
        funboxStore,
        documentsStore
      ),
    [customerStore, commonStore, routerStore, funboxStore, documentsStore]
  );
  useEffect(
    () => () => {
      vm.dispose();
    },
    [vm]
  );
  return <ctx.Provider value={vm}>{children}</ctx.Provider>;
};

export const useDashboardPageVM = () => useVM(ctx);
