import React from "react";
import cn from "classnames";
import { Button, CreditIcon } from "@sizdevteam1/funjoiner-uikit";
import styles from "../styles.module.scss";
import { ICreditTypeDTO } from "../../../services/api";

interface IProps {
  creditType: ICreditTypeDTO;
  amount: number;
  onUpgradeClick?: (creditTypeId: number) => void;
  className?: string;
}

const CreditItem: React.FC<IProps> = ({
  creditType,
  amount,
  onUpgradeClick,
  className,
}) => {
  return (
    <div className={cn(styles.redirect, className)}>
      <div>
        <div className={"typography-h4 text-text-color"}>{creditType.name}</div>
        <div
          className={
            "typography-small mt-1 flex items-center gap-3 text-gray-text-color"
          }
        >
          {creditType.is_free && "Free "}
          {creditType.is_program_credit ? "Program Credit" : "Session Credit"}
          {onUpgradeClick && (
            <>
              <div className="h-4 w-[1px] bg-separator-color"></div>
              <Button
                onClick={
                  onUpgradeClick
                    ? () => onUpgradeClick(creditType.id)
                    : undefined
                }
                className="!typography-label"
                kind="text"
              >
                <i className="icon upgrade-icon" />
                Upgrade
              </Button>
            </>
          )}
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.sessions}>
          <CreditIcon
            className="mr-1"
            size={24}
            isFree={creditType.is_free}
            isProgram={creditType.is_program_credit}
          />

          <div className={"text-h4"}>×&nbsp;{amount}</div>
        </div>
      </div>
    </div>
  );
};

export default CreditItem;
