import React from "react";
import cn from "classnames";
import { ROUTES } from "src/stores/RouterStore";
import useStores from "src/hooks/useStores";
import styles from "./style.module.scss";

interface IProps {
  className?: string;
  route?: (typeof ROUTES)[keyof typeof ROUTES];
  options?: {
    id?: number | string;
    replace?: boolean;
  };
  onClick?: () => void;
}

const BackLink: React.FC<IProps> = ({ className, route, options, onClick }) => {
  const { routerStore } = useStores();

  const handleMove = () => {
    if (route) {
      if (options?.id != null) {
        routerStore.navigate(route, options);
      } else {
        routerStore.navigate(route);
      }
    } else {
      routerStore.history.goBack();
    }
  };

  return (
    <div
      onClick={onClick || handleMove}
      className={cn(styles.back, "text-label", className)}
    >
      <i className="icon back-icon" />
      Back
    </div>
  );
};

export default BackLink;
