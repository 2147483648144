import { PriceWithCents } from "@sizdevteam1/funjoiner-uikit";
import cn from "classnames";
import React from "react";
import styles from "../Checkout.module.scss";

interface IProps {
  finalPrice: number;
  promoDiscount: number;
}
const Subtotal: React.FC<IProps> = ({ finalPrice, promoDiscount }) => {
  return (
    <div className={cn("typography-label", styles.subtotal)}>
      <span>Subtotal</span>
      <PriceWithCents
        amount={finalPrice + promoDiscount}
        prefix="$"
        typography="typography-label"
      />
    </div>
  );
};
export default Subtotal;
