import {
  action,
  computed,
  IComputedValue,
  makeObservable,
  observable,
  reaction,
} from "mobx";
import { CustomerStore, PaymentMethodStore, PaymentStore } from "../../stores";
import {
  StripeElements,
  PaymentRequest,
  PaymentMethod,
} from "@stripe/stripe-js";
import formField from "../../models/formField";
import { validateEmail } from "../../util/validators";
import { ICardDTO } from "../../services/api";
import { assertNever, raise } from "@sizdevteam1/funjoiner-uikit";
import { computedFn } from "mobx-utils";
import cardFromStripePaymentMethod from "../../util/cardFromStripePaymentMethod";

export type SelectablePaymentMethod =
  | {
      type: "card";
      paymentMethodId: string;
      card: ICardDTO;
    }
  | {
      type: "invoice";
    };

type _InternalPaymentMethod =
  | SelectablePaymentMethod
  | {
      type: "new_card";
    }
  | {
      type: "apple_pay";
    };

type Payment = { final_price: number; description: string };
type ApplePaySettings = {
  payment: IComputedValue<Payment | undefined>;
  isImmediateChargeRequired: boolean;
};

export class PaymentMethodPickerVm {
  constructor(
    private options: {
      isInvoiceEnabled: IComputedValue<boolean>;
      applePay?: ApplePaySettings;
    },
    private elements: StripeElements | null,
    private paymentStore: PaymentStore,
    private paymentMethodStore: PaymentMethodStore,
    private customerStore: CustomerStore
  ) {
    makeObservable(this);
    this.selectDefaultPaymentMethod();

    const applePay = this.options.applePay;
    this._disposers.push(
      reaction(
        () => this.options.isInvoiceEnabled.get(),
        (isEnabled) => {
          if (!isEnabled && this.isInvoiceOptionSelected) {
            this.selectDefaultPaymentMethod();
          }
        }
      )
    );

    if (applePay != null) {
      this._disposers.push(
        reaction(
          () => applePay.payment.get(),
          (order) => this._configureApplePay(applePay, order),
          { fireImmediately: true }
        )
      );
    }
  }

  confirmSelectedPaymentMethod = async (): Promise<SelectablePaymentMethod> => {
    if (!this.elements) {
      throw new Error("Elements is not initialized");
    }

    await this._verifyEmail();

    if (this._selectedPaymentMethod.type === "invoice") {
      return this._selectedPaymentMethod;
    }

    let card: ICardDTO;
    if (this._selectedPaymentMethod.type === "card") {
      card = this._selectedPaymentMethod.card;
    } else if (this._selectedPaymentMethod.type === "new_card") {
      const paymentMethod =
        await this.paymentMethodStore.createPaymentMethodFromElements(
          this.elements
        );

      card = await this.paymentMethodStore.attachNewCard(paymentMethod);
    } else if (this._selectedPaymentMethod.type === "apple_pay") {
      card = await this._confirmApplePay();
    } else {
      assertNever(this._selectedPaymentMethod);
    }

    const paymentMethodId = card.payment_method_id;

    this.selectCard(paymentMethodId);
    await this.paymentMethodStore.setDefaultPaymentMethod(paymentMethodId);

    return this._selectedPaymentMethod as SelectablePaymentMethod;
  };

  private _confirmApplePay = async () => {
    const paymentRequest =
      this._paymentRequest ?? raise("Apple Pay is not enabled");
    const cardPromise = new Promise<ICardDTO>((resolve, reject) => {
      paymentRequest.once("paymentmethod", async (requestEvent) => {
        try {
          requestEvent.complete("success");
          const card = await this.paymentMethodStore.attachNewCard(
            cardFromStripePaymentMethod(requestEvent.paymentMethod)
          );

          resolve(card);
        } catch (e) {
          requestEvent.complete("fail");
          console.error(e);
          reject(e);
        }
      });

      paymentRequest.once("cancel", () => {
        reject(new Error("Payment request was canceled"));
      });
    });

    paymentRequest.show();
    return await cardPromise;
  };

  @observable
  private _selectedPaymentMethod: _InternalPaymentMethod = { type: "new_card" };

  @observable
  private _paymentRequest: PaymentRequest | null = null;

  @action
  selectDefaultPaymentMethod = () => {
    if (this.defaultPaymentMethodId != null) {
      this.selectCard(this.defaultPaymentMethodId);
    } else if (this.isApplePayOptionVisible) {
      this.selectApplePay();
    } else {
      this.selectNewCard();
    }
  };

  @computed
  get defaultPaymentMethodId() {
    return this.paymentMethodStore.stripePaymentMethods.default_payment_method;
  }

  @computed
  get isNewCardSelected() {
    return this._selectedPaymentMethod.type === "new_card";
  }

  @computed
  get cards() {
    return this.paymentMethodStore.stripePaymentMethods.payment_methods;
  }

  @action
  selectCard = (cardId: string) => {
    const card =
      this.cards.find((c) => c.payment_method_id === cardId) ??
      raise("Card not found");

    this._selectedPaymentMethod = {
      type: "card",
      paymentMethodId: cardId,
      card,
    };
  };

  isCardSelected = computedFn((cardId: string) => {
    return (
      this._selectedPaymentMethod.type === "card" &&
      this._selectedPaymentMethod.paymentMethodId === cardId
    );
  });

  @action
  selectNewCard = () => {
    this._selectedPaymentMethod = {
      type: "new_card",
    };
  };

  @computed
  get isInvoiceOptionSelected() {
    return this._selectedPaymentMethod.type === "invoice";
  }

  @computed
  get isInvoiceOptionVisible() {
    return (
      this.options.isInvoiceEnabled.get() &&
      this.paymentMethodStore.invoicePaymentMethod != null
    );
  }

  @computed
  get invoicePaymentMethodInfo() {
    return this.paymentMethodStore.invoicePaymentMethod;
  }

  @action.bound
  selectInvoice = () => (this._selectedPaymentMethod = { type: "invoice" });

  @computed
  get isApplePayOptionVisible() {
    return this._paymentRequest != null;
  }

  selectApplePay = () => {
    this._selectedPaymentMethod = { type: "apple_pay" };
  };

  @computed
  get isApplePayOptionSelected() {
    return this._selectedPaymentMethod.type === "apple_pay";
  }

  @computed
  get requiresEmail() {
    return !this.customerStore.customer.email;
  }

  email = formField("", {
    validator: (v) => {
      if (this.requiresEmail && !validateEmail(v)) {
        return "Email is required";
      }
      return null;
    },
  });

  _verifyEmail = async () => {
    if (!this.email.validate()) {
      throw new Error(this.email.error ?? "Invalid email");
    }

    if (this.requiresEmail) {
      await this.customerStore.updateProfile({
        email: this.email.value,
      });
    }
  };

  private _configureApplePay = async (
    settings: ApplePaySettings,
    order: Payment | undefined
  ) => {
    this._paymentRequest?.abort();
    if (order == null) {
      this._paymentRequest = null;
      return;
    }

    const paymentRequest = await this.paymentStore.createPaymentRequest(
      order.description,
      order.final_price,
      !settings.isImmediateChargeRequired
    );

    if (await paymentRequest.canMakePayment()) {
      this._paymentRequest = paymentRequest;
    } else {
      this._paymentRequest = null;
    }
    this.selectDefaultPaymentMethod();
  };

  private _disposers = Array<() => void>();
  dispose = () => {
    this._disposers.forEach((d) => d());
  };
}
