import { TPersonalPronoun } from "../services/api";
import { BaselineQuestion } from "../services/api/common";
import { computed, makeObservable } from "mobx";
import formField, { FormField } from "./formField";
import { IOption } from "@sizdevteam1/funjoiner-uikit/components/Select/interfaces";
import { ALL_PERSONAL_PRONOUNS } from "../services/api/students";

export default class PronounPickerVm {
  constructor(value: TPersonalPronoun, private settings: BaselineQuestion) {
    makeObservable(this);
    this.field = formField<TPersonalPronoun>(value, {
      validator: (v: TPersonalPronoun) => {
        if (settings.status === "required" && v === "none") {
          return "Required";
        }
        return null;
      },
    });
  }

  field: FormField<TPersonalPronoun>;

  @computed
  get selectedOption() {
    return this.pronounToOption(this.field.value);
  }

  @computed
  get availableOptions() {
    return this.availablePronouns.map(this.pronounToOption);
  }

  pronounToOption = (pronoun: TPersonalPronoun): IOption<TPersonalPronoun> => {
    const names: Record<TPersonalPronoun, string> = {
      none: "None",
      she: "She/Her",
      he: "He/Him",
      they: "They/Them",
      "non-binary": "Non-Binary",
    };

    return {
      id: pronoun,
      name: names[pronoun],
    };
  };

  validate = () => this.field.validate();

  @computed
  get availablePronouns(): TPersonalPronoun[] {
    const available: TPersonalPronoun[] = [...ALL_PERSONAL_PRONOUNS];
    if (this.settings.status === "required") {
      available.splice(available.indexOf("none"), 1);
    }

    return available;
  }
}
