import React from "react";
import { observer } from "mobx-react-lite";
import { defineRoute } from "src/util/typedRouting";
import useStores from "src/hooks/useStores";
import { QuestionPipelinePage } from "../QuestionPipelinePage/QuestionPipelinePage";
import api from "src/services/api";
import { startIndexForQuestionSet } from "src/util/questionSetUtils";
import { ROUTES } from "src/stores/RouterStore";

export const afterScheduleQuestionSetsRoute = defineRoute<{
  state: {
    newAttendanceIds: string[];
  };
}>({
  path: "/after-schedule-questions/",
  build: (path) => path,
});

const AfterScheduleQuestionSetsPage: React.FC = observer(() => {
  const { routerStore } = useStores();
  const newAttendanceIds =
    afterScheduleQuestionSetsRoute.useParams().state?.newAttendanceIds;

  const navigateToDashboard = () =>
    routerStore.navigate(ROUTES.DASHBOARD, { replace: true });

  if (newAttendanceIds == null || newAttendanceIds.length === 0) {
    navigateToDashboard();
    return null;
  }

  return (
    <QuestionPipelinePage
      description={
        "The following forms are required. Please fill them out before attendance."
      }
      delegate={{
        fetchSmartForms: () => {
          return api.requiredForAttendance
            .getRequirementsByAttendanceIds(newAttendanceIds)
            .then((res) => res.smart_forms);
        },
        startIndexFor: startIndexForQuestionSet,
        onFinish: () => routerStore.returnToSourcePage(ROUTES.DASHBOARD),
        uploadFile: async (file: File) => {
          return await api.files.uploadFile(file, "END_USER");
        },
      }}
    />
  );
});

export default AfterScheduleQuestionSetsPage;
