import React from "react";
import IconTile from "./IconTile";
import { observer } from "mobx-react-lite";

interface IProps {
  onClick: () => void;
}

export const SharingScheduleTile = observer(({ onClick }: IProps) => {
  return (
    <IconTile
      onClick={onClick}
      title={"Email Friends & Family"}
      subtitle={
        <span>
          <span className="text-main-color hover:text-dark-main-color">
            Share{" "}
          </span>
          booking news with your relatives
        </span>
      }
      iconBgClassName={"bg-surface-purple-color"}
      iconClassName={"bg-on-main-color emails-icon"}
    />
  );
});
