import React from "react";
import { observer } from "mobx-react-lite";
import {
  useWhatNextSectionVm,
  WhatNextSectionVmProvider,
} from "./WhatNextSectionVm";
import useStores from "src/hooks/useStores";
import { DirectionsAndDetailsTile } from "src/components/IconTile/DirectionsAndDetailsTile";
import { UnsignedDocumentsTile } from "src/components/IconTile/UnsignedDocumentsTile";
import RequiredQuestionsTile from "src/components/IconTile/RequiredQuestionsTile";
import { afterScheduleQuestionSetsRoute } from "src/pages/AfterScheduleQuestionSetsPage/AfterScheduleQuestionSetsPage";
import cn from "classnames";
import { Link } from "react-router-dom";
import { ROUTES } from "src/stores/RouterStore";

import { SharingScheduleTile } from "src/components/IconTile/SharingTile";
import { scheduleSharingRoute } from "../ScheduleSharingPage";
import { Loader } from "@sizdevteam1/funjoiner-uikit";
import { IBookingResult } from "../ScheduleSharingPageVM";

interface IProps {
  bookingResult: IBookingResult | undefined;
  showDashboardLink: boolean;
}

const WhatNextSection: React.FC<IProps> = observer((props) => {
  return (
    <WhatNextSectionVmProvider bookingResult={props.bookingResult}>
      <WhatNextSectionImpl {...props} />
    </WhatNextSectionVmProvider>
  );
});

const WhatNextSectionImpl: React.FC<IProps> = observer(
  ({ bookingResult, showDashboardLink }: IProps) => {
    const { routerStore } = useStores();
    const vm = useWhatNextSectionVm();

    const tiles = [
      bookingResult && (
        <SharingScheduleTile
          onClick={() =>
            routerStore.navigateToRoute(
              scheduleSharingRoute.build({
                state: {
                  bookingResult: bookingResult,
                  routeFrom: routerStore.currentPath,
                },
              })
            )
          }
        />
      ),
      vm.location && <DirectionsAndDetailsTile locationId={vm.location.id} />,
      vm.hasRequiredSmartForms && bookingResult && (
        <RequiredQuestionsTile
          onClick={() =>
            routerStore.navigateToRoute(
              afterScheduleQuestionSetsRoute.build({
                state: { newAttendanceIds: bookingResult?.attendanceIds },
              })
            )
          }
        />
      ),
      vm.hasUnsignedDocuments && <UnsignedDocumentsTile />,
    ];

    return (
      <>
        <div className={cn("text-h2")}>What’s Next?</div>
        <div className={"mt-4 flex flex-col gap-4"}>
          {showDashboardLink && (
            <div
              className={
                "typography-main rounded-[10px] bg-on-main-color p-[20px] text-center shadow-big"
              }
            >
              View Schedule via <Link to={ROUTES.DASHBOARD}>Dashboard</Link>
            </div>
          )}
          {tiles}
          {vm.isLoading && <Loader className="p-4" />}
        </div>
      </>
    );
  }
);

export default WhatNextSection;
