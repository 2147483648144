import React from "react";
import { observer } from "mobx-react-lite";
import PageRoot from "src/components/PageRoot";
import { Button, Image } from "@sizdevteam1/funjoiner-uikit";
import useStores from "src/hooks/useStores";
import { ROUTES } from "src/stores/RouterStore";
import WhatNextSection from "../SuccessPages/WhatNextSection/WhatNextSection";
import { defineRoute } from "src/util/typedRouting";
import Success from "src/assets/imgs/payment-success.svg";
import { IBookingResult } from "./ScheduleSharingPageVM";

export const scheduleSuccessRoute = defineRoute<{
  state: {
    bookingResult: IBookingResult;
    emailsSent?: boolean;
  };
}>({
  path: "/thank-you",
  build: (path) => path,
});

const ScheduleSuccessPage: React.FC = observer(() => {
  const { state } = scheduleSuccessRoute.useParams();
  const showDashboardLinkInHeader = !state?.emailsSent;
  const showDashboardLinkInWhatNext = !showDashboardLinkInHeader;
  return (
    <PageRoot>
      <ThankYouHeader showDashboardLink={showDashboardLinkInHeader} />
      <WhatNextSection
        bookingResult={state?.bookingResult}
        showDashboardLink={showDashboardLinkInWhatNext}
      />
    </PageRoot>
  );
});

const ThankYouHeader = observer(
  ({ showDashboardLink }: { showDashboardLink: boolean }) => {
    const { routerStore } = useStores();
    const isWaitlist = routerStore.searchParams["waitlist"] === "true";
    const { state } = scheduleSuccessRoute.useParams();
    return (
      <div className={"mb-6 rounded-[16px] bg-on-main-color p-6"}>
        <div className="flex flex-col  items-center gap-4">
          <div className="flex items-center gap-5">
            <Image
              imageClassName="!h-[58px] !w-[58px]"
              src={Success}
              alt="success"
            />
            <div className="flex flex-col gap-2">
              <span className="typography-h1 text-header-color">
                Thank You!
              </span>
              <span className="typography-main text-text-color">
                {state?.emailsSent
                  ? "Emails sent successfully"
                  : isWaitlist
                  ? "Success, You're on the Waitlist"
                  : "View Schedule Via"}
              </span>
            </div>
          </div>

          {showDashboardLink && (
            <>
              <Button
                className={"w-full"}
                size={"big"}
                kind={"action"}
                onClick={() => routerStore.navigate(ROUTES.DASHBOARD)}
              >
                Dashboard
              </Button>
              {isWaitlist && (
                <Button
                  kind={"text"}
                  onClick={() => routerStore.navigate(ROUTES.SCHEDULE)}
                >
                  or Continue Scheduling
                </Button>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
);
export default ScheduleSuccessPage;
