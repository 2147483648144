import {
  Button,
  Checkbox,
  FlipCard,
  ISOString,
  MarkdownText,
} from "@sizdevteam1/funjoiner-uikit";
import classNames from "classnames";
import React, { useLayoutEffect, useRef, useState } from "react";
import { IStudentDTO } from "src/services/api";
import {
  ApplicationInfoDTO,
  IAvailableDaycampSession,
  IAvailableSessionInProgram,
  TAvailabilityStudentInfo,
  TAvailableProgram,
  WaitlistInfoDTO,
} from "src/services/api/availability";
import formatStartEndDate from "src/util/formatStartEndDate";
import isDaycampProgram from "src/util/isDayCampProgram";
import {
  AgeRestrictionLabel,
  ApplicationsOpenLabel,
  ApplyForApplication,
  DateLabel,
  NoSpotsOpenBlock,
  OrJoinWaitlist,
  RegistrationClosedBlock,
  RestrictionsBlock,
  RestrictionStatus,
  SessionsOpenLabel,
  SpotsOpenLabel,
  WaitListOpenLabel,
} from "./CardsComponents";
import styles from "./Cards.module.scss";
import dayjs from "dayjs";
import formatStartEndTime from "src/util/formatStartEndTime";
import { twMerge } from "tailwind-merge";

export type StudentRestrictions = {
  student: IStudentDTO;
  statuses: RestrictionStatus[];
};

type ProgramScheduleStatus =
  | {
      status: "open";
      application: ApplicationInfoDTO | null;
      spots_left: number | null;
    }
  | {
      status: "application"; // application is blocking schedule and available
    }
  | {
      status: "not_enough_spots";
      spots_left: number | null;
      waitlist: WaitlistInfoDTO | null;
      application: ApplicationInfoDTO | null;
      drop_ins_available: IAvailableSessionInProgram[];
    }
  | {
      status: "registration_closed";
      drop_ins_available: IAvailableSessionInProgram[];
    }
  | {
      status: "student_restriction";
      restrictions: StudentRestrictions[];
    };

interface ProgramCardProps {
  program: TAvailableProgram;
  isSelected: boolean;
  onSelectProgram: () => void;
  selectionKind: "checkbox" | "navigateToScheduleButton";
  onJoinWaitlistClick: () => void;
  onApplyClick: () => Promise<void> | void;
  onDropInClick: ({ id, date }: { id: string; date: ISOString }) => void;
  selectedParticipants: IStudentDTO[];
  selectedUnlinkedParticipantsAmount?: number;
  selectedSessions: IAvailableDaycampSession[];
  setOpenedScheduleSetId?: (id: string) => void;
  showDropIns: boolean;
  showApplications: boolean;
  highlight?: {
    action?: "select" | "apply" | "join_waitlist";
    remove: () => void;
  };
  name?: React.ReactNode;
  hideAgeRestrictionLabel?: boolean;
}

export const ProgramCard = (props: ProgramCardProps) => {
  const {
    isSelected,
    onSelectProgram,
    highlight,
    showDropIns,
    showApplications,
  } = props;
  const schedule = getProgramCardStatus(props);
  const [isFlipped, setIsFlipped] = useState(false);

  const highlightedProgramRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (
      highlight?.action === "select" &&
      !isSelected &&
      schedule.status === "open"
    ) {
      onSelectProgram();
    }

    if (
      highlight?.action === "apply" &&
      !isSelected &&
      (schedule.status === "application" ||
        (schedule.status === "open" && schedule.application) ||
        (schedule.status === "not_enough_spots" && schedule.application))
    ) {
      props.onApplyClick();
      highlight.remove();
      return;
    }

    if (
      highlight?.action === "join_waitlist" &&
      !isSelected &&
      schedule.status === "not_enough_spots" &&
      schedule.waitlist
    ) {
      props.onJoinWaitlistClick();
      highlight.remove();
      return;
    }

    if (highlightedProgramRef.current && highlight) {
      const elem = highlightedProgramRef.current;
      setTimeout(() => {
        window.scrollTo({
          top: elem.getBoundingClientRect().y,
          behavior: "smooth",
        });
      }, 500);
      setTimeout(highlight.remove, 4000);
    }
  }, [highlight, highlightedProgramRef]);

  const programHasMainAction =
    schedule.status === "open" || schedule.status === "application";
  return (
    <div ref={highlightedProgramRef}>
      <FlipCard
        isFlipped={isFlipped}
        front={
          <div
            className={classNames(
              "rounded-lg",
              isSelected && styles.selected,
              highlight && styles.highlighted
            )}
          >
            <CardHeader {...props} />
            <div
              className={classNames(
                "rounded-b-lg p-4",
                programHasMainAction
                  ? "bg-highlight-color"
                  : "bg-table-row-color"
              )}
            >
              <ScheduleDetails
                props={props}
                setIsFlipped={() => setIsFlipped(true)}
                showApplications={showApplications}
                showDropIns={showDropIns}
                showAgeRestrictionLabel={!props.hideAgeRestrictionLabel}
              />
            </div>
          </div>
        }
        renderBack={(style) => (
          <BacksideDetails
            style={style}
            onBack={() => setIsFlipped(false)}
            props={props}
          />
        )}
      />
    </div>
  );
};

const CardHeader = (props: ProgramCardProps) => {
  const {
    program,
    onSelectProgram,
    showApplications,
    onApplyClick,
    onJoinWaitlistClick,
    selectionKind,
    isSelected,
    name,
  } = props;

  const schedule = getProgramCardStatus(props);
  const programHasMainAction =
    schedule.status === "open" || schedule.status === "application";
  return (
    <div className="rounded-t-lg bg-on-main-color p-4">
      <div className="flex items-start justify-between gap-3">
        <div
          className={classNames(
            "flex flex-col gap-1 overflow-hidden text-ellipsis "
          )}
        >
          <div
            className={classNames(
              "typography-main_sb",
              !programHasMainAction ? "text-gray-text-color" : "text-text-color"
            )}
          >
            {name ?? program.program_type.name}
          </div>
          {schedule.status === "student_restriction" && (
            <div className="typography-small__t text-gray-text-color">
              {formatStartEndDate(program)}
            </div>
          )}
        </div>
        {schedule.status === "open" &&
          (selectionKind === "checkbox" ? (
            <Checkbox onChange={onSelectProgram} checked={isSelected} />
          ) : (
            <Button onClick={onSelectProgram} kind="text">
              Schedule
            </Button>
          ))}
        {schedule.status === "not_enough_spots" &&
          (schedule.waitlist && schedule.drop_ins_available.length === 0 ? (
            <Button
              onClick={onJoinWaitlistClick}
              className="h-fit whitespace-nowrap"
              kind="text"
            >
              Join Now
            </Button>
          ) : (
            schedule.spots_left === 0 &&
            schedule.drop_ins_available.length > 0 && (
              <div className="typography-small whitespace-nowrap py-1  text-gray-text-color">
                No Spots
              </div>
            )
          ))}
        {schedule.status === "registration_closed" &&
          program.program_type.mode !== "SESSIONS" && (
            <div className="typography-small py-1 text-gray-text-color">
              Closed
            </div>
          )}
        {schedule.status === "application" && showApplications && (
          <Button
            autoLoading
            onClick={onApplyClick}
            kind="text"
            className="h-fit whitespace-nowrap"
          >
            Apply
          </Button>
        )}
      </div>
    </div>
  );
};

const BacksideDetails = ({
  style,
  props,
  onBack,
}: {
  style: { minHeight: number | undefined; boxSizing: "border-box" };
  onBack: () => void;
  props: ProgramCardProps;
}) => {
  return (
    <div
      style={style}
      className={classNames(
        "flex h-full flex-col  rounded-[10px] bg-on-main-color p-4",
        props.isSelected && styles.selected
      )}
    >
      <ProgramLabelsSet
        showApplications={props.showApplications}
        showDropIns={props.showDropIns}
        showAgeRestrictionLabel={!props.hideAgeRestrictionLabel}
        cardProps={props}
        rowOrCol="row"
        showDates
      />
      <MarkdownText
        className="typography-small__t mt-3 text-text-color"
        image={
          props.program.program_type.thumbnail_file?.download_url
            ? { url: props.program.program_type.thumbnail_file.download_url }
            : undefined
        }
      >
        {props.program.program_type.description}
      </MarkdownText>
      <Button
        onClick={onBack}
        className="!typography-label mt-auto self-center !pt-2"
        kind="text"
      >
        <i className="icon back-icon" />
        Back
      </Button>
    </div>
  );
};
const ScheduleDetails = ({
  props,
  setIsFlipped,
  showDropIns,
  showApplications,
  showAgeRestrictionLabel,
}: {
  props: ProgramCardProps;
  setIsFlipped: () => void;
  showDropIns: boolean;
  showApplications: boolean;
  showAgeRestrictionLabel: boolean;
}) => {
  const { program } = props;
  const programScheduleStatus = getProgramCardStatus(props);
  if (programScheduleStatus.status === "student_restriction")
    return (
      <RestrictionsBlock restrictions={programScheduleStatus.restrictions} />
    );
  if (
    programScheduleStatus.status === "registration_closed" &&
    programScheduleStatus.drop_ins_available.length === 0
  )
    return <RegistrationClosedBlock text={formatStartEndDate(program)} />;

  if (
    programScheduleStatus.status === "not_enough_spots" &&
    programScheduleStatus.application == null &&
    programScheduleStatus.waitlist == null &&
    programScheduleStatus.drop_ins_available.length === 0 &&
    programScheduleStatus.spots_left === 0
  )
    return <NoSpotsOpenBlock text={formatStartEndDate(program)} />;

  const getLabelsCount = () => {
    let labels = 0;
    if (
      programScheduleStatus.status === "open" &&
      programScheduleStatus.spots_left != null &&
      programScheduleStatus.spots_left < 10
    ) {
      labels++;
    }
    if (
      programScheduleStatus.status === "not_enough_spots" &&
      programScheduleStatus.spots_left &&
      programScheduleStatus.spots_left > 9
    ) {
      labels++;
    }

    if (
      programScheduleStatus.status === "open" &&
      programScheduleStatus.application != null
    ) {
      labels++;
    }
    if (
      (programScheduleStatus.status === "not_enough_spots" ||
        programScheduleStatus.status === "registration_closed") &&
      programScheduleStatus.drop_ins_available.length > 0 &&
      showDropIns
    ) {
      labels++;
    }
    if (
      programScheduleStatus.status === "not_enough_spots" &&
      programScheduleStatus.waitlist != null
    ) {
      labels++;
    }
    if (programScheduleStatus.status === "application" && showApplications) {
      labels++;
    }
    if (
      (program.program_type.from_age || program.program_type.to_age) &&
      showAgeRestrictionLabel
    ) {
      labels++;
    }
    if (program.program_type.description) {
      labels++;
    }
    return labels;
  };

  const threeBlocksCondition = isDaycampProgram(program)
    ? getLabelsCount() > 0
    : (programScheduleStatus.status === "open" &&
        programScheduleStatus.application) ||
      (programScheduleStatus.status === "not_enough_spots" &&
        programScheduleStatus.application);

  return (
    <div
      className={classNames(
        "flex w-full flex-col gap-2",
        threeBlocksCondition &&
          "grid grid-cols-[repeat(3,auto)] justify-between gap-2"
      )}
    >
      {!threeBlocksCondition && !isDaycampProgram(program) && (
        <ProgramLabelsSet
          showApplications={showApplications}
          showDropIns={showDropIns}
          cardProps={props}
          showAgeRestrictionLabel={showAgeRestrictionLabel}
          rowOrCol="row"
        />
      )}
      <div className="flex flex-col  justify-between gap-2">
        {"drop_ins_available" in programScheduleStatus &&
        isDaycampProgram(program) &&
        showDropIns &&
        programScheduleStatus.drop_ins_available.length > 0 ? (
          <AvailableSessions
            availableSessions={programScheduleStatus.drop_ins_available}
            onDropInClick={props.onDropInClick}
          />
        ) : (
          <Dates
            program={program}
            className={classNames(
              !isDaycampProgram(program) &&
                threeBlocksCondition &&
                "justify-center"
            )}
            renderDetailsButton={
              program.program_type.description && !isDaycampProgram(program)
                ? () => (
                    <Button
                      onClick={setIsFlipped}
                      className={classNames(
                        "!typography-label",
                        ((programScheduleStatus.status === "open" &&
                          programScheduleStatus.application != null) ||
                          (programScheduleStatus.status ===
                            "not_enough_spots" &&
                            programScheduleStatus.application != null)) &&
                          "!hidden"
                      )}
                      kind="text"
                    >
                      Details
                      <i className="icon arrow-right-icon !mr-0 ml-1" />
                    </Button>
                  )
                : undefined
            }
          />
        )}
        {programScheduleStatus.status === "not_enough_spots" &&
          programScheduleStatus.drop_ins_available.length > 0 &&
          programScheduleStatus.waitlist != null && (
            <OrJoinWaitlist onJoinWaitlistClick={props.onJoinWaitlistClick} />
          )}

        {((programScheduleStatus.status === "open" &&
          programScheduleStatus.application != null) ||
          (programScheduleStatus.status === "not_enough_spots" &&
            programScheduleStatus.application != null)) &&
          showApplications && (
            <ApplyForApplication
              buttonName={programScheduleStatus.application.button_name}
              onApplyClick={props.onApplyClick}
              hasOtherOptionsToSchedule={
                (programScheduleStatus.status === "not_enough_spots" &&
                  programScheduleStatus.drop_ins_available.length > 0) ||
                programScheduleStatus.status === "open"
              }
            />
          )}
      </div>
      {threeBlocksCondition && (
        <>
          <Separator />
          <ProgramLabelsSet
            showDropIns={showDropIns}
            showApplications={showApplications}
            showAgeRestrictionLabel={showAgeRestrictionLabel}
            cardProps={props}
            rowOrCol="col"
            renderDetailsButton={() =>
              program.program_type.description ? (
                <Button
                  onClick={setIsFlipped}
                  className="!typography-label mt-auto"
                  kind="text"
                >
                  Details
                  <i className="icon arrow-right-icon !mr-0 ml-1" />
                </Button>
              ) : undefined
            }
          />
        </>
      )}
    </div>
  );
};

const Separator = () => (
  <div className="h-full w-[1px] bg-separator-color"></div>
);

const ProgramLabelsSet = ({
  cardProps,
  rowOrCol,
  showAgeRestrictionLabel,
  showDates,
  showApplications,
  showDropIns,
  renderDetailsButton,
}: {
  cardProps: ProgramCardProps;
  rowOrCol: "row" | "col";
  showAgeRestrictionLabel: boolean;
  showApplications: boolean;
  showDropIns: boolean;
  showDates?: boolean;
  renderDetailsButton?: () => React.ReactNode;
}) => {
  const { program } = cardProps;
  const programScheduleStatus = getProgramCardStatus(cardProps);
  return (
    <div
      className={classNames(
        "flex  w-fit flex-wrap gap-2",
        rowOrCol === "row"
          ? "h-fit flex-row items-center"
          : "h-full flex-col items-end"
      )}
    >
      {programScheduleStatus.status === "open" &&
        programScheduleStatus.spots_left != null &&
        programScheduleStatus.spots_left < 10 && (
          <SpotsOpenLabel spots={programScheduleStatus.spots_left} />
        )}
      {programScheduleStatus.status === "not_enough_spots" &&
        programScheduleStatus.spots_left != null &&
        programScheduleStatus.spots_left !== 0 && (
          <SpotsOpenLabel spots={programScheduleStatus.spots_left} />
        )}
      {(programScheduleStatus.status === "not_enough_spots" ||
        programScheduleStatus.status === "registration_closed") &&
        programScheduleStatus.drop_ins_available.length > 0 &&
        showDropIns && <SessionsOpenLabel withIcon={rowOrCol === "col"} />}
      {programScheduleStatus.status === "not_enough_spots" &&
        programScheduleStatus.waitlist != null && (
          <WaitListOpenLabel withIcon={false} />
        )}
      {showAgeRestrictionLabel && (
        <AgeRestrictionLabel
          from_age={program.program_type.from_age}
          to_age={program.program_type.to_age}
        />
      )}
      {programScheduleStatus.status === "application" && showApplications && (
        <ApplicationsOpenLabel withIcon={rowOrCol === "col"} />
      )}
      {showDates && (
        <DateLabel
          start_date={program.start_date}
          end_date={program.end_date}
        />
      )}
      {renderDetailsButton?.()}
    </div>
  );
};

const Dates = ({
  program,
  className,
  renderDetailsButton,
}: {
  program: TAvailableProgram;
  className?: string;
  renderDetailsButton?: () => React.ReactNode;
}) => {
  return isDaycampProgram(program) ? (
    <div
      className={twMerge(
        "mx-auto flex flex-col gap-2 whitespace-nowrap",
        className
      )}
    >
      {program.sessions.map((s) => (
        <div key={s.id} className="flex items-center justify-between gap-2">
          <div className="typography-small__t text-text-color">
            {dayjs(s.date).format("MMM Do")}
          </div>
          <div className="typography-small__t text-gray-text-color">
            {formatStartEndTime(s)}
          </div>
        </div>
      ))}
    </div>
  ) : (
    <div
      className={twMerge(
        "typography-main flex min-w-full items-center  gap-2 text-text-color",
        renderDetailsButton ? "justify-between" : "justify-center",
        className
      )}
    >
      <div>{formatStartEndDate(program)}</div>
      {renderDetailsButton?.()}
    </div>
  );
};

const AvailableSessions = ({
  availableSessions,
  onDropInClick,
}: {
  availableSessions: IAvailableSessionInProgram[];
  onDropInClick: ({ id, date }: { id: string; date: ISOString }) => void;
}) => {
  return (
    <div className="flex w-fit flex-col gap-[6px]">
      {availableSessions.map((s) => (
        <div
          key={s.id}
          onClick={() => onDropInClick(s)}
          className="group flex cursor-pointer items-center justify-between gap-2 whitespace-nowrap rounded-md border-[1px] border-solid border-surface-orange-dark-color bg-on-main-color py-[2px] px-[5px] transition-all hover:bg-surface-orange-dark-color"
        >
          <div className="typography-h4 text-surface-orange-dark-color transition-all group-hover:text-on-main-color">
            {dayjs(s.date).format("MMM Do")}
          </div>
          <div className="typography-small__t text-text-color transition-all group-hover:text-on-main-color">
            {formatStartEndTime(s)}
          </div>
        </div>
      ))}
    </div>
  );
};

const getProgramCardStatus = ({
  program,
  selectedParticipants,
  selectedSessions,
  selectedUnlinkedParticipantsAmount,
  isSelected,
}: {
  program: TAvailableProgram;
  selectedParticipants: IStudentDTO[];
  selectedSessions: IAvailableDaycampSession[];
  selectedUnlinkedParticipantsAmount?: number;
  isSelected: boolean;
}): ProgramScheduleStatus => {
  const selectedParticipantsRestrictedInfo = program.student_info.filter((s) =>
    selectedParticipants.find((participant) => participant.id === s.student_id)
  );
  const selectedSessionsInProgram = selectedSessions.filter(
    (s) => s.program_id === program.id
  );

  const spotsLeft =
    program.registration.spots_left != null &&
    selectedUnlinkedParticipantsAmount
      ? program.registration.spots_left - selectedUnlinkedParticipantsAmount
      : program.registration.spots_left;

  if (selectedSessionsInProgram.length > 0) {
    selectedParticipants.forEach((participant) => {
      selectedSessionsInProgram.forEach((s) =>
        selectedParticipantsRestrictedInfo.push({
          type: "has_selected_session_in_program",
          session: s,
          student_id: participant.id,
        })
      );
    });
  }

  if (selectedParticipantsRestrictedInfo.length > 0) {
    return {
      status: "student_restriction",
      restrictions: getRestrictionsForProgram({
        program,
        selectedParticipants,
        info: selectedParticipantsRestrictedInfo,
      }),
    };
  }

  if (
    program.registration.status === "CLOSED" &&
    program.registration.is_time_elapsed
  ) {
    return {
      status: "registration_closed",
      drop_ins_available: isDaycampProgram(program)
        ? program.available_drop_ins
        : [],
    };
  }

  if (
    isSelected ||
    (program.registration.status === "OPEN" &&
      (spotsLeft == null || spotsLeft >= selectedParticipants.length))
  ) {
    return {
      status: "open",
      spots_left: program.available_spots,
      application: program.registration.application,
    };
  }

  if (
    program.registration.status === "CLOSED" &&
    program.registration.application != null &&
    program.registration.application.is_blocking_schedule
  ) {
    return {
      status: "application",
    };
  }

  if (
    program.registration.status === "CLOSED" ||
    (spotsLeft != null && spotsLeft < selectedParticipants.length)
  ) {
    return {
      status: "not_enough_spots",
      drop_ins_available: isDaycampProgram(program)
        ? program.available_drop_ins
        : [],
      spots_left:
        program.registration.status === "CLOSED"
          ? 0
          : program.registration.spots_left,
      waitlist:
        program.registration.status === "CLOSED"
          ? program.registration.waitlist
          : null,
      application: program.registration.application,
    };
  }
  throw new Error("NO STATUS FOR PROGRAM CARD");
};

const getRestrictionsForProgram = ({
  program,
  selectedParticipants,
  info,
}: {
  program: TAvailableProgram;
  selectedParticipants: IStudentDTO[];
  info: TAvailabilityStudentInfo[];
}): StudentRestrictions[] => {
  return selectedParticipants.map((student) => ({
    student,
    statuses: info.flatMap((info) => {
      let statuses: RestrictionStatus[] = [];
      if (info.student_id !== student.id) return [];
      if (info.type === "has_selected_session_in_program") {
        statuses.push({
          type: "already_selected",
          attendance: { kind: "session", date: info.session.date },
        });
      }

      if (info.type === "age_restricted" && student.id === info.student_id) {
        statuses.push({
          type: "age_restricted",
          actual_age: info.actual_age,
          program_type_from_age: program.program_type.from_age,
          program_type_to_age: program.program_type.to_age,
        });
      }

      if (info.type === "has_application_for_program") {
        statuses.push({
          type: "applied",
        });
      }
      if (info.type === "in_waitlist_for_program") {
        statuses.push({ type: "on_waitlist" });
      }

      if (info.type === "already_signed_for_program") {
        statuses.push({
          type: "booked",
          attendance: {
            kind: "program",
            start_date: info.start,
            end_date: info.end,
          },
        });
      }
      if (info.type === "already_signed_for_session") {
        statuses.push({
          type: "booked",
          attendance: {
            kind: "session",
            date: info.start,
          },
        });
      }
      return statuses;
    }),
  }));
};
