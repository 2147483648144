import { observer } from "mobx-react-lite";
import { ApplicationDTO } from "../services/api/availability";
import React, { ReactNode } from "react";
import { assertNever } from "@sizdevteam1/funjoiner-uikit/util";

export const ApplicationStatusText = observer(
  ({
    status,
    className,
    resubmissionRequiredText,
  }: {
    status: ApplicationDTO["status"];
    className?: string;
    resubmissionRequiredText: string;
  }) => {
    let statusText: ReactNode;
    let icon: ReactNode | null;
    switch (status) {
      case "APPROVED":
        statusText = (
          <span className={"text-icon-dark-green-color"}>Approved</span>
        );
        icon = (
          <i
            className={"icon check-in-object-2-icon bg-icon-dark-green-color"}
          />
        );
        break;
      case "REJECTED":
        statusText = <span className={"text-icon-red-color"}>Declined</span>;
        icon = <i className={"icon big-cross-icon bg-icon-red-color"} />;
        break;
      case "PENDING":
        statusText = (
          <span className={"text-icon-grey-color"}>Under Review...</span>
        );
        icon = null;
        break;
      case "RESUBMISSION":
        statusText = (
          <span className={"text-surface-orange-color"}>
            {resubmissionRequiredText}
          </span>
        );
        icon = (
          <i className={"icon delete-refund-icon bg-surface-orange-color"} />
        );
        break;
      case "AWAITING_PAYMENT":
        statusText = (
          <span className={"text-icon-dark-green-color"}>Pay Now</span>
        );
        icon = (
          <i className={"icon issue-payment-icon bg-icon-dark-green-color"} />
        );
        break;
      case "DRAFT":
        throw new Error("Draft applications should not be shown");
      default:
        assertNever(status);
    }

    return (
      <div className={"flex flex-row items-center gap-1"}>
        {icon}
        <div className={"typography-button"}>{statusText}</div>
      </div>
    );
  }
);
