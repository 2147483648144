import cn from "classnames";
import React from "react";
import { CompletenessPercentage } from "src/components/CompletenessPercentage";
import styles from "./PieChart.module.scss";

function PieChart({
  percentage,
  className,
}: {
  percentage: number;
  className?: string;
}) {
  const isNotFull = percentage < 100;

  const getSectionsFillStyleByPercentage = (percentage: number) => {
    if (percentage === 100) {
      return styles.all;
    }
    if (percentage >= 38 && percentage < 50) {
      return styles.oneSection;
    }
    if (percentage >= 50 && percentage <= 62) {
      return styles.threeSections;
    }
    if (percentage > 62 && percentage <= 74) {
      return styles.fourSections;
    }
    if (percentage >= 75 && percentage <= 99) {
      return styles.fiveSections;
    } else return {};
  };

  return (
    <div className={cn("relative h-[47px] w-[56px]", className)}>
      {isNotFull && (
        <CompletenessPercentage
          percentage={percentage}
          className="!typography-h4 absolute"
        />
      )}
      <svg
        className={cn(getSectionsFillStyleByPercentage(percentage))}
        width="56"
        height="47"
        viewBox="0 0 56 47"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.3878 22.2773C9.81037 22.2773 9.3396 21.809 9.36792 21.2322C9.48779 18.7912 10.0269 16.3863 10.9642 14.1236C11.9014 11.8609 13.2207 9.77911 14.862 7.96828C15.2498 7.54044 15.9139 7.54213 16.3222 7.95044L28.8643 20.4925C29.5229 21.1512 29.0564 22.2773 28.125 22.2773L10.3878 22.2773Z"
          fill={isNotFull ? "transparent" : "#0BA9DA"}
        />
        <path
          d="M17.2898 6.98169C16.8815 6.57338 16.8798 5.9093 17.3076 5.52151C19.1185 3.88022 21.2002 2.5609 23.4629 1.62365C25.7256 0.686398 28.1306 0.14728 30.5716 0.027403C31.1483 -0.000919301 31.6167 0.469858 31.6167 1.04729L31.6167 18.7845C31.6167 19.7159 30.4905 20.1824 29.8319 19.5238L17.2898 6.98169Z"
          fill={isNotFull ? "transparent" : "#0BA9DA"}
        />
        <path
          d="M33.0667 1.04729C33.0667 0.469861 33.5351 -0.000916159 34.1118 0.027406C36.5529 0.147283 38.9578 0.686398 41.2205 1.62365C43.4832 2.5609 45.565 3.88021 47.3758 5.52151C47.8036 5.9093 47.8019 6.57338 47.3936 6.98169L34.8516 19.5238C34.1929 20.1824 33.0667 19.7159 33.0667 18.7845L33.0667 1.04729Z"
          fill="var(--separator-color)"
        />
        <path
          d="M48.3609 7.94653C48.7693 7.53823 49.4333 7.53653 49.8211 7.96438C51.4624 9.7752 52.7817 11.8569 53.719 14.1197C54.6562 16.3824 55.1954 18.7873 55.3152 21.2283C55.3436 21.8051 54.8728 22.2734 54.2953 22.2734L36.5582 22.2734C35.6267 22.2734 35.1602 21.1473 35.8189 20.4886L48.3609 7.94653Z"
          fill="var(--separator-color)"
        />
        <path
          d="M31.6165 44.9566C31.6165 45.534 31.1481 46.0048 30.5714 45.9765C28.1304 45.8566 25.7255 45.3175 23.4627 44.3803C21.2 43.443 19.1183 42.1237 17.3074 40.4824C16.8796 40.0946 16.8813 39.4305 17.2896 39.0222L29.8317 26.4801C30.4903 25.8215 31.6165 26.288 31.6165 27.2194L31.6165 44.9566Z"
          fill="var(--separator-color)"
        />
        <path
          d="M16.3223 38.0535C15.914 38.4618 15.2499 38.4635 14.8621 38.0356C13.2208 36.2248 11.9015 34.1431 10.9642 31.8803C10.027 29.6176 9.48787 27.2127 9.36799 24.7717C9.33967 24.1949 9.81045 23.7266 10.3879 23.7266H28.125C29.0565 23.7266 29.523 24.8527 28.8643 25.5114L16.3223 38.0535Z"
          fill="var(--separator-color)"
        />
        <path
          d="M54.2954 23.7266C54.8729 23.7266 55.3436 24.1949 55.3153 24.7717C55.1954 27.2127 54.6563 29.6176 53.7191 31.8803C52.7818 34.1431 51.4625 36.2248 49.8212 38.0356C49.4334 38.4635 48.7693 38.4618 48.361 38.0535L35.819 25.5114C35.1603 24.8528 35.6268 23.7266 36.5583 23.7266L54.2954 23.7266Z"
          fill="var(--separator-color)"
        />
        <path
          d="M47.3934 39.0222C47.8017 39.4305 47.8034 40.0946 47.3756 40.4824C45.5648 42.1237 43.483 43.443 41.2203 44.3803C38.9576 45.3175 36.5527 45.8566 34.1116 45.9765C33.5349 46.0048 33.0665 45.534 33.0665 44.9566L33.0665 27.2195C33.0665 26.288 34.1927 25.8215 34.8514 26.4801L47.3934 39.0222Z"
          fill="var(--separator-color)"
        />
      </svg>
    </div>
  );
}

export default PieChart;
