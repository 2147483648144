import React from "react";

import {
  IQuestionPipelineParameters,
  MultiChoiceVm,
  QuestionPipelineVmProvider,
  TextAnswerVm,
  useQuestionPipelineVm,
} from "./QuestionPipelineVm";
import { observer } from "mobx-react-lite";
import {
  Avatar,
  Button,
  Input,
  Loader,
  MarkdownText,
  Select,
  Textarea,
  assertNever,
  FileUploader,
  Label,
} from "@sizdevteam1/funjoiner-uikit";
import classNames from "classnames";
import storageFileResolver from "../../../services/storageFileResolver";
import { IStorageFileDTO } from "@sizdevteam1/funjoiner-uikit/components/Gallery/Gallery";

export type IQuestionPipelineProps = {
  description: string;
  className?: string;
} & IQuestionPipelineParameters;

export const QuestionPipeline: React.FC<IQuestionPipelineProps> = (props) => (
  <QuestionPipelineVmProvider {...props}>
    <QuestionPipelineImpl
      className={props.className}
      description={props.description}
    />
  </QuestionPipelineVmProvider>
);

const QuestionPipelineImpl: React.FC<{
  description: string;
  className?: string;
}> = observer((props) => {
  const vm = useQuestionPipelineVm();

  return (
    <div className={classNames("flex flex-col", props.className)}>
      <div className="typography-main text-gray-text-color">
        {props.description}
      </div>
      {vm.isLoading ? (
        <Loader className="mt-4" />
      ) : vm.error != null ? (
        <div className=" typography-main mt-4 text-text-color">{vm.error}</div>
      ) : (
        <>
          <div className="mt-4 flex flex-row gap-3">
            <Avatar person={vm.currentStudent} avatarSize={"44px"} />
            <div className="flex flex-col gap-[2px]">
              <div className="typography-h3 text-text-color">
                {vm.currentStudent.full_name}
              </div>
              <div className="typography-label text-gray-text-color">
                Question {vm.currentQuestionOrdinalNumber}/{vm.questionCount}
              </div>
            </div>
            <OtherStudents />
          </div>
          <div className="typography-h2 mt-4 text-text-color">
            {vm.questionSetName}
          </div>
          {vm.resubmissionReason != null && (
            <div className="mt-4 flex flex-col rounded-[10px] border-2 border-solid border-main-color bg-on-main-color p-4">
              <Label>Reason for Resubmission:</Label>
              <div className="typography-main text-text-color">
                {vm.resubmissionReason}
              </div>
            </div>
          )}

          <QuestionView />
          <div className={"mt-auto pt-6 pb-3"}>
            <Buttons />
          </div>
        </>
      )}
    </div>
  );
});

const OtherStudents: React.FC = observer(() => {
  const { otherStudents } = useQuestionPipelineVm();
  const size = 32;

  if (otherStudents.length === 0) return null;

  const VISIBLE_LENGTH = 3;
  const avatars = otherStudents
    .slice(0, VISIBLE_LENGTH)
    .map((student, index) => {
      const sizeInPx = `${size}px`;
      return (
        <div
          key={student.id}
          className={classNames("absolute")}
          style={{
            minWidth: sizeInPx,
            height: sizeInPx,
            width: sizeInPx,
            left: (index * size) / 2,
            zIndex: 3 - index,
          }}
        >
          <Avatar
            key={student.id}
            className={classNames(
              "border border-solid border-separator-color !bg-light-main-color"
            )}
            avatarSize={sizeInPx}
            person={student}
          />
        </div>
      );
    });

  return (
    <div className="ml-auto flex flex-row items-center gap-2">
      <div className="typography-small text-gray-text-color">
        {otherStudents.length} more
      </div>
      <div
        className="relative"
        style={{
          width: `${size + ((otherStudents.length - 1) * size) / 2}px`,
          height: size,
        }}
      >
        {avatars}
      </div>
    </div>
  );
});

const Buttons: React.FC = observer(() => {
  const {
    submitText,
    submit,
    hasPrevious,
    toPreviousQuestion,
    isSubmitDisabled: isButtonDisabled,
    showSkipTheseQuestions,
    skipQuestionSet,
  } = useQuestionPipelineVm();

  return (
    <div className="sticky bottom-3 flex flex-col gap-4">
      <Button
        disabled={isButtonDisabled}
        size="big"
        onClick={submit}
        autoLoading={true}
      >
        {submitText}
      </Button>
      {showSkipTheseQuestions && (
        <Button kind="text" onClick={skipQuestionSet} autoLoading={true}>
          Skip These Questions
        </Button>
      )}
      {hasPrevious && (
        <Button kind="text" onClick={toPreviousQuestion}>
          <i className="icon chevron-left-icon !h-6 !w-6" />
          Previous Question
        </Button>
      )}
    </div>
  );
});

const QuestionView: React.FC = observer(() => {
  const { question } = useQuestionPipelineVm();
  return (
    <div
      key={question.id}
      className="mt-6 flex flex-col motion-safe:animate-slide-fade-in"
    >
      <div className="typography-h3 text-text-color">
        <MarkdownText onlyLinks>{question.text}</MarkdownText>
      </div>
      {question.description && (
        <div className="typography-main mt-1 text-gray-text-color">
          <MarkdownText>{question.description}</MarkdownText>
        </div>
      )}
      <div className="mt-4">
        <AnswerView />
      </div>
    </div>
  );
});

const AnswerView: React.FC = observer(() => {
  const vm = useQuestionPipelineVm();
  const { answerVm } = vm;

  if (!answerVm.isEditable) {
    const answer = answerVm.displayAnswer;
    return (
      <div className="flex flex-col">
        <div className="flex flex-row items-center gap-[2px]">
          <i className="icon lock-icon" />
          <div className="typography-small__t  mt-[2px] italic text-gray-text-color">
            (Answer editing is restricted)
          </div>
        </div>
        <div className="typography-main mx-3 mt-[10px]">
          {typeof answer === "string" ? (
            answer
          ) : (
            <AttachmentWrapView attachments={answer} />
          )}
        </div>
      </div>
    );
  } else if (answerVm instanceof MultiChoiceVm) {
    return <MultiChoice vm={answerVm} />;
  } else if (answerVm instanceof TextAnswerVm) {
    const question = answerVm.question;
    if (question.type === "TEXT") {
      return question.input_type === "INPUT" ? (
        <Input
          value={answerVm.displayAnswer}
          onChange={(e) => answerVm.setAnswer(e.currentTarget.value)}
        />
      ) : (
        <Textarea
          textareaClassName="resize-y min-h-[92px]"
          value={answerVm.displayAnswer}
          onChange={(e) => answerVm.setAnswer(e.currentTarget.value)}
        />
      );
    } else if (question.type === "SELECT") {
      const toOption = (value: string) => ({ id: value, name: value });
      return (
        <Select
          options={question.options.map(toOption)}
          selected={toOption(answerVm.displayAnswer)}
          onSelect={(option) => answerVm.setAnswer(option.id)}
        />
      );
    } else {
      assertNever(question);
    }
  } else {
    return (
      <FileUploader
        allow={["application/pdf", "image/*", "video/*"]}
        className="w-auto"
        initialValue={answerVm.displayAnswer}
        onChanged={answerVm.setUploads}
        uploadFile={vm.uploadFile}
      />
    );
  }
});

const MultiChoice: React.FC<{
  vm: MultiChoiceVm;
}> = observer(({ vm }) => {
  return (
    <div className="flex flex-col gap-3">
      {vm.availableOptions.map((e) => (
        <SelectableOption
          key={e}
          checked={vm.isSelected(e)}
          onChange={(enabled) => vm.toggleOption(e, enabled)}
        >
          {e}
        </SelectableOption>
      ))}
      {vm.questionAllowsOtherOption && (
        <div className="flex flex-col gap-3">
          <SelectableOption
            key={-1}
            checked={vm.isOtherOptionSelected}
            onChange={vm.toggleOtherOption}
          >
            Other
          </SelectableOption>
          {vm.isOtherOptionSelected && (
            <Textarea
              textareaClassName="resize-y min-h-[92px]"
              value={vm.otherOption!}
              onChange={(e) => vm.setOtherOption(e.currentTarget.value)}
            />
          )}
        </div>
      )}
    </div>
  );
});

const SelectableOption = ({
  children,
  checked,
  onChange,
}: {
  children: string;
  checked: boolean;
  onChange: (enabled: boolean) => void;
}) => {
  return (
    <div
      onClick={() => onChange(!checked)}
      className={classNames(
        "flex cursor-pointer flex-row items-center gap-2 rounded-[6px] border-2  border-solid border-dark-main-color p-[6px] transition",
        checked && "bg-dark-main-color"
      )}
    >
      <div className={"flex h-[24px] w-[24px] items-center justify-center"}>
        <i
          className={classNames(
            "icon",
            checked
              ? "check-icon bg-on-main-color"
              : "arrow-icon h-[12px] w-[12px] -rotate-90 bg-dark-main-color"
          )}
        />
      </div>
      <div
        className={classNames(
          checked
            ? "typography-main_sb text-on-main-color"
            : "typography-main text-text-color"
        )}
      >
        {children}
      </div>
    </div>
  );
};

const AttachmentWrapView = ({
  attachments,
}: {
  attachments: IStorageFileDTO[];
}) => {
  const cutLongFileName = (fileName: string) => {
    if (fileName.length > 24) return fileName.substring(0, 28) + "...";
    else return fileName;
  };

  return (
    <div className="flex flex-wrap gap-2">
      {attachments.map((f) => (
        <button
          key={f.key}
          className="typography-button cursor-pointer rounded-[6px] border-none bg-highlight-color px-[10px] py-[6px] text-main-color"
          onClick={async (e) => {
            e.preventDefault();
            await storageFileResolver
              .getSignedLink(f.download_url, true)
              .then((url: string) => {
                window.open(url, "_self");
              });
          }}
        >
          {cutLongFileName(f.public_name)}
        </button>
      ))}
    </div>
  );
};
