import { PaymentMethod } from "@stripe/stripe-js";
import { ICardDTO } from "src/services/api";

const cardFromStripePaymentMethod = (
  paymentMethod: PaymentMethod
): ICardDTO => {
  return {
    payment_method_id: paymentMethod.id,
    brand: paymentMethod.card?.brand!,
    expiration_month: paymentMethod.card?.exp_month!,
    expiration_year: paymentMethod.card?.exp_year!,
    last4: paymentMethod.card?.last4!,
    wallet_type: paymentMethod.card?.wallet?.type ?? null,
  };
};

export default cardFromStripePaymentMethod;
