import apiService from "./apiService";
import { IOrderDTO } from "./orders";

export interface ICreditUpgradeItem {
  credit_id: number;
  new_credit_type_id: number;
}

export interface IUsedCreditRow {
  credit_type_id: number;
  is_program_credit: boolean;
  name: string;
  quantity: number;
  total: number;
  is_free: boolean;
}

export interface IBuyCreditRow {
  credit_type_id: number;
  is_program_credit: boolean;
  name: string;
  quantity: number;
  total: undefined;
  is_free: boolean;
}
export const upgrade = (items: ICreditUpgradeItem[]): Promise<IOrderDTO> =>
  apiService.post("/customer_api/v2/credits/upgrade", {
    data: {
      items,
    },
  });
