import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";
import useStores from "src/hooks/useStores";
import { TAttendanceDTO } from "src/services/api";
import dayjs from "dayjs";
import { Dropdown } from "@sizdevteam1/funjoiner-uikit";
import formatStartEndTime from "src/util/formatStartEndTime";

interface IAttendanceTileProps {
  attendance: TAttendanceDTO;
  actions: TDropdownAction[];
}

type TDropdownAction = {
  icon: React.ReactNode;
  label: string;
  onClick: () => void;
};

const SessionTile: React.FC<IAttendanceTileProps> = observer(
  ({ attendance, actions }: IAttendanceTileProps) => {
    return (
      <div
        className={classNames(
          "relative flex items-center gap-4 rounded-[10px] bg-on-main-color p-4 shadow-big"
        )}
      >
        <div className="flex  justify-center">
          <div
            className={classNames(
              "flex h-[60px] w-[60px] flex-col  items-center justify-center rounded-[50%] bg-[#FCE9DE] text-surface-orange-color"
            )}
          >
            <span className={classNames("typography-label uppercase")}>
              {dayjs(attendance.start).format("MMM")}
            </span>
            <span className="text-h2">
              {dayjs(attendance.start).format("D")}
            </span>
          </div>
        </div>
        <div className={classNames("mr-auto flex flex-col justify-center")}>
          <div
            className={classNames(
              "typography-small w-[217px] overflow-hidden text-ellipsis whitespace-nowrap  text-gray-text-color"
            )}
          >
            {attendance.location_name}
          </div>

          <div className="typography-small mt-1 w-[217px] overflow-hidden text-ellipsis whitespace-nowrap  text-text-color ">
            {attendance.schedule_set_name}
          </div>
          <div className="typography-h3  mt-1 w-[217px] overflow-hidden text-ellipsis  whitespace-nowrap text-text-color">
            {attendance.program_type_name}
          </div>
          <div className="typography-small mt-[2px] text-gray-text-color">
            {`${dayjs(attendance.start).format("hh:mmA")} - ${dayjs(
              attendance.end
            ).format("hh:mmA")}`}
          </div>
        </div>
        {actions.length > 0 && (
          <Dropdown
            base={<i className={"icon more-options-icon icon-hover"} />}
            options={actions.map((a) => (
              <div onClick={a.onClick}>
                {a.icon}
                {a.label}
              </div>
            ))}
          />
        )}
      </div>
    );
  }
);

export default SessionTile;
