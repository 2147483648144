import { observer } from "mobx-react-lite";
import { AvailabilityVM } from "../../pages/AvailabilityPage/components/AvailabilityVM";
import {
  Button,
  ExpandableRow,
  Modal,
  MultiSelect,
  RangeSlider,
  SegmentedControl,
  Switcher,
} from "@sizdevteam1/funjoiner-uikit";

import classNames from "classnames";

const AvailabilityFiltersModal = observer(
  ({ vm }: { vm: AvailabilityVM["filtersVM"] }) => {
    if (!vm.isOpen) return null;

    return (
      <Modal
        alignment="bottom"
        containerClassName="min-w-[366px] max-w-[414px] w-full"
        contentClassName={"rounded-t-xl h-fit "}
      >
        <div className="flex h-full flex-col">
          <div
            className={
              "typography-h2 relative flex justify-center  text-header-color"
            }
          >
            <div className={"mr-6 flex gap-1.5"}>
              <i className={"icon filters-icon"} />
              Filters
            </div>

            <i
              className={"icon icon-hover cross-icon absolute right-0"}
              onClick={vm.close}
            />
          </div>

          <div className={"my-5 h-[1px] w-full bg-separator-color"} />
          <div className={"typography-h3 text-text-color"}>Select Options</div>
          <MultiSelect
            className={"mt-3"}
            {...vm.programTypeVm.toMultiSelectProps()}
          />
          <div className={"typography-h3 mt-6 text-text-color"}>Offerings</div>
          <SegmentedControl
            className={classNames(
              "mt-3",
              vm.allOrAvailable === "all" &&
                vm.funbox.get().mode !== "SESSIONS" &&
                "mb-[144px]"
            )}
            options={[
              {
                key: "all",
                label: "Display All",
              },
              {
                key: "available",
                label: "Only Available",
              },
            ]}
            selected={vm.allOrAvailable}
            onSelect={vm.setAllOrAvailable}
          />

          <div className={"flex flex-col"}>
            {vm.allOrAvailable === "available" &&
              vm.funbox.get().mode !== "SESSIONS" && (
                <div className="mt-6 flex flex-col gap-6">
                  <div
                    className={
                      "typography-h3 flex justify-between text-text-color"
                    }
                  >
                    Include Open Applications
                    <Switcher
                      value={vm.includeOpenApplications}
                      onClick={vm.setIncludeOpenApplications}
                    />
                  </div>
                  <div
                    className={
                      "typography-h3 flex justify-between text-text-color"
                    }
                  >
                    Include Open Waitlists
                    <Switcher
                      value={vm.includeOpenWaitlists}
                      onClick={vm.setIncludeOpenWaitlists}
                    />
                  </div>
                  {vm.allOrAvailable === "available" &&
                    !vm.isSelectedFunboxForCustomers && (
                      <ExpandableRow
                        className={classNames("w-full")}
                        isOpen={vm.isAgeFilterEnabled}
                        children={
                          <div
                            className={
                              "typography-h3  flex justify-between text-text-color"
                            }
                          >
                            Eligible Age
                            <Switcher
                              value={vm.isAgeFilterEnabled}
                              onClick={() => {
                                vm.toggleAgeFilter();
                                vm.setAges(vm.fromAge, vm.toAge);
                              }}
                            />
                          </div>
                        }
                        expand={
                          <div>
                            <RangeSlider
                              className={
                                "mt-5 !h-fit !w-full !p-0 text-text-color"
                              }
                              min={3}
                              max={19}
                              step={1}
                              value1={vm.fromAge}
                              value2={vm.toAge}
                              onChange={vm.setAges}
                              renderValue={(v) =>
                                v === 19 ? "18+" : v.toString()
                              }
                              valueLabel={" yrs"}
                            />
                          </div>
                        }
                      />
                    )}
                </div>
              )}
          </div>
          <Button
            size={"big"}
            className={"mt-[60px] w-full"}
            onClick={vm.apply}
          >
            Apply
          </Button>
        </div>
      </Modal>
    );
  }
);

export const AvailabilityFiltersButton = observer(
  ({ vm }: { vm: AvailabilityVM["filtersVM"] }) => (
    <Button kind={"text"} className={"!typography-main"} onClick={vm.open}>
      <i className={"icon filters-icon !h-6 !w-6"} /> Filters{" "}
      {vm.appliedFilterCount > 0 && `(${vm.appliedFilterCount})`}
    </Button>
  )
);
export default AvailabilityFiltersModal;
