import React, { useEffect, useMemo } from "react";
import useVM from "src/hooks/useVM";
import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from "mobx";

import api, {
  IAvailableDaycampScheduleSet,
  IAvailableOvernightScheduleSet,
  IFunboxDTO,
} from "src/services/api";
import notificator from "src/services/systemNotifications/notificationCenterService";
import { FiltersVM } from "../AvailabilityPage/components/AvailabilityVM";
import {
  ILocationDTO,
  IPagination,
  IProgramTypeDTO,
} from "src/services/api/common";
import dayjs from "dayjs";
import { CustomerStore, RouterStore } from "../../stores";
import useStores from "../../hooks/useStores";
import FunboxStore from "src/stores/FunboxStore";
import { TAvailableScheduleSet } from "../../services/api/availability";

export class ExploreOfferingsPageVM {
  static ITEMS_PER_PAGE = 10;
  private disposers: (() => void)[] = [];
  dispose = () => {
    this.disposers.forEach((dispose) => dispose());
  };

  public filtersVM: FiltersVM;
  constructor(
    routerStore: RouterStore,
    private funboxStore: FunboxStore,

    public readonly selectedFunbox: IFunboxDTO,
    public readonly selectedLocation: ILocationDTO
  ) {
    this.filtersVM = new FiltersVM(
      computed(() => selectedFunbox),
      routerStore
    );
    makeObservable(this);
    this.disposers.push(
      reaction(
        () => this.searchParams,
        () => this.getAvailableProgramTypes(),
        { fireImmediately: true }
      )
    );
  }

  @observable availableProgramTypes: IProgramTypeDTO[] = [];

  @action.bound async selectOfferingToViewSchedules(offering: IProgramTypeDTO) {
    await this.getAvailability(offering);
  }

  @observable loading = false;

  @observable isSelectLocationModalOpen = false;

  @computed get isAtLeastOneOtherFunboxAvailable() {
    return (
      this.funboxStore.funboxes.filter((f) => f.id !== this.selectedFunbox.id)
        .length > 0
    );
  }

  @computed.struct get searchParams() {
    return {
      ...this.filtersVM.searchParams,
      location_id: this.selectedLocation.id,
      funbox_id: this.selectedFunbox.id,
      date_from: dayjs().format("YYYY-MM-DD"),
    };
  }

  @observable selectedScheduleSetWithDescription: TAvailableScheduleSet | null =
    null;

  @action.bound openScheduleSetDescription(ss: TAvailableScheduleSet) {
    this.selectedScheduleSetWithDescription = ss;
  }
  @action.bound closeScheduleSetDescription() {
    this.selectedScheduleSetWithDescription = null;
  }

  @observable selectedOffering?: IProgramTypeDTO;

  @observable availability: IPagination<
    IAvailableDaycampScheduleSet | IAvailableOvernightScheduleSet
  > = {
    offset: 0,
    total: 0,
    has_more: false,
    items: [],
  };

  @observable loadingAvailabilityOfferingId: number | null = null;

  @action.bound private async getAvailability(offering: IProgramTypeDTO) {
    this.loadingAvailabilityOfferingId = offering.id;
    try {
      const availability = await api.availability.getAvailablePrograms({
        ...this.searchParams,
        program_type_id: offering.id,
        offset: 0,
        limit: ExploreOfferingsPageVM.ITEMS_PER_PAGE,
      });
      runInAction(() => {
        this.selectedOffering = offering;
        this.availability = availability;
      });
    } catch (e) {
      notificator.error("Error", e);
    } finally {
      this.loadingAvailabilityOfferingId = null;
    }
  }

  @observable isLoadingMore = false;

  @action.bound async loadMore() {
    if (
      !this.selectedOffering ||
      this.isLoadingMore ||
      !this.availability.has_more
    )
      return;

    this.isLoadingMore = true;
    try {
      const availability = await api.availability.getAvailablePrograms({
        ...this.searchParams,
        program_type_id: this.selectedOffering.id,
        offset: this.availability.items.length,
        limit: ExploreOfferingsPageVM.ITEMS_PER_PAGE,
      });

      runInAction(() => {
        this.availability = {
          offset: availability.offset,
          total: availability.total,
          has_more: availability.has_more,
          items: [...this.availability.items, ...availability.items],
        };
      });
    } catch (e) {
      notificator.error("Error", e);
    } finally {
      this.isLoadingMore = false;
    }
  }

  @action.bound private async getAvailableProgramTypes() {
    this.loading = true;
    try {
      const programTypes = await api.availability.getAvailableProgramTypes({
        searchParams: this.searchParams,
      });
      runInAction(() => {
        this.availableProgramTypes = programTypes.filter(
          (pt) => !pt.is_hidden_from_explore_offerings_page
        );
      });
    } catch (e) {
      notificator.error("Error", e);
    } finally {
      this.loading = false;
    }
  }

  @action.bound async init() {
    await this.getAvailableProgramTypes();
  }
}
const ctx = React.createContext<ExploreOfferingsPageVM | null>(null);

export const ExploreOfferingsPageVMProvider: React.FC<{
  selectedFunbox: IFunboxDTO;
  selectedLocation: ILocationDTO;
}> = ({ children, selectedFunbox, selectedLocation }) => {
  const { routerStore, funboxStore } = useStores();
  const vm = useMemo(
    () =>
      new ExploreOfferingsPageVM(
        routerStore,
        funboxStore,

        selectedFunbox,
        selectedLocation
      ),
    [routerStore, funboxStore, selectedFunbox, selectedLocation]
  );

  useEffect(
    () => () => {
      vm.dispose();
    },
    [vm]
  );

  return <ctx.Provider value={vm}>{children}</ctx.Provider>;
};

export const useExploreOfferingsPageVM = () => useVM(ctx);
