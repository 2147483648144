import React from "react";
import { Button, PriceWithCents } from "@sizdevteam1/funjoiner-uikit";
import { IInvoiceDTO } from "src/services/api";
import * as Sentry from "@sentry/react";
import { InvoiceVm } from "../../../models/InvoiceVm";
import classNames from "classnames";

const Invoice: React.FC<{
  className?: string;
  invoice: IInvoiceDTO;
  vm: InvoiceVm;
}> = ({ invoice, vm, className }) => {
  return (
    <div
      className={classNames(
        "flex flex-col items-stretch overflow-clip rounded-[10px] bg-on-main-color shadow-big",
        className
      )}
    >
      <div className={"flex flex-row justify-between gap-2 p-4"}>
        <div
          className={
            "text-h4  w-fit items-center overflow-hidden text-ellipsis whitespace-nowrap"
          }
        >
          {invoice.description}
        </div>
        <Button
          kind={"text"}
          className="whitespace-nowrap"
          autoLoading={true}
          onClick={() => vm.payInvoice(invoice.id)}
        >
          Pay Now
        </Button>
      </div>
      <div
        className={"flex flex-row justify-between bg-table-row-color px-4 py-2"}
      >
        <Button
          kind={"text"}
          autoLoading={true}
          className={"!typography-small"}
          onClick={() => vm.downloadInvoicePdf(invoice.id)}
        >
          <i className={"icon download-icon"} />
          Download
        </Button>

        <PriceWithCents
          className="text-text-color"
          prefix="$"
          typography="typography-h3"
          amount={invoice.final_price}
        />
      </div>
    </div>
  );
};

export default Invoice;
