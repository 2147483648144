import {
  scheduleRoute,
  TScheduleToOpen,
} from "src/pages/Schedule/SchedulePage/SchedulePage";
import { RouterStore } from "src/stores";
import { ROUTES } from "src/stores/RouterStore";

const navigateToSchedule = (
  routerStore: RouterStore,
  isUserLoggedIn: boolean,
  schedule: TScheduleToOpen
) => {
  isUserLoggedIn
    ? routerStore.navigateToRoute(scheduleRoute.build({ state: { schedule } }))
    : routerStore.navigate(ROUTES.SIGN_IN, {
        state: { schedule },
        from: ROUTES.SCHEDULE,
      });
};

export default navigateToSchedule;
