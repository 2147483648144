import {
  Avatar,
  Button,
  Checkbox,
  ExpandableRow,
  findById,
  ProgramSessionSwitch,
} from "@sizdevteam1/funjoiner-uikit";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import LocationSelect from "src/components/LocationSelect";
import PageHeader from "src/components/PageHeader";
import PageRoot from "src/components/PageRoot";
import useStores from "src/hooks/useStores";
import { IFunboxDTO, IStudentDTO } from "src/services/api";
import { ROUTES } from "src/stores/RouterStore";
import {
  isLinkedState,
  SchedulePageVMProvider,
  ScheduleVM,
  useSchedulePageVM,
} from "src/stores/ScheduleStore/SchedulePageVM";
import ParticipantSelect from "./components/ParticipantSelect";
import SummarySection from "./components/SummarySection";
import styles from "./SchedulePage.module.scss";
import JoinWaitlistPage from "../JoinWaitlistPage";
import StickyFooter from "../../../components/StickyFooter";
import ScheduleDatesButton from "src/components/ScheduleDatesButton/ScheduleDatesButton";
import cn from "classnames";
import classNames from "classnames";
import SizedBox from "src/components/SizedBox";
import { Route, Switch } from "react-router-dom";
import CheckoutAndPayment from "src/pages/Checkout&Payment/Checkout&Payment";
import BeforeScheduleQuestionsPage from "src/pages/BeforeSheduleQuestionsPage/BeforeScheduleQuestionsPage";
import AvailabilityFiltersModal, {
  AvailabilityFiltersButton,
} from "../../../components/AvailabilityFiltersModal/AvailabilityFiltersModal";
import { defineRoute } from "../../../util/typedRouting";
import { ISOString } from "@sizdevteam1/funjoiner-uikit/types";
import ConfirmSelectLocationModal from "./components/ConfirmSelectLocationModal";
import getAge from "src/util/getAge";
import { twMerge } from "tailwind-merge";
import SelectLocationModal from "src/components/SelectLocationModal/SelectLocationModal";
import ProgramScheduleTable from "./components/ScheduleTables/ProgramScheduleTable";
import SessionsScheduleTable from "./components/ScheduleTables/SessionsScheduleTable";
import InfoMessage from "src/components/InfoMessage/InfoMessage";
import SelectProgram from "./components/SelectProgram";
import SelectSession from "./components/SelectSession";
import ParticipantCreateOrEditPage from "src/pages/ParticipantCreateOrEditPage";
import { CompleteCustomerProfilePage } from "../CompleteCustomerProfilePage/CompleteCustomerProfilePage";
import ProvidePaymentInformationPage from "../ProvidePaymentInformationPage";
import ApplicationProcessPage, {
  applicationProcessPageRoute,
} from "src/pages/ApplicationProcessPage/ApplicationProcessPage";

export type TScheduleToOpen =
  | IProgramToOpen
  | ISessionToOpen
  | ISetToOpen
  | IProgramToJoinWaitlist
  | IProgramToApply;

export interface IProgramToOpen {
  type: "program";
  schedule_set_id: string;
  program_id: string;
  selectedScheduleMonth: ISOString;
}

export interface ISessionToOpen {
  type: "session";
  session_id: string;
  session_date: ISOString;
  selectedScheduleMonth: ISOString;
}

export interface ISetToOpen {
  type: "schedule_set";
  schedule_set_id: string;
  selectedScheduleMonth: ISOString;
}

export interface IProgramToJoinWaitlist {
  type: "join_waitlist";
  schedule_set_id: string;
  program_id: string;
  selectedScheduleMonth: ISOString;
}

export interface IProgramToApply {
  type: "apply";
  schedule_set_id: string;
  program_id: string;
  selectedScheduleMonth: ISOString;
}

export const scheduleRoute = defineRoute<{
  state: {
    schedule: TScheduleToOpen;
  };
}>({
  path: "/schedule",
  build: (path) => path,
});

const SchedulePage = observer(() => {
  const selectedSchedule = scheduleRoute.useParams().state?.schedule;
  const { funboxStore, routerStore } = useStores();

  if (funboxStore.selectedFunbox == null) {
    // Redirect component doesn't write from state. have to use navigate.
    // SetTimeout is used to make sure that the route is changed after the page is rendered
    setTimeout(() => {
      routerStore.navigate(ROUTES.SELECT_FUNBOX_PAGE, {
        searchParams: {
          selected_tab: "funbox",
        },
        replace: true,
        rewriteFromOnReplace: true,
      });
    });
    return null;
  }
  if (funboxStore.selectedLocation == null) {
    // Redirect component doesn't write from state. have to use navigate.
    // SetTimeout is used to make sure that the route is changed after the page is rendered
    setTimeout(() => {
      routerStore.navigate(ROUTES.SELECT_FUNBOX_PAGE, {
        replace: true,
        rewriteFromOnReplace: true,
        searchParams: {
          selected_tab: "location",
        },
      });
    });

    return null;
  }
  return (
    <SchedulePageVMProvider scheduleToOpen={selectedSchedule ?? undefined}>
      <SchedulePageImpl />
    </SchedulePageVMProvider>
  );
});

const SchedulePageImpl: React.FC = observer(() => {
  const vm = useSchedulePageVM();

  return (
    <Switch>
      <Route
        path={ROUTES.SCHEDULE_ADD_PARTICIPANT}
        component={ParticipantCreateOrEditPage}
      />
      {vm.participantSelection.isSelectingParticipants && (
        <Route>
          <SelectParticipantsPage />
        </Route>
      )}
      <Route path={ROUTES.SCHEDULE_WAITLIST} component={JoinWaitlistPage} />
      <Route path={ROUTES.SCHEDULE_CHECKOUT}>
        <CheckoutAndPayment />
      </Route>
      <Route
        path={ROUTES.SCHEDULE_QUESTIONS_BEFORE}
        component={BeforeScheduleQuestionsPage}
      />
      <Route path={ROUTES.SCHEDULE_COMPLETE_CUSTOMER_PROFILE}>
        <CompleteCustomerProfilePage
          selectedStudents={vm.participantSelection.selectedParticipants}
        />
      </Route>
      <Route path={ROUTES.SCHEDULE_PROVIDE_PAYMENT_INFORMATION}>
        <ProvidePaymentInformationPage />
      </Route>
      <Route component={Schedule} />
    </Switch>
  );
});

const gapToFooter = 5;

const Schedule = observer(() => {
  const vm = useSchedulePageVM();
  const { funboxStore } = useStores();

  return (
    <>
      <PageRoot className={styles.schedule}>
        <PageHeader showBackLink={true}>Schedule Dates</PageHeader>

        <div className={"flex items-start justify-between gap-3"}>
          <LocationSelect
            className={"ml-[10px]"}
            selectedLocation={funboxStore.selectedLocation}
            onClick={vm.handleOpenSelectLocationModal}
          />
          <AvailabilityFiltersButton vm={vm.availabilityVM.filtersVM} />
        </div>

        <SchedulingSections />
        <div style={{ minHeight: gapToFooter, marginTop: "auto" }} />

        {vm.isConfirmLocationChangeModalOpened && (
          <ConfirmSelectLocationModal
            onClose={() => (vm.isConfirmLocationChangeModalOpened = false)}
            onSubmit={() => {
              vm.isConfirmLocationChangeModalOpened = false;
              vm.isSelectLocationModalOpen = true;
            }}
          />
        )}
      </PageRoot>

      <StickyFooter className={cn(!vm.hasSelectedItems && "!border-t-0 ")}>
        <ScheduleDatesButton
          loading={vm.isSecuringReservation}
          disabled={
            !vm.hasSelectedItems || vm.orderVM.state?.state === "pending"
          }
          confirmSelectionClick={() => vm.selectedSchedule?.confirm()}
          scheduleClick={vm.secureReservation}
          isConfirmed={vm.isConfirmed}
        >
          Secure Reservation
        </ScheduleDatesButton>
        <SummarySection
          orderVM={vm.orderVM}
          className={cn(vm.hasSelectedItems && "p-4")}
        />
      </StickyFooter>
      <AvailabilityFiltersModal vm={vm.availabilityVM.filtersVM} />
      {vm.isSelectLocationModalOpen && (
        <SelectLocationModal
          selectedFunbox={vm.selectedFunbox.get()}
          selectedLocation={vm.selectedLocation.get()}
          onSelectLocation={funboxStore.selectLocationId}
          onClose={() => (vm.isSelectLocationModalOpen = false)}
          isShowingViewMoreButton={vm.atLeastOneOtherFunboxAvailable}
          useGlobalFolderSelector={true}
        />
      )}
    </>
  );
});

const SchedulingSections: React.FC = observer(() => {
  const vm = useSchedulePageVM();
  const state = vm.state;

  return isLinkedState(state) ? (
    <SchedulingSection
      scheduleVM={state.scheduleVM}
      selectedFunbox={vm.selectedFunbox.get()}
      students={vm.participantSelection.selectedParticipants}
    />
  ) : (
    <>
      {Object.entries(state.scheduleVMs).map(([id, sVm]) => (
        <SchedulingSection
          key={id}
          students={[
            findById(+id, vm.participantSelection.selectedParticipants),
          ]}
          scheduleVM={sVm}
          selectedFunbox={vm.selectedFunbox.get()}
        />
      ))}
    </>
  );
});

interface ISchedulingSectionProps {
  students: IStudentDTO[];
  selectedFunbox: IFunboxDTO;
  scheduleVM: ScheduleVM;
}

const SchedulingSection: React.FC<ISchedulingSectionProps> = observer(
  ({ students, scheduleVM, selectedFunbox }) => {
    const { funboxStore } = useStores();
    const vm = useSchedulePageVM();

    const { programsVM, sessionsVM } = scheduleVM;

    const hasSelectedItems =
      programsVM.selected.length > 0 || sessionsVM.selected.length > 0;

    const ref = useRef<HTMLDivElement>(null);

    return (
      <div ref={ref}>
        <ParticipantSelect
          className={"mt-5"}
          selectedParticipants={students}
          actions={
            vm.participantSelection.isSelectedFunboxCustomerAsParticipant.get()
              ? undefined
              : {
                  unlink: vm.unlink,
                  remove: () => {
                    if (students.length === 1)
                      vm.participantSelection.removeParticipant(students[0].id);
                  },
                  select: vm.participantSelection.toSelectParticipants,
                }
          }
        />
        <div className={cn("pb-[80px] pt-6")}>
          {vm.isConfirmed ? (
            <div className="rounded-lg bg-card-color shadow-big">
              {programsVM.selected.map((program) => (
                <ProgramScheduleTable
                  key={program.id}
                  program={program}
                  toggleProgram={programsVM.toggle}
                />
              ))}
              {sessionsVM.selectedSessionsByProgram.map((program) => (
                <SessionsScheduleTable
                  key={program.id}
                  program={program}
                  toggleSession={sessionsVM.toggle}
                />
              ))}
              {!hasSelectedItems && (
                <div
                  className={classNames("text-h3 p-4 text-placeholder-color")}
                >
                  Nothing Selected
                </div>
              )}
              <div
                className={
                  "border-0 border-t-[1px] border-solid  border-separator-color p-4 "
                }
              >
                <Button
                  className="mx-auto "
                  kind={"text"}
                  onClick={() => {
                    vm.selectSchedule(scheduleVM);
                    if (!vm.isLinked) {
                      setTimeout(() => {
                        ref.current?.scrollIntoView({
                          behavior: "smooth",
                          block: "start",
                        });
                      }, 400);
                    }
                  }}
                >
                  {hasSelectedItems ? "Change Dates" : "Select Dates"}
                </Button>
              </div>
            </div>
          ) : (
            <div
              className={classNames(
                !funboxStore.selectedLocation && "!min-h-[112px]"
              )}
            >
              {!funboxStore.selectedLocation ? (
                <InfoMessage className="px-4 py-8">
                  Select Location to View Available Dates
                </InfoMessage>
              ) : (
                <>
                  <ProgramSessionSwitch
                    className={classNames(
                      "mb-4 !w-full !min-w-[327px]",
                      selectedFunbox.mode !== "PROGRAMS_AND_SESSIONS" &&
                        "!hidden"
                    )}
                    sessionsName={selectedFunbox.sessions_option_name}
                    programsName={selectedFunbox.programs_option_name}
                    firstOption={selectedFunbox.both_mode_first_option}
                    selectedOption={vm.selectedTab}
                    show={selectedFunbox.mode}
                    onClick={vm.selectTab}
                  />
                  {vm.selectedTab === "programs" ? (
                    <SelectProgram
                      applyToProgram={(program) =>
                        vm.applyToProgram(
                          program.id,
                          students.map((p) => p.id)
                        )
                      }
                      joinWaitlist={(program) =>
                        vm.joinWaitlist(
                          program.id,
                          students.map((p) => p.id)
                        )
                      }
                      vm={programsVM}
                      showDropIns
                      showApplications
                      onDropInClick={({ id, date }) => {
                        vm.selectTab("sessions");
                        scheduleVM.sessionsVM.focusedDate = date;
                        scheduleVM.sessionsVM.setToHighLight(id, false);
                      }}
                      getSelectedUnlinkedParticipantsAmount={
                        vm.getSelectedUnlinkedParticipantsAmountForProgram
                      }
                      selectedParticipants={students}
                      selectedSessions={sessionsVM.selected}
                    />
                  ) : (
                    <SelectSession
                      vm={sessionsVM}
                      selectedParticipants={students}
                      selectedPrograms={programsVM.selected}
                      getSelectedUnlinkedParticipantsAmount={
                        vm.getSelectedUnlinkedParticipantsAmountForSession
                      }
                    />
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
);

//Select Participants Page

const SelectParticipantsPage: React.FC = observer(() => {
  const { customerStore, routerStore } = useStores();

  const vm = useSchedulePageVM();

  const participantSelectionVM = vm.participantSelection;
  const addParticipant = () =>
    routerStore.navigate(ROUTES.SCHEDULE_ADD_PARTICIPANT);
  const isOnlyOneStudent =
    customerStore.studentsWithoutCustomerAsParticipant.length === 1;

  const [selectedStudents, setSelectedStudents] = React.useState(
    isOnlyOneStudent
      ? customerStore.studentsWithoutCustomerAsParticipant
      : participantSelectionVM.selectedParticipants
  );

  const onStudentSelect = (student: IStudentDTO) => {
    const index = selectedStudents.findIndex((s) => s.id === student.id);

    if (index === -1) {
      setSelectedStudents([...selectedStudents, student]);
    } else {
      setSelectedStudents([
        ...selectedStudents.slice(0, index),
        ...selectedStudents.slice(index + 1),
      ]);
    }
  };

  return (
    <PageRoot className={styles.participants}>
      <PageHeader
        showBackLink={true}
        onBackLinkClick={vm.participantSelection.goBack}
      >
        Select Participants
      </PageHeader>
      {customerStore.studentsWithoutCustomerAsParticipant.length === 0 ? (
        <div className="typography-main text-gray-text-color">
          Please add at least one participant to begin scheduling.
        </div>
      ) : (
        <div>
          {customerStore.studentsWithoutCustomerAsParticipant.length > 1 && (
            <SunAttentionMessage className="mb-5" />
          )}
          {customerStore.studentsWithoutCustomerAsParticipant.map((student) => (
            <Participant
              key={student.id}
              student={student}
              onSelect={onStudentSelect}
              isSelected={selectedStudents.some((s) => s.id === student.id)}
            />
          ))}
        </div>
      )}
      <Button
        className="group !justify-start !p-4"
        kind={"text"}
        onClick={addParticipant}
      >
        <div
          className={cn(
            "box-border flex h-[48px] w-[48px] items-center justify-center rounded-[50%] border-[2px] border-solid border-main-color "
          )}
        >
          <i
            className={twMerge(
              "icon add-participant2-icon  !h-[26px] !w-[29px] bg-main-color"
            )}
          />
        </div>
        <span className="ml-4"> Add New Participant</span>
      </Button>

      <SizedBox height={20} />
      <Button
        className="mb-3 mt-auto w-full"
        size={"big"}
        disabled={!selectedStudents.length}
        onClick={() =>
          vm.participantSelection.selectParticipants(selectedStudents)
        }
      >
        Confirm Selected
      </Button>
    </PageRoot>
  );
});

const SunAttentionMessage = ({ className }: { className?: string }) => {
  const [isVisible, setIsVisible] = useState(true);
  const closeCount = localStorage.getItem(
    "select_participants_help_message_close_count"
  );

  if (closeCount && +closeCount >= 2) return null;

  const closeHandler = () => {
    if (closeCount == null) {
      localStorage.setItem("select_participants_help_message_close_count", "1");
    } else
      localStorage.setItem(
        "select_participants_help_message_close_count",
        (+closeCount + 1).toString()
      );
    setIsVisible(false);
  };

  return (
    <ExpandableRow
      isOpen={isVisible}
      children={<div></div>}
      expand={
        <div
          className={twMerge(
            " box-border flex  gap-4 rounded-[10px] border-[1.7px] border-solid border-main-color bg-on-main-color px-4 py-3 shadow-big ",
            className,
            !isVisible && "opacity-0"
          )}
        >
          <div className="flex flex-col justify-between gap-2">
            <i className="icon sun-attention-icon" />
            <Button
              onClick={closeHandler}
              className="!typography-label"
              kind="text"
            >
              Close
            </Button>
          </div>
          <div className="typography-small__t text-gray-text-color">
            You can register multiple participants simultaneously – even if they
            have different Schedules (Just click Unlink)
          </div>
        </div>
      }
    />
  );
};

interface IProps {
  student: IStudentDTO;
  onSelect: (s: IStudentDTO) => void;
  isSelected: boolean;
}

const Participant: React.FC<IProps> = ({ student, onSelect, isSelected }) => {
  return (
    <div className={styles.participant} onClick={() => onSelect(student)}>
      <div>
        <Avatar person={student} avatarSize={"48px"} />
        <div className={styles.info}>
          <div className={cn(styles.name, "text-label")}>
            {student.full_name}
          </div>
          <div className={cn(styles.age, "text-small")}>
            {getAge(student.birth_date)} yrs
          </div>
        </div>
      </div>
      <div onClick={(e) => e.stopPropagation()}>
        <Checkbox checked={isSelected} onChange={() => onSelect(student)} />
      </div>
    </div>
  );
};

export default SchedulePage;
