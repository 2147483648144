import React from "react";
import style from "./CancelOrReschedulePage.module.scss";
import { observer } from "mobx-react-lite";
import {
  CancelOrRescheduleVMProvider,
  useCancelOrReschedulePageVM,
} from "./CancelOrRescheduleVM";
import { Button, Splash } from "@sizdevteam1/funjoiner-uikit";
import {
  CancelOrRescheduleFrom,
  CancelOrReschedulePageRoot,
  ExpiredMsg,
  StudentTile,
  SubmitButton,
} from "./common";
import useStores from "src/hooks/useStores";
import { ROUTES } from "src/stores/RouterStore";
import { defineRoute } from "src/util/typedRouting";
import { TAttendanceDTO } from "src/services/api";
import { Redirect } from "react-router-dom";
import { rescheduleRoute } from "./SelfReschedulePage";
import classNames from "classnames";
import { useAsync } from "react-async-hook";

export const cancelCreditRoute = defineRoute<{
  state: {
    attendance: TAttendanceDTO;
  };
}>({
  path: "/cancel_credit",
  build: (path) => path,
});

const CancelCreditPage: React.FC = () => {
  const { state } = cancelCreditRoute.useParams();
  const attendance = state?.attendance;
  const { funboxStore } = useStores();
  const { loading, result } = useAsync(
    () => funboxStore.getFunboxByIdOrUrlPart({ id: attendance?.funbox_id }),
    [attendance?.id]
  );
  if (!attendance) {
    return <Redirect to={ROUTES.DASHBOARD} />;
  }

  return loading ? (
    <Splash pathToAnimation={"/splash.json"} />
  ) : !result ? (
    <Redirect to={ROUTES.DASHBOARD} />
  ) : (
    <CancelOrRescheduleVMProvider
      attendance={attendance}
      attendanceFunbox={result}
    >
      <CancelCreditPageImpl />
    </CancelOrRescheduleVMProvider>
  );
};
const CancelCreditPageImpl: React.FC = observer(() => {
  const vm = useCancelOrReschedulePageVM();
  const { routerStore } = useStores();

  const canCancel = vm.from.isCancelAllowed;
  return (
    <CancelOrReschedulePageRoot
      header={"Cancel Reservation"}
      footer={
        <div
          className={classNames(
            canCancel &&
              "border-0 border-t-[1px] border-solid border-separator-color"
          )}
        >
          {canCancel && (
            <Button
              onClick={() =>
                routerStore.navigateToRoute(
                  rescheduleRoute.build({
                    state: {
                      attendance: vm.from.attendance,
                    },
                  })
                )
              }
              className={style.rescheduleInsteadButton}
              kind="text"
            >
              Reschedule Instead
            </Button>
          )}
          <SubmitButton disabled={!canCancel} onSubmit={vm.cancel}>
            Cancel Reservation
          </SubmitButton>
        </div>
      }
    >
      <CancellationLayout />
    </CancelOrReschedulePageRoot>
  );
});

const CancellationLayout = observer(() => {
  const vm = useCancelOrReschedulePageVM();
  const { isCancelAllowed } = vm.from;

  return (
    <div className={style.layout}>
      <StudentTile />
      <span className="text-main">
        The schedule below will be permanently deleted for the reservation if
        you proceed to cancel this Session.
      </span>
      <CancelOrRescheduleFrom />
      {isCancelAllowed ? (
        <span
          className="text-main"
          style={{
            color: "var(--icon-grey-color)",
          }}
        >
          After canceling, the Credit will be available for future bookings.
        </span>
      ) : (
        <ExpiredMsg />
      )}
    </div>
  );
});

export default CancelCreditPage;
