import React from "react";
import { observer } from "mobx-react-lite";
import cn from "classnames";
import PurchaseCreditsSection from "./components/PurchaseCreditsSection";
import ExistingCreditsSection from "./components/ExistingCreditsSection";
import styles from "./Checkout.module.scss";
import SizedBox from "src/components/SizedBox";
import PageRoot from "../../../components/PageRoot";
import Border from "../../../components/Border";
import { useCheckoutAndPaymentPageVM } from "../Checkout&PaymentVM";
import { Button, isMobile, PriceWithCents } from "@sizdevteam1/funjoiner-uikit";
import useStores from "../../../hooks/useStores";
import { ROUTES } from "../../../stores/RouterStore";
import { Redirect } from "react-router-dom";
import PageHeader from "../../../components/PageHeader";
import Policies from "../../../components/Policies";
import { twMerge } from "tailwind-merge";
import classNames from "classnames";
import dayjs from "dayjs";

interface IProps {}

const Checkout: React.FC<IProps> = observer(() => {
  const { paymentStore } = useStores();
  const vm = useCheckoutAndPaymentPageVM();

  return paymentStore.incompleteOrder == null ? (
    <Redirect to={ROUTES.DASHBOARD} />
  ) : (
    <>
      <PageRoot className={styles.checkout}>
        <PageHeader showBackLink>Checkout</PageHeader>
        {!!vm.creditsToUse.length && (
          <div className={cn("text-main", styles.desc)}>
            It appears you need more available credits to complete your
            registration. Please complete the payment below.
          </div>
        )}
        <ExistingCreditsSection creditsToUse={vm.creditsToUse} />
        <PurchaseCreditsSection order={vm.order} />
        <Border />
        <SizedBox height={24} />
        {vm.selectedConfirmedAvailablePaymentPlan && <PaymentPlanSection />}
      </PageRoot>
      <div
        className={cn(styles.footer, {
          [styles.static]: vm.isPromoInputFocused && isMobile(),
        })}
      >
        <Policies />
        <div className={twMerge("mx-auto flex w-[366px] gap-3")}>
          {vm.availablePaymentPlans.length > 0 &&
            !vm.selectedConfirmedAvailablePaymentPlan && (
              <Button
                className="!w-full"
                size={"big"}
                kind="secondary"
                onClick={vm.navigateToPaymentPlans}
              >
                Payment Plans
              </Button>
            )}
          <Button
            className="!w-full"
            size={"big"}
            onClick={vm.proceedToPayment}
            loading={paymentStore.isModifyingOrder}
          >
            {vm.selectedConfirmedAvailablePaymentPlan
              ? "Proceed To Payment"
              : "Pay Now"}
          </Button>
        </div>
      </div>
    </>
  );
});

const PaymentPlanSection = observer(() => {
  const vm = useCheckoutAndPaymentPageVM();
  if (
    vm.order.payment_plan?.payment_plan_id !==
      vm.selectedConfirmedAvailablePaymentPlan?.payment_plan.id ||
    !vm.order.payment_plan
  )
    return null;

  const plan = vm.order.payment_plan;
  const planName = vm.availablePaymentPlans.find(
    (p) => p.payment_plan.id === plan.payment_plan_id
  )?.payment_plan.name;

  return (
    <div className="mb-5 flex flex-col gap-4">
      <div className="typography-h2 flex justify-between text-text-color">
        <span className="whitespace-nowrap">Payment Plan</span>
        <Button
          onClick={vm.navigateToPaymentPlans}
          className="!w-fit"
          kind="text"
        >
          Change
        </Button>
      </div>
      <div className="flex flex-col overflow-hidden rounded-lg bg-on-main-color shadow-big">
        <div className="typography-h3 p-4 capitalize text-text-color">
          {planName}
        </div>
        {plan.installments.map((i) => (
          <div
            key={i.id}
            className={classNames(
              "typography-main flex justify-between px-4 py-2",
              i.ordinal_number === 1
                ? "bg-highlight-color text-text-color"
                : "text-gray-text-color odd:bg-on-main-color even:bg-table-row-color"
            )}
          >
            <span>
              {i.ordinal_number === 1
                ? "Due Now"
                : `${dayjs(i.due_date).format("MMM Do")}`}
            </span>
            <div className="flex items-center">
              <PriceWithCents
                amount={i.initial_amount}
                prefix="$"
                typography="typography-main_sb"
              />
              {i.ordinal_number === 1 &&
                (vm.orderOwnFeesTotalAmount > 0 ||
                  vm.order.payment_plan?.setup_fee?.flat_amount) && (
                  <div className="flex items-center">
                    &nbsp;+&nbsp;
                    <PriceWithCents
                      amount={
                        vm.orderOwnFeesTotalAmount +
                        (vm.order.payment_plan?.setup_fee?.flat_amount || 0)
                      }
                      prefix="$"
                      typography="typography-main_sb"
                    />
                    <span className="typography-small text-gray-text-color">
                      (fee)
                    </span>
                  </div>
                )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
});

export default Checkout;
