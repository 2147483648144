import notificator from "../services/systemNotifications/notificationCenterService";
import * as Sentry from "@sentry/react";
export const withNotification = async <T>(
  fn: () => Promise<T>,
  successMessage?: string
) => {
  try {
    const result = await fn();
    if (successMessage != null) {
      notificator.success("Success!", successMessage);
    }
    return result;
  } catch (e) {
    notificator.error("Error!", e);
    Sentry.captureException(e);
    throw e;
  }
};
