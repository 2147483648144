import React from "react";
import { IAvailableDaycampSession } from "src/services/api/availability";
import dayjs from "dayjs";
import formatStartEndTime from "../../../../../util/formatStartEndTime";

interface IProps {
  program: {
    id: string;
    sessions: IAvailableDaycampSession[];
  };
  toggleSession: (session: IAvailableDaycampSession) => void;
}

const SessionsScheduleTable: React.FC<IProps> = ({
  program,
  toggleSession,
}) => {
  return (
    <div className="flex flex-col">
      <div className="typography-h3 p-4">
        {program.sessions[0].program_type.name}
      </div>
      {program.sessions.map((s) => (
        <SessionCell key={s.id} session={s} toggleSession={toggleSession} />
      ))}
    </div>
  );
};

interface ISessionCellProps {
  session: IAvailableDaycampSession;
  toggleSession: (session: IAvailableDaycampSession) => void;
}

const SessionCell: React.FC<ISessionCellProps> = ({
  session,
  toggleSession,
}) => {
  return (
    <div className="typography-main flex items-center justify-between px-4 py-2 even:bg-table-row-color">
      <div>{dayjs(session.date).format("MMM Do")}</div>
      <div className="text-gray flex items-center gap-4">
        <div>{formatStartEndTime(session)}</div>
        <i
          className={"icon close-icon icon-hover"}
          onClick={() => toggleSession(session)}
        />
      </div>
    </div>
  );
};

export default SessionsScheduleTable;
