import React, { useMemo, useEffect } from "react";
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import useVM from "src/hooks/useVM";
import * as Sentry from "@sentry/react";
import { validateEmail } from "src/util/validators";
import api, { IStudentDTO, TAttendanceDTO } from "src/services/api";
import useStores from "src/hooks/useStores";
import { RouterStore } from "src/stores";
import { scheduleAndPaySuccessRoute } from "./ScheduleAndPaySuccessPage";
import notificator from "src/services/systemNotifications/notificationCenterService";
import { scheduleSuccessRoute } from "./ScheduleSuccessPage";
import { CustomerStore } from "../../stores";
import setDefault from "../../util/setDefault";
import { ROUTES } from "../../stores/RouterStore";
import { ISOString, TimeString } from "@sizdevteam1/funjoiner-uikit/types";
import { findById } from "@sizdevteam1/funjoiner-uikit";
export type IBookingResult = {
  attendanceIds: string[];
};
export type ISchedule = IDaycampSchedule | IOvernightSchedule;

export type IOvernightScheduleProgram = {
  programTypeName: string;
  startDate: ISOString;
  endDate: ISOString;
};
export type IOvernightSchedule = {
  type: "overnight";
  student: IStudentDTO;
  scheduleSetName: string;
  programs: IOvernightScheduleProgram[];
};

export type IDaycampScheduleSession = {
  date: ISOString;
  start_time: TimeString;
  end_time: TimeString;
};

export type IDaycampSchedule = {
  type: "daycamp";
  student: IStudentDTO;
  scheduleSetName: string;
  programTypeName: string;
  sessions: IDaycampScheduleSession[];
};
export class ScheduleSharingPageVM {
  constructor(
    private bookingResult: IBookingResult,
    private routeFrom: string,
    private routerStore: RouterStore,
    private customerStore: CustomerStore
  ) {
    makeObservable(this);
    this.init();
  }

  @observable
  loading = false;

  @observable
  isSendingEmails = false;

  @observable
  hasInitializationError = false;

  @observable
  attendances: TAttendanceDTO[] = [];

  @observable private _includeSchedule: boolean = true;

  @computed
  get includeSchedule(): boolean {
    return this._includeSchedule;
  }

  @action.bound
  setIncludeSchedule(value: boolean) {
    this._includeSchedule = value;
  }
  @computed
  get programTypeNames() {
    const set = new Set(this.attendances.map((a) => a.program_type_name));
    return Array.from(set);
  }

  @computed
  get programTypeNamesString() {
    const lastIndex = this.programTypeNames.length - 1;
    if (lastIndex === 0) return this.programTypeNames[0];

    return (
      this.programTypeNames.slice(0, lastIndex).join(", ") +
      " and " +
      this.programTypeNames[lastIndex]
    );
  }

  @computed
  get scheduleLocationName() {
    return this.attendances[0].location_name;
  }
  @computed
  get schedules(): Map<number, ISchedule[]> {
    const scheduleItemsByStudentId = new Map<number, ISchedule[]>();

    this.attendances.forEach((a) => {
      let scheduleItems = setDefault(scheduleItemsByStudentId, {
        key: a.student_id,
        value: [],
      });

      const student = findById<IStudentDTO>(
        a.student_id,
        this.customerStore.studentsWithCustomerAsParticipant
      );
      const scheduleSetName = a.schedule_set_name;
      const programTypeName = a.program_type_name;

      const scheduleItem = scheduleItems.find(
        (i) =>
          i.student.id === student.id &&
          i.scheduleSetName === scheduleSetName &&
          (i.type === "overnight" || i.programTypeName === programTypeName)
      );

      let items: IDaycampScheduleSession[] | IOvernightScheduleProgram[];
      switch (a.type) {
        case "program":
          items = [
            {
              programTypeName,
              startDate: a.start_date,
              endDate: a.end_date,
            },
          ];

          break;
        case "session_in_program":
          items = [
            {
              date: a.date,
              start_time: a.start_time,
              end_time: a.end_time,
            },
          ];
          break;
        case "program_of_sessions":
          items = a.items.map((element) => ({
            date: element.date,
            start_time: element.start_time,
            end_time: element.end_time,
          }));
          break;
      }

      if (scheduleItem) {
        if (scheduleItem.type === "overnight") {
          scheduleItem.programs = scheduleItem.programs.concat(
            items as IOvernightScheduleProgram[]
          );
        } else {
          scheduleItem.sessions = scheduleItem.sessions.concat(
            items as IDaycampScheduleSession[]
          );
        }
      } else {
        if (a.type === "program") {
          scheduleItems.push({
            type: "overnight",
            student,
            scheduleSetName,
            programs: items as IOvernightScheduleProgram[],
          });
        } else {
          scheduleItems.push({
            type: "daycamp",
            student,
            scheduleSetName,
            programTypeName,
            sessions: items as IDaycampScheduleSession[],
          });
        }
      }
    });

    return scheduleItemsByStudentId;
  }

  @observable emailInputs: { value: string; errorText: string }[] = [
    { value: "", errorText: "" },
  ];

  @action.bound
  onChangeValue(value: string, index: number) {
    this.emailInputs[index].value = value;
    this.emailInputs[index].errorText = "";
  }

  @action.bound
  validate() {
    const notPassedValidationFields = this.emailInputs.filter(
      (i) => !validateEmail(i.value)
    );
    if (notPassedValidationFields.length === 0) return true;
    else {
      this.emailInputs = this.emailInputs.map((e) =>
        notPassedValidationFields.includes(e)
          ? { ...e, errorText: "Email not Correct" }
          : e
      );
      return false;
    }
  }

  @action.bound
  addNewEmailField() {
    this.emailInputs = [...this.emailInputs, { value: "", errorText: "" }];
  }

  @action.bound
  deleteEmailFieldByIndex(index: number) {
    this.emailInputs.splice(index, 1);
  }

  @computed
  get emailInputValues() {
    return this.emailInputs.map((ei) => ei.value);
  }

  @computed
  get isSingleEmailInput() {
    return this.emailInputs.length === 1;
  }

  @action.bound
  async sendScheduleShareEmails() {
    if (!this.validate()) return;
    this.isSendingEmails = true;
    try {
      await api.attendances.shareSchedule({
        recipients: this.emailInputValues,
        attendance_ids: this.bookingResult.attendanceIds,
        include_schedule: this.includeSchedule,
      });
      if (this.routeFrom === ROUTES.DASHBOARD) {
        notificator.success("Shared", "Emails Sent");
        this.routerStore.navigate(ROUTES.DASHBOARD);
      } else {
        this.routerStore.navigateToRoute(
          this.successBackRoute.build({
            state: {
              bookingResult: this.bookingResult,
              emailsSent: true,
            },
          })
        );
      }
    } catch (e) {
      Sentry.captureException(e);
      notificator.error("Failed to Send Emails", e);
    } finally {
      this.isSendingEmails = false;
    }
  }

  private _disposers = Array<() => void>();
  dispose = () => {
    this._disposers.forEach((d) => d());
  };

  @action.bound
  private async init() {
    this.loading = true;
    try {
      const attendances = await api.attendances.getAttendancesByIds(
        this.bookingResult.attendanceIds
      );
      runInAction(() => (this.attendances = attendances));
    } catch (e) {
      Sentry.captureException(e);
      runInAction(() => (this.hasInitializationError = true));
    } finally {
      runInAction(() => (this.loading = false));
    }
  }

  private get successBackRoute() {
    if (this.routeFrom === scheduleAndPaySuccessRoute.path)
      return scheduleAndPaySuccessRoute;
    else return scheduleSuccessRoute;
  }
}

const ctx = React.createContext<ScheduleSharingPageVM | null>(null);

export const ScheduleSharingVMProvider: React.FC<{
  bookingResult: IBookingResult;
  routeFrom: string;
}> = ({ children, bookingResult, routeFrom }) => {
  const { routerStore, customerStore } = useStores();
  const vm = useMemo(
    () =>
      new ScheduleSharingPageVM(
        bookingResult,
        routeFrom,
        routerStore,
        customerStore
      ),
    [bookingResult, routeFrom, routerStore, customerStore]
  );
  useEffect(() => () => vm.dispose());
  return <ctx.Provider value={vm}>{children}</ctx.Provider>;
};

export const useScheduleSharingPageVM = () => useVM(ctx);
