import { computed, makeObservable } from "mobx";
import { computedFn } from "mobx-utils";
import React, { useMemo } from "react";
import useStores from "src/hooks/useStores";
import useVM from "src/hooks/useVM";
import { CustomerStore } from "src/stores";
import { SmartFormUtils } from "../../util/questionSetUtils";

export class SmartFormsCompletenessVm {
  constructor(private customerStore: CustomerStore) {
    makeObservable(this);
  }

  @computed
  get totalCompletenessPercentage(): number | undefined {
    const students = this.customerStore.studentsWithQuestions;
    if (students.length === 0) return undefined;

    let sum = 0;
    for (const student of students) {
      const percentage = this.completenessPercentageFor(student.id);
      if (percentage == null) {
        continue;
      }
      sum += percentage;
    }

    return sum / students.length;
  }

  completenessPercentageFor = computedFn(
    (studentId: number): number | undefined => {
      const smartForms = this.customerStore.smartForms.filter(
        (form) => form.student_id === studentId
      );

      if (smartForms.length === 0) {
        return undefined;
      }

      const incompleteAmount =
        SmartFormUtils.filterIncomplete(smartForms).length;
      const totalAmount =
        incompleteAmount + SmartFormUtils.filterComplete(smartForms).length;

      if (totalAmount === 0) {
        return 100;
      }

      return 100 - (incompleteAmount / totalAmount) * 100;
    }
  );

  dispose = () => {
    //TODO
  };
}

const ctx = React.createContext<SmartFormsCompletenessVm | null>(null);

export const SmartFormsCompletenessVmProvider: React.FC = ({ children }) => {
  const { customerStore } = useStores();
  const vm = useMemo(
    () => new SmartFormsCompletenessVm(customerStore),
    [customerStore]
  );

  React.useEffect(() => () => vm.dispose(), [vm]);

  return <ctx.Provider value={vm}>{children}</ctx.Provider>;
};

export const useSmartFormsCompletenessVm = () => useVM(ctx);
