import { defineRoute } from "../../util/typedRouting";
import { SmartFormDTO } from "../../services/api/smartForms";
import useStores from "../../hooks/useStores";
import { useEffect } from "react";
import { ROUTES } from "../../stores/RouterStore";
import { QuestionPipelinePage } from "../QuestionPipelinePage/QuestionPipelinePage";
import { startIndexForQuestionSet } from "../../util/questionSetUtils";
import api from "../../services/api";

export const smartFormPipelinePageRoute = defineRoute<{
  state: {
    description?: string;
    smartForms: SmartFormDTO[];
  };
}>({
  path: "/application-smart-forms/",
  build: (path) => path,
});

const DEFAULT_DESCRIPTION =
  "The following forms are required. Please fill them out before attendance.";
export const SmartFormPipelinePage = () => {
  const { routerStore } = useStores();
  const params = smartFormPipelinePageRoute.useParams();

  useEffect(() => {
    if (params.state?.smartForms == null) {
      routerStore.returnToSourcePage(ROUTES.SCHEDULE);
    }
  }, []);

  const forms = params.state?.smartForms!;
  const description = params.state?.description ?? DEFAULT_DESCRIPTION;

  return (
    <QuestionPipelinePage
      description={description}
      delegate={{
        fetchSmartForms: () => forms!,
        onFinish: () => routerStore.returnToSourcePage(ROUTES.SCHEDULE),
        startIndexFor: startIndexForQuestionSet,
        uploadFile: async (file: File) => {
          return await api.files.uploadFile(file, "END_USER");
        },
      }}
    />
  );
};
