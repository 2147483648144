import RootStore from "../stores/RootStore";
import { action, makeObservable, observable, runInAction } from "mobx";
import {
  loadStripe,
  PaymentRequest,
  Stripe,
  StripeCardElement,
  StripeCardNumberElement,
} from "@stripe/stripe-js";
import { STRIPE_PK } from "../config";
import api, { ICardDTO, IOrderDTO } from "src/services/api";
import cardFromStripePaymentMethod from "../util/cardFromStripePaymentMethod";
import {
  IAvailableOrderPaymentPlan,
  IScheduleAndPayOrderDTO,
  isScheduleAndPayOrder,
} from "../services/api/orders";
import notificator from "src/services/systemNotifications/notificationCenterService";
import * as Sentry from "@sentry/react";
import { delay } from "@sizdevteam1/funjoiner-uikit";

export default class PaymentStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  private stripePromiseResolver: (v: Stripe | null) => void = () => {};

  stripePromise = new Promise<Stripe | null>(
    (resolve) => (this.stripePromiseResolver = resolve)
  );

  @observable
  incompleteOrder: IOrderDTO | IScheduleAndPayOrderDTO | null = null;

  @observable
  isModifyingOrder = false;

  @action
  setIncompleteOrder = (order: IOrderDTO | IScheduleAndPayOrderDTO | null) => {
    this.incompleteOrder = order;
  };

  @action.bound resetAvailablePaymentPlans() {
    this.availablePaymentPlans = [];
  }

  @observable
  completedOrder: {
    order: IOrderDTO | IScheduleAndPayOrderDTO;
    card: ICardDTO;
  } | null = null;

  @action
  saveCompletedOrder(order: IOrderDTO, card: ICardDTO) {
    this.completedOrder = { order, card };
  }

  @observable
  initialized = false;

  createPaymentMethod = async (
    elementsCard: StripeCardElement | StripeCardNumberElement
  ) => {
    const stripe = await this.stripePromise;
    if (!stripe) throw new Error("Stripe not initialized");
    const result = await stripe.createPaymentMethod({
      type: "card",
      card: elementsCard,
    });

    if (!result.paymentMethod) {
      console.error("[error]", result.error);
      throw new Error(result.error.message);
    }

    return cardFromStripePaymentMethod(result.paymentMethod);
  };

  confirmPayment = async (
    client_secret: string,
    payment_method_id: string,
    save_payment_method: boolean
  ) => {
    const stripe = await this.stripePromise;
    if (!stripe) throw new Error("Stripe not initialized");
    const { error } = await stripe.confirmCardPayment(client_secret, {
      payment_method: payment_method_id,
      setup_future_usage: save_payment_method ? "off_session" : undefined,
    });
    if (error) throw new Error(error.message);
  };

  createPaymentRequest = async (
    description: string,
    amount: number,
    pending: boolean
  ): Promise<PaymentRequest> => {
    const stripe = await this.stripePromise;
    if (!stripe) throw new Error("Stripe not initialized");
    return stripe.paymentRequest({
      country: "US",
      currency: "usd",
      total: {
        label: description,
        amount: amount,
        pending: pending,
      },
      disableWallets: ["googlePay", "browserCard", "link"],
    });
  };

  @action.bound
  async confirmSetupIntent(client_secret: string, paymentMethodId: string) {
    const stripe = await this.stripePromise;
    if (!stripe) throw new Error("Stripe not initialized");
    const { error } = await stripe.confirmCardSetup(client_secret, {
      payment_method: paymentMethodId,
    });
    if (error) {
      throw new Error(error.message);
    }
  }

  waitForOrderProceed(orderId: number, timeout: number = 30000) {
    const to = delay(timeout);
    to.then(() => (expired = true));
    let expired = false;
    const promise = (): Promise<IOrderDTO> =>
      api.orders.getOrderById(orderId).then((response) => {
        if (response.status === "completed") {
          to.cancel();
          return response;
        }
        if (expired)
          return Promise.reject(
            new Error(
              "The transaction was successful, " +
                "but the server still hasn't processed it." +
                " Please try to refresh the page in a minute."
            )
          );
        return delay(1000).then((_) => promise());
      });
    return promise();
  }

  waitForOrderInstallmentToProceed(
    orderId: number,
    installment_id: string,
    timeout: number
  ) {
    const to = delay(timeout);
    to.then(() => (expired = true));

    let expired = false;
    const promise = (): Promise<IScheduleAndPayOrderDTO> =>
      api.orders.getOrderById(orderId).then((o) => {
        if (
          o.payment_plan?.installments.find((i) => i.id === installment_id)
            ?.payment?.status === "completed"
        ) {
          to.cancel();
          return o as IScheduleAndPayOrderDTO;
        }
        if (expired)
          return Promise.reject(
            new Error(
              "The transaction was successful, " +
                "but the server still hasn't processed it." +
                " Please try to refresh the page in a minute."
            )
          );
        return delay(1000).then((_) => promise());
      });
    return promise();
  }

  @action.bound async getAvailablePaymentPlans(order_id: number) {
    try {
      const plans = await api.orders.getAvailablePaymentPlans(order_id);
      runInAction(() => {
        this.availablePaymentPlans = plans;
      });
    } catch (e) {
      Sentry.captureException(e);
      notificator.error("Error!", e);
    }
  }

  @action.bound async attachPaymentPlan({
    order_id,
    payment_plan_id,
  }: {
    order_id: number;
    payment_plan_id: string;
  }) {
    try {
      const order = await api.orders.attachPaymentPlan(
        order_id,
        payment_plan_id
      );
      runInAction(() => {
        this.setIncompleteOrder(order);
      });
    } catch (e) {
      Sentry.captureException(e);
      notificator.error("Error!", e);
    }
  }

  @action.bound async detachPaymentPlan({ order_id }: { order_id: number }) {
    try {
      const order = await api.orders.detachPaymentPlan(order_id);
      runInAction(() => {
        this.setIncompleteOrder(order);
      });
    } catch (e) {
      Sentry.captureException(e);
      notificator.error("Error!", e);
    }
  }

  @observable availablePaymentPlans: IAvailableOrderPaymentPlan[] = [];

  @action.bound
  async applyPromocode(promocode: string) {
    if (this.incompleteOrder == null) {
      return;
    }

    try {
      this.isModifyingOrder = true;
      const newOrder = await api.orders.attachPromocode(
        this.incompleteOrder.id,
        promocode
      );
      if (isScheduleAndPayOrder(newOrder)) {
        await this.getAvailablePaymentPlans(newOrder.id);
      }
      this.setIncompleteOrder(newOrder);
    } catch (e) {
      notificator.error("Unavailable Code", e);
      throw e;
    } finally {
      runInAction(() => (this.isModifyingOrder = false));
    }
  }

  @action.bound
  async removePromocode() {
    try {
      if (this.incompleteOrder?.promocode_id == null) {
        return;
      }

      this.isModifyingOrder = true;
      const newOrder = await api.orders.detachPromocode(
        this.incompleteOrder.id
      );
      if (isScheduleAndPayOrder(newOrder)) {
        await this.getAvailablePaymentPlans(newOrder.id);
      }

      runInAction(() => {
        this.incompleteOrder = newOrder;
      });
    } catch (e) {
      console.log(e);
      notificator.error("Error!", e);
      throw e;
    } finally {
      runInAction(() => (this.isModifyingOrder = false));
    }
  }

  @action.bound
  async changePaymentTypeToInvoice(orderId: number) {
    try {
      this.isModifyingOrder = true;

      const order = await api.orders.changePaymentTypeToInvoice(orderId);
      runInAction(() => {
        this.incompleteOrder = order;
      });

      return order;
    } finally {
      runInAction(() => (this.isModifyingOrder = false));
    }
  }

  @action
  init = async () => {
    const stripeAccount = await api.integrations.stripeAccount();
    this.stripePromiseResolver(
      stripeAccount == null
        ? null
        : stripeAccount.type === "connect"
        ? await loadStripe(STRIPE_PK, {
            stripeAccount: stripeAccount.account_stripe_id,
          })
        : await loadStripe(stripeAccount.public_key)
    );

    runInAction(() => {
      this.initialized = true;
    });
  };
}
