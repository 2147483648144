import React, { useState } from "react";
import { IFunboxDTO, TAttendanceDTO } from "src/services/api";
import { observer } from "mobx-react-lite";
import useStores from "src/hooks/useStores";
import cn from "classnames";
import { Loader, MarkdownText, Modal } from "@sizdevteam1/funjoiner-uikit";
import { useDashboardPageVM } from "../../DashboardPageVM";
import InfiniteScroll from "react-infinite-scroller";
import { rescheduleRoute } from "src/pages/SelfRechedulePage/SelfReschedulePage";
import { toJS } from "mobx";
import { cancelCreditRoute } from "src/pages/SelfRechedulePage/CancelCreditPage";
import { scheduleSharingRoute } from "../../../SuccessPages/ScheduleSharingPage";
import InfoMessage from "src/components/InfoMessage/InfoMessage";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../../stores/RouterStore";
import ProgramTile from "src/components/AttendanceTile/ProgramTile";
import SessionTile from "src/components/AttendanceTile/SessionTile";
import ProgramOfSessionsTile from "src/components/AttendanceTile/ProgramOfSessionsTile";
import exists from "@sizdevteam1/funjoiner-uikit/util/exists";
import { isPolicyCompliant } from "src/util/isPolicyCompliant";

const YourSchedule: React.FC<{}> = observer(() => {
  const [policyToShow, setPolicyToShow] = useState<string | null>(null);
  const { routerStore, commonStore, funboxStore } = useStores();
  const vm = useDashboardPageVM();
  const {
    getSchedule: { items: attendances },
    waitlistRecords,
  } = vm;
  const hasWaitlistRecords = waitlistRecords.length > 0;
  const hasAttendances = attendances.length > 0;

  const attendanceActions = (attendance: TAttendanceDTO) => {
    const funbox = funboxStore.funboxes.find(
      (f) => f.id === attendance.funbox_id
    );
    const credit = commonStore.creditTypesById[attendance.credit_type_id];

    const policyCheckObject = {
      isProgram:
        attendance.type === "program" ||
        attendance.type === "program_of_sessions",
      start: attendance.start,
    };
    if (!funbox || !credit) return [];

    const isRescheduleAllowed = credit.is_free
      ? isPolicyCompliant(
          policyCheckObject,
          funbox.free_credits_settings.reschedule_policy
        )
      : isPolicyCompliant(
          policyCheckObject,
          funbox.paid_credits_settings.reschedule_policy
        );

    const isCancelAllowed = credit.is_free
      ? isPolicyCompliant(
          policyCheckObject,
          funbox.free_credits_settings.cancel_policy
        )
      : isPolicyCompliant(
          policyCheckObject,
          funbox.paid_credits_settings.cancel_policy
        );

    return [
      isRescheduleAllowed && {
        icon: <i className={"icon change-date-icon !bg-main-color"} />,
        label: "Reschedule",
        onClick: () => {
          routerStore.navigateToRoute(
            rescheduleRoute.build({
              state: { attendance: toJS(attendance) },
            })
          );
        },
      },
      {
        icon: <i className={"icon share-icon !bg-main-color"} />,
        label: "Share this Schedule",
        onClick: () => {
          routerStore.navigateToRoute(
            scheduleSharingRoute.build({
              state: {
                routeFrom: routerStore.currentPath,

                bookingResult: {
                  attendanceIds: [attendance.id],
                },
              },
            })
          );
        },
      },
      isCancelAllowed &&
        !attendance.credit.unpaid_payment_plan && {
          icon: <i className={"icon big-cross-icon !bg-delete-color"} />,
          label: "Cancel Reservation",
          onClick: () => {
            routerStore.navigateToRoute(
              cancelCreditRoute.build({
                state: { attendance: toJS(attendance) },
              })
            );
          },
        },
    ].filter(exists);
  };

  return (
    <div className={"mb-4 flex flex-col gap-4"}>
      {vm.selectedFilter !== "Waitlist" ? (
        <>
          {!hasAttendances ? (
            <div
              className={cn(
                "typography-main my-3 text-center text-gray-text-color"
              )}
            >
              <InfoMessage className="px-6 py-4">
                {vm.selectedStudent?.type === "CUSTOMER_AS_PARTICIPANT"
                  ? "You have"
                  : "Participant has "}{" "}
                no Schedules{" "}
                {vm.selectedFilter === "All" ? "yet" : "in selected Fun Box"}
              </InfoMessage>
            </div>
          ) : (
            <InfiniteScroll
              className="flex flex-col gap-4 "
              hasMore={vm.getSchedule.has_more}
              loadMore={vm.loadNextPage}
            >
              {attendances.map((a) =>
                a.type === "program" ? (
                  <ProgramTile
                    key={a.id}
                    attendance={a}
                    actions={attendanceActions(a)}
                  />
                ) : a.type === "program_of_sessions" ? (
                  <ProgramOfSessionsTile
                    key={a.id}
                    attendance={a}
                    actions={attendanceActions(a)}
                  />
                ) : (
                  <SessionTile
                    key={a.id}
                    attendance={a}
                    actions={attendanceActions(a)}
                  />
                )
              )}
              {vm.isLoadingNextPage && <Loader />}
            </InfiniteScroll>
          )}
        </>
      ) : (
        <>
          <div className={"typography-main text-center text-gray-text-color"}>
            {hasWaitlistRecords ? (
              "Your Waitlist requests are displayed below. These reservations still pending approval"
            ) : (
              <InfoMessage className="px-6 py-4">
                This participant is nоt on any Waitlist
                <Link
                  className={"mx-auto mt-2 block w-fit"}
                  to={ROUTES.AVAILABILITY}
                >
                  Check Schedule Now
                </Link>
              </InfoMessage>
            )}
          </div>
          {waitlistRecords.map((wr) => (
            <ProgramTile
              key={wr.id}
              attendance={wr}
              actions={[
                ...(wr.waitlist_policy
                  ? [
                      {
                        icon: <i className={"icon doc-icon"} />,
                        label: "Waitlist Policy",
                        onClick: () => setPolicyToShow(wr.waitlist_policy),
                      },
                    ]
                  : []),
                {
                  icon: (
                    <i className={"icon big-cross-icon !bg-delete-color"} />
                  ),
                  label: "Leave Waitlist",
                  onClick: () =>
                    vm.leaveWaitlist(
                      wr.program_id,
                      wr.student_id,
                      wr.schedule_set_id
                    ),
                },
              ]}
            />
          ))}
        </>
      )}

      {policyToShow != null && (
        <Modal
          onClose={() => setPolicyToShow(null)}
          headerText={"Waitlist Policy"}
          containerClassName="min-w-[366px] max-w-[414px] w-full"
        >
          <MarkdownText className="typography-main text-text-color">
            {policyToShow}
          </MarkdownText>
        </Modal>
      )}
    </div>
  );
});

export default YourSchedule;
