import cn from "classnames";
import React, { useState } from "react";
import { IProgramOfSessionsAttendanceDTO } from "src/services/api";
import { IAttendanceItem } from "src/services/api/attendances";
import dayjs from "dayjs";
import ProgramTile from "./ProgramTile";
import formatStartEndTime from "../../util/formatStartEndTime";
import classNames from "classnames";

type TDropdownAction = {
  icon: React.ReactNode;
  label: string;
  onClick: () => void;
};

interface IProgramOfSessionsTileProps {
  attendance: IProgramOfSessionsAttendanceDTO;
  actions: TDropdownAction[];
}
const ProgramOfSessionsTile = ({
  attendance,
  actions,
}: IProgramOfSessionsTileProps) => {
  const maxVisibleCollapsedItems = 3;
  const visibleCollapsedItems = attendance.items.slice(
    -maxVisibleCollapsedItems
  );
  const tileHeight = 104;
  const expandTileHeight = 80;
  const gap = 8;
  const height =
    tileHeight + (expandTileHeight + gap) * attendance.items.length;
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div
      className={classNames(
        "transition-height flex flex-col items-center duration-300 ease-in-out"
      )}
      style={{
        height: isExpanded ? height : tileHeight,
        marginBottom: isExpanded ? 0 : gap * visibleCollapsedItems.length,
      }}
    >
      <ProgramTile
        className={"cursor-pointer"}
        attendance={attendance}
        actions={actions}
        onClick={() => setIsExpanded(!isExpanded)}
      />
      {attendance.items.map((s, index) => (
        <ExpandedTileItem
          style={{
            transform: isExpanded
              ? undefined
              : `translateY(-${
                  expandTileHeight * (index + 1) +
                  gap *
                    (visibleCollapsedItems.includes(s)
                      ? // for visible items we leave gaps equal to the index of the item in visible stack
                        index + 1 - (visibleCollapsedItems.indexOf(s) + 1)
                      : index + 1)
                }px) translateX(-6px) scale(${
                  366 / 352 - // this is ratio to make sure that the expand tile is the same width as the ProgramTile
                  0.02 * Math.max(1, visibleCollapsedItems.indexOf(s) + 1) // subtract 2% for each item in the stack. invisible items are also scaled 2% not to mes up with borders
                })`,
            // turn off box shadow for all invisible items
            boxShadow:
              !isExpanded && !visibleCollapsedItems.includes(s)
                ? "none"
                : undefined,
            transition: "all 0.3s ease-in-out",
            zIndex: -index - 1,
          }}
          className={cn("mt-2")}
          key={index}
          session={s}
          programTypeName={attendance.program_type_name}
        />
      ))}
    </div>
  );
};

const ExpandedTileItem = ({
  session,
  programTypeName,
  className,
  style,
}: {
  className?: string;
  session: IAttendanceItem;
  programTypeName: string;
  style?: React.CSSProperties;
}) => {
  const hasEnded = dayjs().isAfter(session.date + "T" + session.end_time);
  return (
    <div
      className={cn(
        "box-border flex w-[352px] items-center gap-4 self-end rounded-[10px]  bg-on-main-color py-3 px-[19px] shadow-big transition-all duration-500",
        hasEnded && "text-gray-text-color",
        className
      )}
      style={style}
    >
      <div
        className={cn(
          "flex h-[56px] w-[56px] flex-col items-center justify-center rounded-full ",
          hasEnded
            ? "bg-gray-surface-color text-gray-text-color"
            : "bg-[#FCE9DE] text-surface-orange-color"
        )}
      >
        <span className={cn("typography-label uppercase")}>
          {dayjs(session.date).format("MMM").toLocaleUpperCase()}
        </span>
        <span className="text-h2">{dayjs(session.date).format("D")}</span>
      </div>
      <div className="flex flex-col gap-[2px]">
        <div className="typography-main_sb w-[240px] overflow-hidden text-ellipsis whitespace-nowrap text-text-color">
          {programTypeName}{" "}
        </div>
        <div className="typography-small text-gray-text-color">
          {formatStartEndTime(session)}
        </div>
      </div>
    </div>
  );
};

export default ProgramOfSessionsTile;
