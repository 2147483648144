import React from "react";
import cn from "classnames";
import { ILocationDTO } from "src/services/api";
import classNames from "classnames";
import { ExpandableRow } from "@sizdevteam1/funjoiner-uikit";
import { twMerge } from "tailwind-merge";

interface IProps {
  defaultExpandedLocationId?: number;
  location: ILocationDTO;
  alwaysExpanded: boolean;
}

const LocationInfo: React.FC<IProps> = ({
  location,
  alwaysExpanded,
  defaultExpandedLocationId,
}) => {
  const [isExpanded, setExpanded] = React.useState(
    location.id === defaultExpandedLocationId
  );

  return (
    <div
      className={twMerge(
        "mb-3 cursor-pointer rounded-[10px] bg-card-color shadow-big",
        alwaysExpanded && "cursor-pointer"
      )}
    >
      <ExpandableRow
        className="w-full"
        setIsOpen={!alwaysExpanded ? setExpanded : undefined}
        isOpen={isExpanded}
        children={
          <div className={classNames("flex p-4 ")}>
            <div className="flex w-full">
              <i
                className={
                  "icon location-color-icon mr-2 h-[30px] w-[30px] bg-main-color"
                }
              />
              <div className={classNames("flex w-full justify-between")}>
                <div
                  className={
                    "typography-h3  flex flex-col gap-1 text-text-color"
                  }
                >
                  <div> {location.name} </div>
                  <div
                    className={cn("typography-small__t text-gray-text-color")}
                  >
                    {location.address}
                  </div>
                </div>
                {!alwaysExpanded && (
                  <i
                    className={classNames(
                      "icon chevron-down-icon transition-all",
                      isExpanded && "rotate-180"
                    )}
                  />
                )}
              </div>
            </div>
          </div>
        }
        expand={
          <div
            className={cn(
              "typography-small h-full cursor-default rounded-b-[10px] bg-table-row-color"
            )}
          >
            <div className="px-4 py-3">
              <label className="text-text-color">Direction & Details</label>
              <div className="mb-3 mt-1  text-placeholder-color">
                {location.description}
              </div>
              {location.directions && (
                <a
                  target={"_blank"}
                  href={location.directions}
                  rel="noreferrer"
                  onClick={(e) => e.stopPropagation()}
                >
                  More Details
                </a>
              )}
            </div>
          </div>
        }
      />
    </div>
  );
};

export default LocationInfo;
