import React from "react";
import { observer } from "mobx-react-lite";
import IconTile from "./IconTile";

interface IProps {
  onClick: () => void;
}

const RequiredQuestionsTile: React.FC<IProps> = observer((props: IProps) => {
  return (
    <IconTile
      onClick={props.onClick}
      title="Required Forms"
      subtitle={
        <>
          Answer the{" "}
          <span className="text-main-color hover:text-dark-main-color">
            required forms
          </span>{" "}
          now
        </>
      }
      iconBgClassName="bg-[#f18a46]"
      iconClassName="smart-forms-blank-icon bg-on-main-color"
    />
  );
});

export default RequiredQuestionsTile;
