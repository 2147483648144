import { isValidPhoneNumber } from "react-phone-number-input";

const validateEmail = (email: string) => {
  let re = new RegExp(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/);
  return re.exec(email) != null;
};

const validateStringDate = (dateString: string) => {
  const regEx = /^\d{4}-\d{2}-\d{2}$/;
  if (!dateString.match(regEx)) return false; // Invalid format
  const d = new Date(dateString);
  const dNum = d.getTime();
  if (!dNum && dNum !== 0) return false; // NaN value, Invalid date
  return d.toISOString().slice(0, 10) === dateString;
};

const validateRequired = (value?: string | null) => {
  return value != null && value.trim() !== "";
};

const validateURL = (str?: string | null) => {
  if (str == null) return false;
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
};

const getNameError = (name: string, title: string = "Name") => {
  if (!validateRequired(name)) return title + " is required";
  if (name.length < 2) return title + " must be at least 2 characters";
  if (name.length > 30) return title + " is too long";
  if (!new RegExp(/^[a-zA-Z- .]+$/gi).test(name))
    return "No digits or special symbols allowed";
  return null;
};

const getPhoneError = (phone: string) => {
  if (!phone || phone === "undefined") return "Phone number is required";
  if (!isValidPhoneNumber(phone)) return "Invalid phone number";
  return null;
};

export {
  validateEmail,
  validateStringDate,
  validateRequired,
  validateURL,
  getNameError,
  getPhoneError,
};
