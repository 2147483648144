import React from "react";
import cn from "classnames";
import { observer } from "mobx-react-lite";
import useStores from "src/hooks/useStores";
import PageRoot from "src/components/PageRoot";
import SizedBox from "src/components/SizedBox";
import AttentionMessage from "src/components/AttentionMessage";
import DirectionsBlock from "src/components/DirectionsBlock";
import styles from "./HelpPage.module.scss";
import PageHeader from "../../components/PageHeader";
import formatPhone from "../../util/formatPhone";

interface IProps {}

const HelpPage: React.FC<IProps> = observer(() => {
  const {
    commonStore: { companyProfile, publicSettings },
  } = useStores();
  return (
    <PageRoot className={styles.rules}>
      <PageHeader>Support</PageHeader>
      <p className={cn("text-main text-gray", styles.info)}>
        If you need any information, please feel free to visit our{" "}
        <a href={companyProfile?.website} target={"_blank"} rel="noreferrer">
          website
        </a>
        , or you can reach us through the contact info below.
      </p>
      <div className={styles.contacts}>
        {companyProfile?.phone && (
          <div className={styles.contact}>
            <i className={"icon mobile-icon"} />
            <div className={"text-main "}>
              <a href={`tel:${companyProfile.phone}`}>
                {formatPhone(companyProfile.phone)}
              </a>
            </div>
          </div>
        )}
        {companyProfile?.email && (
          <div className={styles.contact}>
            <i className={"icon emails-icon"} />
            <div className={"text-main "}>
              <a href={`mailto:${companyProfile.email}`}>
                {companyProfile.email}
              </a>
            </div>
          </div>
        )}
      </div>
      <SizedBox height={24} />
      <DirectionsBlock />
      <AttentionMessage
        text={publicSettings.attention_message.text}
        enabled={publicSettings.attention_message.enabled}
        color="#F55050"
      />
    </PageRoot>
  );
});

export default HelpPage;
