import React from "react";
import cn from "classnames";
import { IBuyCreditRow, IUsedCreditRow } from "src/services/api/credits";
import { CreditIcon } from "@sizdevteam1/funjoiner-uikit";
import classNames from "classnames";

interface ICreditsToBuyProps {
  itemsToBuy: IBuyCreditRow[];
  itemsToSchedule: IUsedCreditRow[];
}
const CreditsToBuyOrSchedule: React.FC<ICreditsToBuyProps> = ({
  itemsToSchedule,
  itemsToBuy,
}) => {
  const hasItemsToSchedule = itemsToSchedule.length > 0;
  const hasItemsToBuy = itemsToBuy.length > 0;
  return (
    <div className="flex flex-col gap-2">
      {hasItemsToSchedule && (
        <div className="flex flex-col gap-1">
          <div className={cn("typography-label text-gray-text-color")}>
            Existing Credits
          </div>
          {itemsToSchedule.map((item) => (
            <CreditRow key={item.credit_type_id} item={item} />
          ))}
        </div>
      )}
      {hasItemsToBuy && (
        <div className="flex flex-col gap-1">
          <div className={cn("typography-label  text-gray-text-color")}>
            Purchase Credits
          </div>
          {itemsToBuy.map((item) => (
            <CreditRow key={item.credit_type_id} item={item} />
          ))}
        </div>
      )}
    </div>
  );
};

const CreditRow = ({ item }: { item: IBuyCreditRow | IUsedCreditRow }) => {
  return (
    <div className={classNames("flex items-center text-text-color")}>
      <CreditIcon
        isFree={item.is_free}
        isProgram={item.is_program_credit}
        size={24}
      />
      <div className={"text-h4"}>
        ×&nbsp;{item.quantity}
        {item.total != null && (
          <span className="text-placeholder-color">/{item.total}</span>
        )}{" "}
        <span className="ml-[10px]">{item.name}</span>
      </div>
    </div>
  );
};
export default CreditsToBuyOrSchedule;
