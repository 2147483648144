import React from "react";
import cn from "classnames";
import MultiAvatar from "src/components/MultiAvatar";
import { Button, Modal } from "@sizdevteam1/funjoiner-uikit";
import { TStudentOrCreateIntention } from "src/stores/ScheduleStore/ParticipantSelectionVM";
import styles from "./ParticipantSelect.module.scss";
import classNames from "classnames";

interface IProps {
  className?: string;
  selectedParticipants: Array<TStudentOrCreateIntention>;
  actions?: {
    select: () => void;
    remove: () => void;
    unlink: () => void;
  };
}

const ParticipantSelect: React.FC<IProps> = ({
  className,
  selectedParticipants,
  actions,
}) => {
  const { unlink, remove, select } = actions ?? {};

  const [removeModalOpened, setRemoveModalOpened] = React.useState(false);
  const [unlinkModalOpened, setUnlinkModalOpened] = React.useState(false);
  const len = selectedParticipants.length;
  const isCustomerScheduling =
    selectedParticipants.length === 1 &&
    (selectedParticipants[0].id === -1 ||
      selectedParticipants[0].type === "CUSTOMER_AS_PARTICIPANT");

  const participantNames = isCustomerScheduling
    ? "(You)"
    : selectedParticipants
        .slice(0, 2)
        .map(({ full_name }) => full_name)
        .join(", ");

  return (
    <div className={cn(styles.select, className)}>
      <div
        onClick={select}
        className={classNames(
          "flex items-center",
          select && "group cursor-pointer"
        )}
      >
        <MultiAvatar participants={selectedParticipants} />
        <div style={{ width: actions == null ? 250 : 216 }} className="ml-3">
          <div
            className={cn(styles.name, len > 1 ? "text-main_sb" : "text-h3")}
          >
            <div>{participantNames}</div>
            {len === 1 && !isCustomerScheduling && (
              <i className="icon edit-icon ml-2 h-4 w-4 bg-main-color group-hover:bg-dark-main-color" />
            )}
            {len > 2 && <div className={styles.plus}>+{len - 2}</div>}
          </div>
          {len > 1 && (
            <div
              className={
                "typography-small__t flex items-center text-gray-text-color"
              }
            >
              <i className={"icon connect-icon mr-[2px]"} />
              Participants x{len}
              {select && (
                <i className="icon edit-icon ml-[6px] h-4 w-4 bg-main-color group-hover:bg-dark-main-color" />
              )}
            </div>
          )}
        </div>
      </div>
      {actions &&
        (len > 1 ? (
          <Button kind={"text"} onClick={() => setUnlinkModalOpened(true)}>
            Unlink
          </Button>
        ) : (
          <Button
            className="!typography-label "
            kind={"text"}
            color={"red"}
            onClick={() => setRemoveModalOpened(true)}
          >
            Remove
          </Button>
        ))}

      {removeModalOpened && remove && (
        <RemoveModal
          onClose={() => setRemoveModalOpened(false)}
          remove={remove}
        />
      )}
      {unlinkModalOpened && unlink && (
        <UnlinkModal
          unlink={unlink}
          onClose={() => setUnlinkModalOpened(false)}
        />
      )}
    </div>
  );
};

interface IRemoveModalProps {
  onClose: () => void;
  remove: () => void;
}

const RemoveModal: React.FC<IRemoveModalProps> = ({ onClose, remove }) => {
  return (
    <Modal
      className={styles.confirmModal}
      onClose={onClose}
      headerText={"Remove Participant"}
      containerClassName="min-w-[366px] max-w-[414px]"
    >
      <div className={cn("text-h3", styles.modalBody)}>
        {`If you choose to REMOVE this participant, any selections attached to them will clear.
You will need to reselect the participant and their dates again.`}
      </div>
      <div className={styles.modalButtons}>
        <Button kind={"secondary"} size={"big"} onClick={onClose}>
          Cancel
        </Button>
        <Button size={"big"} onClick={remove}>
          Remove
        </Button>
      </div>
    </Modal>
  );
};

interface IUnlinkModalProps {
  onClose: () => void;
  unlink: () => void;
}

const UnlinkModal: React.FC<IUnlinkModalProps> = (props) => {
  return (
    <Modal
      className={styles.confirmModal}
      onClose={props.onClose}
      headerText={"Unlink Participants"}
      containerClassName="min-w-[366px] max-w-[414px]"
    >
      <div className={cn("text-h3", styles.modalBody)}>
        If you continue, you will be able to schedule each participant
        separately.
        <br />
        <br />
        Relinking is only available when starting a new reservation.
      </div>
      <div className={styles.modalButtons}>
        <Button kind={"secondary"} size={"big"} onClick={props.onClose}>
          Cancel
        </Button>
        <Button size={"big"} onClick={props.unlink}>
          <i className={"icon unlink-icon"} />
          Unlink
        </Button>
      </div>
    </Modal>
  );
};
export default ParticipantSelect;
