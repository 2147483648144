import React, { useMemo } from "react";
import useVM from "src/hooks/useVM";
import useStores from "src/hooks/useStores";
import { action, computed, makeObservable, observable } from "mobx";
import { ROUTES } from "src/stores/RouterStore";
import { AuthStore, PaymentStore, RouterStore } from "src/stores";
import api, {
  ICreateCreditOrderItemDTO,
  ICreditTypeDTO,
} from "src/services/api";
import FunboxStore from "../../stores/FunboxStore";

import { computedFn } from "mobx-utils";
import notificator from "src/services/systemNotifications/notificationCenterService";
import {
  applyDiscountPercent,
  roundToCents,
} from "@sizdevteam1/funjoiner-uikit";

interface IModifier {
  min_quantity: number;
  discountPercent: number;
}

export interface ICreditGroup {
  creditType: ICreditTypeDTO;
  quantity: number;
  discountPercent: number;
}

export class FlexiblePaymentsPageVM {
  constructor(
    private funboxStore: FunboxStore,
    private paymentStore: PaymentStore,
    private routerStore: RouterStore,
    private authStore: AuthStore
  ) {
    if (
      this.routerStore.searchParams.action === "proceed" &&
      this.selectedCreditGroups.length > 0
    ) {
      this.toCheckout();
    }
    makeObservable(this);
  }

  @computed
  get selectedCreditGroups(): { creditTypeId: number; quantity: number }[] {
    try {
      const selectedCreditGroups: {
        credit_type_id: number;
        quantity: number;
      }[] = Array.from(
        JSON.parse(this.routerStore.searchParams.selected_credit_types)
      );

      if (
        !selectedCreditGroups.every(
          (cr) => cr.credit_type_id !== null && cr.quantity !== null
        )
      ) {
        return [];
      } else
        return selectedCreditGroups.map((cr) => ({
          creditTypeId: cr.credit_type_id,
          quantity: cr.quantity,
        }));
    } catch {
      return [];
    }
  }

  @computed get creditGroupsToBuy() {
    return this.creditGroups.filter((gr) => gr.quantity > 0);
  }

  @computed get creditGroupsToBuyTotal() {
    return this.creditGroupsToBuy.reduce((total, gr) => {
      const discountedPrice = applyDiscountPercent(
        gr.creditType.price * gr.quantity,
        gr.discountPercent
      );
      const roundedAmount = roundToCents(discountedPrice);
      return total + roundedAmount;
    }, 0);
  }

  @computed get programCreditGroups() {
    return this.creditGroups.filter((gr) => gr.creditType.is_program_credit);
  }

  @computed get sessionCreditGroups() {
    return this.creditGroups.filter((gr) => !gr.creditType.is_program_credit);
  }

  getDiscountPercent = computedFn((creditTypeId: number, quantity: number) => {
    const creditType = this.funboxStore.creditTypes.find(
      (ct) => ct.id === creditTypeId
    );
    if (!creditType) return 0;
    const modifiers: IModifier[] = creditType.modifiers
      .slice()
      .sort((a, b) => a.min_quantity - b.min_quantity)
      .map((m) => ({
        min_quantity: m.min_quantity,
        discountPercent: m.discount_percent,
      }));

    let discountPercent = 0;

    modifiers.forEach((m) => {
      if (m.min_quantity <= quantity) {
        discountPercent = m.discountPercent;
      }
    });

    return discountPercent;
  });

  @action.bound changeCreditQuantity(
    credit_type_id: number,
    action: "increment" | "decrement"
  ) {
    const group = this.creditGroups.find(
      (gr) => gr.creditType.id === credit_type_id
    );
    if (!group) {
      return;
    }
    group.quantity =
      action === "increment" ? group.quantity + 1 : group.quantity - 1;
    group.discountPercent = this.getDiscountPercent(
      group.creditType.id,
      group.quantity
    );
    if (this.creditGroups.filter((gr) => gr.quantity > 0).length === 0) {
      this.routerStore.setSearchParam("selected_credit_types", undefined, true);
      return;
    }

    this.routerStore.setSearchParam(
      "selected_credit_types",
      JSON.stringify(
        this.creditGroups
          .filter((gr) => gr.quantity > 0)
          .map((gr) => ({
            credit_type_id: gr.creditType.id,
            quantity: gr.quantity,
          }))
      ),
      true
    );
  }

  @observable loading = false;

  @action
  private placeOrder = async (data: ICreateCreditOrderItemDTO[]) => {
    this.loading = true;
    try {
      const order = await api.orders.place(data);
      this.paymentStore.resetAvailablePaymentPlans();
      this.paymentStore.setIncompleteOrder(order);
    } catch (e) {
      notificator.error("Error!", e);
    } finally {
      this.loading = false;
    }
  };

  @action.bound async toCheckout() {
    const orderPayload: ICreateCreditOrderItemDTO[] =
      this.creditGroupsToBuy.map((gr) => ({
        credit_type_id: gr.creditType.id,
        quantity: gr.quantity,
      }));
    if (!this.authStore.loggedIn) {
      const callbackUrl = `${
        ROUTES.FLEXIBLE_PAYMENTS
      }?selected_credit_types=${JSON.stringify(
        this.creditGroupsToBuy.map((gr) => ({
          credit_type_id: gr.creditType.id,
          quantity: gr.quantity,
        }))
      )}&action=proceed`;
      this.routerStore.navigate(ROUTES.SIGN_IN, {
        from: callbackUrl,
      });
      return;
    }
    await this.placeOrder(orderPayload);

    this.routerStore.setSearchParam("action", undefined, true);
    this.routerStore.navigate(ROUTES.CHECKOUT);
  }

  @computed private get creditGroups(): ICreditGroup[] {
    return this.funboxStore.creditTypes
      .filter((ct) => !ct.is_free && !ct.is_hidden_from_flexible_payments_page)
      .map((ct) => {
        const alreadySelectedCredit = this.selectedCreditGroups.find(
          (cr) => cr.creditTypeId === ct.id
        );
        return {
          creditType: ct,
          quantity: alreadySelectedCredit?.quantity ?? 0,
          discountPercent: alreadySelectedCredit
            ? this.getDiscountPercent(
                alreadySelectedCredit.creditTypeId,
                alreadySelectedCredit.quantity
              )
            : 0,
        };
      });
  }
}

const ctx = React.createContext<FlexiblePaymentsPageVM | null>(null);

export const FlexiblePaymentsPageVMProvider: React.FC<{}> = ({ children }) => {
  const { funboxStore, paymentStore, routerStore, authStore } = useStores();

  const vm = useMemo(
    () =>
      new FlexiblePaymentsPageVM(
        funboxStore,
        paymentStore,
        routerStore,
        authStore
      ),
    [funboxStore, paymentStore, routerStore, authStore]
  );

  return <ctx.Provider value={vm}>{children}</ctx.Provider>;
};

export const useFlexiblePaymentsPageVM = () => useVM(ctx);
