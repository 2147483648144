import React from "react";
import SizedBox from "src/components/SizedBox";
import FlexContainer from "src/components/FlexContainer";
import { ICreditOrderItemDTO } from "../../../../services/api/orders";
import { CreditIcon } from "@sizdevteam1/funjoiner-uikit";

const Credit: React.FC<{ data: ICreditOrderItemDTO }> = ({ data }) => {
  return (
    <FlexContainer
      className="gap-2"
      key={data.id}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <FlexContainer
        className="gap-[6px]"
        justifyContent="space-between"
        alignItems={"center"}
      >
        <div className={"text-main"}>{data.credit_type.name}</div>
        <div
          className={"text-small"}
          style={{ color: "var(--gray-text-color)" }}
        >
          {data.credit_type.is_free && "Free "}
          {data.credit_type.is_program_credit ? "Program" : "Session"}
        </div>
      </FlexContainer>
      <div className="flex">
        <CreditIcon
          isProgram={data.credit_type.is_program_credit}
          isFree={data.credit_type.is_free}
          size={24}
        />
        <SizedBox width={2} />
        <div className={"typography-main"}>×&nbsp;{data.quantity}</div>
      </div>
    </FlexContainer>
  );
};

export default Credit;
