import { SessionInProgram } from "../services/api/availability";
import React from "react";
import useStores from "../hooks/useStores";
import cn from "classnames";
import dayjs from "dayjs";
import formatStartEndTime from "../util/formatStartEndTime";
import { ISOString } from "@sizdevteam1/funjoiner-uikit";
import formatStartEndDate from "src/util/formatStartEndDate";

interface ProgramInfoCardProps {
  className?: string;
  scheduleSetName: string;
  programInfo: {
    start_date: ISOString;
    end_date: ISOString;
    location_id: number;
    program_type_name: string;
    sessions?: SessionInProgram[];
  };
}

export const ProgramInfoCard: React.FC<ProgramInfoCardProps> = ({
  programInfo,
  scheduleSetName,
  className,
}) => {
  const { commonStore } = useStores();
  return (
    <div className={cn("overflow-auto rounded-[10px] shadow-big", className)}>
      <div className={"space-y-2 bg-on-main-color p-4"}>
        <div className={"typography-h3 text-header-color"}>
          {scheduleSetName}
        </div>
        <div
          className={"typography-main flex  items-center text-gray-text-color"}
        >
          <i className={"icon locations-icon mr-1"} />
          {commonStore.locationsById[programInfo.location_id]?.name}
          <span className={"typography-label ml-auto"}>
            {programInfo.program_type_name}
          </span>
        </div>
        <div className="typography-main text-center text-text-color">
          {formatStartEndDate({
            start_date: programInfo.start_date,
            end_date: programInfo.end_date,
          })}
        </div>
      </div>
      <div>
        {"sessions" in programInfo &&
          programInfo.sessions != null &&
          programInfo.sessions.map((session) => (
            <div
              key={session.id}
              className={
                "typography-main flex py-2 px-4 text-text-color even:bg-on-main-color"
              }
            >
              {dayjs(session.date).format("MMM Do")}
              <span className={"ml-auto text-gray-text-color"}>
                {formatStartEndTime(session)}
              </span>
            </div>
          ))}
      </div>
    </div>
  );
};
