import React from "react";
import cn from "classnames";
import styles from "../Checkout.module.scss";
import FlexContainer from "src/components/FlexContainer";
import PackageDiscount from "./PackageDiscount";
import { ICalculatedOrderDTO } from "../../../../services/api/orders";
import AddPromocodeButton from "./AddPromocodeButton/AddPromocodeButton";
import CreditRow from "src/components/CreditRow";
import Subtotal from "./Subtotal";
import Fee from "./Fee";
import { PriceWithCents } from "@sizdevteam1/funjoiner-uikit";

interface IProps {
  order: ICalculatedOrderDTO;
}

const PurchaseCreditsSection: React.FC<IProps> = ({ order }) => {
  return (
    <section className={cn(styles.Section)}>
      <div className={cn("text-h3", styles.title)}>Purchase Credits</div>
      {order.items.map((item) =>
        item.type === "credit_item" ? (
          <CreditRow
            key={item.id}
            is_free={item.credit_type.is_free}
            price={item.credit_type.price}
            credit_type_id={item.credit_type.id}
            is_program_credit={item.credit_type.is_program_credit}
            quantity={item.quantity}
            name={item.credit_type.name}
          />
        ) : null
      )}
      {order.discount !== 0 && (
        <>
          <PackageDiscount discount={order.discount} />
          <Subtotal
            promoDiscount={order.promocode_discount}
            finalPrice={order.final_price_without_fees}
          />
        </>
      )}
      {order.final_price_without_fees > 0 && <AddPromocodeButton />}
      <div className="flex flex-col gap-3 py-3">
        {[...order.own_fees, ...order.installment_fees].map((f) => (
          <Fee key={f.id} fee={f} />
        ))}
        <div className="h-[1px] bg-separator-color"></div>
        <FlexContainer
          className={"box-border px-4"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <div className={"text-main_sb text-text-color"}>Final Price</div>
          <div className={"text-h2 text-header-color"}>
            <PriceWithCents
              amount={order.final_price}
              prefix="$"
              typography="typography-h2"
            />
          </div>
        </FlexContainer>
      </div>
    </section>
  );
};

export default PurchaseCreditsSection;
