import {
  action,
  computed,
  IComputedValue,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from "mobx";
import dayjs from "dayjs";
import api, {
  IAvailableDaycampScheduleSet,
  IAvailableDaycampSession,
  IAvailableOvernightScheduleSet,
  IFunboxDTO,
} from "src/services/api";
import * as Sentry from "@sentry/react";
import { IGetAvailabilityDTO } from "src/services/api/availability";
import { ICancelablePromise } from "@sizdevteam1/funjoiner-web-api";
import notificator from "../../../services/systemNotifications/notificationCenterService";
import { ISOString } from "@sizdevteam1/funjoiner-uikit/types";
import { isAbortError } from "@sizdevteam1/funjoiner-uikit";
import { MultiSelectVm } from "../../../models/MultiSelectVm";
import { IProgramTypeDTO } from "../../../services/api/common";
import { RouterStore } from "../../../stores";

export class AvailabilityVM {
  private disposers: (() => void)[] = [];
  public readonly filtersVM: FiltersVM;

  dispose = () => {
    this.disposers.forEach((dispose) => dispose());
  };
  private readonly _minDate: ISOString;

  constructor(
    routerStore: RouterStore,
    public readonly selectedFunbox: IComputedValue<IFunboxDTO>,
    private selectedLocationId: IComputedValue<number>,
    private for_page: "availability_page" | "schedule_page" | "reschedule_page",
    defaultStartMonth?: ISOString,
    minDate?: ISOString
  ) {
    this._minDate = minDate ?? dayjs().format("YYYY-MM-DD");
    this.selectedMonthDayJs = dayjs(this._minDate).startOf("month");
    this.filtersVM = new FiltersVM(selectedFunbox, routerStore);
    makeObservable(this);
    this.loadMonthsWithPrograms().then(() => {
      if (defaultStartMonth && defaultStartMonth >= this._minDate) {
        this.selectedMonthDayJs = dayjs(defaultStartMonth);
      }
      return this.getAvailability();
    });
    this.disposers.push(
      reaction(
        () => this.searchParams,
        (current, prev) => {
          if (
            current.funbox_id !== prev.funbox_id ||
            current.location_id !== prev.location_id
          ) {
            this.loadMonthsWithPrograms().then(this.getAvailability);
          } else {
            this.getAvailability();
          }
        }
      )
    );
    this.disposers.push(
      reaction(
        () => this.selectedFunbox.get(),
        this.filtersVM.resetSelectedProgramTypeIds
      )
    );
  }

  @action.bound refresh() {
    this.getAvailability();
  }

  @observable
  private locationMonthsWithPrograms: ISOString[] = [];

  @computed
  private get selectedLocationMonthsWithPrograms() {
    if (this.selectedLocationId == null) return [];
    return this.locationMonthsWithPrograms;
  }

  @observable
  availability: DaycampAvailability | OvernightAvailability =
    new DaycampAvailability([], []);

  @action.bound
  previousMonth() {
    const index = this.selectedLocationMonthsWithPrograms.indexOf(
      this.selectedMonth
    );
    this.selectedMonthDayJs = dayjs(
      this.selectedLocationMonthsWithPrograms[index - 1]
    );
  }

  @computed get hasNextMonth() {
    return this.selectedMonthDayJs
      .endOf("month")
      .isBefore(this.selectedLocationMaxProgramMonth);
  }
  @action.bound
  nextMonth() {
    const index = this.selectedLocationMonthsWithPrograms.indexOf(
      this.selectedMonth
    );
    this.selectedMonthDayJs = dayjs(
      this.selectedLocationMonthsWithPrograms[index + 1]
    );
  }

  @action.bound setCustomMonthDayJS(monthDayJs: dayjs.Dayjs) {
    this.selectedMonthDayJs = monthDayJs.startOf("month");
  }

  @observable
  private monthsLoading = false;
  @action
  private loadMonthsWithPrograms = async () => {
    this.monthsLoading = true;

    const r = await api.availability.monthsWithPrograms({
      ...this.searchParams,
      date_to: undefined,
      date_from: this._minDate,
    });
    runInAction(() => {
      this.locationMonthsWithPrograms = r;
      this.monthsLoading = false;
    });
    const minMonthWithSession = min(...this.selectedLocationMonthsWithPrograms);
    if (minMonthWithSession !== this.selectedMonthDayJs.format("YYYY-MM-DD")) {
      this.selectedMonthDayJs = dayjs(minMonthWithSession);
    }
  };

  @observable
  selectedMonthDayJs: dayjs.Dayjs;

  @computed
  get loading() {
    return this.availabilityLoading || this.monthsLoading;
  }

  @computed
  get isSomethingAvailable() {
    if (this.for_page === "availability_page")
      return this.availability.scheduleSets.length > 0;
    else {
      return this.availability.type === "daycamp"
        ? this.availability.scheduleSets.length > 0 ||
            this.availability.sessions.length > 0
        : this.availability.scheduleSets.length > 0;
    }
  }

  get selectedFunboxType() {
    return this.selectedFunbox.get().type;
  }

  @computed
  get hasSelectedLocationPrograms() {
    return this.selectedLocationMonthsWithPrograms.length > 0;
  }

  @computed
  get selectedMonth() {
    return this.selectedMonthDayJs.format("YYYY-MM-DD");
  }
  @computed get minAvailableDate() {
    return min(...this.selectedLocationMonthsWithPrograms) ?? this._minDate;
  }
  @computed
  get selectedLocationMaxProgramMonth() {
    return max(...this.selectedLocationMonthsWithPrograms) || null;
  }

  private overnightPromise?: ICancelablePromise<
    IAvailableOvernightScheduleSet[]
  >;

  private dayCampPromise?: [
    ICancelablePromise<IAvailableDaycampScheduleSet[]>,
    ICancelablePromise<IAvailableDaycampSession[]>
  ];

  @computed.struct
  private get searchParams(): IGetAvailabilityDTO {
    const startOfMonth = this.selectedMonthDayJs.startOf("month");
    const from_dayjs = startOfMonth.isBefore(dayjs()) ? dayjs() : startOfMonth;

    return {
      date_from: from_dayjs.format("YYYY-MM-DD"),
      date_to: this.selectedMonthDayJs.endOf("month").format("YYYY-MM-DD"),
      location_id: this.selectedLocationId.get(),
      funbox_id: this.selectedFunbox.get().id,
      show_programs_without_program_credits_attached:
        (this.for_page === "availability_page" &&
          this.selectedFunbox.get().mode !== "PROGRAMS") ||
        undefined,
      view:
        this.for_page === "reschedule_page"
          ? "available"
          : this.filtersVM.searchParams.view,
      ...this.filtersVM.searchParams,
    };
  }

  @action
  private setEmptyAvailability() {
    this.availability =
      this.selectedFunboxType === "overnight_camps"
        ? new OvernightAvailability([])
        : new DaycampAvailability([], []);
  }

  @observable private availabilityLoading = false;
  @action.bound
  private async getAvailability() {
    const searchParams = { ...this.searchParams };

    if (
      searchParams.location_id == null ||
      this.locationMonthsWithPrograms.length === 0
    ) {
      this.setEmptyAvailability();
      return;
    }
    this.availabilityLoading = true;
    try {
      if (this.selectedFunboxType === "overnight_camps") {
        const availableOvernightSetsPromise =
          api.availability.getAvailableOvernightPrograms({
            searchParams,
          });

        if (
          this.overnightPromise &&
          availableOvernightSetsPromise !== this.overnightPromise
        ) {
          this.overnightPromise.cancel();
        }

        this.overnightPromise = availableOvernightSetsPromise;
        const availableOvernightSets = await availableOvernightSetsPromise;

        runInAction(() => {
          this.availability = new OvernightAvailability(availableOvernightSets);
          this.availabilityLoading = false;
        });
      } else {
        const availableDaySetsPromise =
          this.for_page === "schedule_page" &&
          this.selectedFunbox.get().mode === "SESSIONS"
            ? Object.assign(Promise.resolve([]), { cancel: () => {} })
            : api.availability.getAvailableDaycampPrograms({
                searchParams,
              });

        const availableSessionsPromise =
          this.for_page !== "availability_page" &&
          (this.selectedFunbox.get().mode === "SESSIONS" ||
            this.selectedFunbox.get().mode === "PROGRAMS_AND_SESSIONS")
            ? api.availability.getAvailableDaycampSessions({
                searchParams,
              })
            : // empty promise with cancel method to avoid errors
              Object.assign(Promise.resolve([]), { cancel: () => {} });

        if (
          this.dayCampPromise &&
          (availableDaySetsPromise !== this.dayCampPromise[0] ||
            availableSessionsPromise !== this.dayCampPromise[1])
        ) {
          this.dayCampPromise.forEach((p) => p.cancel());
        }

        this.dayCampPromise = [
          availableDaySetsPromise,
          availableSessionsPromise,
        ];

        const [availableScheduleSets, availableSessions] = await Promise.all([
          availableDaySetsPromise,
          availableSessionsPromise,
        ]);

        runInAction(() => {
          const filteredScheduleSets = availableScheduleSets
            .map((scheduleSet) => {
              const filteredPrograms = scheduleSet.programs.map((program) => {
                const filteredSessions = program.sessions.filter((session) => {
                  return (
                    !dayjs(session.date).isAfter(dayjs(searchParams.date_to)) &&
                    !dayjs(session.date).isBefore(dayjs(searchParams.date_from))
                  );
                });

                return {
                  ...program,
                  sessions: filteredSessions,
                };
              });

              return {
                ...scheduleSet,
                programs: filteredPrograms,
              };
            })
            .filter((scheduleSet) => scheduleSet.programs.length > 0);

          this.availability = new DaycampAvailability(
            this.selectedFunbox.get().type === "classes"
              ? filteredScheduleSets
              : availableScheduleSets,
            availableSessions
          );
          this.availabilityLoading = false;
        });
      }
    } catch (e) {
      if (!isAbortError(e)) {
        console.error(e);
        notificator.error("Failed to load availability");
        this.setEmptyAvailability();
        this.availabilityLoading = false;
        Sentry.captureException(e);
      }
    }
  }
}

type FiltersState = {
  allOrAvailable: "all" | "available";
  includeOpenApplications: boolean;
  includeOpenWaitlists: boolean;
  selectedProgramTypeIds: number[] | undefined;
  isAgeFilterEnabled: boolean;
  fromAge: number;
  toAge: number;
};
export class FiltersVM {
  constructor(
    public readonly funbox: IComputedValue<IFunboxDTO>,
    private readonly routerStore: RouterStore
  ) {
    makeObservable(this);
    this.localState = this._createLocalState();
  }

  get isSelectedFunboxForCustomers() {
    return this.funbox.get().participants_or_customers === "CUSTOMERS";
  }
  @computed get appliedFilterCount() {
    let n = 0;
    if (this.appliedState.selectedProgramTypeIds) n++;
    if (this.appliedState.isAgeFilterEnabled) n++;
    if (this.appliedState.allOrAvailable === "available") n++;
    if (this.appliedState.includeOpenApplications) n++;
    if (this.appliedState.includeOpenWaitlists) n++;
    return n;
  }

  @observable private _isOpen = false;
  @computed get isOpen() {
    return this._isOpen;
  }
  @action.bound open() {
    this.localState = this._createLocalState();
    this._isOpen = true;
  }
  @action.bound close() {
    this._isOpen = false;
    this.localState = this._createLocalState();
  }

  @action.bound apply() {
    this.routerStore.setSearchParam(
      "allOrAvailable",
      this.localState.allOrAvailable,
      true
    );
    this.routerStore.setSearchParam(
      "includeOpenApplications",
      this.localState.allOrAvailable === "available" &&
        this.localState.includeOpenApplications
        ? "true"
        : undefined,
      true
    );
    this.routerStore.setSearchParam(
      "includeOpenWaitlists",
      this.localState.allOrAvailable === "available" &&
        this.localState.includeOpenWaitlists
        ? "true"
        : undefined,
      true
    );
    this.routerStore.setSearchParam(
      "selectedProgramTypeIds",
      this.localState.programTypeIdsVm.payload === "all"
        ? undefined
        : this.localState.programTypeIdsVm.payload.join(","),
      true
    );
    this.routerStore.setSearchParam(
      "isAgeFilterEnabled",
      this.localState.allOrAvailable === "available" &&
        this.localState.isAgeFilterEnabled
        ? "true"
        : undefined,
      true
    );
    this.routerStore.setSearchParam(
      "fromAge",
      this.localState.isAgeFilterEnabled
        ? this.localState.fromAge.toString()
        : undefined,
      true
    );
    this.routerStore.setSearchParam(
      "toAge",
      this.localState.isAgeFilterEnabled
        ? this.localState.toAge.toString()
        : undefined,
      true
    );
    this._isOpen = false;
  }

  @computed get allOrAvailable() {
    return this.localState.allOrAvailable;
  }
  @action.bound setAllOrAvailable(value: "all" | "available") {
    this.localState.allOrAvailable = value;
  }

  @computed get includeOpenApplications() {
    return this.localState.includeOpenApplications;
  }

  @action.bound setIncludeOpenApplications(includeOpenApplications: boolean) {
    this.localState.includeOpenApplications = includeOpenApplications;
  }

  @computed get includeOpenWaitlists() {
    return this.localState.includeOpenWaitlists;
  }
  @action.bound setIncludeOpenWaitlists(includeOpenWaitlists: boolean) {
    this.localState.includeOpenWaitlists = includeOpenWaitlists;
  }

  @computed get isAgeFilterEnabled() {
    return this.localState.isAgeFilterEnabled;
  }
  @action.bound toggleAgeFilter() {
    this.localState.isAgeFilterEnabled = !this.localState.isAgeFilterEnabled;
  }

  @computed get fromAge() {
    return this.localState.fromAge;
  }
  @computed get toAge() {
    return this.localState.toAge;
  }

  @computed get programTypeVm() {
    return this.localState.programTypeIdsVm;
  }

  @action.bound setAges(v1: number, v2: number) {
    this.localState.fromAge = v1;
    this.localState.toAge = v2;
  }

  @computed.struct
  get searchParams() {
    let result: {
      from_age?: number;
      to_age?: number;
      program_type_ids?: number[];
      view?: "all" | "available";
      include_waitlist?: boolean;
      include_applications?: boolean;
    } = {
      program_type_ids: this.appliedState.selectedProgramTypeIds,
    };
    if (this.appliedState.allOrAvailable === "available") {
      result = {
        ...result,
        view: "available",
        include_waitlist: this.appliedState.includeOpenWaitlists,
        include_applications: this.appliedState.includeOpenApplications,
      };
      if (this.appliedState.isAgeFilterEnabled) {
        result = {
          ...result,
          from_age:
            this.appliedState.fromAge === 19 ? 18 : this.appliedState.fromAge,
          to_age:
            this.appliedState.toAge === 19
              ? undefined
              : this.appliedState.toAge,
        };
      }
    }
    return result;
  }

  // this is used for local filtering
  @computed get allOrAvailableApplied() {
    return this.appliedState.allOrAvailable;
  }

  @computed private get appliedState(): FiltersState {
    return {
      allOrAvailable:
        this.routerStore.searchParams["allOrAvailable"] === "available"
          ? "available"
          : "all",
      includeOpenApplications:
        this.routerStore.searchParams["includeOpenApplications"] === "true",
      includeOpenWaitlists:
        this.routerStore.searchParams["includeOpenWaitlists"] === "true",

      selectedProgramTypeIds: this.selectedProgramTypeIds,
      isAgeFilterEnabled:
        this.routerStore.searchParams["isAgeFilterEnabled"] === "true",
      fromAge: +this.routerStore.searchParams["fromAge"] || 3,
      toAge: +this.routerStore.searchParams["toAge"] || 18,
    };
  }
  @action.bound resetSelectedProgramTypeIds() {
    this.routerStore.setSearchParam("selectedProgramTypeIds", undefined, true);
  }
  @computed private get selectedProgramTypeIds() {
    const strValue = this.routerStore.searchParams["selectedProgramTypeIds"];
    try {
      return strValue.split(",").map((e) => parseInt(e));
    } catch {
      return undefined;
    }
  }
  @observable private localState: LocalFiltersVm;
  private _createLocalState() {
    return new LocalFiltersVm(
      { ...this.appliedState },
      computed(() => this.funbox.get().active_program_types)
    );
  }
}

class LocalFiltersVm {
  constructor(
    appliedState: FiltersState,
    availableProgramTypes: IComputedValue<IProgramTypeDTO[]>
  ) {
    makeObservable(this);
    this.programTypeIdsVm = new MultiSelectVm({
      availableOptions: availableProgramTypes,
      supportsAll: true,
      allOption: "All Offerings",
      initialValue: appliedState.selectedProgramTypeIds ?? "all",
    });
    this.allOrAvailable = appliedState.allOrAvailable;
    this.includeOpenApplications = appliedState.includeOpenApplications;
    this.includeOpenWaitlists = appliedState.includeOpenWaitlists;
    this.isAgeFilterEnabled = appliedState.isAgeFilterEnabled;
    this.fromAge = appliedState.fromAge;
    this.toAge = appliedState.toAge;
  }

  toFiltersState(): FiltersState {
    const programTypeIds = this.programTypeIdsVm.payload;
    return {
      allOrAvailable: this.allOrAvailable,
      includeOpenApplications: this.includeOpenApplications,
      includeOpenWaitlists: this.includeOpenWaitlists,
      selectedProgramTypeIds:
        programTypeIds === "all" ? undefined : programTypeIds,
      isAgeFilterEnabled: this.isAgeFilterEnabled,
      fromAge: this.fromAge,
      toAge: this.toAge,
    };
  }

  programTypeIdsVm: MultiSelectVm<number>;
  @observable allOrAvailable: "all" | "available";
  @observable includeOpenApplications: boolean;
  @observable includeOpenWaitlists: boolean;
  @observable isAgeFilterEnabled: boolean;
  @observable fromAge: number;
  @observable toAge: number;
}
export class DaycampAvailability {
  type = "daycamp" as const;
  constructor(
    public scheduleSets: IAvailableDaycampScheduleSet[],
    public sessions: IAvailableDaycampSession[]
  ) {}
}

export class OvernightAvailability {
  type = "overnight" as const;
  constructor(public scheduleSets: IAvailableOvernightScheduleSet[]) {}
}

function min<T>(...args: T[]) {
  let result: T = args[0];
  for (let item of args) {
    if (item < result) {
      result = item;
    }
  }
  return result;
}
function max<T>(...args: T[]) {
  let result: T = args[0];
  for (let item of args) {
    if (item > result) {
      result = item;
    }
  }
  return result;
}
