import { Button, Modal, Select } from "@sizdevteam1/funjoiner-uikit";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";
import { IFunboxDTO, ILocationDTO } from "src/services/api";
import {
  SelectLocationModalVMProvider,
  useSelectLocationModalVM,
} from "./SelectLocationModalVM";

const SelectLocationModal = ({
  selectedFunbox,
  selectedLocation,
  onSelectLocation,
  onClose,
  isShowingViewMoreButton,
  useGlobalFolderSelector,
}: {
  selectedFunbox: IFunboxDTO;
  selectedLocation: ILocationDTO;
  onSelectLocation: (location_id: number) => void;

  onClose: () => void;
  isShowingViewMoreButton: boolean;
  useGlobalFolderSelector: boolean;
}) => {
  return (
    <SelectLocationModalVMProvider
      selectedFunbox={selectedFunbox}
      selectedLocation={selectedLocation}
      onSelectLocation={onSelectLocation}
      onClose={onClose}
      isShowingViewMoreButton={isShowingViewMoreButton}
      useGlobalFolderSelector={useGlobalFolderSelector}
    >
      <SelectLocationModalImpl />
    </SelectLocationModalVMProvider>
  );
};

const SelectLocationModalImpl = observer(() => {
  const vm = useSelectLocationModalVM();

  return (
    <Modal
      containerClassName="min-w-[366px] max-w-[414px] w-full !rounded-none !shadow-none select-none"
      contentClassName={"!p-6"}
      onClose={vm.onClose}
      alignment="top"
      closeOnBackDrop
    >
      <div className="flex">
        <i className="icon funbox-icon" />
        <div className="typography-main ml-1 w-[284px] overflow-hidden text-ellipsis whitespace-nowrap text-gray-text-color">
          {vm.selectedFunbox.name}
        </div>
        <i
          onClick={vm.onClose}
          className="icon close-icon ml-auto cursor-pointer"
        />
      </div>
      {vm.isAtLeastOneLocationFolderExist && vm.useGlobalFolderSelector && (
        <Select
          className="mt-4"
          inputContainerClassName="!pl-[26px]"
          renderSelected={() => (
            <div
              className={classNames(
                "typography-h2 flex w-full justify-center [&>div]:text-header-color [&>div>i]:hidden",
                vm.selectedLocationFolderOption.id === "all" && "mr-[30px]"
              )}
            >
              {vm.selectedLocationFolderOption.name}
            </div>
          )}
          options={vm.locationFolderOptions}
          onSelect={vm.selectLocationFolderOption}
        />
      )}
      <div className="mt-4 flex flex-col gap-4">
        {vm.funboxLocationsByFolder.map((l) => (
          <LocationTile
            key={l.id}
            location={l}
            selectLocation={() => vm.selectLocation(l)}
            isSelected={vm.selectedLocation.id === l.id}
          />
        ))}

        {vm.funboxLocationsByFolder.length === 0 && (
          <div className="flex flex-col gap-2 rounded-[10px] border-[1px] border-solid border-separator-color bg-card-color px-4 py-8 shadow-big">
            <div className="typography-main_sb text-center text-gray-text-color">
              No Locations in this Folder
            </div>
            {vm.selectedLocationFolderOption.id !== "all" && (
              <Button
                onClick={() =>
                  vm.selectLocationFolderOption({
                    name: <div>All Locations</div>,
                    id: "all",
                  })
                }
                kind="text"
              >
                See All Locations
              </Button>
            )}
          </div>
        )}
        {vm.isShowingViewMoreButton && (
          <div className="mx-6 flex items-center justify-between gap-6">
            <div className="h-[1px] w-full bg-separator-color"></div>
            <div className="typography-h4 text-placeholder-color">or</div>
            <div className="h-[1px] w-full bg-separator-color"></div>
          </div>
        )}
        {vm.isShowingViewMoreButton && (
          <Button
            className="mb-[14px]"
            onClick={vm.navigateToSelectFunboxPage}
            kind="text"
          >
            View More Options
          </Button>
        )}
      </div>
    </Modal>
  );
});

const LocationTile = ({
  location,
  selectLocation,
  isSelected,
}: {
  location: ILocationDTO;
  selectLocation: () => void;
  isSelected: boolean;
}) => {
  return (
    <div
      onClick={selectLocation}
      className={classNames(
        "box-border flex  cursor-pointer  gap-3 overflow-hidden text-ellipsis  rounded-[10px] border-[2px] border-solid bg-on-main-color p-5 shadow-big",
        isSelected ? "border-main-color" : "border-[1px] border-separator-color"
      )}
    >
      <i className="icon location-color-icon h-8 w-8 bg-main-color" />
      <div className="flex flex-col gap-1">
        <div className="typography-h3 text-header-color">{location.name}</div>
        <div className="typography-small__t text-gray-text-color">
          {location.address}
        </div>
      </div>
    </div>
  );
};

export default SelectLocationModal;
