import React from "react";
import IconTile from "./IconTile";
import { observer } from "mobx-react-lite";
import useStores from "../../hooks/useStores";
import { ROUTES } from "../../stores/RouterStore";

export const UnsignedDocumentsTile = observer(() => {
  const { routerStore } = useStores();

  return (
    <IconTile
      onClick={() => routerStore.navigate(ROUTES.DOCUMENTS)}
      title={"Documents Missing"}
      subtitle={
        <span>
          Please sign the required{" "}
          <span className="text-main-color hover:text-dark-main-color">
            documents
          </span>
        </span>
      }
      iconBgClassName={"bg-surface-orange-color"}
      iconClassName={"bg-on-main-color doc-blank-icon"}
    />
  );
});
