import { Button } from "@sizdevteam1/funjoiner-uikit";
import cn from "classnames";
import React, { useEffect, useState } from "react";
import styles from "./ScheduleDatesButton.module.scss";

interface IProps {
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  confirmSelectionClick: () => void;
  scheduleClick: () => void;
  isConfirmed: boolean;
}

const ScheduleDatesButton: React.FC<IProps> = ({
  className,
  disabled,
  confirmSelectionClick,
  scheduleClick,
  loading,
  children,
  isConfirmed,
}) => {
  const [fullButtonView, setFullButtonView] = useState(isConfirmed);

  useEffect(() => {
    (!isConfirmed || disabled) && setFullButtonView(false);
  }, [disabled, isConfirmed]);

  return (
    <Button
      autoLoading
      loading={loading}
      onClick={
        fullButtonView
          ? scheduleClick
          : () => {
              setFullButtonView(true);
              confirmSelectionClick();
            }
      }
      className={cn(
        disabled && styles.disabled,
        fullButtonView ? styles.fullButton : styles.circle,
        className
      )}
      disabled={disabled}
    >
      {fullButtonView ? (
        <span className="flex-shrink-0">{children}</span>
      ) : (
        <i className="icon out-icon !h-6 !w-6 rotate-180" />
      )}
    </Button>
  );
};

export default ScheduleDatesButton;
