import React from "react";
import styles from "./StickyFooter.module.scss";
import cn from "classnames";
interface IStickyFooterProps extends React.HTMLProps<HTMLDivElement> {}

const StickyFooter: React.FC<IStickyFooterProps> = ({
  className,
  children,
  ...rest
}) => {
  return (
    <div className={cn(styles.root, className)} {...rest}>
      {children}
    </div>
  );
};

export default StickyFooter;
