import {
  applyDiscountPercent,
  Button,
  CreditIcon,
  FlipCard,
  Label,
  pluralize,
  PriceWithCents,
  roundToCents,
  Splash,
} from "@sizdevteam1/funjoiner-uikit";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import PageHeader from "src/components/PageHeader";
import PageRoot from "src/components/PageRoot";

import {
  FlexiblePaymentsPageVM,
  FlexiblePaymentsPageVMProvider,
  ICreditGroup,
  useFlexiblePaymentsPageVM,
} from "./FlexiblePaymentsPageVM";
import useStores from "../../hooks/useStores";
import { Redirect } from "react-router-dom";
import { ROUTES } from "../../stores/RouterStore";

const FlexiblePaymentsPage = observer(() => {
  const { funboxStore } = useStores();
  if (funboxStore.isCreditTypesLoading) {
    return <Splash pathToAnimation={"/splash.json"} />;
  }
  if (funboxStore.selectedFunbox == null) {
    return <Redirect to={ROUTES.ROOT} />;
  }
  return (
    <FlexiblePaymentsPageVMProvider>
      <FlexiblePaymentsPageImpl />
    </FlexiblePaymentsPageVMProvider>
  );
});

const FlexiblePaymentsPageImpl = observer(() => {
  const vm = useFlexiblePaymentsPageVM();

  return (
    <div>
      <PageRoot className="!min-h-[calc(100dvh-238px)]">
        <PageHeader showBackLink>Flexible Payments</PageHeader>
        <div className="typography-main text-gray-text-color">
          This option allows you to purchase credits for scheduling present or
          future reservations:
        </div>
        <div className="mt-6 mb-6 flex flex-col gap-6">
          <ProgramCredits vm={vm} />
          <SessionCredits vm={vm} />
        </div>
      </PageRoot>
      {vm.creditGroupsToBuy.length > 0 && (
        <div className="sticky bottom-0 mx-auto  border-0  border-t-[1px] border-solid border-separator-color bg-on-main-color py-3">
          <div className="mx-auto flex w-[366px] flex-col">
            <Label>Purchase</Label>
            <div className="flex flex-col gap-1">
              {vm.creditGroupsToBuy.map((gr) => (
                <div key={gr.creditType.id} className="flex text-text-color">
                  <CreditIcon
                    isProgram={gr.creditType.is_program_credit}
                    isFree={gr.creditType.is_free}
                    size={24}
                  />
                  <div className="typography-h4 min-w-[34px] text-text-color">
                    ×&nbsp;{gr.quantity}
                  </div>
                  <div className="typography-h4 mx-[10px]  overflow-hidden text-ellipsis text-text-color">
                    {gr.creditType.name}
                  </div>
                  <PriceWithCents
                    className="ml-auto"
                    typography="typography-main_sb"
                    amount={roundToCents(
                      applyDiscountPercent(
                        gr.creditType.price * gr.quantity,
                        gr.discountPercent
                      )
                    )}
                    prefix="$"
                  />
                </div>
              ))}
            </div>
            {vm.creditGroupsToBuy.length > 1 && (
              <div>
                <div className="my-4 h-[1px] bg-separator-color"> </div>
                <div className="typography-main_sb flex justify-between text-text-color">
                  <div>Total</div>
                  <PriceWithCents
                    typography="typography-main_sb"
                    prefix="$"
                    amount={vm.creditGroupsToBuyTotal}
                  />
                </div>
              </div>
            )}
          </div>
          <Button
            loading={vm.loading}
            onClick={vm.toCheckout}
            className="mx-auto mt-4 w-full max-w-[366px]"
            size="big"
          >
            Proceed To Checkout
          </Button>
        </div>
      )}
    </div>
  );
});

const ProgramCredits = observer(({ vm }: { vm: FlexiblePaymentsPageVM }) => {
  if (vm.programCreditGroups.length === 0) return null;
  return (
    <div>
      <div className="typography-h2 text-header-color">Programs</div>
      <div className="mt-4 flex flex-col gap-5">
        {vm.programCreditGroups.map((gr) => (
          <CreditTypeGroup key={gr.creditType.id} group={gr} vm={vm} />
        ))}
      </div>
    </div>
  );
});

const SessionCredits = observer(({ vm }: { vm: FlexiblePaymentsPageVM }) => {
  if (vm.sessionCreditGroups.length === 0) return null;

  return (
    <div>
      <div className="typography-h2 text-header-color">Sessions</div>
      <div className="mt-4 flex flex-col gap-5">
        {vm.sessionCreditGroups.map((gr) => (
          <CreditTypeGroup key={gr.creditType.id} group={gr} vm={vm} />
        ))}
      </div>
    </div>
  );
});

const CreditTypeGroup = observer(
  ({ group, vm }: { group: ICreditGroup; vm: FlexiblePaymentsPageVM }) => {
    const [isFlipped, setIsFlipped] = useState(false);

    return (
      <FlipCard
        isFlipped={isFlipped}
        front={
          <div className="flex flex-col  overflow-hidden rounded-[10px]">
            <div className="flex flex-col gap-[6px] bg-on-main-color  py-3 px-4">
              <div className="flex  items-start justify-between gap-2">
                <div className="flex flex-col gap-[6px]">
                  <div className="flex gap-[6px]">
                    <CreditIcon
                      size={24}
                      isFree={group.creditType.is_free}
                      isProgram={group.creditType.is_program_credit}
                    />
                    <div className="typography-h3 max-w-[210px] text-header-color">
                      {group.creditType.name}
                    </div>
                  </div>
                  <div className="typography-small  flex items-center">
                    <div className="flex items-center gap-[6px]">
                      <PriceWithCents
                        className="text-header-color"
                        typography="typography-label"
                        prefix="$"
                        amount={applyDiscountPercent(
                          group.creditType.price,
                          group.discountPercent
                        )}
                      />
                      {group.discountPercent > 0 && (
                        <PriceWithCents
                          className="text-placeholder-color line-through decoration-gray-text-color"
                          typography="typography-small"
                          prefix="$"
                          amount={group.creditType.price}
                        />
                      )}
                    </div>

                    {group.creditType.modifiers.length > 0 && (
                      <div className="flex">
                        <div className="mx-[10px] h-4 w-[1px] bg-separator-color"></div>
                        <Button
                          className="!typography-label h-4"
                          onClick={() => setIsFlipped(true)}
                          kind="text"
                        >
                          View Discounts
                          <i className="icon arrow-right-icon ml-[4px] !mr-0" />
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
                {group.quantity === 0 ? (
                  <Button
                    color={
                      group.creditType.is_program_credit ? "main" : "darkOrange"
                    }
                    className={`h-10`}
                    onClick={() =>
                      vm.changeCreditQuantity(group.creditType.id, "increment")
                    }
                    kind="outlined"
                  >
                    Add
                  </Button>
                ) : (
                  <div className="flex h-[40px] items-center gap-2">
                    <Button
                      color={
                        group.creditType.is_program_credit
                          ? "main"
                          : "darkOrange"
                      }
                      onClick={() =>
                        vm.changeCreditQuantity(
                          group.creditType.id,
                          "decrement"
                        )
                      }
                      kind="icon"
                    >
                      <i className={"icon partial-icon"} />
                    </Button>
                    <div
                      className={classNames(
                        "typography-h3 min-w-[12px] text-center",
                        group.creditType.is_program_credit
                          ? "text-main-color"
                          : "text-surface-orange-dark-color"
                      )}
                    >
                      {group.quantity}
                    </div>

                    <Button
                      color={
                        group.creditType.is_program_credit
                          ? "main"
                          : "darkOrange"
                      }
                      onClick={() =>
                        vm.changeCreditQuantity(
                          group.creditType.id,
                          "increment"
                        )
                      }
                      kind="icon"
                    >
                      <i className={"icon add-icon"} />
                    </Button>
                  </div>
                )}
              </div>
            </div>
            <div className="typography-small__t  bg-table-row-color px-4 py-3 text-gray-text-color">
              {group.creditType.description}
            </div>
          </div>
        }
        renderBack={(style) => (
          <div
            style={style}
            className="flex h-full flex-col justify-between gap-1 rounded-[10px] bg-on-main-color px-4 py-3"
          >
            <div className="flex flex-col gap-[6px]">
              {group.creditType.modifiers.map((m, index) => (
                <div key={index} className="flex justify-between">
                  <div className="typography-main text-text-color">
                    {m.min_quantity}+{" "}
                    {group.creditType.is_program_credit ? "Program" : "Session"}
                    &nbsp;
                    {pluralize("Credit", m.min_quantity)}
                  </div>
                  <div className="typography-main_sb flex items-center gap-1 text-right text-icon-dark-green-color">
                    <div className="typography-main text-gray-text-color">
                      Save Up To
                    </div>
                    {m.discount_percent}%
                  </div>
                </div>
              ))}
            </div>
            <Button
              onClick={() => setIsFlipped(false)}
              className="!typography-label mx-auto"
              kind="text"
            >
              <i className="icon back-icon" />
              Back
            </Button>
          </div>
        )}
      />
    );
  }
);

export default FlexiblePaymentsPage;
